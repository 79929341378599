export function OfferDto({
    name,
    assetId,
    type = "gatedAsset",
    isActive = 0,
    status = "underReview",
    statusText = null,
    thankYouPage,
    clientId
} = {
    name: null,
    assetId: null,
    campaign: null,
    type: "gatedAsset",
    isActive: 0,
    status: "underReview",
    statusText: null,
    thankYouPage: null,
    clientId: null,
})
{
    return {
        name,
        assetId,
        clientId,
        type,
        isActive,
        status,
        statusText,
        thankYouPageUrl: thankYouPage
    };
}