import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { LandingPage} from "@/models/landing-page/landingPage";
import { LandingPageDto } from "@/models/landing-page/landingPageDto";
import { removeEmpty } from "@/helpers/objectUtils";

export const landingPageDtoToObject = dto =>
{
    const object = flowRight(removeEmpty,LandingPage)(dto);
    return object;
};

export const objectToLandingPageDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, LandingPageDto)(data);
    return dto;
};
