export function ColumnPreset({
    name = "",
    columns = []
} = {})
{
    return {
        name,
        columns
    };
}
