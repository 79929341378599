<template>
  <div id="login-box">
    <div id="login-box-holder">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div
              v-if="msg.show"
              :class="['alert', msg.className]"
              role="alert"
            >
              The following error occurred: <br>
              <strong>{{ msg.text }}</strong>
              <br>
              Please try again.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <header class="login-header">
              <div class="login-logo">
                <img
                  src="@/assets/img/logo.svg"
                  alt="Genly"
                >
              </div>
            </header>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="login-forgot-password-container">
              <div class="login-box-inner logged-out">
                <h1 class="login-forgot-password-title text-center">
                  Reset your password
                </h1>
                <p>
                  Please enter the confirmation code you have
                  received along with a new password.
                </p>
                <form
                  class="form-forgot-password my-4"
                  autocomplete="off"
                  @submit.prevent
                >
                  <div
                    class="form-group"
                    :class="{ 'has-error': !isPasswordValid }"
                  >
                    <div class="styled-input">
                      <input
                        v-model="confirmationCode"
                        name="confirmation-code"
                        type="text"
                        autocomplete="new-password"
                        placeholder="Confirmation Code"
                      >
                      <i
                        class="styled-input__showpassword"
                        data-fa-i2svg
                      >
                        <font-awesome-icon icon="question" />
                      </i>
                    </div>

                    <password
                      v-model="newPassword"
                      @validation="isValid => isPasswordValid = isValid"
                    />

                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="loading || !isPasswordValid"
                        @click="changePassword"
                      >
                        Set new password
                      </button>
                    </div>
                    <div class="col-auto">
                      <router-link
                        id="login-forget-link"
                        :to="{ name: 'login' }"
                        class="forgot-link col-xs-12"
                      >
                        Back to login
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/mixins/errorHandler";
import {TOAST_HIDE} from "@/components/performance/constants";
import Password from '@/components/common/password/Password';
import { mapActions, mapMutations } from "vuex";
import mt from "@/store/mutationTypes";

export default {
    components: {
        Password
    },
    mixins: [errorHandler],
    data: function ()
    {
        return {
            confirmationCode: null,
            newPassword: '',
            msg: {
                className: '',   //alert-success, alert-info, alert-warning, alert-danger
                text: '',
                stack: '',
                show: false
            },
            isPasswordValid: false,
        };
    },
    computed:
        {
            loading()
            {
                return this.$store.state.isLoading;
            }
        },
    beforeDestroy()
    {
        this.setRedirectRoute('performance');
    },
    methods:
        {
            ...mapMutations({
                setRedirectRoute:mt.SetRedirectRoute,
            }),
            ...mapActions({
                setNewPasswordReset: "setNewPasswordReset"
            }),
            async changePassword()
            {
                this.msg.show = false;

                if (!this.isPasswordValid)
                {
                    this.msg.className = 'alert-warning';
                    this.msg.text = 'Your new password is not strong enough.';
                    this.msg.show = true;
                    return;
                }

                try
                {
                    await this.setNewPasswordReset({
                        confirmationCode: this.confirmationCode,
                        newPassword: this.newPassword
                    });

                    this.handleSuccess('Your password was successfully reset. Please use your new password to login.');
                    this.$router.push({ name: 'login' });
                }
                catch(error)
                {
                    // TODO: check the error. if it's about the session token expiring,
                    //      redirect to the login page with a message.

                    this.handleError(`There was an error ${ error }`);
                }
            }
        }
};
</script>

<style lang="scss" scoped>
#login-box-holder {
    background-color: #fff;
    min-height: 100vh;
    position: relative;

    .container-fluid {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.login-header {
    width: 100%;

    .login-logo {
        width: 445px;
        max-width: 100%;
        // height: 120px;
        margin: auto;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.form-forgot-password {
    margin: 0 auto;
    width: 290px;
}

.login-forgot-password-container {
    margin: 40px auto;
    max-width: 445px;

    .login-forgot-password-title {
        margin: 20px 0;
    }

    .login-forgot-password-form {
        width: 290px;
        margin: auto;
    }
}

.login-forgot-link-wrap {
    margin: 10px 0;
}

.forgot-link {
    color: #016392;
    font-size: 14px;
    font-weight: bold;
}

.login-forgot-submit-wrap {
    margin: 10px 0;
}

.help-block {
    margin-top: 16px;
    line-height: 16px !important;
    font-size: 12px;
    letter-spacing: .1px;
}
</style>
