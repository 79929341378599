import moment from "moment";

export function Comment({
    id = null,
    adCreativeId = null,
    commentText = null,
    createdBy = null,
    createdAt = null
})
{
    return {
        id,
        adCreativeId,
        commentText,
        createdBy,
        createdAt: createdAt ? moment(createdAt).format("YYYY-DD-MM hh:mm A") : null
    };
}