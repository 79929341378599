import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from '@/store/store';
import {removeEmpty} from "@/helpers/objectUtils";
import {get, post, put} from "@/services/httpService";

const resource = "/s/clients";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'clientPage');
    return {
        objectFilters,
        filterParams
    };
};

const fetchStats = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetch = id =>
{
    return get(`${resource}/${id}`);
};

const fetchAll = (payload = {}) =>
{
    return get(`${resource}`, {
        params: payload
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (id, payload = {}) =>
{
    return put(`${resource}/${id}`, payload);
};

export {
    fetch,
    fetchAll,
    fetchStats,
    create,
    update
};