function AdBlockerError(...args)
{
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}

AdBlockerError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        configurable: true,
        writable: true
    }
});

Reflect.setPrototypeOf(AdBlockerError, Error);

export default AdBlockerError;
