var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "login-box" } }, [
    _c("div", { attrs: { id: "login-box-holder" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.msg.show
              ? _c(
                  "div",
                  {
                    class: ["alert", _vm.msg.className],
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v("\n            The following error occurred: "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.msg.text))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n            Please try again.\n          "),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "login-forgot-password-container" }, [
              _c("div", { staticClass: "login-box-inner logged-out" }, [
                _c(
                  "h1",
                  { staticClass: "login-forgot-password-title text-center" },
                  [
                    _vm._v(
                      "\n                Reset your password\n              "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                Please enter the confirmation code you have\n                received along with a new password.\n              "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "form-forgot-password my-4",
                    attrs: { autocomplete: "off" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": !_vm.isPasswordValid },
                      },
                      [
                        _c("div", { staticClass: "styled-input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmationCode,
                                expression: "confirmationCode",
                              },
                            ],
                            attrs: {
                              name: "confirmation-code",
                              type: "text",
                              autocomplete: "new-password",
                              placeholder: "Confirmation Code",
                            },
                            domProps: { value: _vm.confirmationCode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.confirmationCode = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "styled-input__showpassword",
                              attrs: { "data-fa-i2svg": "" },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "question" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("password", {
                          on: {
                            validation: (isValid) =>
                              (_vm.isPasswordValid = isValid),
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "submit",
                              disabled: _vm.loading || !_vm.isPasswordValid,
                            },
                            on: { click: _vm.changePassword },
                          },
                          [
                            _vm._v(
                              "\n                      Set new password\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "forgot-link col-xs-12",
                              attrs: {
                                id: "login-forget-link",
                                to: { name: "login" },
                              },
                            },
                            [
                              _vm._v(
                                "\n                      Back to login\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("header", { staticClass: "login-header" }, [
          _c("div", { staticClass: "login-logo" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/logo.svg"), alt: "Genly" },
            }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }