export function LandingPageTarget({
    id = null,
    callToAction = "",
    callToActionText = "",
    description = "",
    headline = "",
    thankYouPageUrl = null,
    type = "",
    createdBy = null
} = {})
{
    return {
        id,
        callToAction,
        callToActionText,
        description,
        headline,
        thankYouPageUrl,
        type
    };
}
