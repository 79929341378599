import moment from "moment";
import projConfig from "@/projConfig";
import mt from "@/store/mutationTypes";
import EntityAdapter from "@/store/adapter";
import {fetch} from "@/repositories/BillingRepository";
import {billingDtoToObject} from "@/models/billing";
import {getTimezonesFromAbbreviation} from "@/services/formsDataService";

const state =
    {
        billingData: EntityAdapter().createEntityAdapter(),
        dateRange: {
            start: moment().startOf('year'),
            end: moment()
        }
    };

const mutations =
    {
        [mt.SetBillingData](state, data)
        {
            state.billingData = state.billingData.setAll(data, state.billingData);
        },
        [mt.RemoveBillingData](state, data)
        {
            state.billingData = state.billingData.removeOne(data, state.billingData);
        },
        [mt.SetBillingDateRangeFilter](state, dateRange)
        {
            state.dateRange.start = dateRange.start;
            state.dateRange.end = dateRange.end;
        },
    };

const getters =
    {
        getBillingDataIds: (state) => state.billingData.ids,
        getBillingDataEntities: (state) => state.billingData.entities,
        getBillingDataById: (state) => id => state.billingData.entities[id],
        getBillingData(state, getters)
        {
            return getters.getBillingDataIds.map(id => getters.getBillingDataEntities[id]);
        },
        getBillingDateRangeFilter(state, getters)
        {
            const timezone = getTimezonesFromAbbreviation(getters.getAPITimezone);

            const start = moment.tz(moment.utc(state.dateRange.start.format('YYYY-MM-DD')).format('YYYY-MM-DD'), timezone);
            const end = moment.tz(moment.utc(state.dateRange.end.format('YYYY-MM-DD')).clone().endOf("day").format(), timezone);
            return {
                start: start,
                end: end.clone().endOf("day")
            };
        },
    };

const actions =
    {
        async fetchClientsBillingStats({ commit},{ id })
        {
            try
            {
                commit(mt.SetLoading, true);
                const response = await fetch(id);

                if (response && response.status === 200 && response.data) 
                {
                    commit(mt.SetBillingData, [billingDtoToObject(response.data)]);
                }
                else
                {
                    commit(mt.SetBillingData,[]);
                    commit(mt.ClearTransactionHistoryData);
                    commit(mt.SetTransactionHistoryPaging,{
                        top: projConfig.itemsPerPage,
                        skip: 0,
                        totalCount: 0,
                        currentCount: 0,
                        currPage: 1
                    });
                }

            }
            catch(error)
            {
                throw error || new Error('');
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
    };

const BillingDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default BillingDataModule;
