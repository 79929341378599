import {Transactions} from "@/models/transactions/transactions";
import {flowRight} from "lodash";
import {removeEmpty} from "@/helpers/objectUtils";
import {TransactionsDto} from "@/models/transactions/transactionsDto";

export const transactionsDtoToObject = dto =>
{
    const object = flowRight(removeEmpty,Transactions)(dto);
    return object;
};
export const transactionsObjectToDto = transactionObject =>
{
    return TransactionsDto(transactionObject);
};
