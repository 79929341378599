import $store from "@/store/store";
import enums from "./constants";
import moment from "moment";
const DashboardPage = () => import('./dashboard/DashboardPage.vue');
const ClientPage = () => import('@/modules/performance/clients/ClientPage.vue');
const CampaignPage = () => import('@/modules/performance/campaign/CampaignPage.vue');
const OffersPage = () => import("./OffersPage.vue");
const AdGroupPage = () => import('@/modules/performance/adGroup/AdGroupPage.vue');
const AdCreativePage = () => import('@/modules/performance/adCreative/AdCreativePage.vue');

const isActiveFilter = routeName =>
{
    let activeFilter = $store.getters.getActiveFilter.find(
        fltr => fltr.type === routeName
    );
    return activeFilter ? activeFilter.value : null;
};

/**
 * Creates query parameters for each route
 * @param {string} routeName Name of the current route
 */
const updateQS = routeName =>
{
    const filters =
    {
        dateRange: $store.getters.getDateRange,
        clId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.CLIENT),
        cmpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.CAMPAIGN),
        offerId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.OFFER),
        lpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.LANDING_PAGE),
        netCmpGrpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD_GROUP),
        netCmpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.NETWORK_CAMPAIGN),
        adId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD),
        adCreativeId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD_CREATIVE),
        batchNum: $store.getters.getBatchCurrNumFilter,
        objectives: $store.getters.getObjectiveFilter,
        searchBy: $store.getters.getSearchBy,
        adStatuses: $store.getters.getAdStatuses,
        netCmpStatuses: $store.getters.getNetCmpStatuses,
        deliveryMetric: $store.getters.getMetricFilter,
        placements: $store.getters.getPlacementFilter,
        leadInfo: $store.getters.getLeadInfoFilter,
        isActive: isActiveFilter(routeName),
        verticals: $store.getters.getVerticalFilter
    };
    let query = {};
    const propsObjects = ['dateRange', 'client'];
    const filtersObj = ['deliveryMetric', 'placements', 'objectives', 'searchBy', 'leadInfo', 'verticals', 'adStatuses', 'netCmpStatuses'];

    for (let prop in filters)
        if (filters[prop])
            if (propsObjects.indexOf(prop) > -1)
            {
                if(prop === 'dateRange')
                {
                    const temp = {...filters[prop]};
                    temp.start = temp.start.format(moment.HTML5_FMT.DATE);
                    temp.end = temp.end.format(moment.HTML5_FMT.DATE);
                    query[prop] = JSON.stringify(temp);
                }
                else
                {
                    query[prop] = JSON.stringify(filters[prop]);
                }
            }
            else if (filtersObj.indexOf(prop) > -1)
            {
                let tempObj = {};
                Object.defineProperty(tempObj, prop, { value: {}, writable: true });

                for (let elem in filters[prop]['fields'])
                    if (filters[prop]['fields'][elem] != null)
                        tempObj[prop][elem] = filters[prop]['fields'][elem];

                if (Object.keys(tempObj[prop]) < 1) continue;

                query[prop] = JSON.stringify(tempObj[prop]);
            }
            else
            {query[prop] = filters[prop];}

    return query;
};

const beforeEnter = async(to, from, next) =>
{
    if (Object.keys(to.query).length === 0)
    {
        const query = updateQS(to.name);
        next({name: to.name, query});
    }
    else
    {
        next();
    }
};

const routes = [
    {
        name: "clientPage",
        path: 'clients',
        component: ClientPage,
        beforeEnter: beforeEnter
    },
    {
        name: "dashboardPage",
        path: 'dashboard',
        component: DashboardPage,
        beforeEnter: beforeEnter
    },
    {
        name: "campaignPage",
        path: 'campaigns',
        component: CampaignPage,
        beforeEnter: beforeEnter
    },
    {
        name: "offersPage",
        path: 'offers',
        component: OffersPage,
        beforeEnter: beforeEnter
    },
    {
        name: "adGroupPage",
        path: 'ad-group',
        component: AdGroupPage,
        beforeEnter: beforeEnter
    },
    {
        name: "networkAdRoot",
        path: 'network-ads',
        component: AdCreativePage,
        beforeEnter: beforeEnter
    }
];

export default routes;
