import {NUMBER_OF_ELEMENTS_ON_PAGE} from '@/components/hierachy/enums';
import Page from '@/models/Page';
import mt from '../mutationTypes';

const state =
{
    page: {}
};

const mutations =
{
    [mt.SetHierarchyPage](state, {id, page})
    {
        state.page[id] = {
            ...state.page[id],
            ...page
        };
    },
};

const getters =
{
    getHierarchyPage: state => id =>
    {
        return state.page[id] || new Page({
            top: NUMBER_OF_ELEMENTS_ON_PAGE
        });
    }
};

const actions = {};

const HierarchyDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default HierarchyDataModule;
