import {GroupAvailableColumns, GroupColumnsPages} from '@/modules/performance/services/columns/columnsConfig';
import {USER_GROUPS} from '@/store/constants';
import store from "@/store/store";

const AvailableTableColumns = (currentPage = null, filterForPage = false) =>
{
    let availableColumns = [];
    let page = currentPage;

    const setAvailableColumns = columns => availableColumns = columns;
    const getAvailableColumns = () => availableColumns;
    const userGroup = store.getters.getUserGroup;

    // used in presets
    const isColumnAvailable = (columnKey, unaccessableColumns = []) =>
    {
        if(unaccessableColumns.includes(columnKey)) return false;
        const hasAccessToColumn = canShowColumnForUserGroup(columnKey);
        // TODO: check if offer doesn't need it
        const canShowColumnOnPage = canShowColumnOnPageForUserGroup(columnKey, page);
        return (availableColumns.indexOf(columnKey) >= 0) && (hasAccessToColumn || canShowColumnOnPage);
    };
    
    // used in table
    const hasTableColumn = (columnKey, visibleColumns, unaccessableColumns = []) =>
    {
        if(availableColumns.length === 0) return false;
        if(unaccessableColumns.includes(columnKey)) return false;
        const settingsColumn = visibleColumns[columnKey];
        let canShowColumn = true;
        let isAvailableAsUserColumn = true;
        
        if(columnKey in visibleColumns)
        {
            canShowColumn = typeof settingsColumn === 'object' ? settingsColumn.value : settingsColumn;
            // remove the column e.g. clientCpm, clientCpl etc, if there is a priority column for it
            // it is set as notAvailableForCol property in column object
            const priorityColumn = getPriorityColumnForUserGroup(userGroup, visibleColumns[columnKey], columnKey) || null;
            const isPriorityColumnAvailable = availableColumns.indexOf(priorityColumn) > -1;
            isAvailableAsUserColumn = priorityColumn !== null ? !isPriorityColumnAvailable : true;
        }

        // TODO: refactor getters in this class
        const hasAccessToColumn = filterForPage ? canShowColumnForUserGroup(columnKey, page) : canShowColumnForUserGroup(columnKey);
        const canShowColumnOnPage = canShowColumnOnPageForUserGroup(columnKey, page);

        return availableColumns.indexOf(columnKey) >= 0 && canShowColumn && (hasAccessToColumn || canShowColumnOnPage) && isAvailableAsUserColumn;
    };
    
    function canShowColumnForUserGroup(columnKey, page = null)
    {
        if(userGroup === null) return false;
        
        // used to support different structure for columns for each user group on different pages
       
        let segment = GroupAvailableColumns[userGroup];
        if(!Array.isArray(segment))
        {
            if(page && GroupAvailableColumns[userGroup][page])
                segment = [...GroupAvailableColumns[userGroup].default, ...GroupAvailableColumns[userGroup][page]];
            
            if(segment.default)
                segment = segment.default;
        }
        
        if(segment.length === 0) return true; // empty columns means everything is available
        if(columnKey === "name") return true; // everyone has access to name column
        
        return segment.indexOf(columnKey) > -1;
    }
    
    function canShowColumnOnPageForUserGroup(columnKey, page)
    {
        if(userGroup === null) return false;
        return columnKey in GroupColumnsPages[userGroup] && GroupColumnsPages[userGroup][columnKey].indexOf(page) > -1;
    }
    
    function getPriorityColumnForUserGroup(userGroup, settingsColumn, columnKey)
    {
        if([USER_GROUPS.SYSTEM_ADMINISTRATORS, USER_GROUPS.ADMINISTRATORS, USER_GROUPS.MEDIA_BUYERS].includes(userGroup))
            if(["spend", "clientSpend"].includes(columnKey))
                return null;

        return settingsColumn.notAvailableForCol;
    }

    return {
        setAvailableColumns,
        getAvailableColumns,
        isColumnAvailable,
        hasTableColumn
    };
};

export default AvailableTableColumns;
