export function LandingPageTargetDto({
    thankYouHeadline = null,
    thankYouDescription = null,
    thankYouCtaValue = null,
    thankYouPageUrl = null,
    type = null,
    callToActionText = null
})
{
    return {
        type,
        headline: thankYouHeadline,
        description: thankYouDescription,
        callToAction: thankYouCtaValue,
        thankYouPageUrl: thankYouPageUrl,
        callToActionText,
    };
}
