import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
import {Activity} from "@/models/activity/activity";
import {ActivityDto} from "@/models/activity/activityDto";

export const activityDtoToObject = (dto, index) =>
{
    const object = Activity(dto);
    return object;
};

export const objectToActivityDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, ActivityDto)(data);
    return dto;
};
