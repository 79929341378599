export function FbPage({
    id = null,
    name = null,
    pictureUrl = null,
    url = null,
    clientId = null,
    category = null,
    sourceId = null
} = {})
{
    return {
        id,
        name,
        pictureUrl,
        url,
        clientId,
        category,
        sourceId
    };
}