export function LandingPageDto({
    id = null,
    offerId = null,
    clientId = null,
    campaignId = null,
    fbPageId = null,
    privacyId = null,
    lpTargetId = null,
    headline = null,
    questionHeadline = null,
    description = null,
    url = null,
    name = null,
    source = null,
    isActive = null,
    status = null,
    useAdImage = null
} = {})
{
    return {
        id,
        offerId,
        clientId,
        cmpId: campaignId,
        fbPageId,
        privacyId,
        lpTargetId,
        headline,
        questionHeadline,
        description,
        url,
        name,
        source,
        isActive,
        status,
        useAdImage
    };
}
