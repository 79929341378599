import {USER_GROUPS} from "@/store/constants";
import constants, {PAGES} from "../components/performance/constants";
import store from '@/store/store';
import { reduce, find } from "lodash";

const commentNode = (el, vnode) =>
{
    const comment = document.createComment(' ');

    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
    });

    vnode.text = ' ';
    vnode.elm = comment;
    vnode.isComment = true;
    vnode.tag = undefined;

    vnode.data = vnode.data || {};
    vnode.data.directives = undefined;

    if (vnode.componentInstance)
        vnode.componentInstance.$el = comment;
    if (el.parentNode)
        el.parentNode.replaceChild(comment, el);

};

const removeNode = (vnode) =>
{
    if(vnode.elm.parentElement)
        vnode.elm.parentElement.removeChild(vnode.elm);
};

const userGroupPermissions = {
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: {},
    [USER_GROUPS.ADMINISTRATORS]: {},
    [USER_GROUPS.MANAGERS]: [],
    [USER_GROUPS.MARKETERS]: {},
    [USER_GROUPS.SALES]: [],
    [USER_GROUPS.MEDIA_BUYERS]: [],
    [USER_GROUPS.ORGANIZATION_USER]: {
        [PAGES.AD]: {
            ['BUTTON']: ['draft'],
            ['EDIT_PENCIL']: [],
        },
    },
    [USER_GROUPS.ADVERTISER]: {},
};

const transformPermissionArrayInDictionary = (permissions) =>
{
    return reduce(permissions, (result, permission) =>
    {
        result[permission] = true;
        return result;
    }, {});
};

const ifAnyPermissionGranted = (permissions, userPermissions) =>
{
    if (permissions === undefined || permissions === null || permissions.length === 0)
        return true;

    if (userPermissions !== undefined)
        userPermissions =  transformPermissionArrayInDictionary(userPermissions);

    for (let i = 0; i < permissions.length; i++)
        if (userPermissions && permissions[i] !== undefined)
            // returns true if permission exists
            return userPermissions[permissions[i]];

    return false;
};

const ifOnlyPermissionGranted = (permissions, userPermissions) =>
{
    if (permissions === undefined || permissions === null || permissions.length === 0)
        return true;

    for (let i = 0; i < permissions.length; i++)
        if (userPermissions)
            return find(userPermissions, (userPermission) =>
            {
                return userPermission === permissions[0];
            });
    return false;
};

// TODO: send config to permission
const permissionByPage = (el, binding, vnode) =>
{
    const userGroup = store.getters.getUserGroup;
    const {page, element, permissions} = binding.value;
    let userPermissions;
    if(userGroupPermissions[userGroup][page])
        userPermissions = userGroupPermissions[userGroup][page][element];
    else
        return;
    
    if(binding.modifiers['any'])
        if(!ifAnyPermissionGranted(permissions, userPermissions))
            
            // TODO: It does not want to work with commentNode
            // return commentNode(el, vnode);
            return removeNode(vnode);
    
    if(binding.modifiers['none'])
        // TODO: when we will need it
        return;
    if(binding.modifiers['only'])
        
        if(permissions && binding.value['only'])
        {
            const permission = ifOnlyPermissionGranted(permissions, userPermissions);
            if(permission !== binding.value['only'])
                return removeNode(vnode);
        }
};

const permissionByElement = (el, binding, vnode) =>
{
    const userGroup = store.getters.getUserGroup;
    const {config, value} = binding.value;
    if(!value)
        throw new Error('No value found on permission config');
    const {only} = binding.modifiers;
    if(only)
    {
        const prop = Object.keys(binding.modifiers).filter(modifier => modifier !== "only")[0];
        if(!prop)
            throw new Error('No property found on permission config');
        const allowed = config[prop][value];
        const hasPermission = typeof allowed !== "undefined" && (allowed.length === 0 || allowed.includes(userGroup));
        if(!hasPermission)
            return removeNode(vnode);
    }
};

const permissionsStrategy = {
    page: permissionByPage,
    element: permissionByElement
};

const permissions = {
    inserted: (el, binding, vnode) =>
    {
        const strategy = binding.value["config"] ? "element" : "page";
        try
        {
            return Reflect.apply(permissionsStrategy[strategy], this, [el, binding, vnode]);
        }
        catch (error)
        {
            console.error(error);
            return true;
        }
    },
    update: (el, binding, vnode) =>
    {
        const strategy = binding.value["config"] ? "element" : "page";
        try
        {
            return Reflect.apply(permissionsStrategy[strategy], this, [el, binding, vnode]);
        }
        catch (error)
        {
            console.error(error);
            return true;
        }
    }
};

export default permissions;
