import mt from '../../mutationTypes';
import moment from 'moment';

const state =
{
    batches: { currNum: 1 },
    dateRange:
    {
        start: moment().subtract(14,'days').startOf('day'),
        end: moment().subtract(1,'days').endOf('day')
    }
};

const mutations =
{
    [mt.SetDateRangeOperationsFilter](state, dateRange)
    {
        state.dateRange.start = dateRange.start;
        state.dateRange.end = dateRange.end;
    },
    [mt.SetBatchCurrOperationsFilter](state, batchNum)
    {
        state.batches.currNum = batchNum.currNum;
    }
};

const getters =
{
    getDateRangeOperationsFilter(state)
    {
        return { start: state.dateRange.start, end: state.dateRange.end };
    },
    getBatchCurrOperationsFilter(state)
    {
        return state.batches.currNum;
    }
};

const actions =
{

};

const FiltersOperationsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default FiltersOperationsDataModule;
