export function SuggestionDto({
    url,
    clientId
} = {})
{
    return {
        url,
        clientId,
        async: true
    };
}
