export default function createColumnizator(data)
{
    if(!Array.isArray(data))
        throw new Error("Provide array for creating custom data columns");
    
    return data.map(singleRowData => ({
        showUpRate: !isNaN(singleRowData.crmDemosPerformed / singleRowData.crmDemosSet) ? singleRowData.crmDemosPerformed / singleRowData.crmDemosSet : 0,
        ...singleRowData
    }));
}

export function createSingleRowColumnizator(row)
{
    return Object.assign({}, row, {
        showUpRate: !isNaN(row.crmDemosPerformed / row.crmDemosSet) ? row.crmDemosPerformed / row.crmDemosSet : 0,
    });
}
