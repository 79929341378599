import Vue from 'vue';
import moment from "moment";
import axios from 'axios';
import projConfig from '../../../projConfig';
import {convertMomentDateToTimestamp} from '@/services/dateService';
import mt from '../../mutationTypes';
import enums from "@/components/performance/constants";
import {createUrlFilters} from '@/helpers/urlUtils';
import createColumnizator, {createSingleRowColumnizator} from '../../../models/Columnizator';
import createTotalizator from '../../../models/Totalizator';
import EntityAdapter from "@/store/adapter";
import {fetch, fetchAll, fetchStats, create, update} from "@/repositories/ClientRepository";

const state =
{
    clients: EntityAdapter().createEntityAdapter(),
    clientsFilteredIds: [],
    totals: {},
    loading: false,
    updatedAt: moment(),

    networkStats: {},

    // FIXME: move to service or separate module
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'spend',
        desc: true
    }
};

const mutations =
{
    [mt.SetClients](state, data)
    {
        state.clients = state.clients.setAll(data, state.clients);

        // TODO: move to service / computed props
        for (let row of data)
        {
            let clientNetStat = state.networkStats[row.clientId];

            if (clientNetStat && clientNetStat.isExpanded)
            {
                // TODO: move to controller
                this.dispatch('loadClientNetworkStats', {clientId: row.clientId});
            }
            else
            {
                Vue.set(state.networkStats, row.clientId, {});
                Vue.set(state.networkStats[row.clientId], 'isLoaded', false);
                Vue.set(state.networkStats[row.clientId], 'isExpanded', false);
                Vue.set(state.networkStats[row.clientId], 'data', null);
            }
        }
    },
    [mt.SetClientsTotals](state, data)
    {
        state.totals = data;
    },
    [mt.AddClient](state, client)
    {
        state.clients = state.clients.upsertOne(client, state.clients);
    },
    [mt.UpdateClient](state, client)
    {
        state.clients = state.clients.updateOne(client, state.clients);
    },
    [mt.SetClientPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetClientCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetClientSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.ClearClientsData](state)
    {
        state.clients = state.clients.removeAll(state.clients);
    },
    [mt.SetClientsFilteredIds](state, data)
    {
        state.clientsFilteredIds = data;
    },
    [mt.SetClientStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    }
};

const getters =
{
    getClientsIds: (state) => state.clients.ids,
    getClientsEntities: (state) => state.clients.entities,
    getClientById: (state) => id => state.clients.entities[id],
    getClientsFilteredIds: (state) => state.clientsFilteredIds,

    getClients(state, getters)
    {
        return getters.getClientsIds.map(id => getters.getClientsEntities[id]);
    },
    getClientStatTotals(state)
    {
        return state.totals;
    },
    getClientPage(state)
    {
        return state.page;
    },
    getClientSort(state)
    {
        return state.sort;
    },
    getPropertyOfClient: state => (id, prop) =>
    {
        const client = state.clients[id];
        if(typeof client !== "undefined" && typeof prop !== "undefined")
            return client[prop];
        return null;
    },
    getClientLastUpdatedAt(state)
    {
        return state.updatedAt;
    }
};

const actions =
{
    loadClientNetworkStats({ commit, rootState, rootGetters }, filters)
    {
        const dateRange = rootGetters.getDateRangeFilters;

        let startDate = dateRange.start;
        let endDate = dateRange.end;
        let batchNum = rootState.filters.batches.currNum;
        let clientId = filters.clientId;
        const localUrl = createUrlFilters(rootGetters);

        let url = projConfig.apiRoot + `/s/clients/${clientId}/networks`;

        // add filters, if any
        let qs = [];
        if (startDate != null)
            qs.push(`startDate=${convertMomentDateToTimestamp(startDate)}`);
        if (endDate != null)
            qs.push(`endDate=${convertMomentDateToTimestamp(endDate)}`);
        if (batchNum != null)
            qs.push(`batchNum=${batchNum}`);

        if (qs.length > 0)
            url += "?" + qs.join('&');

        // add filters, if any
        if(localUrl.length > 0)
            url += `&${localUrl.join("&")}`;

        commit(mt.SetLoading, true);

        return axios.get(url)
            .then(res =>
            {
                commit(mt.SetClientNetworkStats,
                    {
                        clientId,
                        data: res.data
                    });

                commit(mt.SetLoading, false);
            })
            .catch(error =>
            {
                commit(mt.SetLoading, false);

                if (error && error.response && error.response.status === 404)
                    commit(mt.SetClientNetworkStats,
                        {
                            clientId,
                            data: null
                        });
                else
                    throw error;
            });
    },
    async fetchClientsStats({ commit, state, rootGetters }, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);
            if (response && response.status === 200 && response.data)
            {
                let responseData = [];
                response.data.data.forEach(client =>
                {
                    let clientData = {...client, createdAt: 0};
                    responseData.push(clientData);
                });

                commit(mt.SetClients, createColumnizator(responseData));

                if(rootGetters.getAllFiltersCount('clientPage'))
                    commit(mt.SetClientsFilteredIds, responseData.map(data => data.id));
                else
                    commit(mt.SetClientsFilteredIds, []);

                commit(mt.SetClientsTotals, createTotalizator(response.data.totals));
                commit(mt.SetClientPaging, {
                    ...response.data.pages,
                    currentCount: response.data.data.length
                });
            }
            else
            {
                commit(mt.SetClients, []);
                commit(mt.SetClientsTotals, null);
                commit(mt.SetClientsFilteredIds, []);

                commit(mt.SetClientPaging, {
                    top: projConfig.itemsPerPage,
                    skip: 0,
                    totalCount: 0,
                    currentCount: 0,
                    currPage: 1
                });
            }
        }
        catch(error)
        {
            throw error || new Error('Error getting client stats.');
        }
        finally
        {
            commit(mt.SetClientStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },
    async fetchClients({commit}, { top = 1000, skip = 0 } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchAll({top, skip});
            commit(mt.SetClients, response.data.data);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchClientsByName({ commit }, { top = 1000, skip = 0, load = true, clientName } = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll({
                clientName,
                top,
                skip
            });
            if(response && response.data) return response.data.data;
        }
        finally
        {
            if(load) commit(mt.SetLoading, false);
        }
    },

    async loadClientAndSetFilter({ dispatch, commit, getters }, clientId)
    {
        try
        {
            await dispatch('fetchClientInfo', {id: clientId});

            const client = getters.getClientById(clientId);

            // legacy filters
            commit(mt.SetClientFilter, { id: client.id, name: client.name });
            commit(mt.SetObjectFilter, { id: client.id, name: client.name, type: enums.OBJECT_FILTERS.CLIENT });

            return Promise.resolve();
        }
        catch(error)
        {
            console.error(error);
        }

    },
    async fetchClientInfo({ commit }, { id })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch(id);

            if (response && response.status && response.status === 200)
                commit(mt.AddClient, response.data);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    // used in combo select
    async fetchClientDetails({ commit }, { id })
    {
        commit(mt.SetLoading, true);
        const {data = {}} = await fetch(id);
        commit(mt.SetLoading, false);
        return data;
    },
    async createClient({commit, rootGetters}, client)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(client);
            response.data.createdAt = new Date().getTime();
            response.data.activeCampaigns = 0;
            commit(mt.AddClient, createSingleRowColumnizator(response.data));
            if(rootGetters.getAllFiltersCount('clientPage'))
                commit(mt.SetClientsFilteredIds, [...new Set([response.data.id, ...state.clientsFilteredIds])]);
        }
        catch(error)
        {
            throw new Error("Error creating client");
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateClient({commit}, {id, client})
    {
        try
        {
            commit(mt.SetLoading, true);
            await update(id, client);
            commit(mt.UpdateClient, {id, changes: client});
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleClientDelivery({ commit }, { id, turnOn })
    {
        let client = { id, isActive: turnOn };

        await update(id, client);
        commit(mt.UpdateClient, {id, changes: client});
    },
};

const ClientDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default ClientDataModule;
