import { setDtoBase } from '@/models/DtoBase';
import { flowRight } from "lodash";
import { Client } from '@/models/client/client';
import { ClientDto } from '@/models/client/clientDto';
import { removeEmpty } from "@/helpers/objectUtils";

export const clientDtoToObject = dto =>
{
    const object = flowRight(removeEmpty, Client)(dto);
    return object;
};

export const objectToClientDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, ClientDto)(data);
    return dto;
};
