export function NetworkAdPreview({
    id = null,
    preview = null,
    type = null
})
{
    return {
        id,
        preview,
        type
    };
}