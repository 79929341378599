import {deleteReq, get, post, put} from "@/services/httpService";

const resource = "/s/lp-tracking-parameters";

const fetch = ({lpId} = {}) =>
{
    return get(`${resource}`, {
        params: {
            lpId
        }
    });
};

const update = ({id, data} = {}) =>
{
    return put(`${resource}/${id}`, {
        ...data
    });
};

const create = data =>
{
    return post(`${resource}`, {
        ...data
    });
};

const destroy = id =>
{
    return deleteReq(`${resource}/${id}`);
};

export {
    fetch,
    update,
    create,
    destroy
};