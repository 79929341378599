const mutations = {
    //#region Auth
    UpsertUser: "upsertUser",
    UpdateUser: "updateUser",
    RemoveAllUsers: "RemoveAllUsers",
    AddCompany: "addCompany",

    SetUserSession: "setUserSession",
    SetUserInfo: "setUserInfo",
    SetUserGroup: "setUserGroup",
    SetUserPersonalInfo: "setUserPersonalInfo",
    SetUserPicture: "setUserPicture",
    SetUserCompanyInfo: "setUserCompanyInfo",
    ResetUserSession: "resetUserSession",
    SetRedirectRoute: "setRedirectRoute",
    SetUserSettingsColumnVisibility: "setUserSettingsColumnVisibility",
    SetUserSelectedColumns: "setUserSelectedColumns",
    SetSelectedColumnPreset: "setSelectedColumnPreset",
    SetSelectedLeadsPreset: "setSelectedLeadsPreset",
    //#endregion

    //#region AdSets
    UpsertAdSets: "upsertAdSets",
    //#endregion
    //
    // #region Loader
    SetLoading: "setLoading",
    //#endregion

    //#region Filters
    SetDateRangeFilter: "setDateRangeFilter",
    SetDateDisplayFilter: "setDateDisplayFilter",
    SetNetworkCampaignFilter: "setNetworkCampaignFilter",
    SetLandingFilter: "setLandingFilter",
    SetAdGroupFilter: "setAdGroupFilter",
    SetCampaignFilter: "setCampaignFilter",
    SetClientFilter: "setClientFilter",
    SetAdFilter: "setAdFilter",
    SetAdCreativeFilter: "SetAdCreativeFilter",
    SetBatchMaxNum: "setBatchMaxNum",
    SetBatchCurrNum: "setBatchCurrNum",
    SetSearchByNameFilter: "setSearchByNameFilter",
    SetAdStatusFilter: "setAdStatusFilter",
    SetNetCmpStatusFilter: "SetNetCmpStatusFilter",
    SetLeadInfoFilter: "setLeadInfoFilter",
    SetObjectiveFilter: "setObjectiveFilter",
    SetActiveFilter: "setActiveFilter",
    SetDefaultActiveFilter: "setDefaultActiveFilter",
    SetDefaultLeadInfoFilter: "setDefaultLeadInfoFilter",
    SetPlacementFilter: "setPlacementFilter",
    SetGlobalPaging: "setGlobalPaging",
    SetIsParseQS: "setIsParseQS",
    SetDeliveryMetricFilter: "setDeliveryMetricFilter",
    SetVerticalFilter: "setVerticalFilter",
    SetVerticals: "setVerticals",
    SetObjectFilter: "setObjectFilter",
    SetClearObjectFilter: "setClearObjectFilter",
    SetNextObjectFilter: "setNextObjectFilter",
    //#endregion

    //#region clientData
    SetClients: "setClients",
    SetClientsTotals: "setClientsTotals",
    SetClientStatsData: "setStatsClientData",
    SetClientPaging: "setClientPaging",
    SetClientCurrPage: "setClientCurrPage",
    SetClientSorting: "setClientSorting",
    SetClientNetworkStats: "setClientNetworkStats",
    SetClientTotalNetworkStats: "setClientTotalNetworkStats",
    ClearClientsData: "clearClientsData",
    AddClient: "addClient",
    UpdateClient: "updateClient",
    SetClientsFilteredIds: "setClientsFilteredIds",
    SetClientStatsLastUpdatedAt: "SetClientStatsLastUpdatedAt",
    //#endregion

    //#region campaignData
    SetCampaigns: "setCampaigns",
    SetCampaignTotalsStat: "SetCampaignTotalsStat",
    UpsertOneCampaignsDetails: "UpsertOneCampaignsDetails",
    UpsertManyCampaigns: "UpsertManyCampaigns",
    RemoveAllCampaignData: "RemoveAllCampaignData",
    RemoveCampaignData: "RemoveCampaignData",
    AddCampaign: "addCampaign",
    UpdateCampaign: "UpdateCampaign",

    SetCampaignStatsLastUpdatedAt: "SetCampaignStatsLastUpdatedAt",
    SetCampaignPaging: "setCampaignPaging",
    SetCampaignCurrPage: "setCampaignCurrPage",
    SetCampaignSorting: "setCampaignSorting",
    SetCampaignNetworkStats: "setCampaignNetworkStats",
    SetCampaignNetworkExpanded: "setCampaignNetworkExpanded",

    SetCampaignTotalNetworkStats: "setCampaignTotalNetworkStats",
    SetCampaignAdditionalTotals: "setCampaignAdditionalTotals",
    SetCampaignAdditionalTotalNetworkStats: "setCampaignAdditionalTotalNetworkStats",
    SetCampaignTotalsActive: "SetCampaignTotalsActive",
    SetCampaignTotalsInactive: "SetCampaignTotalsInactive",
    SetCampaignFilteredIds: "setCampaignFilteredIds",

    SetCampaignSchedules:"SetCampaignSchedules",
    AddCampaignSchedules:"AddCampaignSchedules",
    UpdateCampaignSchedules:"UpdateCampaignSchedules",
    RemoveCampaignSchedule: "RemoveCampaignSchedule",
    RemoveAllCampaignSchedule:"RemoveAllCampaignSchedule",

    //#endregion

    //#region offersData
    SetOffers: "setOffers",
    SetOffersTotals: "SetOffersTotals",
    SetOffer: "setOffer",
    SetOffersPaging: "setOffersPaging",
    SetOffersSorting: "setOffersSorting",
    SetOfferState: "setOfferState",
    AddOffer:"addOffer",
    UpdateOffer:"updateOffer",
    UpsertOffer:"upsertOffer",
    ClearOffersData: "clearOffersData",
    RemoveOffer: "removeOffer",
    SetOfferPreview: "setOfferPreview",
    SetOfferData: "setOfferData",
    SetOffersFilteredIds: "setOffersFilteredIds",
    SetOfferStatsLastUpdatedAt: "SetOfferStatsLastUpdatedAt",
    UpsertOfferExpanded: "UpsertOfferExpanded",
    UpdateOfferExpanded: "UpdateOfferExpanded",
    //#endregion

    //#region adGroupData
    SetAdGroups: "setAdGroups",
    SetAdGroupTotalsStat: "setAdGroupTotalsStat",
    UpdateAdGroup: "updateAdGroup",
    UpsertOneAdGroupsDetails: "upsertOneAdGroupsDetails",
    UpsertManyAdGroupsDetails: "upsertManyAdGroupsDetails",
    RemoveAllAdGroupData: "removeAllAdGroupData",
    RemoveAdGroup: "removeAdGroup",

    SetAdGroupCurrPage: "setAdGroupCurrPage",
    SetAdGroupSorting: "setAdGroupSorting",
    SetAdGroupState: "setAdGroupState",

    SetAdGroupPaging: "setAdGroupPaging",

    SetAdGroupFilteredIds: "setAdGroupFilteredIds",
    RemoveAdGroupFilteredId: "removeAdGroupFilteredId",
    SetAdGroupStatsLastUpdatedAt: "SetAdGroupStatsLastUpdatedAt",
    UpsertAdGroupExpanded: "UpsertAdGroupExpanded",
    UpdateAdGroupExpanded: "UpdateAdGroupExpanded",
    RemoveAllAdGroupExpanded:"RemoveAllAdGroupExpanded",
    //#endregion

    //#region networkCampaignData
    UpsertManyNetworkCampaigns: "UpsertManyNetworkCampaigns",
    SetNetworkCampaignsTotals: "SetNetworkCampaignsTotals",
    AddNetworkCampaign: "AddNetworkCampaign",
    UpdateNetworkCampaign: "UpdateNetworkCampaign",

    SetNetworkCampaignData: "setNetworkCampaignData",
    SetNetworkCampaignPaging: "setNetworkCampaignPaging",
    SetNetworkCampaignCurrPage: "setNetworkCampaignCurrPage",
    SetNetworkCampaignSorting: "setNetworkCampaignSorting",

    SetNetworkCampaignDuplicationJob: "setNetworkCampaignDuplicationJob",
    //#endregion

    //#region landingData
    SetLandingPages: "setLandingPages",
    SetLandingPagesTotals: "setLandingPagesTotals",
    AddLandingPage: "addLandingPage",
    UpdateLandingPage: "updateLandingPage",
    UpsertLandingPage: "upsertLandingPage",
    UpsertLandingPages: "upsertLandingPages",
    RemoveLandingPage: "removeLandingPage",

    SetLandingPaging: 'setLandingPaging',
    SetLandingSorting: 'setLandingSorting',

    SetPrivacyPolicy: "setPrivacyPolicy",
    UpdatePrivacyPolicy: "updatePrivacyPolicy",

    SetFormQuestions: "setFormQuestions",
    AddFormQuestion: "addFormQuestion",
    UpsertFormQuestions: "upsertFormQuestions",
    RemoveFormQuestion: "removeFormQuestion",

    SetTargets: "setTargets",
    UpsertTarget: "upsertTarget",
    UpdateTarget: "updateTarget",

    SetTrackingParameters: "setTrackingParameters",
    AddTrackingParameter: "addTrackingParameter",
    RemoveTrackingParameter: "removeTrackingParameter",
    //endregion


    //#region networkAdData
    AddNetworkAds: "addNetworkAds",
    UpdateNetworkAd: "updateNetworkAd",
    UpsertNetworkAd: "upsertNetworkAd",
    RemoveAllNetworkAd: "removeAllNetworkAd",

    SetAdPreview: "setAdPreview",
    SetAdPreviewData: "setAdPreviewData",
    SetAdPaging: "setAdPaging",
    SetAdCurrPage: "setAdCurrPage",
    SetAdSorting: "setAdSorting",
    ResetAdPreviewData: "resetAdPreviewData",
    //#endregion

    //#region adCreativeData
    SetAdCreatives: "setAdCreatives",
    SetAdCreativesTotals: "setAdCreativesTotals",
    SetAdCreativeThumbnail: "setAdCreativeThumbnail",
    UpsertAdCreativeThumbnail: "upsertAdCreativeThumbnail",
    AddAdCreative: "addAdCreative",
    UpsertManyAdCreatives: "upsertManyAdCreatives",
    UpdateAdCreative: "updateAdCreative",
    SetAdCreativeLoading: "setAdCreativeLoading",
    SetAdCreativeFilteredIds: "setAdCreativeFilteredIds",
    RemoveAdCreativeFilteredId: "removeAdCreativeFilteredId",
    SetAdStatsLastUpdatedAt: "SetAdStatsLastUpdatedAt",
    UpsertAdCreativeExpanded: "UpsertAdCreativeExpanded",
    UpdateAdCreativeExpanded: "UpdateAdCreativeExpanded",
    RemoveAllAdCreativeExpanded: "RemoveAllAdCreativeExpanded",

    //#region thumbnails
    SetThumbnailForOffer: "setThumbnailForOffer",
    SetThumbnailsForOffer: "setThumbnailsForOffer",
    UpsertManyThumbnailsForOffers: "upsertManyThumbnailsForOffers",
    ClearOfferPreviewData: "clearOfferPreviewData",
    UpsertManyThumbnailsForLandingPages: "upsertManyThumbnailsForLandingPage",
    SetThumbnailsIdsForLandingPages: "setThumbnailsIdsForLandingPage",
    UpdateThumbnailsForLandingPage: "updateThumbnailsForLandingPage",
    UpsertManyAdCreativePreviewsForLandingPage: "upsertManyAdCreativePreviewsForLandingPage",
    UpsertThumbnailForOffer: "upsertThumbnailForOffer",
    UpsertManyThumbnailsForAdCreatives: "upsertManyThumbnailsForAdCreatives",
    UpsertThumbnailsForAdCreative: "upsertThumbnailsForAdCreative",
    UpsertThumbnailsForNetworkAd: "upsertThumbnailsForNetworkAd",
    RemoveAllLandingPageThumbnails: "removeAllLandingPageThumbnails",
    RemoveAllLandingPageAdPreviews: "removeAllLandingPageAdPreviews",
    RemoveAllAdCreativeThumbnails: "removeAllAdCreativeThumbnails",
    RemoveAllNetworkAdThumbnails: "removeAllNetworkAdThumbnails",

    // endthumbnails

    SetAdCreativePaging: "setAdCreativePaging",
    SetAdCreativeCurrPage: "setAdCreativeCurrPage",
    SetAdCreativeSorting: "setAdCreativeSorting",
    RemoveAllAdCreatives: "RemoveAllAdCreatives",
    RemoveAdCreative: "removeAdCreative",
    AddAdCreativeComment: "addAdCreativeComment",
    UpdateAdCreativeComments: "updateAdCreativeComments",
    UpdateAdCreativeActivities: "UpdateAdCreativeActivities",
    //#endregion

    //#region leadsData
    SetLeadsData: "setLeadsData",
    SetLeadsPaging: "setLeadsPaging",
    SetLeadsCurrPage: "setLeadsCurrPage",
    SetLeadsSorting: "setLeadsSorting",
    UpdateLead: "updateLead",
    SetLeadsColumnsVisible: "setLeadsColumnsVisible",
    ToggleLeadsColumnVisibility: "toggleLeadsColumnVisibility",
    SetLeadsLastUpdatedAt: "setLeadsLastUpdatedAt",
    AddLead: "addLead",
    ClearLeadsData: "clearLeadsData",
    //#endregion

    //#region Filters for the Dashboard
    SetDateRangeDashboardFilter: "setDateRangeDashboardFilter",
    SetClientDashboardFilter: "setClientDashboardFilter",
    SetCampaignDashboardFilter: "setCampaignDashboardFilter",
    SetActiveDashboardFilter: "setActiveDashboardFilter",
    SetBatchMaxNumDashboard: "setBatchMaxNumDashboard",
    SetBatchCurrDashboard: "setBatchCurrNumDashboard",
    SetLpDashboardFilter: "setLpDashboardFilter",
    SetDashboardUpdatedAt: "setDashboardUpdatedAt",
    //#endregion

    //#region Filters for the Operations
    SetBatchCurrOperationsFilter: "setBatchCurrOperationsFilter",
    SetDateRangeOperationsFilter: "setDateRangeOperationsFilter",
    //#endregion

    //#region operations: overview data
    SetOverviewData: "setOverviewData",
    SetOverviewPaging: "setOverviewPaging",
    SetOverviewSorting: "setOverviewSorting",
    SetOverviewCurrPage: "setOverviewCurrPage",
    SetLandingDataOverview: "setLandingDataOverview",
    //#endregion

    //#region operations: overview data
    SetLeaderboardData: "setLeaderboardData",
    SetLeaderboardPaging: "setLeaderboardPaging",
    SetLeaderboardSorting: "setLeaderboardSorting",
    SetLeaderboardCurrPage: "setLeaderboardCurrPage",
    //#endregion

    //#region MediaBuy placement data
    SetMediaPlacementData: "setMediaPlacementData",
    SetMediaPlacementPaging: "setMediaPlacementPaging",
    SetMediaPlacementSort: "setMediaPlacementSort",
    //#endregion

    //#region MediaBuy campaignType data
    SetMediaCampaignTypeData: "setMediaCampaignType",
    SetMediaLoadingStatuses: "setMediaLoadingStatuses",
    SetMediaCampaignTypeSort: "setMediaCampaignTypeSort",
    //#endregion

    //#region MediaBuy charts data
    SetDoughnutMediaData: "setDoughnutMediaData",
    SetDoughnutMediaOpt: "setDoughnutMediaOpt",
    SetLineMediaData: "setLineMediaData",
    SetLineMediaRawData: "setLineMediaRawData",
    SetLineMediaOpt: "setLineMediaOpt",
    SetLineMediaScale: "setLineMediaScale",
    SetMediaSocialEngagement: "SetMediaSocialEngagement",
    SetPolarAreaMediaLeadsData: "setPolarAreaMediaLeadsData",
    SetPolarAreaMediaImpressionsData: "setPolarAreaMediaImpressionsData",
    SetPolarAreaMediaClicksData: "setPolarAreaMediaClicksData",
    SetPolarAreaMediaOpt: "setPolarAreaMediaOpt",
    SetCRMStats: "setCRMStats",
    //#endregion

    //#region batchData
    SetBatchPaging: "setBatchPaging",
    UpsertOneBatchesData:"UpsertOneBatchesData",
    UpsertManyBatchesData:"UpsertManyBatchesData",
    //#endregion

    //#region users data
    SetUsersData: "setUsersData",
    SetUsersEvents: "setUsersEvents",
    SetUsersEventsPaging: "setUsersEventsPaging",
    SetUsersEventsSort: "setUsersEventsSort",
    SetAdsStatsData: "setAdsStatsData",
    SetUserLeadsColumns: "setUserLeadsColumns",
    SetUserSettingsNotifications: "SetUserSettingsNotifications",
    SetUserSettingsTOS: "SetUserSettingsTOS",
    //#endregion

    //#regions AdsDashboard
    SetAdsDashboardSort: "setAdsDashboardSort",
    SetBatchDurationDashboard: "setBatchDurationDashboard",
    //#endregion

    //#region AdsDashboard geoStats data
    SetAdsGeoStatsData: "setAdsGeoStatsData",
    SetAdsGeoStatsPaging: "setAdsGeoStatsPaging",
    //#endregion

    //#region AdCreateData
    SetFbPages: "setFbPages",
    AddFbPages: "addFbPages",
    UpdateAudience: "updateAudience",
    UpsertAudience: "upsertAudience",
    UpsertManyAudiences: "upsertManyAudiences",
    RemoveAudiences: "removeAudiences",
    SetAudiencePaging: "setAudiencePaging",
    UpsertManyNetworkAudiences: "upsertManyNetworkAudiences",
    UpdateNetworkAudience: "updateNetworkAudience",
    //#endregion

    //#region  IntegrationsData
    SetIntegrationData: "setIntegrationData",
    SetIntegrationPaging: "setIntegrationPaging",
    SetIntegrationsCurrPage: "setIntegrationsCurrPage",
    SetGenlyLeadStatuses: "setGenlyLeadStatuses",
    SetClientSalesforceLeadStatuses: "setClientSalesforceLeadStatuses",
    SetSalesforceAvailableLedStatuses: "setSalesforceAvailableLedStatuses",
    //#endregion IntegrationsData

    //#region AssetsData
    SetAssetEmotionalScore: "setAssetEmotionalScore",
    //#endregion AssetsData

    //#region Payments
    SetPaymentToken: "setPaymentToken",

    //#region Hierarchy
    SetHierarchyPage: "setHierarchyPage",

    //#region Notifications
    SetNotifications: "setNotifications",
    AddNotification: "addNotification",
    UpdateNotification: "updateNotification",
    UpdateNotifications: "updateNotifications",
    DeleteNotification: "deleteNotification",
    DeleteNotifications: "deleteNotifications",
    //#region TransactionHistory
    SetBillingData: "setBillingData",
    RemoveBillingData: "removeBillingData",
    SetBillingDateRangeFilter: "setBillingDateRangeFilter",
    //#endregion TransactionHistory

    //#region TransactionHistory
    SetTransaction: "setTransaction",
    SetTransactionHistory: "setTransactionHistory",
    SetTransactionHistoryPaging: "setTransactionHistoryPaging",
    SetTransactionHistoryCurrPage: "setTransactionHistoryCurrPage",
    SetTransactionHistorySorting: "setTransactionHistorySorting",
    ClearTransactionHistoryData: "clearTransactionHistoryData",
    SetTransactionHistoryStatsLastUpdatedAt: "setTransactionHistoryStatsLastUpdatedAt"
    //#endregion TransactionHistory
};

export default mutations;
