var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.menuClose,
          expression: "menuClose",
        },
      ],
      ref: "dropdownMenu",
      class: [
        { "disabled-dropdown": _vm.disabled },
        "dropdown",
        "dropdown-toggle",
      ],
      on: { click: _vm.sendOpenEvent },
    },
    [
      _c(
        "div",
        {
          class: _vm.toggleClasses,
          attrs: {
            id: "dropdown-toggle",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _vm._t("toggle-button-content"),
          _vm._v(" "),
          _c("font-awesome-icon", {
            staticClass: "dropdown--arrow",
            attrs: { icon: "caret-down" },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          class: _vm.menuClasses,
          style: _vm.menuStyles,
          attrs: { "aria-labelledby": "dropdown-toggle" },
        },
        [_vm._t("menu-content")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }