<template>
  <div>
    <div
      class="styled-input relative"
      :class="{ 'has-error': !isPasswordValid }"
    >
      <input
        v-model="password"
        name="password"
        placeholder="New password"
        :type="passwordInputMode"
        autocomplete="new-password"
        @input="validatePassword"
      >
      <i
        class="styled-input__showpassword cursor-pointer"
        :class="{ 'show': isPasswordShowMode }"
        data-fa-i2svg
        @click="isPasswordShowMode = !isPasswordShowMode"
      >
        <svg
          class="svg-inline--fa fa-eye fa-w-18"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="eye"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          data-fa-i2svg
        >
          <path
            fill="currentColor"
            d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
          />
        </svg>
      </i>
      <div
        v-tippy="{ placement : 'bottom',
                   arrow: true,
                   onShow: shouldShowTooltip,
                   content: $refs['tippy-content'],
                   followCursor: 'horizontal',
                   boundary: 'viewport' }"
        class="strength-meter"
      >
        <span :class="[getStrengthProps]" />
      </div>
    </div>

    <p
      v-show="!isPasswordValid"
      class="help-block"
    >
      {{ passwordValidationMessage }}
    </p>

    <div id="template-1"
         ref="tippy-content"
         :class="{'hidden': !passwordStrength.feedback.warning}">
      <div class="max-w-md w-full tippy-content">
        <div class="m-2">
          <h3 class="text-white f-16">
            {{ passwordStrength.feedback.warning }}
          </h3>
          <ul class="pt-1">
            <li v-for="(suggestion, key) in passwordStrength.feedback.suggestions"
                :key="key"
                class="">
              {{ suggestion }}
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

export default {
    props:
    {
        value: {
            type: String,
            required: true
        }
    },
    data()
    {
        return {
            password: "",
            isPasswordValid: false,
            isPasswordShowMode: false,
            passwordValidationMessage: 'Password must be at least 11 characters.',
            passwordStrength: {
                score: null,
                feedback: {
                    warning: null,
                    suggestions: []
                }
            }
        };
    },
    computed:
    {
        passwordInputMode()
        {
            return this.isPasswordShowMode ? "text" : "password";
        },
        getStrengthProps()
        {
            if(this.password.length === 0)
                return "";
            switch (this.passwordStrength.score)
            {
                case 0:
                case 1:
                    return "quarter";
                case 2:
                    return "half";
                case 3:
                    return "three-quarters";
                case 4:
                    return "full";
            }

            return "";
        }
    },
    methods:
    {
        shouldShowTooltip()
        {
            return !!this.passwordStrength.feedback.warning;
        },
        validatePassword()
        {
            const options = {
                dictionary: {
                    ...zxcvbnCommonPackage.dictionary,
                    ...zxcvbnEnPackage.dictionary,
                },
                graphs: zxcvbnCommonPackage.adjacencyGraphs,
                translations: zxcvbnEnPackage.translations,
            };
            zxcvbnOptions.setOptions(options);

            this.isPasswordValid =
                  this.password.length >= 11;

            this.passwordStrength = zxcvbn(this.password);

            this.$emit("input", this.password);
            this.$emit("validation", this.isPasswordValid);
        }
    }
};
</script>

<style lang="scss" scoped>
  @import "../../../assets/styles/theme/variables";
  .help-block {
    line-height: 16px !important;
    font-size: 12px;
    letter-spacing: .1px;
  }

  .strength-meter {
    bottom: -10px;
    display: flex;
    height: 4px;
    position: absolute;
    width: 100%;

    span {
      border-radius: 2px;
      display: block;
      height: 100%;
      transition: all 200ms ease-in-out;
      max-width: 0;
      width: 100%;

      &.quarter {
        max-width: 25%;
        background-color: $color-danger;
      }

      &.half {
        max-width: 50%;
        background-color: $color-danger;
      }

      &.three-quarters {
        max-width: 75%;
        background-color: $color-warning;
      }

      &.full {
        max-width: 100%;
        background-color: $color-success;
      }
    }

  }

  ::v-deep.tippy-content {
      text-align: left;
  }
</style>
