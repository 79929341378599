import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {fetch, create, destroy} from "@/repositories/FormQuestionsRepository";
import {formQuestionDtoToObject} from "@/models/landing-page-form-questions";

const state = {
    formQuestions: EntityAdapter().createEntityAdapter()
};

const mutations = {
    [mt.SetFormQuestions](state, formQuestions)
    {
        state.formQuestions = state.formQuestions.setAll(formQuestions, state.formQuestions);
    },
    [mt.AddFormQuestion](state, formQuestion)
    {
        state.formQuestions = state.formQuestions.addOne(formQuestion, state.formQuestions);
    },
    [mt.UpsertFormQuestions](state, formQuestions)
    {
        state.formQuestions = state.formQuestions.upsertMany(formQuestions, state.formQuestions);
    },
    [mt.RemoveFormQuestion](state, id)
    {
        state.formQuestions = state.formQuestions.removeOne(id, state.formQuestions);
    }
};

const getters = {
    getFormQuestionById: state => id => state.formQuestions.entities[id]
};

const actions = {
    async fetchFormQuestions({commit}, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id});

            if(response && response.status === 200)
            {
                const formQuestions = response.data.data;
                commit(mt.SetFormQuestions, formQuestions);
                const formQuestionsIds = formQuestions.map(question => question.id);
                commit(mt.UpdateLandingPage, {
                    id,
                    changes: {
                        // remove duplicate values
                        //formQuestionsIds: [...new Set([...landingPage.formQuestionsIds, ...formQuestionsIds])]
                        formQuestionsIds
                    }
                });
                // TODO: get form questions where needed from the store
                return formQuestions;
            }
        }
        catch(error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async createFormQuestionsFields({ commit, rootGetters }, questions = {
        lpId,
        lpFields
    })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(questions);

            if(response.data && response.data.landingPageFields)
            {
                // because of the returned object from api,
                // we do dto transformation here, instead of ResponseTransformer
                const formQuestions = response.data.landingPageFields;
                commit(mt.UpsertFormQuestions, formQuestions.map((value, index) => formQuestionDtoToObject(value, index)));

                const landingPage = rootGetters.getLandingPageById(questions.lpId);
                commit(mt.UpdateLandingPage, {
                    id: landingPage.id,
                    changes: {
                        formQuestionsIds: {...landingPage.formQuestionsIds, ...formQuestions.map(question => question.id)}
                    }
                });
            }
        }
        catch (error)
        {
            if(error.data)
                throw new Error(error.data);
            
            throw new Error(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async deleteFormQuestionsField({ commit, getters, rootGetters }, {id})
    {
        try
        {
            commit(mt.SetLoading, true);
            await destroy(id);
            const landingPageId = getters.getFormQuestionById(id).lpId;
            commit(mt.RemoveFormQuestion, id);
            // remove from landing page
            const landingPage = rootGetters.getLandingPageById(landingPageId);
            const formQuestionsIds = landingPage.formQuestionsIds || [];
            
            commit(mt.UpdateLandingPage, {
                id: landingPageId,
                changes: {
                    formQuestionsIds: formQuestionsIds.filter(lpFQId => lpFQId !== id)
                }
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
};

const FormQuestionsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default FormQuestionsDataModule;
