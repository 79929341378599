import * as Sentry from "@sentry/browser";
import {get} from "@/services/httpService";
import Vue from 'vue';
import Vuex from 'vuex';
import projConfig from '../projConfig';

import userModule from './modules/auth';
import FiltersPerformanceDataModule from "@/store/modules/performance/filters";
import AdGroupDataModule from "@/store/modules/performance/adGroupData";
import DashboardDataModule from "@/store/modules/performance/dashboardData";
import NetworkAdDataModule from "@/store/modules/performance/networkAdData";
import NetworkCampaignDataModule from "@/store/modules/performance/networkCampaignData";
import CampaignDataModule from "@/store/modules/performance/campaignData";
import ClientDataModule from "@/store/modules/performance/clientData";
import LeadsDataModule from "@/store/modules/performance/leadsData";
import LandingDataModule from "@/store/modules/performance/landingData";
import BatchDataModule from "@/store/modules/batchData";
import UsersDataModule from "@/store/modules/users/usersData";
import OverviewDataModule from "@/store/modules/operations/overviewData";
import LeaderboardDataModule from "@/store/modules/operations/leaderboardData";
import FiltersOperationsDataModule from "@/store/modules/operations/filters";
import AdCreativeDataModule from "@/store/modules/performance/adCreativeData";
import AssetsDataModule from "@/store/modules/assets";
import HierarchyDataModule from "@/store/modules/hierarchy";
import NetworkAssetDataModule from "@/store/modules/networkAssetData";
import OffersDataModule from "@/store/modules/performance/offersData";
import IntegrationsDataModule from "@/store/modules/integrations/integrationsData";
import SalesforceDataModule from "@/store/modules/integrations/salesforceData";
import PaymentsDataModule from "@/store/modules/payments/paymentsData";
import ThumbnailsDataModule from "@/store/modules/performance/thumbnailsData";
import PrivacyPolicyDataModule from '@/store/modules/performance/privacyPolicyData';
import AudienceDataModule from '@/store/modules/performance/audienceData';
import CampaignSchedulesDataModule from '@/store/modules/performance/campaignSchedulesData';
import FormQuestionsDataModule from '@/store/modules/performance/formQuestionsData';
import TargetsDataModule from '@/store/modules/performance/targetsData';
import TrackingParametersDataModule from '@/store/modules/performance/trackingParametersData';
import NotificationsDataModule from '@/store/modules/notifications/notificationsData';
import AdSetsDataModule from '@/store/modules/performance/adSetsData';
import TransactionHistoryModule from "@/store/modules/billing/transactionHistoryData";
import BillingDataModule from "@/store/modules/billing/billingData";

Vue.use(Vuex);

const store = new Vuex.Store({
    state:
    {
        // global objects
        isCopyDisabled: false,
        componentsInLoading: 0,
        apiInfo: '',
        isLoading: false,
    },
    getters:
    {
        getCopyFlag(state)
        {
            return state.isCopyDisabled;
        },
        // common transform wrappers
        getApiInfo(state)
        {
            return state.apiInfo;
        },
        getIsLoading(state)
        {
            return state.isLoading;
        }
    },
    mutations:
    {
        setCopyFlag(state, isCopyDisabled)
        {
            state.isCopyDisabled = isCopyDisabled;
        },
        // synchronous state changes
        setLoading(state, isLoading)
        {
            if (isLoading)
            {
                state.componentsInLoading++;
            }
            else
            {
                state.componentsInLoading--;
                // when componentsInLoading is a negative number send a report to sentry
                if(state.componentsInLoading < 0)
                    Sentry.captureException(new Error(`componentsInLoading value is negative: ${state.componentsInLoading}`), {
                        tags: {
                            section: "errors",
                        },
                    });
                // don't allow negative numbers
                state.componentsInLoading = Math.max(0, state.componentsInLoading);
            }

            state.isLoading = (state.componentsInLoading > 0);
        },
        setApiInfo(state, apiInfo)
        {
            state.apiInfo = apiInfo;
        }
    },
    actions:
    {
        getCopyFlag({ commit })
        {
            const isCopyDisabled = projConfig.isCopyDisabled;

            commit('setCopyFlag', isCopyDisabled);
        },
        // async methods that use mutations
        async getApiInfo({ commit })
        {
            let url = projConfig.apiRoot + '/status';
            try
            {
                const response = await get(url);
                commit('setApiInfo', response.data);
            }
            catch(error)
            {
                console.log(error);
            }
        }
    },
    modules:
    {
        // compartmentalized stores
        // mutations and actions from modules are registered globally.
        user: userModule,
        filters: FiltersPerformanceDataModule,
        AdGroupDataModule: AdGroupDataModule,
        AdCreativeDataModule: AdCreativeDataModule,
        AssetsDataModule: AssetsDataModule,
        AudienceDataModule: AudienceDataModule,
        BatchDataModule: BatchDataModule,
        CampaignDataModule: CampaignDataModule,
        ClientDataModule: ClientDataModule,
        CampaignSchedulesDataModule: CampaignSchedulesDataModule,
        DashboardDataModule: DashboardDataModule,
        FiltersOperationsDataModule: FiltersOperationsDataModule,
        FormQuestionsDataModule: FormQuestionsDataModule,
        HierarchyDataModule: HierarchyDataModule,
        IntegrationsDataModule: IntegrationsDataModule,
        LandingDataModule: LandingDataModule,
        LeadsDataModule: LeadsDataModule,
        LeaderboardDataModule: LeaderboardDataModule,
        NetworkAdDataModule: NetworkAdDataModule,
        NetworkAssetDataModule: NetworkAssetDataModule,
        NetworkCampaignDataModule: NetworkCampaignDataModule,
        OffersDataModule: OffersDataModule,
        OverviewDataModule: OverviewDataModule,
        PaymentsDataModule: PaymentsDataModule,
        PrivacyPolicyDataModule: PrivacyPolicyDataModule,
        SalesforceDataModule: SalesforceDataModule,
        TargetsDataModule: TargetsDataModule,
        ThumbnailsDataModule: ThumbnailsDataModule,
        TrackingParametersDataModule: TrackingParametersDataModule,
        UsersDataModule: UsersDataModule,
        NotificationsDataModule: NotificationsDataModule,
        AdSetsDataModule: AdSetsDataModule,
        BillingDataModule: BillingDataModule,
        TransactionHistoryModule: TransactionHistoryModule
    }
});

export default store;
