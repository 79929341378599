import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {offerThumbnailDtoToObject} from "@/models/offer-thumbnail";
import {
    fetchAllForLandingPages,
    fetchAllForOffers,
    fetchOneForOffer
} from "@/repositories/ThumbnailsRepository";
import {landingPageThumbnailDtoToObject} from "@/models/landing-page-thumbnail";

const state = {
    offerThumbnails: EntityAdapter().createEntityAdapter(),
    landingPageThumbnails: EntityAdapter().createEntityAdapter(),
    landingPageAdPreviews: EntityAdapter().createEntityAdapter(),
    adCreativeThumbnails: EntityAdapter().createEntityAdapter(),
    networkAdThumbnails: EntityAdapter().createEntityAdapter(),
};

const mutations = {
    [mt.UpsertManyThumbnailsForOffers](state, data)
    {
        state.offerThumbnails = state.offerThumbnails.upsertMany(data, state.offerThumbnails);
    },
    [mt.UpsertThumbnailForOffer](state, data)
    {
        state.offerThumbnails = state.offerThumbnails.upsertOne(data, state.offerThumbnails);
    },
    [mt.ClearOfferPreviewData](state)
    {
        state.offerThumbnails = state.offerThumbnails.removeAll(state.offerThumbnails);
    },

    [mt.UpsertManyThumbnailsForLandingPages](state, data)
    {
        state.landingPageThumbnails = state.landingPageThumbnails.upsertMany(data, state.landingPageThumbnails);
    },
    [mt.RemoveAllLandingPageThumbnails](state)
    {
        state.landingPageThumbnails = state.landingPageThumbnails.removeAll(state.landingPageThumbnails);
    },

    [mt.UpsertManyAdCreativePreviewsForLandingPage](state, data)
    {
        state.landingPageAdPreviews = state.landingPageAdPreviews.upsertMany(data, state.landingPageAdPreviews);
    },
    [mt.RemoveAllLandingPageAdPreviews](state)
    {
        state.landingPageAdPreviews = state.landingPageAdPreviews.removeAll(state.landingPageAdPreviews);
    },

    [mt.UpsertManyThumbnailsForAdCreatives](state, data)
    {
        state.adCreativeThumbnails = state.adCreativeThumbnails.upsertMany(data, state.adCreativeThumbnails);
    },
    [mt.UpsertThumbnailsForAdCreative](state, data)
    {
        state.adCreativeThumbnails = state.adCreativeThumbnails.upsertMany(data, state.adCreativeThumbnails);
    },
    [mt.RemoveAllAdCreativeThumbnails](state)
    {
        state.adCreativeThumbnails = state.adCreativeThumbnails.removeAll(state.adCreativeThumbnails);
    },

    [mt.UpsertThumbnailsForNetworkAd](state, data)
    {
        state.networkAdThumbnails = state.networkAdThumbnails.upsertMany(data, state.networkAdThumbnails);
    },
    [mt.RemoveAllNetworkAdThumbnails](state)
    {
        state.networkAdThumbnails = state.networkAdThumbnails.removeAll(state.networkAdThumbnails);
    },
};

const getters = {
    getOffersThumbnailsIds: state => state.offerThumbnails.ids,
    getOffersThumbnailsEntities: state => state.offerThumbnails.entities,
    getOfferThumbnailsById: state => id => state.offerThumbnails.entities[id],

    getLandingPagesThumbnailsIds: state => state.landingPageThumbnails.ids,
    getLandingPagesThumbnailsEntities: state => state.landingPageThumbnails.entities,
    getLandingPageThumbnailById: state => id => state.landingPageThumbnails.entities[id],
    
    getLandingPageAdPreviewsIds: state => state.landingPageAdPreviews.ids,
    getLandingPageAdPreviewsEntities: state => state.landingPageAdPreviews.entities,
    getLandingPageAdPreviewsById: state => id => state.landingPageAdPreviews.entities[id],

    getAdCreativeThumbnailsIds: state => state.adCreativeThumbnails.ids,
    getAdCreativeThumbnailsEntities: state => state.adCreativeThumbnails.entities,
    getAdCreativeThumbnailsById: state => id => state.adCreativeThumbnails.entities[id],

    getNetworkAdThumbnailsIds: state => state.networkAdThumbnails.ids,
    getNetworkAdThumbnailsEntities: state => state.networkAdThumbnails.entities,
    getNetworkAdThumbnailsById: state => id => state.networkAdThumbnails.entities[id]
};

const actions = {
    async fetchThumbnailsForOffers({ commit, getters }, { ids = [], type = "thumbnail" })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchAllForOffers({ ids, type });
            let thumbnailForOffers = [];
            const thumbnails = response.data.filter(Boolean);
    
            thumbnails.forEach(thumbnail =>
            {
                const offerThumbnail = offerThumbnailDtoToObject(thumbnail);
                thumbnailForOffers.push(offerThumbnail);
                const offer = getters.getOfferById(offerThumbnail.offerId);
                
                if(offer)
                {
                    const offerThumbnailsIds = offer.thumbnailsIds ? offer.thumbnailsIds : [];
                    commit(mt.UpdateOffer, {
                        id: offerThumbnail.offerId,
                        changes: {
                            thumbnailsIds: [...new Set([...offerThumbnailsIds, offerThumbnail.id])]
                        }
                    });
                }
                
            });

            commit(mt.UpsertManyThumbnailsForOffers, thumbnailForOffers);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchOfferThumbnail({ commit, rootGetters }, { id , type = "thumbnail"})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchOneForOffer({id, type});

            if(response && response.status === 200)
            {
                const thumbnail = offerThumbnailDtoToObject(response.data);
                const offer = rootGetters.getOfferById(id);

                if(offer)
                {
                    const offerThumbnailsIds = offer.thumbnailsIds ? offer.thumbnailsIds : [];
                    commit(mt.UpdateOffer, {
                        id,
                        changes: {
                            thumbnailsIds: [...offerThumbnailsIds, thumbnail.id]
                        }
                    });
                }
                commit(mt.UpsertThumbnailForOffer, thumbnail);
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchLandingPagesThumbnails({ commit }, ids)
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetchAllForLandingPages({ids});
            let thumbnails = [];
    
            // remove values with errors
            const landingPagesIds = ids.filter((id, index) => response[index]);
            const filteredResponse = response.filter(Boolean);
            
            filteredResponse
                .forEach((promise, index) =>
                {
                    const thumbnail = landingPageThumbnailDtoToObject(filteredResponse[index].data);
                    thumbnails.push(thumbnail);
                    commit(mt.UpdateThumbnailsForLandingPage, {
                        id: landingPagesIds[index],
                        thumbnailsIds: [thumbnail.id]
                    });
                });
            commit(mt.UpsertManyThumbnailsForLandingPages, thumbnails);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
};

const ThumbnailsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default ThumbnailsDataModule;
