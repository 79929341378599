import {ErrorBase} from '@/errors/ErrorBase';

export class GenlySuggestionJobError extends ErrorBase 
{

    jobResult;

    constructor({
        name, message, cause, jobResult
    } = {})
    {
        super({name, message, cause});
        this.jobResult = jobResult;
    }
}
