import {isMobile} from "@/services/deviceService";

const projConfig =
{
    apiRoot: "https://stg-api.preciseonline.com",
    websocket: "wss://stg-ws-api.preciseonline.com",
    itemsPerPage: !isMobile() ? 50 : 20,
    isLocal: false,
    isCopyDisabled: false,
    refreshDelay: 120, //set the delay in seconds
    showUserOrgAddButton: false,
    env: "DEVELOPMENT"
};

export default projConfig;
