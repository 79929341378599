import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {privacyPolicyDtoToObject} from "@/models/privacy-policy";
import {fetch, update, create} from "@/repositories/PrivacyPolicyRepository";

const state = {
    privacyPolicies: EntityAdapter().createEntityAdapter()
};

const mutations = {
    [mt.SetPrivacyPolicy](state, privacyPolicy)
    {
        state.privacyPolicies = state.privacyPolicies.addOne(privacyPolicy, state.privacyPolicies);
    },
    [mt.UpdatePrivacyPolicy](state, privacyPolicy)
    {
        state.privacyPolicies = state.privacyPolicies.updateOne(privacyPolicy, state.privacyPolicies);
    }
};

const getters = {
    getPrivacyPolicyById: state => id => state.privacyPolicies.entities[id]
};

const actions = {
    async fetchPrivacyPolicy({ commit }, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id, ...rest});

            if(response && response.status === 200)
            {
                const privacyPolicy = response.data;
                commit(mt.SetPrivacyPolicy, privacyPolicy);

                return privacyPolicy;
            }
    
            // TODO: get privacy policy where needed from the store
            return privacyPolicyDtoToObject();
        }
        catch (error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updatePrivacyPolicy({ commit }, {id, data = {
        privacyPolicyLinkText,
        privacyPolicyLinkUrl,
        privacyPolicyDisclaimerTitle,
        privacyPolicyDisclaimerText
    }} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            await update({id, data});

            commit(mt.UpdatePrivacyPolicy, {
                id,
                changes: {
                    ...data
                }
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createPrivacyPolicy({ commit }, privacyPolicy = {
        clientId,
        fbPageName,
        privacyPolicyLinkText,
        privacyPolicyLinkUrl,
        privacyPolicyDisclaimerTitle,
        privacyPolicyDisclaimerText,
        privacyPolicyDisclaimer
    } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create({
                ...privacyPolicy,
            });

            commit(mt.SetPrivacyPolicy, response.data);

            // return newly created privacy policy
            return response.data.id;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
};

const PrivacyPolicyDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default PrivacyPolicyDataModule;
