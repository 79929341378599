let required = function() { throw new Error("Method is required"); };
const ITransformer = () =>
{
    transform: required;
    shouldTransform: required;
};

const Transformer = () => {};
Transformer.prototype = Object.create(ITransformer);

export default Transformer;
