import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import
{
    faPlus,
    faMinus,
    faSync,
    faExternalLinkAlt,
    faCopy,
    faPencilAlt,
    faDollarSign,
    faAngleRight,
    faAngleLeft,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faCaretDown,
    faFax,
    faPlusCircle,
    faUser,
    faBuilding,
    faBullseye,
    faTachometerAlt,
    faThList,
    faTh,
    faChartBar,
    faEdit,
    faCogs,
    faTimes,
    faPhone,
    faTrash,
    faEnvelope,
    faMoneyBill,
    faCalendarAlt,
    faUsers,
    faDownload,
    faEye,
    faHandPointUp,
    faThumbsUp,
    faSortDown,
    faSortUp,
    faSortAmountDown,
    faSortAmountUp,
    faSortAlphaDown,
    faSortAlphaUp,
    faChevronCircleLeft,
    faTimesCircle,
    faCaretUp,
    faCaretRight,
    faCircle,
    faDotCircle,
    faClock,
    faPercent,
    faMobile,
    faDesktop,
    faPaperPlane,
    faPlay,
    faPause,
    faUpload,
    faQuestion,
    faKey,
    faCog,
    faSearch,
    faFilter,
    faCrop,
    faInfoCircle,
    faBars,
    faFileDownload,
    faExclamationCircle,
    faLink,
    faPaperclip,
    faEllipsisV,
    faSort,
    faSpinner,
    faCheckSquare,
    faComments,
    faHistory,
    faCheck,
    faFileImage,
    faBox,
    faSitemap,
    faArchive,
    faFile,
    faBell,
    faCamera
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faCreditCard, faQuestionCircle, faImage } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faPlus,
    faMinus,
    faSync,
    faExternalLinkAlt,
    faCopy,
    faDotCircle,
    faPencilAlt,
    faDollarSign,
    faAngleRight,
    faAngleLeft,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faCaretUp,
    faCaretDown,
    faFax,
    faPlusCircle,
    faUser,
    faBuilding,
    faBullseye,
    faTachometerAlt,
    faThList,
    faTh,
    faChartBar,
    faEdit,
    faCogs,
    faTimes,
    faPhone,
    faTrash,
    faEnvelope,
    faMoneyBill,
    faCalendarAlt,
    faCalendar,
    faUsers,
    faDownload,
    faEye,
    faHandPointUp,
    faThumbsUp,
    faSortDown,
    faSortUp,
    faSortAmountDown,
    faSortAmountUp,
    faSortAlphaDown,
    faSortAlphaUp,
    faChevronCircleLeft,
    faCircle,
    faCaretRight,
    faCircle,
    faClock,
    faPercent,
    faMobile,
    faDesktop,
    faPaperPlane,
    faPlay,
    faPause,
    faUpload,
    faQuestion,
    faQuestionCircle,
    faKey,
    faCog,
    faSearch,
    faFilter,
    faCrop,
    faInfoCircle,
    faBars,
    faFileDownload,
    faExclamationCircle,
    faLink,
    faPaperclip,
    faEllipsisV,
    faSort,
    faSpinner,
    faCheckSquare,
    faComments,
    faHistory,
    faCheck,
    faCreditCard,
    faFileImage,
    faBox,
    faSitemap,
    faArchive,
    faFile,
    faBell,
    faCamera,
    faTimesCircle,
    faImage
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
