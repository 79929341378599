var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "wrapper", class: { safari: _vm.safari } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_vm.loading ? _c("loader", { key: "loader" }) : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }