export function Company({
    id,
    billingAddress,
    billingEmail,
    name,
    phoneNumber,
    website,
} = {})
{
    return {
        id,
        billingAddress,
        billingEmail,
        name,
        phoneNumber,
        website
    };
}
