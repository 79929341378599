import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {User} from '@/models/user/user';
import {UserDto} from '@/models/user/userDto';
import {flowRight} from 'lodash';
import { AuthDto } from './authDto';

export const userDtoToObject = dto =>
{
    const object = User(dto);
    return object;
};

export const objectToUserDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, UserDto)(data);
    return dto;
};

export const authDtoToObject = dto =>
{
    const object = AuthDto(dto);
    return object;
};
