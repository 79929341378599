import {objectToCommentDto} from "@/models/comment";
import {objectToActivityDto} from "@/models/activity";
import {objectToAdCreativeStatusDto} from '@/models/ad-creative-status';
import {objectToAdCreativeDto} from "@/models/ad-creative";

export function AdCreativeRequestFactory()
{
    const create = path =>
    {
        if(path.indexOf("comments") > -1)
            return objectToCommentDto;
        else if(path.indexOf("activities") > -1)
            return objectToActivityDto;
        else if(path.indexOf("status") > -1)
            return objectToAdCreativeStatusDto;

        return objectToAdCreativeDto;
    };

    return {
        create
    };
}
