import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {NetworkAdPreview} from '@/models/network-ad-preview/networkAdPreview';
import {NetworkAdPreviewDto} from '@/models/network-ad-preview/networkAdPreviewDto';
import {flowRight} from 'lodash';

const normalizePreviewElement = ({
    id,
    preview,
    type
}) =>
{
    if(typeof preview === "undefined")
        return {
            id: `${id}-${type}`,
            type
        };
    const element = new DOMParser().parseFromString(preview.data.html, "text/html");
    return {
        id: `${id}-${type}`,
        preview: element.documentElement.getElementsByClassName('ad-preview-wrap')[0],
        type
    };
};

export const networkAdPreviewDtoToObject = dto =>
{
    const object = flowRight(NetworkAdPreview, normalizePreviewElement)(dto);
    return object;
};

export const objectToNetworkAdPreviewDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, NetworkAdPreviewDto)(data);
    return dto;
};
