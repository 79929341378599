import {removeEmpty} from '@/helpers/objectUtils';

function PendingMetadata({
    name = null,
    status = null,
    sessionId = null,
    index = null,
    field = null
})
{
    return removeEmpty({
        name,
        status,
        sessionId,
        index,
        field
    });
}

/* const JobResults = Object.freeze({
    NONE: 0,
    OK: 1,
    SOURCE_NOT_FB: 2,
    SOURCE_NOT_FOUND: 3,
    COPY_NOT_FOUND: 4,
    ERROR: 5,
    STARTED: 6,
    RUNNING: 7,
}); */

function JobResult({
    none,
    ok,
    sourceNotFB,
    sourceNotFound,
    copyNotFound,
    error,
    started,
    running
} = {})
{
    return {
        none,
        ok,
        sourceNotFB,
        sourceNotFound,
        copyNotFound,
        error,
        started,
        running
    };
}

export function PendingJob({
    jobId = null,
    metadata = new PendingMetadata(),
    status = null,
    netCmpGrpId = null,
    jobResult = JobResult
} = {})
{
    return {
        jobId,
        metadata,
        status,
        netCmpGrpId,
        jobResult,
        timestamp: Date.now()
    };
}
