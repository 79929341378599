import {RESPONSE_TYPES} from '@/helpers/enums/enums';
import Transformer from "@/services/transformers/ITransformer";
import {responseMappings} from "@/services/transformers/transformMapper";

const postArrayResponse = (response, action) =>
{
    return {
        ...response,
        data: response.data.map(action)
    };
};

const arrayResponse = (response, action) =>
{
    return {
        ...response,
        data:
        {
            ...response.data,
            data: response.data.data.map((value, index) => action(value, index))
        }
    };
};

const objectResponse = (response, action) =>
{
    let responseData = response.data;

    return {
        ...response,
        data: Reflect.apply(action, this, [responseData])
    };
};

function ResponseTransformer(){}
ResponseTransformer.prototype = Object.create(Transformer);
ResponseTransformer.prototype = {
    ...ResponseTransformer.prototype,
    transform: response =>
    {
        const {url, method, responseType} = response.config;
        // always assumes that the api url is set as /s/{route}/
        const route = url.split('/')[2];

        if(Object.prototype.hasOwnProperty.call(responseMappings, route))
        {
            const action = responseMappings[route];
            const shouldTransform = action.shouldTransform(url) && ![RESPONSE_TYPES.BLOB, RESPONSE_TYPES.ARRAY_BUFFER].includes(responseType);

            if(!shouldTransform)
                return response;

            const transformAction = action.transformer.factory ? Reflect.apply(action.transformer.factory, this, [url, method]) : action.transformer;
            // some endpoints (e.g. ad-creative POST) returns array of single data in response
            if (method === 'post' && Array.isArray(response.data))
                return postArrayResponse(response, transformAction);

            // single object response
            if(!Array.isArray(response.data.data))
                return objectResponse(response, transformAction);

            // array of data response
            return arrayResponse(response, transformAction);
        }

        return response;
    }
};

export default ResponseTransformer;
