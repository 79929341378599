import { post, put } from "@/services/httpService";

const resource = "/auth";

const login = (params) =>
{
    const {username, password} = params;
    return post(`${resource}`, {
        username,
        password
    });
};

const refreshAuthToken = (params) =>
{
    const {username, refreshToken} = params;
    return put(`${resource}/${username}`, {
        refreshToken
    });
};

export
{
    login, refreshAuthToken
};
