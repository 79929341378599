export function IntegrationDTO({
    redirectHost = "",
    clientIntegrationId = null
} = {})
{
    return {
        redirectHost,
        clientIntegrationId
    };
}
