'use strict';
function addClass(element, name)
{
    element.classList ? element.classList.add(name) : element.className += ` ${name}`;
}

function removeClass(element, ...name)
{
    element.classList.remove(...name);
}

function removeSVGClass(element, name)
{
    var reg = new RegExp(`(\\s|^)${name}(\\s|$)`);
    element.className.baseVal = element.className.baseVal.replace(reg, ' ');
}

function hasClass(element, name)
{
    if(element.classList)
    {
        return element.classList.contains(name);
    }
    else
    {
        var reg = new RegExp(`(\\s|^)${name}(\\s|$)`);
        return reg.test(element.className);
    }
}

function toggleClass(element, name)
{
    element.classList.toggle(name);
}

export {addClass, removeClass, removeSVGClass, hasClass, toggleClass};
