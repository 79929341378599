export function SuggestionFieldDto({
    field,
    clientId
} = {})
{
    return  {
        field,
        clientId,
        async: true
    };
}
