export function Campaign({
    id,
    batchId,
    clientId,
    cpcGoal,
    cplGoal,
    cpmGoal,
    dailyLeadsGoal,
    addMethod,
    isActive,
    maxBudget,
    name,
    shouldRepeat,
    status,
    type,
    clientPermissions,
    startDate,
    endDate,
    maxMp,
    jobId,

    activeCampaigns,
    batchStatus,
    batchEndDate,
    batchEndDateSpendAdjust,
    batchGoals,
    batchStartDate,
    batchStartDateSpendAdjust,
    budget,
    clicks,
    clientName,
    clientCpc,
    clientCpl,
    clientSpend,
    clientCpm,
    cmpType,
    comments,
    conversionRate,
    cpc,
    cpl,
    cpm,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    impressions,
    leads,
    likes,
    lpNeeded,
    mediaPercentage,
    netCmpNeeded,
    networkLeads,
    postReactions,
    scheduledNetworkCampaigns,
    shares,
    spend,
    createdAt,
    campaignSchedulesIds,
    batchesIds,
    isClientSpendVariable,
    maxCpc,
    maxCpm,
    maxCpl,
} = {})
{
    return {
        id,
        name,
        batchId,
        clientId,
        cpcGoal,
        cplGoal,
        cpmGoal,
        addMethod,
        dailyLeadsGoal,
        maxBudget,
        status,
        type: type || cmpType,
        clientPermissions,
        startDate,
        endDate,
        isActive,
        shouldRepeat,
        maxMp,
        jobId,

        activeCampaigns,
        batchStatus: batchStatus || [],
        batchEndDate,
        batchEndDateSpendAdjust,
        batchGoals,
        batchStartDate,
        batchStartDateSpendAdjust,
        budget,
        clicks,
        clientName,
        clientCpc,
        clientCpl,
        clientSpend,
        clientCpm,
        cmpType: cmpType || type,
        comments,
        conversionRate,
        cpc,
        cpl,
        cpm,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        impressions,
        leads,
        likes,
        lpNeeded,
        mediaPercentage,
        netCmpNeeded,
        networkLeads,
        postReactions,
        scheduledNetworkCampaigns,
        shares,
        spend,
        createdAt,
        campaignSchedulesIds,
        batchesIds,
        isClientSpendVariable,
        maxCpc,
        maxCpm,
        maxCpl,
    };
}
