import { PRESETS } from "../helpers/enums/enums";

export default function createSalesPerformancePreset()
{
    return {
        name: PRESETS.SALES_PERFORMANCE,
        columns: [
            "isActive",
            "leads",
            "crmWorking",
            "crmContacted",
            "crmContactRate",
            "crmDemosSet",
            "crmDemoSetRate",
            "crmDemosPerformed",
            "showUpRate",
            "crmWins",
            "crmWinRate",
            "crmNeedsSalesFollowing",
            "crmLosses",
            "crmDisqualified"
        ]
    };
}
