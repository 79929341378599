import Dropdown       from './components/common/Dropdown';
import Multiselect    from "vue-multiselect";
import DateTimePicker from 'vue-bootstrap-datetimepicker';

import 'vue-multiselect/dist/vue-multiselect.min.css';

const GlobalComponents = {
    install(Vue)
    {
        Vue.component('Dropdown', Dropdown);
        Vue.component('Datepicker', DateTimePicker);
        Vue.component('Multiselect', Multiselect);
    }
};

export default GlobalComponents;
