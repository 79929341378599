import {removeEmpty} from '@/helpers/objectUtils';
import {createUrlFiltersParameters, createUrlObjectFilters} from '@/helpers/urlUtils';
import {get, post, put} from '@/services/httpService';
import store from '@/store/store';

const resource = "/s/network-campaigns";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'networkCampaignsPage');
    return {
        objectFilters,
        filterParams
    };
};

const getFilters = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;
    
    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;
    
    const filterParams = createFilters(sort);
    
    return removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        netCmpGrpId: filters.id,
        ...filterParams.objectFilters,
        ...filterParams.filterParams
    });
};

const fetchStats = stateParams =>
{
    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

const fetchAll = (payload = {}, rest) =>
{
    return get(`${resource}`, {
        params: payload,
        ...rest
    });
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

const duplicate = ({id, duplicateAs}) =>
{
    return post(`${resource}/${id}/duplicate`, { duplicateAs });
};

const checkDuplicationStatus = ({id, jobId}) =>
{
    return get(`${resource}/${id}/duplicate/${jobId}`);
};

const updateStatus = ({id, status}) =>
{
    return put(`${resource}/${id}/status`, {
        status
    });
};

export {
    getFilters,
    fetchStats,
    fetch,
    fetchAll,
    toggle,
    duplicate,
    checkDuplicationStatus,
    updateStatus
};
