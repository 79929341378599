import {ENTITY_SUCCESS_STATE} from '@/helpers/enums/enums';
import createColumnizator from '@/models/Columnizator';
import {PendingJob} from '@/models/PendingJob';
import createTotalizator from '@/models/Totalizator';
import {
    fetch,
    checkDuplicationStatus,
    duplicate, fetchAll,
    fetchStats,
    getFilters,
    toggle, updateStatus
} from '@/repositories/NetworkCampaignRepository';
import {LocalStorageObservableService} from '@/services/localStorageObservableService';
import {LocalStorageService} from '@/services/localStorageService';
import EntityAdapter from '@/store/adapter';
import moment from "moment";
import projConfig from '../../../projConfig';
import mt from '../../mutationTypes';
import enums, {JOB_STATUS} from '@/components/performance/constants';

const state =
{
    networkCampaigns: EntityAdapter().createEntityAdapter(),
    totals: null,
    updatedAt: moment(),
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'spend',
        desc: true
    },
    duplicationJobs: {}
};

const mutations =
{
    [mt.UpsertManyNetworkCampaigns](state, networkCampaigns)
    {
        state.networkCampaigns = state.networkCampaigns.upsertMany(networkCampaigns, state.networkCampaigns);
        state.updatedAt = moment();
    },
    [mt.SetNetworkCampaignsTotals](state, totals)
    {
        state.totals = totals;
    },
    [mt.AddNetworkCampaign](state, networkCampaign)
    {
        state.networkCampaigns = state.networkCampaigns.addOne(networkCampaign, state.networkCampaigns);
    },
    [mt.UpdateNetworkCampaign](state, networkCampaign)
    {
        state.networkCampaigns = state.networkCampaigns.updateOne(networkCampaign, state.networkCampaigns);
    },
    [mt.SetNetworkCampaignPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetNetworkCampaignCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetNetworkCampaignSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.SetNetworkCampaignDuplicationJob](state, { id, job })
    {
        state.duplicationJobs[id] = job;
    }
};

const getters =
{
    getNetworkCampaignsIds: (state) => state.networkCampaigns.ids,
    getNetworkCampaignsEntities: (state) => state.networkCampaigns.entities,
    getNetworkCampaignById: (state) => id => state.networkCampaigns.entities[id],
};

const actions =
{
    async fetchNetworkCampaignsStats({ commit, rootGetters}, {filters, config})
    {
        try
        {
            commit(mt.SetLoading, true);
            const stateParams = getFilters(state, filters);
            const response = await fetchStats(stateParams);
            let responseData = response && response.data ? response.data.data : [];
            let responseDataTotals = response && response.data ? response.data.totals : {};

            const networkCampaigns = createColumnizator(responseData.map(networkCampaign => ({
                ...networkCampaign,
                adGroupId: stateParams.netCmpGrpId
            })));
            const totals = createTotalizator(responseDataTotals);

            commit(mt.UpsertManyNetworkCampaigns, networkCampaigns);
            commit(mt.SetNetworkCampaignsTotals, totals);

            if(networkCampaigns.length !== 0)
            {
                const adGroup = rootGetters.getAdGroupById(stateParams.netCmpGrpId);
                if(adGroup)
                {
                    commit(mt.UpdateAdGroup, {
                        id: adGroup.id,
                        changes: {
                            networkCampaignsIds: [...new Set([
                                ...networkCampaigns.map(networkCampaign => networkCampaign.id),
                                ...adGroup.networkCampaignsIds
                            ])]
                        }
                    });

                    const adGroupExpanded = {
                        id: adGroup.id,
                        networkCampaignsIds: [...new Set([
                            ...networkCampaigns.map(networkCampaign => networkCampaign.id),
                            ...adGroup.networkCampaignsIds
                        ])]
                    };
                    if (config.isExpanded)
                        adGroupExpanded.isExpanded = config.isExpanded;

                    commit(mt.UpsertAdGroupExpanded, adGroupExpanded);
                }
            }
            else
            {
                commit(mt.UpsertAdGroupExpanded, config.isExpanded ? {
                    id: stateParams.netCmpGrpId,
                    isExpanded: config.isExpanded,
                } : {id: stateParams.netCmpGrpId});
            }
        }
        catch (error)
        {
            throw error || new Error('Error getting network campaigns stats.');
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleNetworkCampaignDelivery({ commit }, { id, turnOn })
    {
        try
        {
            let actionString = (turnOn ? 'play' : 'pause');
            commit(mt.SetLoading, true);
            const response = await toggle({id, action: actionString});

            if (response && response.status && [200, 204].includes(response.status))
                commit(mt.UpdateNetworkCampaign, {
                    id,
                    changes: {
                        isActive: turnOn
                    }
                });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadNetworkCampaignAndSetFilter({dispatch, commit, getters}, id)
    {
        try
        {
            const networkCampaign = await dispatch('fetchNetworkCampaignDetails', {id});
            // legacy mutation
            commit(mt.SetNetworkCampaignFilter, { id: networkCampaign.id, name: networkCampaign.name });
            commit(mt.SetObjectFilter, { id: networkCampaign.id, name: networkCampaign.name, type: enums.OBJECT_FILTERS.NETWORK_CAMPAIGN });
            return Promise.resolve(getters.getNetworkCampaignById(id));
        }
        catch (error)
        {
            console.error(error);
        }
    },
    async fetchNetworkCampaignDetails({ commit }, {id})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id});
            const networkCampaign = response.data;

            commit(mt.AddNetworkCampaign, networkCampaign);
            return networkCampaign;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async requestCampaignDuplication({ commit, getters }, { id, duplicateAs })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await duplicate({id, duplicateAs});

            /*
               Response is
               {
                   public id: string;
                   public status: 'none' | 'started' | 'running' | 'done' | 'error'
                   public jobResult: [none = 0 | ok = 1 | sourceNotFB = 2 | sourceNotFound = 3 |
                                       copyNotFound = 4 | error = 5 | started = 6 | running = 7];
                   public error: string;
               }
               */

            if (!response || !response.status || ![200, 204].includes(response.status))
                throw response;

            const responseData = response.data;
            const localStorageObservable = LocalStorageObservableService.getInstance();
            const jobs = localStorageObservable.get(LocalStorageService.NETWORK_CAMPAIGN_DUPLICATE_JOBS);

            localStorageObservable.set(LocalStorageService.NETWORK_CAMPAIGN_DUPLICATE_JOBS, {
                ...jobs,
                [id]: new PendingJob({
                    metadata: {
                        name: getters.getNetworkCampaignById(id).name
                    },
                    jobId: responseData?.id,
                    jobResult: responseData.jobResult,
                    status: responseData.status
                })
            });
        }
        catch(error)
        {
            let errMsg = error.response ? error.response.data : (!error.request ? error.message : null);

            throw new Error(errMsg);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async checkCampaignDuplicationStatus({ }, { id, jobId })
    {
        try
        {
            const response = await checkDuplicationStatus({id, jobId});

            /*
                Response is
                {
                    public id: string;
                    public status: 'none' | 'started' | 'running' | 'done' | 'error'
                    public jobResult: NetworkCampaign; // not null only if status = 'done'
                    public error: string;
                }
                */

            if (!response || !response.status || ![200, 204].includes(response.status))
                throw response;

            const responseData = response.data;
            const {status: jobStatus, error = ""} = responseData;

            switch(jobStatus)
            {
                case JOB_STATUS.STARTED:
                case JOB_STATUS.RUNNING:
                    return;
                case JOB_STATUS.ERROR:
                    throw new Error(error);
                case JOB_STATUS.DONE:
                    return ENTITY_SUCCESS_STATE.UPDATED;
            }

            return ENTITY_SUCCESS_STATE.UPDATED;
        }
        catch(error)
        {
            // 401 here means copied campaign wasn't found in the DB.
            let errMsg = error.response ? error.response.data : (!error.request ? error.message : null);

            throw new Error(errMsg);
        }
    },
    async updateNetworkCampaignStatus({ commit, dispatch }, { id, adGroupId, status })
    {
        try
        {
            commit(mt.SetLoading, true);

            await updateStatus({id, status});

            commit(mt.UpdateNetworkCampaign, {
                id,
                changes: { status }
            });
            await dispatch('fetchAdGroupDetails', {id: adGroupId});
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchNetworkCampaignsForAdGroup({ commit }, { adGroupId })
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetchAll({
                netCmpGrpId: adGroupId
            }, {
                isThrowable: true
            });

            const responseData = response.data.data;
            return responseData;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const NetworkCampaignDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default NetworkCampaignDataModule;
