import { fetchStats } from '@/repositories/UserRepository';
import mt from '../../mutationTypes';
import projConfig from '../../../projConfig';

const state =
{
    leaderboardData: [],
    statTotals:
    {
        leads: null,
        conversionRate: null,
        ctr: null,
        socialSignals: null,
    },
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'leads',
        desc: true
    }
};

const mutations =
{
    [mt.SetLeaderboardData](state, res)
    {
        
        if (!res || !res.data || res.data.length < 1) return state.leaderboardData = [];
        
        const leads = res.data.reduce((max, elem) => max && max.leads > elem.leads ? max : elem, null);
        state.statTotals.leads = { marketer: leads.user, value: leads.leads };

        const conversionRate = res.data.reduce((max, elem) => max && max.conversionRate > elem.conversionRate ? max : elem, null);
        state.statTotals.conversionRate = { marketer: conversionRate.user, value: conversionRate.conversionRate };

        const ctr = res.data.reduce((max, elem) => max && max.ctr > elem.ctr ? max : elem, null);
        state.statTotals.ctr = { marketer: ctr.user, value: ctr.ctr };

        const socialSignals = res.data.reduce((max, elem) => max && max.socialSignals > elem.socialSignals ? max : elem, null);
        state.statTotals.socialSignals = { marketer: socialSignals.user, value: socialSignals.socialSignals };

        state.leaderboardData = res.data;
    },
    [mt.SetLeaderboardPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging,
            {
                currPage: state.page.currPage,
                totalResults: page.totalCount,
                currentResults: page.currentCount,
                totalPages: state.page.totalPages
            });
    },
    [mt.SetLeaderboardCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetLeaderboardSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    }
};

const getters =
{
    getLeaderboardData(state)
    {
        return state.leaderboardData;
    },
    getLeaderboardPage(state)
    {
        return state.page;
    },
    getLeaderboardSort(state)
    {
        return state.sort;
    },
    getLeaderboardTotals(state)
    {
        return state.statTotals;
    }
};

const actions =
{
    async loadLeaderboardData({ commit, state }, filters)
    {
        try 
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);
            if (response && response.status === 200 && response.data) 
            {
                commit(mt.SetLeaderboardData,
                    {
                        data: response.data.data
                    });
                commit(mt.SetLeaderboardPaging,
                    {
                        ...response.data.pages,
                        currentCount: response.data.data.length
                    });
            }
            else 
            {
                commit(mt.SetLeaderboardData, { data: [] });
                commit(mt.SetLeaderboardPaging, {
                    top: projConfig.itemsPerPage,
                    skip: 0,
                    totalCount: 0,
                    currentCount: 0,
                    currPage: 1
                });
            }
        }
        catch (error) 
        {
            console.error(error);
            throw error || new Error('');
        }
        finally 
        {
            commit(mt.SetLoading, false);
        }
    }
};

const LeaderboardDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default LeaderboardDataModule;
