import { CampaignSchedules } from "@/models/campaign-schedules/campaignSchedules";
import { CampaignSchedulesDto } from "@/models/campaign-schedules/campaignSchedulesDto";
import {flowRight} from 'lodash';

export const campaignSchedulesDtoToObject = dto =>
{
    const object = CampaignSchedules(dto);
    return object;
};

export const objectToCampaignSchedulesDto = data =>
{
    const dto = flowRight(CampaignSchedulesDto)(data);
    return dto;
};
