import {LANDING_PAGE_FIELD_TYPE, LANDING_PAGE_FIELDS} from '@/components/performance/constants';
import {removeEmpty} from '@/helpers/objectUtils';
import {FormQuestion} from '@/models/landing-page-form-questions/formQuestion';
import {FormQuestionDto} from '@/models/landing-page-form-questions/formQuestionDto';
import {curry, flowRight} from 'lodash';

const getFieldName = (type, optionsJson) =>
{
    if (type === LANDING_PAGE_FIELD_TYPE.STRING && !optionsJson)
        return LANDING_PAGE_FIELDS.SHORT_ANSWER;
    else if (type === LANDING_PAGE_FIELD_TYPE.STRING && optionsJson.length !== 0)
        return LANDING_PAGE_FIELDS.MULTIPLE_CHOICE;
    else
        return type;
};

const mappedFieldsLabels =
    {
        ["fullName"]: 'Full Name',
        ["firstName"]: 'First Name',
        ["lastName"]: 'Last Name',
        ["email"]: 'Email',
        ["phoneNumber"]: 'Phone',
        ["phone"]: 'Phone',
        ["company"]: 'Company',
        ["companyName"]: 'Company Name',
        ["jobTitle"]: 'Job Title',
        ["website"]: 'Website',
        ["companySize"]: 'Number of Employees',
        ["shortAnswer"]: 'Short Answer'
    };

const addParametersToDto = curry((index, field) => ({
    ...field,
    index,
    label: mappedFieldsLabels[field.type] || field.name,
    name: getFieldName(field.type, field.optionsJson),
    optionsJson: field.optionsJson && field.optionsJson.length > 0 ? JSON.parse(field.optionsJson) : null
}));

export const formQuestionDtoToObject = (dto, index) =>
{
    const object = flowRight(removeEmpty, FormQuestion, addParametersToDto(index))(dto);
    return object;
};

/**
 * transforms Form Question Object to FormQuestion DTO
 * It does not need setDtoBase because it is not part of main request
 * It should be used to transform lpFields from LandingPageFieldsDto
 * @param data
 * @return {FormQuestionDto}
 */
export const objectToFormQuestionDto = data =>
{
    const dto = flowRight(removeEmpty, FormQuestionDto)(data);
    return dto;
};
