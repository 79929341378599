import { objectToNetworkAudienceDto } from "@/models/network-audience";
import { objectToAudienceDto } from "@/models/audience";

export function AudienceRequestFactory()
{
    const create = path =>
    {
        if(path.indexOf("network-audiences") > -1)
            return objectToNetworkAudienceDto;

        return objectToAudienceDto;
    };

    return {
        create
    };
}
