import axios from 'axios';
import moment from "moment";
import projConfig from '@/projConfig';
import router from '@/router.js';
import requestSourceService from "./requestSourceService";
import requestHelper, { requestRefresh } from "@/services/requestHelper.service";
import { apiErrorsHandler } from './errorsService';
import { forkJoin, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import CancelationError from '@/errors/cancelationError';
import AdBlockerError from '@/errors/adBlockerError';
import ResponseTransformer from "@/services/transformers/ResponseTransformer";
import RequestTransformer from "@/services/transformers/RequestTransformer";
import { LocalStorageService } from './localStorageService';
import { TOKEN } from '@/helpers/enums/enums';
import { REFRESH_TOKEN_ERRORS } from '@/errors/RefreshTokenError';
import store from '@/store/store';
import { USER_ERRORS } from '@/errors/UserError';
import * as Sentry from "@sentry/browser";

const requestHelperInstance = requestHelper.getInstance();

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc
 * */
const config = {
    baseURL: projConfig.apiRoot
};

/**
 * Creating the instance of Axios
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config);

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * @param {*} config
 */
const authInterceptor = config =>
{
    // don't add authorization header if request is for amazonaws (s3 bucket)
    if (config.url && (config.url.indexOf("amazonaws") > -1 || config.url.indexOf("facebook") > -1))
        return config;

    // token refreshed and user is loaded
    // attempt to reload the request using the new auth token
    // https://github.com/axios/axios/issues/934
    /* error.config.headers['Authorization'] = res.newToken;
    return axios.request(error.config); */
    /** add auth token */
    const token = LocalStorageService.getInstance().get(TOKEN);
    if (token)
        config.headers.Authorization = `Bearer ${token}`;

    return config;
};

const loggerInterceptor = config =>
{
    /** Add logging here */
    return config;
};

const requestDtoMapper = request =>
{
    const {method} = request;
    if(method === "get") return request;
    const transformer = new RequestTransformer();
    return transformer.transform(request);
};

const responseDtoMapper = response =>
{
    //if(config.cancelToken) throw new axios.Cancel('Operation canceled by the user.');
    const {method} = response.config;
    // only allow transformations on POST and GET responses
    if(!["post", "get"].includes(method)) return response;
    const transformer = new ResponseTransformer();
    return transformer.transform(response);
};

// Good place to put this since all needed objects are available
const unsecuredRoutes = ["login", "forgot-password", "new-password", "reset-password"];

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
httpClient.interceptors.request.use(requestDtoMapper);

let counter = 0;
const REQUEST_LIMIT = 3;
/** Adding the response interceptors */
httpClient.interceptors.response.use(responseDtoMapper,
    async error =>
    {

        apiErrorsHandler(error);
        const originalRequest = error.config;

        // eslint-disable-next-line
        if (error && error.response && error.response.status === 401 &&
            !unsecuredRoutes.includes(router.currentRoute.name) && !originalRequest._isRetried)
        {
            originalRequest._isRetried = true;
            try
            {
                return requestRefresh({httpClient, originalRequest, authInterceptor});
            }
            catch(error)
            {
                console.error({error});
                switch(error.cause)
                {
                    case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_REQUEST_ERROR:
                        // don't do anything on refresh token connection errors, such as ERR_NAME_NOT_FOUND
                        Sentry.captureException(new Error(`[responseInterceptor]: exchangeRefreshToken connection error`), {
                            tags: {
                                section: "errors",
                            },
                        });
                        // retry for the refresh token

                        counter++;
                        if(counter > REQUEST_LIMIT)
                            return;
                        return requestRefresh({httpClient, originalRequest, authInterceptor});
                    case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_MISSING:
                    case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_EXPIRED:
                        await store.dispatch('logoutUser');
                        router.push({ name: "login", replace: true });
                        return;
                }

                return;
            }
        }
        // Only rethrow if not a 401
        const errorMessage = error.response ? error.response : error;
        return Promise.reject(errorMessage);
    }
);

const get = async(url, config = {}) =>
{
    const {cancelToken} = config;
    if(cancelToken)
    {
        config = {...config, cancelToken };
    }
    else if(config.cancelable)
    {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        requestSourceService.getInstance().request = { source, url, timestamp: moment().unix() };
        config = {...config, cancelToken: source.token };
    }
    else
    {
        config = {...config };
    }

    try
    {
        if(config.isDisabler) requestHelperInstance.pendingRequestsInProgress = true;
        const request = await httpClient.get(url, config);

        return request;
    }
    catch (error)
    {
        // we don't throw every error back as it might stop the flow for some requests.
        // only canceled and explicitly set to throwable will be handled
        if (axios.isCancel(error))
            throw new CancelationError(error);

        if(config.isThrowable)
            throw error;
        // handle ad block errors (only on ad creatives at the moment)
        const { message, response } = error;

        const isAdCreatives = url.indexOf("/s/ad-creatives/") > -1;
        const hasNoResponse = typeof response === "undefined";
        const isNetworkError = message === "Network Error";

        if(isAdCreatives && hasNoResponse && isNetworkError)
            throw new AdBlockerError("Ad Blocker detected");
    }
    finally
    {
        if(config.isDisabler) requestHelperInstance.pendingRequestsInProgress = false;
    }
};

const catchObservableError = error => throwError(error.message || "Multiple Request Error");

const getMultiple = requests => forkJoin(requests).pipe(catchError(catchObservableError));

const post = async(url, data = {}, config) =>
{
    try
    {
        if(config && !config.isSilent)
            requestHelperInstance.pendingRequestsInProgress = true;

        const request = await httpClient.post(url, data, config);
        return request;
    }
    finally
    {
        requestHelperInstance.pendingRequestsInProgress = false;
    }
};

const put = async(url, data = {}, config) =>
{
    try
    {
        if(config && !config.isSilent)
            requestHelperInstance.pendingRequestsInProgress = true;
        const request = await httpClient.put(url, data, config);
        return request;
    }
    finally
    {
        requestHelperInstance.pendingRequestsInProgress = false;
    }
};

const patch = async(url, data = {}, config) =>
{
    try
    {
        if(config && !config.isSilent)
            requestHelperInstance.pendingRequestsInProgress = true;
        const request = await httpClient.patch(url, data, config);
        return request;
    }
    finally
    {
        requestHelperInstance.pendingRequestsInProgress = false;
    }
};

const deleteReq = async (url, config) =>
{
    try
    {
        if(config && !config.isSilent)
            requestHelperInstance.pendingRequestsInProgress = true;

        const request = await httpClient.delete(url, config);
        return request;
    }
    finally
    {
        requestHelperInstance.pendingRequestsInProgress = false;
    }
};



export { httpClient, get, getMultiple, post, put, patch, deleteReq };
