import {removeEmpty} from '@/helpers/objectUtils';
import {ColumnPreset} from '@/models/column-presets/columnPreset';
import {ColumnPresetDto} from '@/models/column-presets/columnPresetDto';
import {setDtoBase} from '@/models/DtoBase';
import {flowRight} from 'lodash';

export const columnPresetDtoToObject = dto =>
{
    const object = ColumnPreset(dto);
    return object;
};

export const objectToColumnPresetDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, ColumnPresetDto)(data);
    return dto;
};
