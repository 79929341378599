import {get, post, put} from "@/services/httpService";

const resource = "/s/privacy-policies";

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        isThrowable: true,
        ...rest
    });
};

const update = ({id, data} = {}) =>
{
    return put(`${resource}/${id}`, {
        ...data
    });
};

const create = (data) =>
{
    return post(`${resource}`, {
        ...data
    });
};

export {
    fetch,
    update,
    create
};