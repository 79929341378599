export function NetworkAd({
    id,
    adCreativeId,
    cmpType,
    lpId,
    image1,
    isActive,
    largeRectangleUrl,
    source,
    status,
    statusText,
    title,
    name,
    networkAdSetId,
    
    // stats props
    clicks,
    clientCpc,
    clientCpl,
    clientSpend,
    comments,
    conversionRate,
    cpc,
    cpl,
    cpm,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    description,
    frequency,
    impressions,
    leads,
    likes,
    mediaPercentage,
    networkLeads,
    networkStatus,
    postReactions,
    reach,
    shares,
    spend
} = {})
{
    return {
        id,
        adCreativeId,
        cmpType,
        landingPageId: lpId,
        image1,
        isActive,
        largeRectangleUrl,
        source,
        status,
        statusText,
        title,
        name,
        networkAdSetId,
        thumbnailsIds: [],
        
        // stats props
        clicks,
        clientCpc,
        clientCpl,
        clientSpend,
        comments,
        conversionRate,
        cpc,
        cpl,
        cpm,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        description,
        frequency,
        impressions,
        leads,
        likes,
        mediaPercentage,
        networkLeads,
        networkStatus,
        postReactions,
        reach,
        shares,
        spend,
    };
}
