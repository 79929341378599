import moment from "moment";
import mt from '../../mutationTypes';
import projConfig from '@/projConfig';
import enums from '@/components/performance/constants';
import createColumnizator from '@/models/Columnizator';
import createTotalizator from '@/models/Totalizator';
import EntityAdapter from "@/store/adapter";
import {fetchStats, create, update, fetch, fetchAll, toggle} from "@/repositories/OffersRepository";

const state = {
    offers: EntityAdapter().createEntityAdapter(),
    offersFilteredIds: [],
    expandedOffers: EntityAdapter().createEntityAdapter(),
    totals: null,
    updatedAt: moment(),

    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort: {
        field: 'spend',
        desc: true
    }
};

const mutations = {
    [mt.SetOffers](state, offers)
    {
        state.offers = state.offers.setAll(offers, state.offers);
    },
    [mt.SetOffersTotals](state, data)
    {
        state.totals = data;
    },
    [mt.SetOffersPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetOffersSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.AddOffer](state, offer)
    {
        state.offers = state.offers.addOne(offer, state.offers);
    },
    [mt.UpsertOffer](state, offer)
    {
        state.offers = state.offers.upsertOne(offer, state.offers);
    },
    [mt.UpdateOffer](state, offer)
    {
        state.offers = state.offers.updateOne(offer, state.offers);
    },
    [mt.ClearOffersData](state)
    {
        state.offers = state.offers.removeAll(state.offers);
    },
    [mt.RemoveOffer](state, id)
    {
        state.offers = state.offers.removeOne(id, state.offers);
    },
    [mt.SetOffersFilteredIds](state, data)
    {
        state.offersFilteredIds = data;
    },
    [mt.SetOfferStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    },
    [mt.UpsertOfferExpanded](state, offer)
    {
        state.expandedOffers = state.expandedOffers.upsertOne(offer, state.expandedOffers);
    },
    [mt.UpdateOfferExpanded](state, offer)
    {
        state.expandedOffers = state.expandedOffers.updateOne(offer, state.expandedOffers);
    },
};

const getters =  {
    getOffersIds: (state) => state.offers.ids,
    getOffersEntities: (state) => state.offers.entities,
    getOfferById: (state) => id => state.offers.entities[id],
    getOffersFilteredIds: (state) => state.offersFilteredIds,
    getOffers(state, getters)
    {
        return getters.getOffersIds.map(id => getters.getOffersEntities[id]);
    },
    getOffersPage: state =>
    {
        return state.page;
    },
    getOffersIdsWithoutThumbnails: (state, getters) =>
    {
        return getters
            .getOffersIds
            .map(getters.getOfferById)
            .filter(offer => offer.thumbnailsIds.length === 0)
            .map(({id}) => id);
    },
    getOffersTotals: state =>
    {
        return state.totals;
    },
    getOffersLastUpdatedAt(state)
    {
        return state.updatedAt;
    },
    getOfferExtendById: (state) => id => state.expandedOffers.entities[id],
    getLandingPagesForOffer: (state, getters, rootState, rootGetters) => offerId =>
    {
        const offerExpanded = getters.getOfferExtendById(offerId);
        if (!offerExpanded)
            return;
        const {landingPagesIds} = offerExpanded;
        return landingPagesIds ? landingPagesIds.map(rootGetters.getLandingPageById) : [];
    },
};

const actions = {
    async fetchOffersStats({commit, state, rootGetters}, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);
            if (response && response.status === 200 && response.data)
            {
                let responseData = [];
                response.data.data.forEach(offer =>
                {
                    let offerData = {
                        ...offer,
                        createdAt: 0,
                        thumbnailsIds: [],
                        landingPagesIds: []
                    };
                    responseData.push(offerData);
                });
                commit(mt.SetOffers, createColumnizator(responseData));
                commit(mt.SetOffersTotals, createTotalizator(response.data.totals));
                commit(mt.SetOffersPaging, {
                    ...response.data.pages,
                    currentCount: response.data.data.length
                });
                if (rootGetters.getAllFiltersCount('offersPage'))
                    commit(mt.SetOffersFilteredIds, responseData.map(data => data.id));
                else
                    commit(mt.SetOffersFilteredIds, []);
            }
            else
            {

                commit(mt.SetOffers, []);
                commit(mt.SetOffersFilteredIds, []);
                commit(mt.SetOffersTotals, null);
                commit(mt.SetOffersPaging,
                    {
                        top: projConfig.itemsPerPage,
                        skip: 0,
                        totalCount: 0,
                        currPage: 1,
                        currentCount: 0
                    });
            }
        }
        catch (error)
        {
            throw new Error("No offers found");
        }
        finally
        {
            commit(mt.SetOfferStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },

    async fetchOffersDataWithThumbnails({ dispatch, getters })
    {
        await dispatch("fetchOffersStats");
        await dispatch("fetchThumbnailsForOffers", {ids: getters.getOffersIdsWithoutThumbnails});
    },
    async loadOffersAndSetFilter({ dispatch, commit, getters }, id)
    {
        await dispatch('fetchOfferDetails', { id });
        const offer = getters.getOfferById(id);
        commit(mt.SetObjectFilter, { id: offer.id, name: offer.name, type: enums.OBJECT_FILTERS.OFFER });
        return Promise.resolve(offer);
    },
    async fetchOfferDetails({ commit }, { id, ...rest } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id, ...rest});
            if (response && response.status && response.status === 200)
                commit(mt.UpsertOffer, response.data);
            else
                console.log(new Error(response));
        }
        catch (error)
        {
            console.log(new Error(error));
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleOfferDelivery({ commit }, { id, turnOn })
    {
        try
        {
            let action = turnOn ? "play" : "pause";

            commit(mt.SetLoading, true);
            const res = await toggle({id, action});

            commit(mt.SetLoading, false);

            if (res && res.status && [200, 204].includes(res.status))
                // if all is good, commit to state
                commit(mt.UpdateOffer, {
                    id,
                    changes: {
                        isActive: turnOn
                    }
                });
        }
        catch (error)
        {
            let errMsg = error.response
                ? (errMsg = error.response.data)
                : !error.request
                    ? error.message
                    : null;
            throw new Error(errMsg);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createOffer({ commit, rootGetters }, offer = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(offer);
            commit(mt.AddOffer, {
                ...response.data,
                createdAt: new Date().getTime()
            });
            if(rootGetters.getAllFiltersCount('offersPage'))
                commit(mt.SetOffersFilteredIds, [...new Set([response.data.id, ...state.offersFilteredIds])]);
            return response.data.id;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async updateOffer({ commit }, {id, offer})
    {
        try
        {
            commit(mt.SetLoading, true);
            await update({id, offer});
            commit(mt.UpdateOffer, {
                id,
                changes: offer
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async fetchOfferByName({ commit }, { offerName, clientId, top = 1000, skip = 0, load = true, ...rest } = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll({
                offerName,
                clId: clientId,
                top,
                skip
            }, rest);
            if(load) commit(mt.SetLoading, false);
            if(response && response.data) return response.data.data;
        }
        catch(error)
        {
            if(load) commit(mt.SetLoading, false);
            throw error;
        }
    }
};

const OffersDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default OffersDataModule;
