export function NetworkAudience({
    id,
    audId,
    isExclusion,
    isActive,
    name,
    description,
    size,
    source,
    sourceId,
    accountId,
    createdAt,
    updatedAt
} = {})
{
    return {
        id,
        audienceId: audId,
        size,
        name,
        description,
        source,
        sourceId,
        accountId,
        isActive,
        isExclusion,
        createdAt,
        updatedAt
    };
}
