import {PRESETS} from '@/helpers/enums/enums';
import Column from '@/models/Column';
import AvailableTableColumns from '@/modules/performance/services/availableTableColumns';
import {USER_GROUPS} from '@/store/constants';
import store from '@/store/store';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import {BehaviorSubject} from 'rxjs';

export class ColumnManager
{
    columns =   {
        isActive: new Column({name: "Is Active"}),
        leads: new Column({name: "Leads"}),
        cpl: new Column({name: "CPL", siblingToggle: "clientCpl"}),
        clientCpl: new Column({name: "CPL", notAvailableForCol: "cpl"}),
        spend: new Column({name: "Spend", siblingToggle: "clientSpend"}),
        clientSpend: new Column({name: "Spend", notAvailableForCol: "spend"}),
        mediaPercentage: new Column({name: "Media %"}),
        conversionRate: new Column({name: "CVR"}),
        status: new Column({name: "Status"}),
        demoSetRate: new Column({name: "Demo Set Rate"}),
        showUpRate: new Column({name: "Show-up Rate", visible: [PRESETS.SALES_PERFORMANCE]}),
        crmDemosPerformed: new Column({name: "Demos"}),
        crmDemosSet: new Column({name: "Demo Set"}),
        crmWins: new Column({name: "Wins"}),
        crmWinRate: new Column({name: "Win Rate"}),
        crmDisqualified: new Column({name: "Disqualified"}),
        crmNeedsSalesFollowing: new Column({name: "Needs Sales Follow-up"}),
        crmWorking: new Column({name: "Reached Out"}),
        crmContacted: new Column({name: "Contacted"}),
        crmContractSent: new Column({name: "Contract Sent"}),
        crmLosses: new Column({name: "Closed - Lost"}),
        crmDemoSetRate: new Column({name: "Demo Set Rate"}),
        crmContactRate: new Column({name: "Contact Rate"}),
        clicks: new Column({name: "Clicks"}),
        cpc: new Column({name: "CPC", siblingToggle: "clientCpc"}),
        clientCpc: new Column({name: "CPC", notAvailableForCol: "cpc"}),
        impressions: new Column({name: "Impressions"}),
        ctr: new Column({name: "CTR"}),
        cpm: new Column({name: "CPM", siblingToggle: "clientCpm"}),
        clientCpm: new Column({name: "CPM", notAvailableForCol: "cpm"}),
        networkLeads: new Column({name: "Network Leads"}),
        grossIncome: new Column({name: "Gross Income"}),
    };
    
    leadsColumns = {
        isActive: Column({ name: "Is Active" }),
        receivedDate: Column({ name: "Received Date" }),
        firstName: Column({ name: "First Name" }),
        lastName: Column({ name: "Last Name" }),
        email: Column({ name: "Email" }),
        crmStatus: Column({ name: "CRM Status" }),
        rawCrmStatus: Column({ name: "Client CRM Status" }),
        phoneNumber: Column({ name: "Phone Number" }),
        jobTitle: Column({ name: "Job Title" }),
        company: Column({ name: "Company" }),
        address: Column({ name: "Address" }),
        companyWebsite: Column({ name: "Company Website" }),
        pageName: Column({ name: "Page Name" }),
        pageUrl: Column({ name: "Page Url" }),
        clientId: Column({ name: "Client ID" }),
        source: Column({ name: "Source" }),
        sourceCampaignId: Column({ name: "Source Campaign ID" }),
        sourceAdId: Column({ name: "Source Ad Id" }),
        pageVariant: Column({ name: "Page Variant" }),
        fullPageUrl: Column({ name: "Full Page URL" }),
        pageId: Column({ name: "Page ID" }),
        bestTime: Column({ name: "Best Time" }),
        audienceId: Column({ name: "Audience ID" }),
        assetTitle: Column({ name: "Asset Title" }),
        companyInfoJson: Column({ name: "Company Info" }),
        companySize: Column({ name: "Company Size" }),
        contactInfoJson: Column({ name: "Contact Info" }),
        jobDescription: Column({ name: "Job Description" }),
        pageSearchTerms: Column({ name: "Page Search Terms" }),
        preferredContact: Column({ name: "Preferred Contact" }),
        sourceChannel: Column({ name: "Source Channel" }),
        sourceMedium: Column({ name: "Source Medium" }),
        handraise: Column({ name: "Handraise" }),
        note: Column({ name: "Note" }),
        
        city: Column({ name: "City" }),
        country: Column({ name: "Country" }),
        // adId: true,
        // campaignId: true,
        //ipAddress: Column({ name: "IP Address" }),
        state: Column({ name: "State" }),
        zipCode: Column({ name: "Zip Code" })
    };
    
    availableTableColumns = null;
    availableColumns = [];
    unaccessableColumns = [];
    filteredColumns = new BehaviorSubject({});
    filteredLeadsColumns = new BehaviorSubject(this.leadsColumns);
    presets = [];
    userGroup = store.getters.getUserGroup;
    isAdmin = store.getters.isAdmin;
    isSystemAdmin = store.getters.isSystemAdmin;
    isMediaBuyer = store.getters.isMediaBuyer;
    
    canViewLeads = this.isMediaBuyer || this.isAdmin || this.isSystemAdmin;
    
    constructor(page, unaccessableColumns = [])
    {
        if(arguments.length > 0)
        {
            this.unaccessableColumns = unaccessableColumns;
            // filter for page only on Campaign, AdGroup, AdCreative
            this.availableTableColumns = new AvailableTableColumns(page, true);
        }
    }
    
    showCol(columnKey)
    {
        if(!this.availableTableColumns)
            throw new Error("Method can't be used");
        
        return this.availableTableColumns.hasTableColumn(columnKey, this.getUserSettingsColumns(), this.unaccessableColumns);
    }
    
    isColumnAvailable(columnKey)
    {
        if(!this.availableTableColumns)
            throw new Error("Method can't be used");
        
        const isColumnAvailableForUserGroup = this.getColumnSiblingsTogglesForUserGroup(columnKey);
        return this.availableTableColumns.isColumnAvailable(columnKey, this.unaccessableColumns) && isColumnAvailableForUserGroup;
    }
    
    getColumnRealName(column)
    {
        if([USER_GROUPS.SYSTEM_ADMINISTRATORS, USER_GROUPS.ADMINISTRATORS, USER_GROUPS.MEDIA_BUYERS].includes(this.userGroup))
            if(["clientSpend"].includes(column))
                return "Client Spend";
    
        return this.columns[column]?.name || "";
    }
    
    getColumnSiblingsTogglesForUserGroup(columnKey)
    {
        if([USER_GROUPS.SYSTEM_ADMINISTRATORS, USER_GROUPS.ADMINISTRATORS, USER_GROUPS.MEDIA_BUYERS].includes(this.userGroup))
        {
            const allowedSiblings = ['clientSpend'];
            const managerColumn = this.columns[columnKey];
            return managerColumn.notAvailableForCol ? allowedSiblings.includes(columnKey) : true;
        }
    
        return true;
    }
    
    getUserSettingsColumns()
    {
        return reduce(this.columns, (prev, column, key) =>
        {
            if(this.unaccessableColumns.includes(key))
                return prev;
            
            if(Array.isArray(column.visible) && column.visible.includes(store.getters.getSelectedPresetName))
            {
                prev[key] = column;
                return prev;
            }
            else
            {
                prev[key] = column;
            }
            return prev;
        }, {});
    }
    
    getUserSettingColumn(columnKey)
    {
        const column = this.getUserSettingsColumns()[columnKey];
        if(!column)
            throw new Error("No column found");
        return column;
    }
    
    setColumnsVisibility(columnsData)
    {
        if(typeof columnsData === "object")
        {
            this.columns = merge({}, this.columns, columnsData);
        }
        else if(typeof this.columns[columnsData] === "object")
        {
            this.columns[columnsData].value = !this.columns[columnsData].value;
            // TODO: go through business logic for sibling toggles
            const sibling = this.columns[columnsData].siblingToggle;
            if(sibling && this.columns[sibling])
                this.columns[sibling].value = !this.columns[sibling].value;
        }
        else
        {
            this.columns[columnsData].value = !this.columns[columnsData].value;
        }
        
        return this.columns;
    }
    
    runFilterColumns(allColumns)
    {
        const newColumns = Object.keys(allColumns)
            .filter(key => this.isColumnAvailable(key))
            .reduce((prev, key) =>
            {
                prev[key] = {
                    ...allColumns[key],
                    name: this.getColumnRealName(key)
                };
                return prev;
            }, {});
        
        this.filteredColumns.next(newColumns);
    }
    
    setLeadsColumnsVisibility(columnsData)
    {
        this.leadsColumns = columnsData;
        return this.leadsColumns;
    }
    
    // used on leads table
    runFilterLeadsColumns()
    {
        let newColumns = [];
        
        if(this.canViewLeads)
            newColumns = Object.keys(this.leadsColumns)
                //.filter(columnKey => !["receivedDate", "firstName", "lastName", "email", "rawCrmStatus"].includes(columnKey))
                .reduce((prev, columnKey) =>
                {
                    prev[columnKey] = this.leadsColumns[columnKey];
                    return prev;
                }, {});
        
        this.filteredLeadsColumns.next(newColumns);
    }
    
    setAvailableColumns(columns)
    {
        this.availableColumns = columns;
        // temporary use availableTableColumns
        this.availableTableColumns.setAvailableColumns(columns);
        this.runFilterColumns(this.columns);
    }
    
    getAvailableColumns()
    {
        return this.availableColumns;
    }
    
    getColumns()
    {
        return this.columns;
    }
    
    getLeadsColumns()
    {
        return this.leadsColumns;
    }
    
    getFilteredColumns()
    {
        return this.filteredColumns.asObservable();
    }
    
    getFilteredLeadsColumns()
    {
        return this.filteredLeadsColumns.asObservable();
    }
    
    // FIXME: not a proper way to get value from Subject
    getFilteredColumnsValue()
    {
        return this.filteredColumns.getValue();
    }
    
    // FIXME: not a proper way to get value from Subject
    getFilteredLeadsColumnsValue()
    {
        return this.filteredLeadsColumns.getValue();
    }
}
