export function AdAsset({
    id = null,
    clientId = null,
    assetType = null,
    name = null,
    order = null,
    parentAssetId = null,
    size = null,
    uri = null
} = {})
{
    return {
        id,
        clientId,
        assetType,
        name,
        order,
        parentAssetId,
        size,
        url: uri
    };
}