import {fetchAll} from '@/repositories/AdSetsRepository';
import moment from "moment";
import projConfig from '../../../projConfig';
import mt from '../../mutationTypes';
import EntityAdapter from "../../../store/adapter";

const state = {
    adSets: EntityAdapter().createEntityAdapter(),
    updatedAt: moment(),
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'spend',
        desc: true
    }
};

const mutations =
{
    [mt.UpsertAdSets](state, data)
    {
        state.adSets = state.adSets.upsertMany(data, state.adSets);
    },
    [mt.SetAdGroupStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    }
};

const getters =
{
    getAdSetsIds: (state) => state.adSets.ids,
    getAdSetsEntities: (state) => state.adSets.entities,
    getAdSetsById: (state) => id => state.adSets.entities[id],
};

const actions =
{
    async fetchAdSets({ commit }, params = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            
            const response = await fetchAll(params, {
                isThrowable: true
            });
            const responseData = response.data.data;
            
            commit(mt.UpsertAdSets, responseData);
            
            return responseData;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const AdSetsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default AdSetsDataModule;
