import {Subject} from "rxjs";

export default (() =>
{
    let instance;
    let sources = [];
    let searches = {};

    const init = () =>
    {
        const subjects = {
            request: new Subject(),
            cancel: new Subject(),
            search: new Subject()
        };

        const cancelStrategy = sources => sources;
        const requestStrategy = (sources, data) =>
        {
            sources.push(data);
            return sources;
        };

        const searchStrategy = (sources, {source, type, value}) =>
        {
            searches[type] = {
                value,
                source
            };
            return searches[type];
        };

        const strategy = {
            request: requestStrategy,
            cancel: cancelStrategy,
            search: searchStrategy
        };

        return new Proxy(subjects, {
            get: (proxy, name) => proxy[name].asObservable(),
            set: (proxy, name, value) =>
            { // intercepts property assignments
                proxy[name].next(Reflect.apply(strategy[name], null, [sources, value]));
                return true;
            }
        });
    };

    return {
        getInstance: () =>
        {
            if(!instance)

                instance = init();

            return instance;
        }
    };
})();
