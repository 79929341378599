// import { map } from "lodash";
// import { objectToFormQuestionDto } from "@/models/landing-page-form-questions";

export function LandingPageFieldsDto({
    lpId,
    lpFields
} = {})
{
    return {
        lpId,
        // lpFields: map(lpFields, (field) => objectToFormQuestionDto(field)),
        lpFields
    };
}
