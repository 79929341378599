import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {Integration} from '@/models/integration/integration';
import {IntegrationDTO} from '@/models/integration/integrationDto';
import {flowRight, startCase} from 'lodash';

export const integrationDtoToObject = dto =>
{
    const transformedDto = {
        ...dto,
        integrationTypeUncased: dto.integrationType,
        // TODO: check if this can be derived in getter
        integrationType: startCase(dto.integrationType)
    };
    
    const object = Integration(transformedDto);
    return object;
};

export const objectToIntegrationDto = (data) =>
{
    const dto = flowRight(removeEmpty, setDtoBase, IntegrationDTO)(data);
    return dto;
};
