import mt from '@/store/mutationTypes';
import { create, fetchAll, fetchEvents, update } from '@/repositories/UserRepository';

const state =
{
    data: [],
    events: [],
    eventsPaging:
    {
        skip: 0,
        top: 50,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    eventsSort:
    {
        desc: true,
        field: "date"
    },
};

const mutations =
{
    [mt.SetUsersData](state, { data })
    {
        state.data = data;
    },
    [mt.SetUsersEvents](state, events)
    {
        state.events = events;
    },
    [mt.SetUsersEventsPaging](state, paging)
    {
        const totalPages = Math.ceil(paging.totalCount / paging.top);
        const currPage =  (paging.skip / paging.top) + 1;
        state.eventsPaging = Object.assign(paging, {totalPages, currPage});
    },
    [mt.SetUsersEventsSort](state, sort)
    {
        state.eventsSort = sort;
    },
};

const getters =
{
    getUsersData(state)
    {
        return state.data;
    },
    getUsersEvents(state)
    {
        return state.events;
    },
    getUsersEventsPaging(state)
    {
        return state.eventsPaging;
    },
    getUsersEventsSort(state)
    {
        return state.eventsSort;
    },
};

const actions =
{
    async fetchUsersData({ commit })
    {
        try
        {
            commit(mt.SetLoading, true);

            const usersResponse = await fetchAll();

            commit(mt.SetLoading, false);
            commit(mt.SetUsersData, usersResponse);
            if (usersResponse.status === 200 )
                return { success: true };
            else
                return { success: false };
        }
        catch(error)
        {
            return { success: false };
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateUser({ commit }, user)
    {
        try
        {
            commit(mt.SetLoading, true);

            const userResponse = await update(user);

            const res = userResponse.status === 200 ? true : false;
            return { success: res };
        }
        catch(error)
        {
            return { success: false, error };
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createNewUser({commit}, user)
    {
        try
        {
            commit(mt.SetLoading, true);
            if (!user.hasOwnProperty('isEmailVerified'))    // eslint-disable-line
                user['isEmailVerified'] = true;

            const userResponse = await create(user);
            return userResponse;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchUsersEvents({ state, commit}, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const usersEventsResponse = await fetchEvents(state, filters);
            if (usersEventsResponse && usersEventsResponse.status === 200 && usersEventsResponse.data)
            {
                commit(mt.SetUsersEvents, usersEventsResponse.data.data);
                commit(mt.SetUsersEventsPaging, usersEventsResponse.data.pages);
                return {success: true};
            }
            else
            {
                return {success: false};
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const UsersDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default UsersDataModule;
