import {AdCreativeStatus} from '@/models/ad-creative-status/adCreativeStatus';
import {AdCreativeStatusJob} from '@/models/ad-creative-status/adCreativeStatusJob';
import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import {removeEmpty, removeNullAndUndefined} from '@/helpers/objectUtils';

import {AdCreativeStatusDto} from '@/models/ad-creative-status/adCreativeStatusDto';

export const adCreativeStatusDtoToObject = dto =>
{
    const object = flowRight(
        removeNullAndUndefined,
        AdCreativeStatus
    )(dto);
    return object;
};

export const objectToAdCreativeStatusDto = data =>
{
    const dto = flowRight(removeEmpty, AdCreativeStatusDto)(data);
    return dto;
};

export const adCreativeStatusJobDtoToObject = dto =>
{
    const object = flowRight(
        removeNullAndUndefined,
        setDtoBase,
        AdCreativeStatusJob
    )(dto);
    return object;
};
