import {USER_GROUPS} from '@/store/constants';
import {curry, flowRight} from 'lodash';
import { AdGroup } from "@/models/ad-group/adGroup";
import { AdGroupDto } from "@/models/ad-group/adGroupDto";
import { removeEmpty, removeNullAndUndefined } from '@/helpers/objectUtils';
import { getAudiencesIds } from "@/components/performance/components/services/audiences";

export const adGroupDtoToObject = dto =>
{
    const object = flowRight(removeNullAndUndefined, AdGroup)(dto);
    return object;
};

const setPropertiesByUserGroup = curry((userGroup, data) =>
{
    // when an Advertiser or Organization User creates an ad group, the daily budget is sent to the API in the field clientDailyBudget instead of dailyBudget.
    // we nullify dailyBudget here so it can be cleared out in objectToAdGroupDto function
    if([USER_GROUPS.ADVERTISER, USER_GROUPS.ORGANIZATION_USER].includes(userGroup))
        return {
            ...data,
            clientDailyBudget: data.dailyBudget,
            dailyBudget: null
        };

    return data;
});

export const objectToAdGroupDto = (data, userGroup = null) =>
{
    // prepare data object
    const adGroupData = Object.assign({}, data, {
        audiences: data.audiences && data.audiences.length !== 0 ? getAudiencesIds(data.audiences) : []
    });
    const dto = flowRight(removeEmpty, AdGroupDto, setPropertiesByUserGroup(userGroup))(adGroupData);

    return dto;
};
/**For ad group we send data from requestMappings that's why we don't need (setDtoBase)**/
