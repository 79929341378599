import {LEAD_INTEGRATION_FIELD_TYPE} from "@/helpers/enums/enums";

export function LeadIntegrationField({
    id, // number;
    liId, // number; the lead integration id
    type = LEAD_INTEGRATION_FIELD_TYPE.LEAD, // LeadIntegrationFieldType
    clientField, // string;
    internalField, // string;  null if the "type" is "custom"
    value = null //string; null if the "type" is not "custom"
} = {})
{
    let props = {
        liId,
        type,
        name: internalField,
        localValue: clientField,
        value
    };

    return id ? Object.assign({}, props, {id}) : props;
}
