export function Audience({
    id = null,
    externalId = null,
    clientId = null,
    audienceSize = 0,
    displayName = "",
    name = "",
    sources = [],
    networkAudiencesIds = []
} = {})
{
    return {
        id,
        clientId,
        size: audienceSize || 0,
        name: displayName || name,
        sources,
        networkAudiencesIds,
        externalId
    };
}
