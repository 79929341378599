import { TOKEN } from '@/helpers/enums/enums';
import { removeEmpty } from '@/helpers/objectUtils';
import { createUrlFiltersParameters, createUrlObjectFilters } from '@/helpers/urlUtils';
import { ColumnManager } from '@/modules/performance/services/columns/ColumnManager';
import
{
    LEADS_PRESET_NAME,
    LOCAL_STORAGE_SETTINGS,
    LeadsPresetGroupedColumns
} from '@/modules/performance/services/columns/columnsConfig';
import { get, patch, post, put } from '@/services/httpService';
import { LocalStorageService } from '@/services/localStorageService';
import { USER_GROUPS } from '@/store/constants';
import store from '@/store/store';
import { pull } from 'lodash';
import Vue from 'vue';

const resource = "/s/leads";

const createFilters = (sort, injectedObjectFilters) =>
{
    const objectFilters = {
        ...createUrlObjectFilters(),
        ...injectedObjectFilters
    };

    const {clId, cmpId, offerId, lpId, netCmpGrpId, netCmpId, adCreativeId} = objectFilters;

    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'leadsPage');
    return {
        objectFilters: {
            clId,
            cmpId,
            lpId,
            offerId,
            netCmpId,
            netCmpGrpId,
            adCreativeId
        },
        filterParams
    };
};

const getSelectArray = userGroup =>
{
    // local storage should have all the selected columns except for LeadsPresetGroupedColumns
    const settings = Vue.ls.get(LOCAL_STORAGE_SETTINGS);
    const leadsColumnPreset = JSON.parse(settings)[LEADS_PRESET_NAME];
    // local storage is not set yet.
    // send defaults in such case
    let columns = leadsColumnPreset ? leadsColumnPreset.columns : Object.keys(new ColumnManager().getLeadsColumns());

    let result = columns.concat(LeadsPresetGroupedColumns) || [];
    // Always ask for fraudStatus and fraudReasons if user is Admin/SysAdmin or Media buyer
    if([USER_GROUPS.SYSTEM_ADMINISTRATORS, USER_GROUPS.ADMINISTRATORS, USER_GROUPS.MEDIA_BUYERS].includes(userGroup))
    {
        result.push('fraudStatus');
        result.push('fraudReasons');
    }

    if (![USER_GROUPS.SYSTEM_ADMINISTRATORS,
        USER_GROUPS.ADMINISTRATORS,
        USER_GROUPS.MEDIA_BUYERS,
        USER_GROUPS.ORGANIZATION_USER,
        USER_GROUPS.ADVERTISER].includes(userGroup))
        pull(result, 'rawCrmStatus');

    return result;
};

const fetchStats = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;
    // filters is always added on table details view in campaign manager
    const {injectedObjectFilters = {}} = filters || {};

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const userGroup = store.getters.getUserGroup;
    let selectedFields = getSelectArray(userGroup);

    const filterParams = createFilters(sort, injectedObjectFilters);

    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        select: selectedFields.join(","),
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
    });

    return get(`${resource}`, {
        params: stateParams,
        isThrowable: true
    });
};

const fetch = id =>
{
    return get(`${resource}/${id}`);
};

const fetchAll = (payload = {}) =>
{
    return get(`${resource}`, {
        params: payload
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (id, payload = {}) =>
{
    return put(`${resource}/${id}`, payload);
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

const download = ({sort} = {}, filters = {}) =>
{
    let top = 5000;
    let skip = 0;
    const {field, desc} = sort;
    const userGroup = store.getters.getUserGroup;
    let selectedFields = getSelectArray(userGroup);
    const filterParams = createFilters(sort, filters);

    // this is  getRawLeadsUrl
    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
        select: selectedFields.join(","),
    });

    return get(`${resource}`, {
        params: stateParams,
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': LocalStorageService.getInstance().get(TOKEN)
        },
        responseType: 'blob',
    });
};

const partialUpdate = (id, payload = {}) =>
{
    return patch(`${resource}/${id}`, payload);
};

export
{
    create, download, fetch,
    fetchAll,
    fetchStats, partialUpdate, toggle, update
};
