import {get} from "@/services/httpService";

const fetchAllForOffers = ({ids = [], type = "thumbnail"} = {}) =>
{
    const resource = "/s/offers/preview";
    return get(resource, {
        params: {
            offerIds: ids.join(','),
            type
        },
        isThrowable: true
    });
};

const fetchOneForOffer = ({id, type = "thumbnail"} = {}) =>
{
    const resource = `/s/offers/${id}/preview`;
    return get(resource, {
        params: {
            type
        }
    });
};

const fetchAllForLandingPages = ({ids = []} = {}) =>
{
    const resource = "/s/landing-pages";
    const promises = ids.map(id => get(`${resource}/${id}/preview?type=desktopThumbnail`));
    return Promise.all(promises.map(promise => promise));
};

const fetchOneForLandingPage = ({id, type = "desktopThumbnail"} = {}, ) =>
{
    const resource = "/s/landing-pages";
    return get(`${resource}/${id}/preview`, {
        params: {
            type
        }
    });
};

export {
    // fetch,
    // fetchAll,
    fetchAllForOffers,
    fetchOneForOffer,
    fetchAllForLandingPages,
    fetchOneForLandingPage
    // create,
    // update
};