export function WSMessage({
    action,
    adCreativeId,
    adHeadline,
    clientId,
    firstName,
    lastName,
    messageId,
    messageType,
    read,
    source,
    username,
    createdAt
} = {})
{
    return {
        id: messageId,
        action,
        adCreativeId,
        adHeadline,
        clientId,
        firstName,
        lastName,
        messageType,
        read,
        source,
        username,
        createdAt
    };
}


