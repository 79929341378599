import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from "@/store/store";
import {removeEmpty} from "@/helpers/objectUtils";
import {get, post, put} from "@/services/httpService";

const resource = "/s/offers";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'offersPage');
    return {
        objectFilters,
        filterParams
    };
};

const fetchStats = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

const fetchAll = (payload = {}, ...rest) =>
{
    return get(`${resource}`, {
        params: payload,
        ...rest
    });
};

const create = (offer = {}) =>
{
    return post(resource, offer);
};

const update = ({id, offer = {}}) =>
{
    return put(`${resource}/${id}`, offer);
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

export {
    fetch,
    fetchAll,
    fetchStats,
    create,
    update,
    toggle
};