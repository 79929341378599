import { get } from "@/services/httpService";

const resource = "/s/verticals";

const fetchAll = (payload = {}) =>
{
    return get(`${resource}`, payload);
};

export {
    fetchAll
};