export function LeadDto({
    adId,
    address,
    assetTitle,
    audienceId,
    bestTime,
    campaignId,
    city,
    clientId,
    company,
    companyInfoJson,
    companySize,
    companyWebsite,
    contactInfoJson,
    country,
    email,
    firstName,
    fraudStatus,
    handRaise,
    id,
    ipAddress,
    isActive,
    jobDescription,
    jobTitle,
    landingPageId,
    lastName,
    pageId,
    pageName,
    pageSearchTerms,
    pageUrl,
    pageVariant,
    phoneNumber,
    preferredContact,
    receivedDate,
    source,
    sourceAdId,
    sourceCampaignId,
    sourceChannel,
    sourceMedium,
    state,
    zipCode,
    lpId,
    netCmpId,
    adCreativeId,
    netAdId
})
{
    return {
        adId,
        address,
        assetTitle,
        audienceId,
        bestTime,
        campaignId,
        city,
        clientId,
        company,
        companyInfoJson,
        companySize,
        companyWebsite,
        contactInfoJson,
        country,
        email,
        firstName,
        fraudStatus,
        handRaise,
        id,
        ipAddress,
        isActive,
        jobDescription,
        jobTitle,
        landingPageId,
        lastName,
        pageId,
        pageName,
        pageSearchTerms,
        pageUrl,
        pageVariant,
        phoneNumber,
        preferredContact,
        receivedDate,
        source,
        sourceAdId,
        sourceCampaignId,
        sourceChannel,
        sourceMedium,
        state,
        zipCode,
        lpId,
        netCmpId,
        adCreativeId,
        netAdId
    };
}
