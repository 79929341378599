import { updateQueryString } from '@/services/queryService';
const LeadsPage = () => import('./LeadsPage.vue');

const beforeEnter = async(to, from, next) =>
{
    if (Object.keys(to.query).length === 0)
    {
        const query = updateQueryString(to.name);
        next({name: to.name, query});
    }
    else
    {
        next();
    }
};

const routes = [
    {
        name: "leadsPage",
        path: "/",
        component: LeadsPage,
        beforeEnter: beforeEnter
    },
];

export default routes;
