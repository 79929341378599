import {deleteReq, get, post, put} from "@/services/httpService";

const resource = "/s/landing-page-fields";

const fetch = ({id} = {}) =>
{
    return get(`${resource}`, {
        params: {
            lpId: id
        },
        isThrowable: true
    });
};

const update = ({id, data} = {}) =>
{
    return put(`${resource}/${id}`, {
        ...data
    });
};

const create = (data) =>
{
    return post(`${resource}`, {
        ...data
    });
};

const destroy = id =>
{
    return deleteReq(`${resource}/${id}`);
};

export {
    fetch,
    update,
    create,
    destroy
};