const isJsonString = value =>
{
    try
    {
        JSON.parse(value);
    }
    catch (error)
    {
        return false;
    }
    return true;
};

export { isJsonString };