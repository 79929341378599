export function CampaignSchedulesDto({
    id,
    clientId,
    cmpId,
    dayOfWeek,
    timeRange
} = {})
{
    return {
        id,
        clientId,
        cmpId,
        dayOfWeek,
        timeRange,
    };
}