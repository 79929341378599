import moment from "moment";
import {getTimezonesFromAbbreviation} from "@/services/formsDataService";
const timezone = getTimezonesFromAbbreviation('PDT');

export function TransactionsDto({
    trnDtDate = null, // the value of "Date" drop-down
    trnDtTime = null, // the value of "Date Time" drop-down
    clientId = null, // the id of the client selected in the "Client" combo select
    method = "", //"ach" | "promo", // the value of the "Type" drop-down mapped: Admin Credit -> "promo", ACH/Check -> "ach"
    amount = null, // Amount from "Amount" :D
    extInvoiceId = null, // value of "Invoice #"
    extInvoiceUri = "" // value of "Invoice link"
} = {}) 
{
    return {
        trnDt: moment.tz(moment.utc(new Date(`${trnDtDate} ${trnDtTime}`), "YYYY-MM-DD hh:mm A"), timezone).format(),
        clientId,
        method,
        amount,
        extInvoiceId,
        extInvoiceUri,

    };
}
