import mt from "@/store/mutationTypes";
import {get} from "@/services/httpService";
import {paymentTokenDtoToObject} from "@/models/payment-token";

const state = {
    token: null
};

const mutations = {
    [mt.SetPaymentToken](state, {token} = {})
    {
        state.token = token;
    }
};

const getters = {
    getPaymentToken(state)
    {
        return state.token;
    }
};

const actions = {
    async fetchPaymentToken({ commit })
    {
        try
        {
            const url = "/s/payments/config";
            commit(mt.SetLoading, true);

            const {data} = await get(url, {
                isThrowable: true
            });

            const response = paymentTokenDtoToObject(data);
            commit(mt.SetPaymentToken, response);

            return response;
        }
        catch (error)
        {
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const PaymentsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default PaymentsDataModule;
