// TODO: remove statuses
const LANDING_PAGE_STATUSES = Object.freeze({
    RUNNING: 0,
    PAUSED: 1,
    GENLY_APPROVAL: 2,
    CA: 3
});

const WEBHOOKS_STATE = Object.freeze({
    POST: "POST",
    GET: "GET",
    POST_WITH_BODY: "POST_WITH_BODY"
});

const CREATE_VIEW_STATE = Object.freeze({
    REGULAR: "REGULAR",
    DEFAULT: "DEFAULT",
    CREATE: "CREATE",
    STEPPER: "STEPPER",
    CAMPAIGN: "CAMPAIGN",
    EDIT: "EDIT",
    DELETING: "DELETING",
    SUCCESS: "SUCCESS",
    CREATE_AS_NEW: "CREATE_AS_NEW",
    CREATE_DRAFT: "CREATE_DRAFT",
    DUPLICATE: "DUPLICATE",
    FORCE_CLOSE: "FORCE_CLOSE"
});
const NAVIGATION_SCREEN = Object.freeze({
    PROFILE: "profile",
    COMPANY_PROFILE: "companyProfile",
    NOTIFICATIONS: "notifications",
});
const CALL_TO_ACTION_AD_NATIVE = Object.freeze({
    "Call Now": "Call Now",
    'Buy Now': "Buy Now",
    'Book Now': "Book Now",
    'Contact Us': "Contact Us",
    'Download': "Download",
    'Get Offer': "Get Offer",
    'Get Now': "Get Now",
    'Install': "Install",
    'Learn More': "Learn More",
    'Read More': "Read More",
    'Sign Up': "Sign Up",
    'Subscribe': "Subscribe",
    'Watch Now': "Watch Now",
    'Compare': "Compare",
});

const CALL_TO_ACTION_AD_SOCIAL = Object.freeze({
    'apply_now': "Apply Now",
    'call': "Call",
    'contact': "Contact",
    'download': "Download",
    'get_offer': "Get Offer",
    'learn_more': "Learn More",
    'sign_up': "Sign Up",
    'see_more': "See More",
    'subscribe': "Subscribe",
    'watch_video': "Watch Video",
    'book_travel': "Book Now",
    'watch_more': "Watch More",
});

const DEFAULT_VALUES = Object.freeze({
    DEFAULT_SOCIAL_CTA: 'learn_more',
    DEFAULT_NATIVE_CTA: 'Learn More'
});

const VISIBILITY_VIEW_STATE = Object.freeze({
    VISIBLE: "VISIBLE",
    HIDDEN: "HIDDEN"
});

const LANDING_PAGE_NESTED_VIEW = Object.freeze({
    AD_CREATIVE: "AD_CREATIVE",
    AD: "AD",
    OFFER: "OFFER"
});

const PROCESS_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    IN_CONFIRMATION: "IN_CONFIRMATION",
    CONFIRMED: "CONFIRMED",
    FINISHED: "FINISHED"
});

const AD_STATUS_PROCESS_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    APPROVAL: "APPROVAL",
    FEEDBACK_PROVIDED: "FEEDBACK_PROVIDED",
    REJECTING: "REJECTING",
    PUBLISH: "PUBLISH",
    DELETE: "DELETE"
});

const AD_TYPES = Object.freeze({
    NATIVE: "native",
    SOCIAL: "social",
    VERIZON: "verizon",
    DISPLAY: "display"
});

const AD_FORMAT = Object.freeze({
    VIDEO: "video",
    BANNER: "banner",
    OTHER: "other"
});

const LANDING_PAGE_NETWORKS = Object.freeze({
    FACEBOOK: "fb",
    GOOGLE: "google",
    YAHOO: "yahoo",
    OUTBRAIN: "outbrain"
});

const ALLOWED_AD_TYPE = Object.freeze({
    ALL: "all",
    NATIVE: "native",
    SOCIAL: "social",
    NONE: "none"
});

const AD_PREVIEW_TYPES = Object.freeze({
    MOBILE_IN_APP: "MOBILE_IN_APP",
    MOBILE_WEB: "MOBILE_WEB",
    MOBILE_PENCIL: "MOBILE_PENCIL",
    DESKTOP_WEB: "DESKTOP_WEB",
    DESKTOP_PENCIL: "DESKTOP_PENCIL",
    TABLET_WEB: "TABLET_WEB",
    FB_MOBILE: "FB_MOBILE",
    INSTAGRAM_MOBILE: "INSTAGRAM_MOBILE",
    FB_DESKTOP: "FB_DESKTOP",
    AUDIENCE_INTERSTITAL: "AUDIENCE_INTERSTITAL",
    AUDIENCE_NATIVE: "AUDIENCE_NATIVE",
    AUDIENCE_BANNER: "AUDIENCE_BANNER",
    VERIZON: "VERIZON",
});

const LANDING_PAGE_TYPE = Object.freeze({
    REGULAR: "REGULAR",
    FACEBOOK: "FACEBOOK"
});

const OBJECT_FILTERS = Object.freeze({
    CLIENT: "client",
    CAMPAIGN: "campaign",
    OFFER: "offer",
    LANDING_PAGE: "landingPage",
    AD_GROUP: "adGroup",
    NETWORK_CAMPAIGN: "networkCampaign",
    AD_SET: "adSet",
    AD: "ad",
    AD_CREATIVE: "adCreative",
    AD_STATUS: "ad_status"
});

const ROUTE_NAMES = Object.freeze({
    CLIENT: "clientPage",
    CAMPAIGN: "campaignPage",
    OFFER: "offersPage",
    LANDING_PAGE: "landingPage",
    AD_GROUP: "adGroupPage",
    AD: "networkAdRoot",
    AD_CREATIVE: "adCreativePage",
    LEADS: "leadsPage"
});

const EDIT_MODAL = Object.freeze({
    CLIENT: "client",
    CAMPAIGN: "campaign",
    OFFER: "offer",
    LANDING_PAGE: "landingPage",
    AD_GROUP: "adGroup",
    AD: "ad",
});

const ASSET_TYPE = Object.freeze({
    PDF: "pdf",
    PNG: "png",
    JPG: "jpg",
    GIF: "gif"
});

const STATUSES = Object.freeze({
    RUNNING: "running",
    DRAFT: "draft",
    PAUSED: "paused",
    READY: "ready",
    EDITORIAL_REVIEW: "editorialReview",
    ADMIN_REVIEW: "adminReview",
    CLIENT_REVIEW: "clientReview",
    DELETED: "deleted",
    REJECTED: "rejected",
    CLIENT_REJECTED: "clientRejected",
    NETWORK_REJECTED: "networkRejected",
    RETIRED: "retired",
    NOT_DELIVERING: "notDelivering",
    SCHEDULED: "scheduled",
    PAUSED_SCHEDULE_CAP: "pausedByScheduleCap",
    PAUSED_BY_TARGETING: "pausedByTargeting",
    PIPELINE: "pipeline",
});

const EFFECTIVE_STATUSES = Object.freeze({
    NOT_DELIVERING_CAMPAIGN: "notDeliveringCampaign",
    NOT_DELIVERING_NETWORK_CAMPAIGN: "notDeliveringNc",
    NOT_DELIVERING_AD: "notDeliveringAd",
    NOT_DELIVERING_AD_GROUP: "notDeliveringAdGroup",
    PENDING_REVIEW: "pendingReview",
    NOT_DELIVERING_CAMPAIGN_SCHEDULED: "notDeliveringCampaignScheduled",
    PAUSED_NETWORK_CAMPAIGN_SCHEDULED: "pausedNcScheduled",
    NOT_DELIVERING_NETWORK_CAMPAIGN_SCHEDULED: "notDeliveringNcScheduled",
    NOT_DELIVERING_FOR_LOW_QUALITY: "notDeliveringForLowQuality",
    NETWORK_AD_UNDER_REVIEW: "networkAdUnderReview",
});

const STATUSES_MAPS = Object.freeze({
    [STATUSES.DRAFT]: "Draft",
    [STATUSES.READY]: "Ready",
    [STATUSES.RUNNING]: "Running",
    [STATUSES.PAUSED]: "Paused",
    [STATUSES.EDITORIAL_REVIEW]: "Editorial Review",
    [STATUSES.ADMIN_REVIEW]: "Admin Review",
    [STATUSES.CLIENT_REVIEW]: "Client Review",
    [STATUSES.DELETED]: "Deleted",
    [STATUSES.REJECTED]: "Rejected",
    [STATUSES.CLIENT_REJECTED]: "Client Rejected",
    [STATUSES.NETWORK_REJECTED]: "Network Rejected",
    [STATUSES.RETIRED]: "Retired"
});

const STATUSES_MAPS_FOR_ORGANIZATION_ADVERTISER_BASE = Object.freeze({
    [STATUSES.DRAFT]: "Draft",
    [STATUSES.RUNNING]: "Active",
    [STATUSES.PAUSED]: "Inactive",
    [STATUSES.ADMIN_REVIEW]: "Under Review",
    [STATUSES.CLIENT_REVIEW]: "Client Review",
    [STATUSES.DELETED]: "Archived",
    [STATUSES.REJECTED]: "Rejected",
    [STATUSES.NETWORK_REJECTED]: "Rejected",
});

const CAMPAIGN_STATUSES = Object.freeze({
    RUNNING: "running",
    PAUSED: "paused",
    PAUSED_BY_SCHEDULE_CAP: "pausedByScheduleCap",
    PAUSED_BY_SCHEDULE_GLOBAL: "pausedByScheduleGlobal",
    PAUSED_BY_SCHEDULE_CLIENT: "pausedByScheduleClient",
    PAUSED_FOR_PAYMENT: "pausedForPayment",
    PAUSED_BY_BATCH: "pausedByBatch",
    STARTING: "starting"
});

const PAGES = Object.freeze({
    CLIENT: "Client",
    CAMPAIGN: "Campaign",
    OFFERS: "Offers",
    AD_GROUP: "AdGroup",
    AD: "Ad",
    LEADS: "Leads",
    NETWORK_CAMPAIGN: "NetworkCampaign"
});

const FORM_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    PRISTINE: "PRISTINE",
    DIRTY: "DIRTY",
    UNEDITABLE: "UNEDITABLE",
    VALID: "VALID",
    INVALID: "INVALID",
    EXTENDED: "EXTENDED",
    VALIDATE: "VALIDATE"
});

const INPUT_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    FOCUS: "FOCUS",
    BLUR: "BLUR"
});

const COMMENT_TYPE = Object.freeze({
    USER: "USER",
    OTHER: "OTHER"
});

const PREVIEW_MODE = Object.freeze({
    AD: "AD",
    [LANDING_PAGE_TYPE.FACEBOOK]: LANDING_PAGE_TYPE.FACEBOOK,
    [LANDING_PAGE_TYPE.REGULAR]: LANDING_PAGE_TYPE.REGULAR,
    OFFER: "OFFER"
});

const FB_FORM_TEXT_CONTENT_TYPE = Object.freeze({
    PARAGRAPH: 'PARAGRAPH',
    BULLETS: 'BULLETS'
});

const LANDING_PAGE_FIELD_TYPE = Object.freeze({
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
    EMAIL: 'email',
    PHONE: 'phone',
    OPTIONS: 'options',
    COMPANY_NAME: 'companyName',
    FULL_NAME: 'fullName',
    JOB_TITLE: 'jobTitle',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    POST_CODE: 'postCode',
    ZIP: 'zip',
});

const LANDING_PAGE_FIELDS = Object.freeze({
    EMAIL: 'email',
    COMPANY_NAME: 'companyName',
    FULL_NAME: 'fullName',
    JOB_TITLE: 'jobTitle',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PHONE: 'phoneNumber',
    NUMBER_OF_EMPLOYEES: 'Number Of Employees',
    SHORT_ANSWER: 'shortAnswer',
    MULTIPLE_CHOICE: 'multipleChoice',
});

const AD_CREATIVE_TYPE = Object.freeze({
    social: 'Facebook',
    native: 'Yahoo',
    display: 'Google Ads',
    dynamic: 'Dynamic',
    verizon: 'Yahoo DSP',
    pin: 'Pinterest',
    quora: 'Quora',
    linkedin: 'LinkedIn',
    outbrain: 'Outbrain',
});
const SOURCE_TYPE = Object.freeze({
    FB: 'Facebook',
    YAHOO: 'Yahoo',
    GOOGLE: 'Google Ads',
    OUTBRAIN: 'Outbrain',
    TW: 'Twitter',
    TB: 'Taboola',
    PIN: 'Pinterest',
    QUORA: 'Quora',
    AMAZON: 'Amazon',
    LINKEDIN: 'LinkedIn',
    VERIZON: 'Yahoo DSP'
});

const CAMPAIGN_TYPE = Object.freeze({
    AWARENESS: 'Awareness',
    TRAFFIC: 'Traffic',
    CONVERSION: 'Conversion',
    LEADS: 'Leads',
    LEAD_MAGNET: "LeadMagnet"
});

const TOAST_HIDE = Object.freeze({
    TIMER: 300000,
    TIMER_FOR_ERROR: 30000,
    FIVE_MINUTES: 300000
});

const CTA = Object.freeze({
    VIEW_WEBSITE: 'VIEW_WEBSITE',
    DOWNLOAD: 'DOWNLOAD'
});

const CTA_TEXT = Object.freeze({
    VIEW_WEBSITE: 'View Website',
    DOWNLOAD: 'Download'
});

const CLIENT_PERMISSIONS = Object.freeze({
    READ: "read",
    WRITE: "write",
    READ_WRITE: "readWrite"
});

const AD_BLOCKER_STATE = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED"
};
const PAGE_STATE = Object.freeze({
    READY: "READY",
    LOADING: "LOADING"
});

const JOB_STATUS = Object.freeze({
    NONE: "none",
    STARTED: "started",
    RUNNING: "running",
    DONE: "done",
    ERROR: "error"
});


const FB_PAGE_ANCESTOR_STATES = Object.freeze({
    EQUAL: "EQUAL",
    DIFFERENT: "DIFFERENT"
});

const TRANSACTION_METHOD = Object.freeze({
    PROMO: 'promo',
    ACH: 'ach'
});

const TRANSACTION_METHOD_TEXT = Object.freeze({
    PROMO: 'Admin Credit',
    ACH: 'ACH/Check'
});

export default {
    AD_PREVIEW_TYPES,
    AD_TYPES,
    LANDING_PAGE_STATUSES,
    OBJECT_FILTERS,
    ROUTE_NAMES,
    CREATE_VIEW_STATE,
    PAGES
};

// note: always export as object and avoid default export
export
{
    ROUTE_NAMES,
    AD_TYPES,
    AD_PREVIEW_TYPES,
    CREATE_VIEW_STATE,
    WEBHOOKS_STATE,
    VISIBILITY_VIEW_STATE,
    LANDING_PAGE_NESTED_VIEW,
    LANDING_PAGE_TYPE,
    ASSET_TYPE,
    STATUSES,
    STATUSES_MAPS,
    FORM_STATE,
    PROCESS_STATE,
    AD_STATUS_PROCESS_STATE,
    PAGES,
    COMMENT_TYPE,
    PREVIEW_MODE,
    FB_FORM_TEXT_CONTENT_TYPE,
    CALL_TO_ACTION_AD_NATIVE,
    CALL_TO_ACTION_AD_SOCIAL,
    DEFAULT_VALUES,
    LANDING_PAGE_FIELD_TYPE,
    LANDING_PAGE_FIELDS,
    AD_CREATIVE_TYPE,
    ALLOWED_AD_TYPE,
    EFFECTIVE_STATUSES,
    CAMPAIGN_TYPE,
    INPUT_STATE,
    TOAST_HIDE,
    CTA,
    CTA_TEXT,
    CAMPAIGN_STATUSES,
    CLIENT_PERMISSIONS,
    SOURCE_TYPE,
    AD_BLOCKER_STATE,
    AD_FORMAT,
    LANDING_PAGE_NETWORKS,
    FB_PAGE_ANCESTOR_STATES,
    EDIT_MODAL,
    PAGE_STATE,
    JOB_STATUS,
    STATUSES_MAPS_FOR_ORGANIZATION_ADVERTISER_BASE,
    NAVIGATION_SCREEN,
    OBJECT_FILTERS,
    TRANSACTION_METHOD,
    TRANSACTION_METHOD_TEXT,
};
