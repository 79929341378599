import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { Campaign} from "@/models/campaign/campaign";
import { CampaignDto} from "@/models/campaign/campaignDto";
import { removeEmpty } from "@/helpers/objectUtils";

export const campaignDtoToObject = dto =>
{
    const object = flowRight(removeEmpty,Campaign)(dto);
    return object;
};

const createCampaignRequest = ({
    clientId = null,
    name = null,
    type = null,
    cplGoal = null,
    cpc: cpcGoal = null,
    cpm: cpmGoal = null,
    batchesRepeat: shouldRepeat = null,
    isActive = null,
    status = null,
    clientPermissions = null,
    maxMp = null,
    isClientSpendVariable = null,
    maxCpc = null,
    maxCpm = null,
    maxCpl= null,
    async
} = {}) =>
{
    return {
        clientId,
        name,
        type,
        cplGoal,
        cpcGoal,
        cpmGoal,
        shouldRepeat,
        isActive,
        status,
        clientPermissions,
        maxMp,
        isClientSpendVariable,
        maxCpc,
        maxCpm,
        maxCpl,
        async
    };
};

export const objectToCampaignDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, CampaignDto, createCampaignRequest)(data);
    return dto;
};
