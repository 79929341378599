import * as Sentry from "@sentry/browser";
import moment from 'moment';
import VueRouter from 'vue-router';
import routes from './components/routes';
import { REFRESH_TOKEN_ERRORS } from './errors/RefreshTokenError';
import { TOKEN, TOKEN_EXPIRATION } from './helpers/enums/enums';
import { LocalStorageService } from './services/localStorageService';
import { refresh } from './services/requestHelper.service';
import store from './store/store';

const router = new VueRouter({
    routes,
    scrollBehavior (to, from, savedPosition)
    {
        if(to && to.query && to.query.modal) return { x: 0, y: window.scrollY };
        if(from && from.query && from.query.modal) return { x: 0, y: window.scrollY };
        if (savedPosition)
            return savedPosition;

        return { x: 0, y: 0 };
    }
});

function isAccessTokenExpired()
{
    const now = moment();
    const expiration = LocalStorageService.getInstance().get(TOKEN_EXPIRATION);
    return now.isAfter(expiration);
}

let counter = 0;
const REQUEST_LIMIT = 3;
// Good place to put this since all needed objects are available
const unsecuredRoutes = ["login", "forgot-password", "new-password", "reset-password"];
router.beforeEach(async  (to, from, next) =>
{
    if(to.name === 'login')
        Sentry.captureException(new Error(`[On Login Route]: User was redirected to login page `), {
            tags: {
                section: "errors",
            },
        });

    if(from.name === null)
        await store.dispatch("loadUser");

    // stop second click on b-nav-item
    if(to.name === from.name && Object.keys(to.query).length === 0) return next(false);

    let isRouteSecured = !unsecuredRoutes.includes(to.name);

    //store.commit(mt.SetRedirectRoute, { redirectRoute: window.location.hash.substring(2) });
    if (!LocalStorageService.getInstance().get(TOKEN) && isRouteSecured)
    {
        next({ name: "login", replace: true });
        return;
    }

    // check token expiration and refresh here
    if(isAccessTokenExpired())
        try
        {
            await refresh();
        }
        catch(error)
        {
            console.error({error});
            switch(error.cause)
            {
                case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_REQUEST_ERROR:
                    // retry for the refresh token on connection errors, such as ERR_NAME_NOT_FOUND
                    Sentry.captureException(new Error(`[isAccessTokenExpired]: exchangeRefreshToken connection error`), {
                        tags: {
                            section: "errors",
                        },
                    });

                    counter++;
                    if(counter > REQUEST_LIMIT)
                        return;

                    await refresh();
                    return;
                case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_MISSING:
                case REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_EXPIRED:
                    Sentry.captureException(new Error(`[isAccessTokenExpired]: logout user ${error.cause} `), {
                        tags: {
                            section: "errors",
                        },
                    });
                    await store.dispatch('logoutUser');
                    next({ name: "login", replace: true });
                    return;
            }
        }

    if (!store.getters.canAccessComponent(to.name) && isRouteSecured)
        next(false);
    else
        next();
});

export default router;
