import { setDtoBase } from "@/models/DtoBase";
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
import { SuggestionDto } from "./suggestionDto.js";
import { Suggestion } from "./suggestion.js";
import { SuggestionJob } from "./suggestionJob.js";
import { SuggestionFieldDto } from "./suggestionFieldDto.js";

export const suggestionDtoToObject = (dto) => 
{
    const object = Suggestion(dto);
    return object;
};

export const suggestionJobDtoToObject = (dto) => 
{
    const object = SuggestionJob(dto);
    return object;
};

export const objectToSuggestionDto = (data) => 
{
    const dto = flowRight(removeEmpty, setDtoBase, SuggestionDto)(data);
    return dto;
};

export const objectToSuggestionFieldDto = (data) => 
{
    let { field } = data;
    switch (field) 
    {
        case "adTitle":
            field = "headline";
            break;
        case "adDescription":
            field = "description";
            break;
        case "aiGeneratedImageText":
            field = "imageText";
            break;
    }

    const dto = flowRight(removeEmpty, setDtoBase, SuggestionFieldDto)({ ...data, field });
    return dto;
};
