import {removeEmptyWithKeep} from '@/helpers/objectUtils';
import {Lead} from '@/models/lead/lead';
import {LeadDto} from '@/models/lead/leadDto';
import {flowRight} from 'lodash';

export const leadDtoToObject = data =>
{
    const leadIntegrationFieldObject = Lead(data);
    return leadIntegrationFieldObject;
};

export const objectToLeadDto = data =>
{
    const leadIntegrationFieldDto = flowRight(removeEmptyWithKeep(["fraudStatus"]), LeadDto)(data);
    return leadIntegrationFieldDto;
};
