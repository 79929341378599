const imageFallback = {
    componentUpdated: (el, binding) => 
    {
        let cl = el.cloneNode(true);
        //if(binding) cl.setAttribute('data', binding.value);
        let parent = el.parentNode;
        if(parent) 
        {
            parent.removeChild(el);
            parent.prepend(cl);
        }
        
    }
}; 

export default imageFallback;