import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { Offer } from "@/models/offer/offer";
import { OfferDto } from "@/models/offer/offerDto";
import { removeEmpty } from "@/helpers/objectUtils";

const findClientId = data =>
{
    const clientId = data.campaign ? data.campaign.clientId : data.clientId;
    return {
        ...data,
        clientId
    };
};

export const offerDtoToObject = dto =>
{
    const object =  flowRight(removeEmpty, Offer)(dto);
    return object;
};

export const objectToOfferDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, OfferDto, findClientId)(data);
    return dto;
};
