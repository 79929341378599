export function Client({
    id,
    addMethod,
    billingAddress,
    billingEmail,
    isActive,
    name,
    phoneNumber,
    verticalId,
    website,
    canSeeOffers,

    activeCampaigns,
    batchEndDateSpendAdjust,
    batchStartDateSpendAdjust,
    clicks,
    comments,
    conversionRate,
    cpc,
    cpl,
    clientCpl,
    cpm,
    clientCpm,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    impressions,
    leads,
    likes,
    networkLeads,
    postReactions,
    shares,
    spend,
    clientSpend,
    showUpRate,
    createdAt
} = {})
{
    return {
        id,
        name,
        phoneNumber,
        addMethod,
        billingAddress,
        billingEmail,
        website,
        isActive,
        verticalId,
        canSeeOffers,
        activeCampaigns,
        batchEndDateSpendAdjust,
        batchStartDateSpendAdjust,
        clicks,
        comments,
        conversionRate,
        cpc,
        cpl,
        clientCpl,
        cpm,
        clientCpm,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        impressions,
        leads,
        likes,
        networkLeads,
        postReactions,
        shares,
        spend,
        clientSpend,
        showUpRate,
        createdAt
    };
}
