import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from "@/store/store";
import {removeEmpty} from "@/helpers/objectUtils";
import {get, post, put} from "@/services/httpService";

const resource = "/s/landing-pages";

const createFilters = (sort, page = "landingPage") =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, page);
    return {
        objectFilters,
        filterParams
    };
};

const fetchStatsForOffer = ({sort} = {}, offerId) =>
{
    const filterParams = createFilters(sort, "offersPage");

    const stateParams = removeEmpty({
        ...filterParams.objectFilters,
        ...filterParams.filterParams
    });

    return get(`${resource}/stats`, {
        params: {
            offerId,
            ...stateParams
        }
    });
};

const fetchStat = ({id} = {}) =>
{
    return get(`${resource}/stats`, {
        params: {
            lpId: id
        }
    });
};

const fetchStats = ({page, sort, currPage} = {}, filters) =>
{
    const filterParams = createFilters(sort);

    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetchStatsForOverview = ({page, sort, currPage} = {}, filters) =>
{
    const filterParams = createFilters(sort);

    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const { adCrStatuses } = filterParams.filterParams;
    const stateParams = removeEmpty({
        top,
        skip,
        batchNum: 1,
        orderBy: field,
        orderByDesc: desc,
        adCrStatuses,
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

// do we move this to thumbnails store?
const fetchPreview = ({id, type = "desktop"} = {}) =>
{
    return get(`${resource}/${id}/preview?type=${type}`);
};

const fetchByName = ({name, top = 1000, skip = 0, offerId = null, clientId = null, source = null, ...rest} = {}) =>
{
    return get(`${resource}`,{
        params: {
            lpName: name,
            offerId,
            clId: clientId,
            source,
            top,
            skip
        },
        ...rest
    });
};

const create = (landingPage = {}) =>
{
    return post(resource, landingPage);
};

const update = ({id, landingPage = {}} = {}) =>
{
    return put(`${resource}/${id}`, landingPage);
};

const updateStatus = ({id, status} = {}) =>
{
    return put(`${resource}/${id}/status`, {
        status
    });
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

export {
    fetch,
    //fetchAll,
    fetchStats,
    fetchStat,
    fetchStatsForOffer,
    fetchPreview,
    fetchByName,
    create,
    update,
    updateStatus,
    toggle,
    fetchStatsForOverview
};
