export default
{
    computed:
    {
        isLoggedIn()
        {
            return this.$store.state.user.isLoggedIn;
        }
    },
    methods:
    {
        initRedirect()
        {
            if(this.isLoggedIn)
                this.$router.push({ name: "performance", replace: true });
            else
                this.$router.push({ name: "login" });
        }
    }
};
