import { get } from "@/services/httpService";

const resource = "/s/fb-pages";

const fetchAll = (payload = {}, ...rest) =>
{
    return get(`${resource}`, {
        params: payload,
        ...rest
    });
};

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};
export {
    fetchAll,
    fetch
};