export function PrivacyPolicyDto({
    clientId = null,
    fbPageName = null,
    privacyPolicyLinkText = null,
    privacyPolicyLinkUrl = null,
    privacyPolicyDisclaimerTitle = null,
    privacyPolicyDisclaimerText = null,
    privacyPolicyDisclaimer = null,
    type = null,
    requireUserConsent = null,
    userConsentPrompt = null,
    status = null,
    statusText = null
} = {})
{
    return {
        linkText: privacyPolicyLinkText, // for update
        url: privacyPolicyLinkUrl, // for update
        title: privacyPolicyDisclaimerTitle, // for update
        text: privacyPolicyDisclaimerText, // for update

        clientId,
        name: fbPageName,
        type,
        status,
        statusText // should this one (statusText: null) be sent though??

        // dont send it for now
        // requireUserConsent: 0,
        // userConsentPrompt: '',
    };
}
