<template>
  <section class="loader">
    <div class="popover top loading-popover">
      <div class="popover-content">
        <div class="atom-spinner">
          <div class="spinner-inner">
            <div class="spinner-line" />
            <div class="spinner-line" />
            <div class="spinner-line" />
            <!--Chrome renders little circles malformed :(-->
            <div class="spinner-circle">
              <p>Loading</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Loader'
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/mixins";
@import "../assets/styles/theme/variables";

.loader {
    &__embed {
        .loading-popover {
            background: transparent;
            border: none;
            position: relative;
            top: 0;
            right: 0;
        }

        .atom-spinner {
            height: 20px;
            width: 20px;

            & .spinner-line:nth-child(1),
            & .spinner-line:nth-child(2),
            & .spinner-line:nth-child(3) {
                border-left-color: white;
            }
        }

        .spinner-circle {
            p {
                display: none;
            }
        }
    }
}
.loading-popover {
    top: 10px;
    left: unset;
    right: 30px;
    display: block;
    position: fixed;

    .popover-content {
        padding: 9px 14px;
    }
}

.atom-spinner,
.atom-spinner * {
    box-sizing: border-box;
}

.atom-spinner {
    height: 120px;
    width: 120px;
    overflow: hidden;
}

.atom-spinner .spinner-inner {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

.atom-spinner .spinner-circle {
    display: block;
    position: absolute;
    color: #28729c;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
        font-size: calc(40px * 0.28);
    }
}

.atom-spinner .spinner-line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation-duration: 1s;
    border-left-width: calc(60px / 25);
    border-top-width: calc(60px / 25);
    border-left-style: solid;
    border-top-style: solid;
    border-top-color: transparent;
}

.atom-spinner .spinner-line:nth-child(1) {
    animation: atom-spinner-animation-1 1s linear infinite;
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    border-left-color: #eab32f;
}

.atom-spinner .spinner-line:nth-child(2) {
    animation: atom-spinner-animation-2 1s linear infinite;
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    border-left-color: #1aae69;
}

.atom-spinner .spinner-line:nth-child(3) {
    animation: atom-spinner-animation-3 1s linear infinite;
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    border-left-color: #28729c;
}

@keyframes atom-spinner-animation-1 {
    100% {
        transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-2 {
    100% {
        transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-3 {
    100% {
        transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
}

@include media-breakpoint-down($md) {
    .loading-popover {
        @include transform-translate-x(-50%);

        top: 20%;
        left: 50%;
        right: unset;
    }

    .atom-spinner {
        height: 60px;
        width: 60px;

        .spinner-circle {
            p {
                font-size: calc(22px * 0.28);
            }
        }
    }
}
</style>
