import Page from '@/models/Page';
import { getFilters } from '@/repositories/AdGroupRepository';
import moment from "moment";
import projConfig from '../../../projConfig';
import mt from '../../mutationTypes';
import createColumnizator from '../../../models/Columnizator';
import createTotalizator from '../../../models/Totalizator';
import
{
    fetch,
    fetchStats,
    fetchByName,
    create,
    update,
    destroy,
    toggleDelivery, toggleSchedule
} from '@/repositories/AdGroupRepository';
import { removeEmpty } from "@/helpers/objectUtils";
import enums from "@/components/performance/constants";
import NetworkCreateError from "@/errors/networkCreateError";
import Filters from "@/models/Filters";
import EntityAdapter from "../../../store/adapter";
import { objectToAdGroupDto } from "@/models/ad-group";

const state = {
    adGroupAdapter: EntityAdapter().createEntityAdapter(),
    adGroupFilteredIds: [],
    expandedAdGroup: EntityAdapter().createEntityAdapter(),

    loading: false,
    adGroupTotalsStat: null,
    updatedAt: moment(),
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'spend',
        desc: true
    }
};

const mutations =
{
    [mt.SetAdGroups](state, data)
    {
        state.adGroupAdapter = state.adGroupAdapter.setAll(data, state.adGroupAdapter);

        state.updatedAt = moment();
    },

    [mt.SetAdGroupTotalsStat](state, data)
    {
        state.adGroupTotalsStat = data;
    },

    [mt.UpsertOneAdGroupsDetails](state, data)
    {
        state.adGroupAdapter = state.adGroupAdapter.upsertOne(data, state.adGroupAdapter);
    },
    [mt.UpsertManyAdGroupsDetails](state, data)
    {
        state.adGroupAdapter = state.adGroupAdapter.upsertMany(data, state.adGroupAdapter);
    },

    [mt.UpdateAdGroup](state, data)
    {
        state.adGroupAdapter = state.adGroupAdapter.updateOne(data, state.adGroupAdapter);
    },

    [mt.RemoveAllAdGroupData](state)
    {
        state.adGroupAdapter = state.adGroupAdapter.removeAll(state.adGroupAdapter);
    },

    [mt.RemoveAdGroup](state, id)
    {
        state.adGroupAdapter = state.adGroupAdapter.removeOne(id, state.adGroupAdapter);
    },

    [mt.SetAdGroupFilteredIds](state, data)
    {
        state.adGroupFilteredIds = data;
    },
    [mt.RemoveAdGroupFilteredId](state, id)
    {
        state.adGroupFilteredIds = state.adGroupFilteredIds.filter(((filteredId) => filteredId !== id));
    },

    [mt.SetAdGroupCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },

    [mt.SetAdGroupPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging,
            {
                currPage: state.page.currPage,
                totalResults: page.totalCount,
                currentResults: page.currentCount,
                totalPages: state.page.totalPages
            });
    },

    [mt.SetAdGroupSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.SetAdGroupStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    },
    [mt.UpsertAdGroupExpanded](state, data)
    {
        state.expandedAdGroup = state.expandedAdGroup.upsertOne(data, state.expandedAdGroup);
    },
    [mt.UpdateAdGroupExpanded](state, data)
    {
        state.expandedAdGroup = state.expandedAdGroup.updateOne(data, state.expandedAdGroup);
    },
    [mt.RemoveAllAdGroupExpanded](state)
    {
        state.expandedAdGroup = state.expandedAdGroup.removeAll(state.expandedAdGroup);
    },
};

const getters =
{
    getAdGroupIds: (state) => state.adGroupAdapter.ids,
    getAdGroupEntities: (state) => state.adGroupAdapter.entities,
    getAdGroupById: (state) => id => state.adGroupAdapter.entities[id],
    getAdGroupFilteredIds: (state) => state.adGroupFilteredIds,
    getAdGroupExtendIds: (state) => state.expandedAdGroup.ids,
    getAdGroupExtendEntities: (state) => state.expandedAdGroup.entities,
    getAdGroupExtendById: (state) => id => state.expandedAdGroup.entities[id],
    getAdGroupNetworkCampaigns: (state, getters, rootState, rootGetters) => adGroupId =>
    {
        const adGroupExpanded = getters.getAdGroupExtendById(adGroupId);
        if (!adGroupExpanded)
            return;
        const { networkCampaignsIds } = adGroupExpanded;
        return networkCampaignsIds ? networkCampaignsIds.map(rootGetters.getNetworkCampaignById) : [];
    },

    getAdGroupTotals: (state) => state.adGroupTotalsStat,
    getAdGroupPage: (state) => state.page,
    getAdGroupSort: (state) => state.sort,
    getAdGroupLastUpdatedAt: (state) => state.updatedAt,
};

const actions =
{
    async fetchAdGroupStats({ commit, state, rootGetters }, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const stateParams = getFilters(state, filters);
            const response = await fetchStats(stateParams);

            if (response && response.status === 200 && response.data)
            {
                let parseResponse = createColumnizator(response.data.data);

                if (filters && filters.shouldUpsert)
                    commit(mt.UpsertManyAdGroupsDetails, parseResponse);
                else
                    commit(mt.SetAdGroups, parseResponse);

                commit(mt.SetAdGroupTotalsStat, createTotalizator(response.data.totals));
                commit(mt.SetAdGroupPaging,
                    {
                        ...response.data.pages,
                        currentCount: response.data.data.length
                    });
                if (rootGetters.getAllFiltersCount('adGroup'))
                    commit(mt.SetAdGroupFilteredIds, parseResponse.map(data => data.id));
                else
                    commit(mt.SetAdGroupFilteredIds, []);
                return { success: true };
            }
            else
            {
                commit(mt.SetAdGroups, []);
                commit(mt.SetAdGroupFilteredIds, []);
                commit(mt.SetAdGroupTotalsStat, null);
                commit(mt.SetAdGroupPaging, new Page());
                return { success: false };
            }
        }
        catch (error)
        {
            if (error && error.response && error.response.status === 404)
            {
                commit(mt.SetAdGroups, []);
                commit(mt.SetAdGroupPaging,
                    {
                        top: projConfig.itemsPerPage,
                        skip: 0,
                        totalCount: 0,
                        currPage: 1,
                        currentCount: 0
                    });
            }
            else
            {
                throw error || new Error('Error getting network campaign stats.');
            }
        }
        finally
        {
            commit(mt.SetAdGroupStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },
    async fetchAdGroupStatsVanilla({ commit }, filters)
    {
        const stateParams = removeEmpty({
            ...Filters(filters)
        });

        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(stateParams);

            if (response && response.status === 200 && response.data)
            {
                const responseData = response.data;
                const adGroupStatsData = createColumnizator(responseData.data);
                commit(mt.SetAdGroups, adGroupStatsData);
                commit(mt.SetAdGroupTotalsStat, createTotalizator(responseData.totals));
                return {
                    page: responseData.pages,
                    data: adGroupStatsData
                };
            }
        }
        catch (error)
        {
            throw error || new Error('Error getting network campaign stats.');
        }
        finally
        {
            commit(mt.SetAdGroupStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },
    async fetchAdGroupByName({ commit }, { name, top = 1000, skip = 0, load = true, clientId = null, campaignId = null, campaignTypes = null, clientPermissions = null, ...rest } = {})
    {
        try
        {
            if (load) commit(mt.SetLoading, true);

            const response = await fetchByName({ name, top, skip, campaignId, clientId, clientPermissions, campaignTypes, ...rest });

            if (response && response.data && response.data.data)
            {
                commit(mt.UpsertManyAdGroupsDetails, response.data.data);
                return response.data.data;
            }

            return [];
        }
        finally
        {
            if (load) commit(mt.SetLoading, false);
        }
    },
    async fetchAdGroupDetails({ commit, getters }, { id, ...rest } = {})
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetch({ id, ...rest });

            const { data } = response || {};

            if (data && typeof data === "object")
            {
                const adGroup = getters.getAdGroupById(id);
                if (adGroup)
                {
                    const { adCreativesIds = [], networkCampaignsIds = [] } = adGroup;
                    commit(mt.UpdateAdGroup, {
                        id,
                        changes: {
                            ...data,
                            adCreativesIds: [...adCreativesIds],
                            networkCampaignsIds: [...networkCampaignsIds]
                        }
                    });
                }
                else
                {
                    commit(mt.UpsertOneAdGroupsDetails, data);
                }
            }

            return data;
        }
        catch (error)
        {
            console.error(error);
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async createAdGroup({ commit, getters, rootGetters }, data)
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await create(objectToAdGroupDto(data, rootGetters.getUserGroup));
            commit(mt.UpsertOneAdGroupsDetails, {
                ...response.data,
                netCmpCount: 0,
                netCmpActiveCount: 0,
                createdAtLocal: new Date().getTime()
            });
            if (rootGetters.getAllFiltersCount('adGroup'))
                commit(mt.SetAdGroupFilteredIds, [...new Set([response.data.id, ...state.adGroupFilteredIds])]);

            commit(mt.SetAdGroupPaging, {
                top: getters.getAdGroupPage.top,
                skip: getters.getAdGroupPage.skip,
                totalCount: getters.getAdGroupPage.totalCount + 1,
                currPage: getters.getAdGroupPage.currPage,
                currentCount: getters.getAdGroupPage.currentCount,
            });
            return response.data;
        }
        catch (error)
        {
            console.error(error);
            throw new Error(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    /*
    * Update Ad Group
    * Request URL: /s/network-campaign-groups/${id}
    * Request Method: PUT
    * Returns: Status Code: 204 if successful
    * */
    async updateAdGroup({ commit, rootGetters }, { id, data })
    {
        try
        {
            const adGroupDto = objectToAdGroupDto(data, rootGetters.getUserGroup);
            commit(mt.SetLoading, true);
            await update(id, adGroupDto);
            commit(mt.UpdateAdGroup, {
                id,
                changes: adGroupDto
            });
            return { id, adGroupDto };
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async deleteAdGroup({ commit, getters }, { id })
    {
        try
        {
            commit(mt.SetLoading, true);

            await destroy(id);
            commit(mt.RemoveAdGroup, id);
            commit(mt.RemoveAdGroupFilteredId, id);
            if (getters.getAdGroupPage.totalCount < 1)
                commit(mt.SetAdGroupPaging, new Page());
            else commit(mt.SetAdGroupPaging, {
                top: getters.getAdGroupPage.top,
                skip: getters.getAdGroupPage.skip,
                totalCount: getters.getAdGroupPage.totalCount - 1,
                currPage: getters.getAdGroupPage.currPage,
                currentCount: getters.getAdGroupPage.currentCount,
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadAdGroupAndSetFilter({ dispatch, commit }, adGroupId)
    {
        try
        {
            const adGroupDetails = await dispatch('fetchAdGroupDetails', { id: adGroupId });
            commit(mt.SetObjectFilter, { id: adGroupDetails.id, name: adGroupDetails.name, type: enums.OBJECT_FILTERS.AD_GROUP });
            return Promise.resolve(adGroupDetails);
        }
        catch (error)
        {
            console.error(error);
        }
    },
    async toggleAdGroupDelivery({ dispatch, commit }, { id, turnOn })
    {
        try
        {
            commit(mt.SetLoading, true);
            let status = (turnOn ? 'running' : 'paused');
            const response = await toggleDelivery({ id, status });

            if (response && response.status && [200, 204].includes(response.status))
            {
                commit(mt.UpsertOneAdGroupsDetails, { id, isOn: turnOn });
                await dispatch('fetchAdGroupDetails', { id });
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleAdGroupSchedule({ dispatch, commit }, { id })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await toggleSchedule({ id });

            if (response && response.status && [200, 204].includes(response.status))
            {
                await dispatch('fetchAdGroupDetails', { id });
                await dispatch('fetchNetworkCampaignsStats', { id });
            }
            return response;
        }
        catch (error)
        {
            if (error.data)
                throw new NetworkCreateError(error.data, error);

            throw new Error(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
};

const AdGroupDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default AdGroupDataModule;
