export default function createOrganizationUserColumns()
{
    return [
        "leads",
        "clientCpm",
        "clientSpend",
        "clientCpl",
        "crmDemosPerformed",
        "crmDemosSet",
        "crmWins",
        "crmWinRate",
        "status",
        "conversionRate",
        "clicks",
        "clientCpc",
        "impressions",
        "ctr"
    ];
}