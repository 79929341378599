import {Company} from '@/models/company/company';
import {CompanyDto} from '@/models/company/companyDto';
import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";

export const companyDtoToObject = dto =>
{
    const object = Company(dto);
    return object;
};

export const objectToCompanyDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, CompanyDto)(data);
    return dto;
};
