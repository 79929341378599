import projConfig from '../../projConfig';
import mt from '../mutationTypes';
import { post, get, put, deleteReq } from '@/services/httpService';
import EntityAdapter from "@/store/adapter";

const state =
{
    batches: EntityAdapter().createEntityAdapter(),

    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
};

const mutations =
{
    [mt.UpsertOneBatchesData](state, data)
    {
        state.batches = state.batches.upsertOne(data, state.batches);
    },
    [mt.UpsertManyBatchesData](state, data)
    {
        state.batches = state.batches.upsertMany(data, state.batches);
    },
    [mt.SetBatchPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging,
            {
                currPage: state.page.currPage,
                totalResults: page.totalCount,
                currentResults: page.currentCount,
                totalPages: state.page.totalPages
            });
    },
    [mt.SetAdCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    }
};

const getters = {

    getBatchesById: state => id => state.batches.entities[id],

    getBatchesPaging(state) 
    {
        return state.page;
    }
};

const actions = {
    async fetchBatchesForCampaign({ commit, state }, {id, currPage = null, ...rest} = {})
    {
        try
        {
            let url = `/s/batches`;
            
            let top = state.page.top;
            let skip = state.page.skip;

            if (currPage && currPage !== state.currPage)
                skip = (currPage - 1) * top;
            
            commit(mt.SetLoading, true);

            const response = await get(url, {
                params : {
                    top,
                    skip,
                    cmpId: id
                },
                ...rest
            });

            commit(mt.SetLoading, false);
            if(response && response.data.data)
            {
                const batchesData = response.data.data;

                commit(mt.UpsertManyBatchesData, batchesData);
                const batchesIds = batchesData.map(batches => batches.id);
                commit(mt.UpdateCampaign, {
                    id,
                    changes:{
                        batchesIds
                    }
                });
                commit(mt.SetBatchPaging,
                    {
                        ...response.data.pages,
                        currentCount: response.data.data.length
                    });
            }
        }
        catch(error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createNewBatch({ commit }, batch = {
        clientId,
        cmpId,
        id,
        name,
        clientPayment,
        clientPaidPer,
        clientStartDate,
        clientEndDate,
        networks,
        leadsGoal,
        clicksGoal,
        impressionsGoal,
        goalCompletionType,
        startDate,
        endDate,
        isActive
    })
    {
        try
        {
            commit(mt.SetLoading, true);
            const url = "/s/batches/";
            const response = await post(url, batch);
            commit(mt.UpsertOneBatchesData, response.data);
            if (response.data.isActive)
                commit(mt.UpdateCampaign, {
                    id: response.data.cmpId,
                    changes:{
                        batchId: response.data.id
                    }
                });
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async updateBatch({ commit }, {id, batch = {} })
    {
        try
        {
            const url = `/s/batches/${id}`;
            commit(mt.SetLoading, true);

            await put(url, batch);

            commit(mt.UpsertOneBatchesData ,{id, ...batch});

            if (batch.isActive)
                commit(mt.UpdateCampaign, {
                    id: batch.cmpId,
                    changes:{
                        batchId: batch.id
                    }
                });
            
            commit(mt.SetLoading, false);
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async deleteBatch({commit}, id)
    {
        try
        {
            let url = `/s/batches/${id}`;

            commit(mt.SetLoading, true);
            await deleteReq(url);
            commit(mt.SetLoading, false);
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    }
};

const BatchDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default BatchDataModule;
