export function Integration({
    id,
    clientId,
    integrationTypeUncased,
    integrationType,
    isExpired
} = {})
{
    return {
        id,
        clientId,
        integrationType,
        integrationTypeUncased,
        isActive: !isExpired
    };
}
