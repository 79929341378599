import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
import {CommentDto} from "@/models/comment/commentDto";
import {Comment} from "@/models/comment/comment";

export const commentDtoToObject = dto =>
{
    const object = Comment(dto);
    return object;
};

export const objectToCommentDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, CommentDto)(data);
    return dto;
};
