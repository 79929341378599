import {get} from "@/services/httpService";

const resource = "/s/clients";

const fetch = id =>
{
    return get(`${resource}/${id}/billing`);
};
export {
    fetch,
};
