import enums, {JOB_STATUS, STATUSES} from '@/components/performance/constants';
import {ENTITY_SUCCESS_STATE} from '@/helpers/enums/enums';
import {removeAllEmpty, removeEmpty} from '@/helpers/objectUtils';
import {createUrlFiltersParameters, createUrlObjectFilters} from '@/helpers/urlUtils';
import {objectToCampaignDto} from '@/models/campaign';
import {PendingJob} from '@/models/PendingJob';
import projConfig from '@/projConfig';
import {
    checkToggle,
    create,
    fetch,
    fetchAll,
    fetchNetworkStats,
    fetchStats,
    toggle,
    update
} from '@/repositories/CampaignRepository';
import {get} from '@/services/httpService';
import {LocalStorageObservableService} from '@/services/localStorageObservableService';
import {LocalStorageService} from '@/services/localStorageService';
import EntityAdapter from '@/store/adapter';
import moment from 'moment';
import Vue from 'vue';
import createColumnizator from '../../../models/Columnizator';
import createTotalizator from '../../../models/Totalizator';
import mt from '../../mutationTypes';


const state = {
    campaignAdapter: EntityAdapter().createEntityAdapter(),
    campaignFilteredIds: [],
    campaignTotalsStat: null,

    statTotalsActive: [],
    statTotalsInactive: [],

    networkStats: {},
    totalNetworkStats: [],

    updatedAt: moment(),
    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort: {
        field: "spend",
        desc: true
    }
};

const mutations = {
    [mt.SetCampaigns](state, data)
    {
        state.campaignAdapter = state.campaignAdapter.setAll(data, state.campaignAdapter);

        for (let row of data)
        {
            let campaignNetStat = state.networkStats[row.id];

            if (campaignNetStat && campaignNetStat.isExpanded)
            {
                this.dispatch('loadCampaignNetworkStats', { cmpId: row.id });
            }
            else
            {
                Vue.set(state.networkStats, row.id, {});
                Vue.set(state.networkStats[row.id], "isLoaded", false);
                Vue.set(state.networkStats[row.id], "isExpanded", false);
                Vue.set(state.networkStats[row.id], "data", null);
            }
        }
    },
    [mt.AddCampaign](state,data)
    {
        state.campaignAdapter = state.campaignAdapter.addOne(data, state.campaignAdapter);
    },
    [mt.SetCampaignTotalsStat](state, data)
    {
        state.campaignTotalsStat = data;
    },
    [mt.UpsertOneCampaignsDetails](state, data)
    {
        state.campaignAdapter = state.campaignAdapter.upsertOne(data, state.campaignAdapter);
    },
    [mt.UpsertManyCampaigns](state, data)
    {
        state.campaignAdapter = state.campaignAdapter.upsertMany(data, state.campaignAdapter);
        for (let row of data)
        {
            let campaignNetStat = state.networkStats[row.id];

            if (campaignNetStat && campaignNetStat.isExpanded)
            {
                this.dispatch('loadCampaignNetworkStats', { cmpId: row.id });
            }
            else
            {
                Vue.set(state.networkStats, row.id, {});
                Vue.set(state.networkStats[row.id], "isLoaded", false);
                Vue.set(state.networkStats[row.id], "isExpanded", false);
                Vue.set(state.networkStats[row.id], "data", null);
            }
        }
    },
    [mt.RemoveAllCampaignData](state)
    {
        state.campaignAdapter = state.campaignAdapter.removeAll(state.campaignAdapter);
    },
    [mt.RemoveCampaignData](state, id)
    {
        state.campaignAdapter = state.campaignAdapter.removeOne(id, state.campaignAdapter);
    },
    [mt.UpdateCampaign](state, campaign)
    {
        state.campaignAdapter = state.campaignAdapter.updateOne(campaign, state.campaignAdapter);
    },
    [mt.SetCampaignNetworkStats](state, res)
    {
        // at this point, object at client id with properties should exist
        state.networkStats[res.cmpId].isLoaded = true;
        state.networkStats[res.cmpId].data = res.data;
    },
    [mt.SetCampaignNetworkExpanded](state, obj)
    {
        if (state.networkStats[obj.cmpId])
            state.networkStats[obj.cmpId].isExpanded = obj.isExpanded;
    },
    [mt.SetCampaignPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = page.skip / page.top + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetCampaignCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetCampaignSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.SetCampaignTotalNetworkStats](state, stats)
    {
        state.totalNetworkStats = stats;
    },
    [mt.SetCampaignTotalsActive](state, totals)
    {
        state.statTotalsActive = totals;
    },
    [mt.SetCampaignTotalsInactive](state, totals)
    {
        state.statTotalsInactive = totals;
    },
    [mt.SetCampaignFilteredIds](state, data)
    {
        state.campaignFilteredIds = data;
    },
    [mt.SetCampaignStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    }
};

const getters = {
    getCampaignIds: (state) => state.campaignAdapter.ids,
    getCampaignEntities: (state) => state.campaignAdapter.entities,
    getCampaignById: (state) => id => state.campaignAdapter.entities[id],
    getCampaignFilteredIds: (state) => state.campaignFilteredIds,

    getCampaigns(state, getters)
    {
        return getters.getCampaignIds.map(id => getters.getCampaignEntities[id]);
    },
    getCampaignTotals(state)
    {
        return state.campaignTotalsStat;
    },
    getCampaignStatTotalsActive(state)
    {
        return state.statTotalsActive || {};
    },
    getCampaignStatTotalsInactive(state)
    {
        return state.statTotalsInactive || {};
    },
    getCampaignPage(state)
    {
        return state.page;
    },
    getCampaignSort(state)
    {
        return state.sort;
    },
    getCampaignNetworkStats: state => cmpId =>
    {
        return (
            state.networkStats[cmpId] || {
                isLoaded: false,
                isExpanded: false,
                data: null
            }
        );
    },
    getCampaignTotalNetworkStats(state)
    {
        return state.totalNetworkStats;
    },
    getCampaignLastUpdatedAt(state)
    {
        return state.updatedAt;
    }
};

const actions = {

    async loadCampaignNetworkStats({ commit, state }, filters)
    {
        commit(mt.SetLoading, true);
        try
        {
            const response = await fetchNetworkStats(state, filters);

            commit(mt.SetLoading, false);

            if (response && response.data && response.status === 200)
            {
                commit(mt.SetCampaignNetworkStats, {
                    cmpId: filters.cmpId,
                    data: response.data
                });
                return {success: true};
            }
            else
            {
                commit(mt.SetCampaignNetworkStats, {
                    cmpId: filters.cmpId,
                    data: []
                });
                return {success: false};
            }
        }
        catch(error)
        {
            commit(mt.SetLoading, false);

            if (error && error.response && error.response.status === 404)
                commit(mt.SetCampaignNetworkStats, {
                    cmpId: filters.cmpId,
                    data: null
                });
            else throw error;
        }
    },
    async loadCampaignsData({commit, state, rootGetters}, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);
            if (response && response.status === 200 && response.data)
            {
                let responseData = [];
                // TODO: add to model
                response.data.data.forEach(campaign =>
                {
                    let campaignData = {...campaign , createdAt: 0};
                    responseData.push(campaignData);
                });

                if(filters && filters.shouldUpsert)
                    commit(mt.UpsertManyCampaigns, createColumnizator(responseData));
                else
                    commit(mt.SetCampaigns, createColumnizator(responseData));

                commit(mt.SetCampaignTotalsStat, createTotalizator(response.data.totals));
                commit(mt.SetCampaignPaging, {
                    ...response.data.pages,
                    currentCount: response.data.data.length
                });

                if(rootGetters.getAllFiltersCount('campaignPage'))
                    commit(mt.SetCampaignFilteredIds, responseData.map(data => data.id));
                else
                    commit(mt.SetCampaignFilteredIds, []);

                let batchForCampaigns = [];
                response.data.data.forEach(batch =>
                {
                    const campaignBatch = {id: batch.batchId, ...batch.batchGoals};
                    batchForCampaigns.push(campaignBatch);
                });
                commit(mt.UpsertManyBatchesData, batchForCampaigns);
            }
            else
            {
                commit(mt.SetCampaigns, []);
                commit(mt.SetCampaignTotalsStat, null);
                commit(mt.SetCampaignFilteredIds, []);

                commit(mt.SetCampaignPaging, {
                    top: projConfig.itemsPerPage,
                    skip: 0,
                    totalCount: 0,
                    currentCount: 0,
                    currPage: 1
                });
            }
        }
        catch(error)
        {
            throw error || new Error("Error getting campaigns.");
        }
        finally
        {
            commit(mt.SetCampaignStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },
    async loadCampaignsTotals({ commit, rootGetters }, withActive)
    {
        try
        {
            let url = "/s/campaigns/stats";
            const timeZoneDates =  rootGetters.getDateRangeFilters;
            const filterParams = createUrlFiltersParameters({
                timeZoneDates,
                isActive: [{type: "campaignPage", value: withActive}] // TODO: create interface/dto for active filters
            }, {}, "campaignPage");
            const additionalFilter = withActive ? "" : "isActive";
            const stateParams = removeEmpty({
                ...filterParams
            }, additionalFilter);


            commit(mt.SetLoading, true);

            const response = await get(url,{
                params: { ...stateParams }
            });

            commit(mt.SetLoading, false);

            if (response && response.data && response.status === 200)
            {
                if(withActive) commit(mt.SetCampaignTotalsActive, response.data.totals);
                else commit(mt.SetCampaignTotalsInactive, response.data.totals);
                return { success: true };
            }
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error || new Error("Error getting campaign totals data.");
        }
    },
    async loadEveryCampaignData({ dispatch, state})
    {
        await Promise.all([
            dispatch("loadCampaignsTotals", true),
            dispatch("loadCampaignsTotals", false)
        ]);

        dispatch("loadCampaignsData", {
            currPage: state.page.currPage
        });
    },

    async fetchCampaigns({ commit }, {top = 1000, skip = 0, load = true} = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll( {
                top,
                skip
            });

            commit(mt.SetCampaigns, createColumnizator(response.data.data));
            return response.data.data;
        }
        catch(error)
        {
            console.log(error); // eslint-disable-line
            throw new Error(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
            commit(mt.SetCampaignStatsLastUpdatedAt);
        }
    },
    async loadCampaignTotalNetworkStats({commit, state, rootState, rootGetters}, filters)
    {
        let url = projConfig.apiRoot + '/s/networks/stats/?applyFiltersTo=campaign';
        const objectFilters = createUrlObjectFilters(rootGetters);
        const timeZoneDates = rootGetters.getDateRangeFilters;
        const urlFiltersData = {...rootState.filters, timeZoneDates};
        const filterParams = createUrlFiltersParameters(urlFiltersData, state.sort, 'campingPage');

        let {top, skip} = state.page;
        const {field, desc} = state.sort;

        if (filters && filters.currPage !== state.currPage)
            skip = (filters.currPage - 1) * top;

        const stateParams = removeEmpty({
            top,
            skip,
            orderBy: field,
            orderByDesc: desc,
            ...filterParams,
            ...objectFilters
        });

        commit(mt.SetLoading, true);

        try
        {
            const response = await get(url, {
                params: {...stateParams}
            });
            commit(mt.SetLoading, false);
            if (response && response.status === 200 && response.data)
            {
                commit(mt.SetCampaignTotalNetworkStats, response.data.data);
                return {success: true};
            }
            else
            {
                commit(mt.SetCampaignTotalNetworkStats, []);
                return {success: false};
            }
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            console.log(error); // eslint-disable-line
        }
    },
    async loadCampaignAndSetFilter({ dispatch, commit, getters }, cmpId)
    {
        try
        {
            await dispatch("fetchCampaignDetails", {id: cmpId });
            const campaign = getters.getCampaignById(cmpId);
            // legacy mutation
            commit(mt.SetCampaignFilter, {
                id: campaign.id,
                name: campaign.name
            });
            commit(mt.SetObjectFilter, {
                id: campaign.id,
                name: campaign.name,
                type: enums.OBJECT_FILTERS.CAMPAIGN
            });
            return Promise.resolve(campaign);
        }
        catch (error)
        {
            console.error(error);
        }
    },
    async toggleCampaignDelivery({ commit, getters }, { id, turnOn })
    {
        try
        {
            let action = turnOn ? "play" : "pause";
            let payload = {"async": true};
            
            const res = await toggle({id, action, payload});
            
            if (res && res.status && [200, 204].includes(res.status))
            {
                const statusJob = res.data;
                
                const campaign = getters.getCampaignById(id);
                // add jobid to localstorage
                const localStorageObservable = LocalStorageObservableService.getInstance();
                const jobs = localStorageObservable.get(LocalStorageService.CAMPAIGN_JOBS);
                
                localStorageObservable.set(LocalStorageService.CAMPAIGN_JOBS, {
                    ...jobs,
                    [id]: new PendingJob(removeAllEmpty({
                        metadata: {
                            name: campaign.name,
                            status: turnOn ? STATUSES.RUNNING : STATUSES.PAUSED
                        },
                        jobId: statusJob?.jobId
                    }))
                });
            }
        }
        catch (error)
        {
            let errMsg;
            errMsg = error.response
                ? (errMsg = error.response.data)
                : !error.request
                    ? error.message
                    : null;
            throw new Error(errMsg);
        }
    },
    async checkToggleCampaignDelivery({ commit }, {campaignId, jobId, status})
    {
        try
        {
            const response = await checkToggle({campaignId, jobId});
            
            if (!response && !response.status && ![200, 204].includes(response.status))
                return;
    
            const responseData = response.data;
            const {status: jobStatus, error} = responseData;
            
            switch(jobStatus)
            {
                case JOB_STATUS.STARTED:
                case JOB_STATUS.RUNNING:
                    return;
                case JOB_STATUS.ERROR:
                    throw new Error(error);
                case JOB_STATUS.DONE:
                    commit(mt.UpdateCampaign, {
                        id: campaignId,
                        changes: {
                            status
                        }
                    });
                    break;
            }
            
            return ENTITY_SUCCESS_STATE.UPDATED;
        }
        catch (error)
        {
            throw new Error(error);
        }
    },
    async fetchCampaignDetails({ commit }, { id, ...rest })
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetch( {id, ...rest});
            if (response && response.status && response.status === 200)
                commit(mt.UpsertOneCampaignsDetails, response.data);
            else
                throw new Error(response);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createCampaign({ commit, rootGetters }, campaign)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(campaign);

            if (response && response.status && response.status === 200)
            {
                commit(mt.AddCampaign,{
                    ...response.data,
                    createdAt: new Date().getTime(),
                    activeCampaigns: null
                });
                if(rootGetters.getAllFiltersCount('campaignPage'))
                    commit(mt.SetCampaignFilteredIds, [...new Set([response.data.id, ...state.campaignFilteredIds])]);

                return { success: true, campaign: response.data };
            }

            else {return { success: false };}
        }
        catch(error)
        {
            console.error(error); // eslint-disable-line
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateCampaign({ commit }, { id, data })
    {
        try
        {
            commit(mt.SetLoading, true);
            await update({id, campaign: data});
            commit(mt.UpsertOneCampaignsDetails, {id, ...data});
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchCampaignByName({ commit }, { top = 1000, skip = 0, load = true, clientId = null, name,clientPermissions = null, ...rest} = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll( {
                cmpName: name,
                clId: clientId,
                top,
                skip,
                clientPermissions
            },
            rest);
            if(load) commit(mt.SetLoading, false);
            if(response && response.data) return response.data.data;
        }
        catch(error)
        {
            console.error(error);
            if(load) commit(mt.SetLoading, false);
            throw error;
        }
    }
};

const CampaignDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default CampaignDataModule;
