import { replaceString } from "../helpers/stringUtils";

// use this to map error information if needed, as the message is set from the api endpoint
const LOCATION_ENUM = {
    general: "",
};

const getErrorLocation = (url) =>
{
    const urlArray = url.split("/");
    if (urlArray.length > 0)
    {
        // remove /s/ from url and check for query parameters
        const string =
            urlArray[2].indexOf("?") > -1
                ? urlArray[2].substring(0, urlArray[2].indexOf("?"))
                : urlArray[2];
        return replaceString("-", string);
    }
    return "general";
};

const apiErrorsHandler = (error) =>
{
    // in case we need to ditch the error handler
    if (
        error.config &&
        Object.prototype.hasOwnProperty.call(error.config, "errorHandle") &&
        error.config.errorHandle === false
    )
        return Promise.reject(error);

    if (error.response)
        switch (error.response.status)
        {
            /*case 401:
            case 404:
                break;*/
            case 500:
                // eslint-disable-next-line no-case-declarations
                const errorLocation = getErrorLocation(error.response.config.url);
                // eslint-disable-next-line no-case-declarations
                const errorMessage = LOCATION_ENUM[errorLocation]
                    ? LOCATION_ENUM[errorLocation]
                    : errorLocation;

                VueEvent.$emit("api-error", errorMessage);
                break;
        }
};

const getErrorMessageFromErrorObject = (error, defaultMessage = "") =>
{
    if (typeof error === "string")
        return error;
    if (typeof error === "object" && error.rawData)
        return error.rawData;
    if (typeof error === "object" && error.message)
        return error.message;
    return defaultMessage;
};

export { apiErrorsHandler, getErrorMessageFromErrorObject };
