import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
import { NetworkAudience } from './networkAudience';
import { NetworkAudienceDto } from './networkAudienceDto';

export const networkAudienceDtoToObject = dto =>
{
    const object = NetworkAudience(dto);
    return object;
};

export const objectToNetworkAudienceDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, NetworkAudienceDto)(data);
    return dto;
};
