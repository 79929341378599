export function AdCreativeStatusDto({
    status = null,
    statusText = null,
    networkCampaignId = null,
    adSetId = null,
} = {})
{
    return {
        status,
        statusText,
        ncId: networkCampaignId,
        nasId: adSetId,
        async: true
    };
}
