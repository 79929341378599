import {deleteReq, get, post, put} from "@/services/httpService";

const resource = "/s/campaign-schedules";


const fetch = ({cmpId, ...rest} = {} ) =>
{
    return get(`${resource}`, {
        params: {
            cmpId
        },
        ...rest
    });
};

const update = ({id, data} = {}) =>
{
    return put(`${resource}/${id}`, {
        ...data
    });
};

const create = (data) => 
{
    return post(`${resource}`, {
        ...data
    });
};
const destroy = id => 
{
    return deleteReq(`${resource}/${id}`);
};
export {
    fetch,
    update,
    create,
    destroy
};