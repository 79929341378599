import store from '@/store/store';
import { BehaviorSubject } from 'rxjs';

export default (() =>
{
    let instance;

    const init = () =>
    {
        const subjects = {
            pendingRequestsInProgress: new BehaviorSubject(false),
        };

        return new Proxy(subjects, {
            get: (proxy, name) =>
            {
                return proxy[name];
            },
            set: (proxy, name, value) =>
            { // intercepts property assignments
                proxy[name].next(value);
                return true;
            }
        });
    };

    return {
        getInstance: () =>
        {
            if(!instance)

                instance = init();

            return instance;
        }
    };
})();

async function refresh()
{
    await store.dispatch("exchangeRefreshToken");
    await store.dispatch("loadUser");
}

async function requestRefresh({httpClient, originalRequest, authInterceptor} = {})
{
    await refresh();
    // token refreshed and user is loaded
    // attempt to reload the request using the new auth token
    // https://github.com/axios/axios/issues/934
    const newRequest = authInterceptor(originalRequest);
    return httpClient.request(newRequest);
}

export { refresh, requestRefresh };
