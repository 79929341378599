export function Transactions({
    id = null,
    clientId = null,
    type = "",	// "credit" | "debit"
    method = "",	// "ach" | "card" | "promo"
    amount = 0,
    extInvoiceId = "",
    extInvoiceUri = "",
    sourceType = "",	// "capture" | "charge" | "credit" | "preAuth" | "refund" | "void"
    state = "",	// "pending" | "paid" | "rejected" | "chargeback" | "error"
    trnStartsAt = null,
    trnEndsAt = null,
    trnDt = null,

    paymentMethod = "",	// "card" | "bank"
    cardType = "", 	// "visa" | "mastercard"
    lastFour = "",
    clientName = "",

} = {}) 
{
    return {
        id,
        clientId,
        type,
        method,
        amount,
        extInvoiceId,
        extInvoiceUri,
        sourceType,
        state,
        trnStartsAt,
        trnEndsAt,
        trnDt,
        paymentMethod,
        cardType,
        lastFour,
        clientName,
    };
}