function DtoBase()
{
    this.__stamp = "DTO";
}

function setDtoBase(object)
{
    DtoBase.call(object);
    return object;
}

export {setDtoBase};
