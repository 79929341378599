export function NetworkCampaignStat({
    accountId,
    campaignId,
    clicks,
    clientCpc,
    clientCpl,
    clientId,
    clientSpend,
    cmpType,
    comments,
    conversionRate,
    cpc,
    cpl,
    cpm,
    createdBy,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    endDate,
    id,
    impressions,
    isActive,
    leads,
    likes,
    lpId,
    mediaPercentage,
    name,
    networkLeads,
    postReactions,
    shares,
    source,
    spend,
    startDate,
    status,
    networkStatus,
    // just as a map, it is not returned from the api
    netCmpGrpId
} = {})
{
    return {
        accountId,
        adGroupId: netCmpGrpId,
        campaignId,
        clicks,
        clientCpc,
        clientCpl,
        clientId,
        clientSpend,
        cmpType,
        comments,
        conversionRate,
        cpc,
        cpl,
        cpm,
        createdBy,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        endDate,
        id,
        impressions,
        isActive,
        leads,
        likes,
        lpId,
        mediaPercentage,
        name,
        networkLeads,
        postReactions,
        shares,
        source,
        spend,
        startDate,
        status,
        networkStatus
    };
}
