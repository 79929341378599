import { get } from "@/services/httpService";

const resource = "/s/batches";

const fetchMax = () =>
{
    return get(`${resource}/max`);
};

export
{
    fetchMax
};
