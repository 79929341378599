/* eslint-disable curly */
import en from "vue-upload-component/docs/i18n/en";

const EntityAdapter = () =>
{

    const DidMutate = {
        EntitiesOnly: 0,
        Both: 1,
        None: 2,
    };

    DidMutate[DidMutate.EntitiesOnly] = 'EntitiesOnly';
    DidMutate[DidMutate.Both] = 'Both';
    DidMutate[DidMutate.None] = 'None';

    const initialEntityAdapter = () =>
    {
        return {
            ids: [],
            entities: {}
        };
    };

    function selectIdValue(entity, selectId)
    {
        return selectId(entity);
    }

    function createEntityAdapter(options)
    {
        // TBD
        if (options === undefined)
        {
            options = {};
        }

        // set default id to the id that is in entity object
        var _a = Object.assign({
            selectId: ((instance) =>
            {
                return instance.id;
            })
        }, options);

        var selectId = _a.selectId;

        var stateAdapter = createStateAdapter(selectId);
        return Object.assign({selectId: selectId}, initialEntityAdapter(), stateAdapter);
    }

    function createStateOperator(mutator)
    {
        return (
            function operation(arg, state)
            {

                // We will mutate clonedEntityState
                var clonedEntityState = {
                    ids: [...state.ids],
                    entities: Object.assign({}, state.entities),
                };
                const didMutate = mutator(arg, clonedEntityState);
                if (didMutate === DidMutate.Both)
                {
                    return Object.assign({}, state, clonedEntityState);
                }
                if (didMutate === DidMutate.EntitiesOnly)
                {
                    return Object.assign(Object.assign({}, state), {entities: clonedEntityState.entities});
                }
                return state;
            });
    }

    function createStateAdapter(selectId)
    {

        function addOneMutably(entity, state)
        {
            const key = selectIdValue(entity, selectId);
            if (key in state.entities)
            {
                return DidMutate.None;
            }
            state.ids.push(key);
            state.entities[key] = entity;
            return DidMutate.Both;
        }


        function addManyMutably(entities, state)
        {
            let didMutate = false;
            for (const entity of entities)
            {
                didMutate = addOneMutably(entity, state) !== DidMutate.None || didMutate;
            }
            return didMutate ? DidMutate.Both : DidMutate.None;
        }


        function setAllMutably(entities, state)
        {
            state.ids = [];
            state.entities = {};
            addManyMutably(entities, state);
            return DidMutate.Both;
        }

        function setOneMutably(entity, state)
        {
            const key = selectIdValue(entity, selectId);
            if (key in state.entities)
            {
                state.entities[key] = entity;
                return DidMutate.EntitiesOnly;
            }
            state.ids.push(key);
            state.entities[key] = entity;
            return DidMutate.Both;
        }

        function removeOneMutably(key, state)
        {
            return removeManyMutably([key], state);
        }

        function removeManyMutably(keysOrPredicate, state)
        {
            const keys = keysOrPredicate instanceof Array
                ? keysOrPredicate
                : state.ids.filter(((key) => keysOrPredicate(state.entities[key])));
            const didMutate = keys
                .filter(((key) => key in state.entities))
                .map(((key) => delete state.entities[key])).length > 0;
            if (didMutate)
            {
                state.ids = state.ids.filter(((id) => id in state.entities));
            }
            return didMutate ? DidMutate.Both : DidMutate.None;
        }

        function removeAll(state)
        {
            return Object.assign({}, state, {
                ids: [],
                entities: {},
            });
        }

        function takeNewKey(keys, update, state)
        {
            const original = state.entities[update.id];
            const updated = Object.assign({}, original, update.changes);
            const newKey = selectIdValue(updated, selectId);
            const hasNewKey = newKey !== update.id;
            if (hasNewKey)
            {
                keys[update.id] = newKey;
                delete state.entities[update.id];
            }
            state.entities[newKey] = updated;
            return hasNewKey;
        }

        function updateOneMutably(update, state)
        {
            return updateManyMutably([update], state);
        }

        function updateManyMutably(updates, state)
        {
            const newKeys = {};
            updates = updates.filter(((update) => update.id in state.entities));
            const didMutateEntities = updates.length > 0;
            if (didMutateEntities)
            {
                const didMutateIds = updates.filter(((update) => takeNewKey(newKeys, update, state))).length > 0;
                if (didMutateIds)
                {
                    state.ids = state.ids.map(((id) => newKeys[id] || id));
                    return DidMutate.Both;
                }
                else
                {
                    return DidMutate.EntitiesOnly;
                }
            }
            return DidMutate.None;
        }

        function mapMutably(map, state)
        {
            const changes = state.ids.reduce((
                (changes, id) =>
                {
                    const change = map(state.entities[id]);
                    if (change !== state.entities[id])
                    {
                        changes.push({id, changes: change});
                    }
                    return changes;
                }), []);
            const updates = changes.filter((({id}) => id in state.entities));
            return updateManyMutably(updates, state);
        }

        function mapOneMutably({map, id}, state)
        {

            const entity = state.entities[id];
            if (!entity)
            {
                return DidMutate.None;
            }
            const updatedEntity = map(entity);
            return updateOneMutably({
                id: id,
                changes: updatedEntity,
            }, state);
        }

        function upsertOneMutably(entity, state)
        {
            return upsertManyMutably([entity], state);
        }

        function upsertManyMutably(entities, state)
        {
            const added = [];
            const updated = [];
            for (const entity of entities)
            {
                const id = selectIdValue(entity, selectId);
                if (id in state.entities)
                {
                    updated.push({id, changes: entity});
                }
                else
                {
                    added.push(entity);
                }
            }
            const didMutateByUpdated = updateManyMutably(updated, state);
            const didMutateByAdded = addManyMutably(added, state);
            switch (true)
            {
                case didMutateByAdded === DidMutate.None &&
                didMutateByUpdated === DidMutate.None:
                    return DidMutate.None;
                case didMutateByAdded === DidMutate.Both ||
                didMutateByUpdated === DidMutate.Both:
                    return DidMutate.Both;
                default:
                    return DidMutate.EntitiesOnly;
            }
        }

        return {
            /***
             * removeAll: Clear entity collection.
             */
            removeAll,

            /***
             * addOne : Add one entity to the collection.
             */
            addOne: createStateOperator(addOneMutably),

            /***
             * addMany: Add multiple entities to the collection.
             */
            addMany: createStateOperator(addManyMutably),

            /***
             * setAll: Replace current collection with provided collection.
             */
            setAll: createStateOperator(setAllMutably),

            /***
             * setOne: Add or Replace one entity in the collection.
             */
            setOne: createStateOperator(setOneMutably),

            /***
             * updateOne: Update one entity in the collection. Supports partial updates.
             * usage:
             * state = state.updateOne({ state = state.updateOne({ changes: data, changes: data}, state);
             */
            updateOne: createStateOperator(updateOneMutably),

            /***
             * updateMany: Update multiple entities in the collection. Supports partial updates
             * usage:
             * state = state.updateMany({
             * updateObjects.map((data) => Object.assign({}, { id: data.id, changes: data })), state);
             */
            updateMany: createStateOperator(updateManyMutably),

            /***
             * upsertOne: Add or Update one entity in the collection. Supports partial updates.
             */
            upsertOne: createStateOperator(upsertOneMutably),

            /***
             * upsertMany: Add or Update multiple entities in the collection. Supports partial updates.
             */
            upsertMany: createStateOperator(upsertManyMutably),

            /***
             * removeOne: Remove one entity from the collection.
             */
            removeOne: createStateOperator(removeOneMutably),

            /***
             * removeMany: Remove multiple entities from the collection, by id or by predicate.
             */
            removeMany: createStateOperator(removeManyMutably),

            /***
             * map: Update multiple entities in the collection by defining a map function, similar to Array.map.
             */
            map: createStateOperator(mapMutably),

            /***
             * mapOne: Update one entity in the collection by defining a map function.
             */
            mapOne: createStateOperator(mapOneMutably),
        };
    }

    return {
        createEntityAdapter
    };
};

export default EntityAdapter;