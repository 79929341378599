export function AdGroupDto({
    name,
    clientId,
    campaignId,
    offerId,
    dailyBudget,
    clientDailyBudget,
    audiences,
    type
} = {})
{
    return {
        name, // string,
        clientId, // number,    // taken from the selected campaign
        cmpId: campaignId, // number,
        offerId, // number,
        dailyBudget, // number,
        clientDailyBudget, // number,
        audiences, // number[],
        type // "awareness" | "conversion" | "leadGeneration" | "traffic" // this one is optional. only send if campaign type is selfService
    };
}
