import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {fetch, create, destroy} from "@/repositories/TrackingParametersRepository";

const state = {
    trackingParameters: EntityAdapter().createEntityAdapter()
};

const mutations = {
    [mt.SetTrackingParameters](state, trackingParameters)
    {
        state.trackingParameters = state.trackingParameters.setAll(trackingParameters, state.trackingParameters);
    },
    [mt.AddTrackingParameter](state, trackingParameter)
    {
        state.trackingParameters = state.trackingParameters.addOne(trackingParameter, state.trackingParameters);
    },
    [mt.RemoveTrackingParameter](state, id)
    {
        state.trackingParameters = state.trackingParameters.removeOne(id, state.trackingParameters);
    }
};

const getters = {
    getTrackingParameterById: state => id => state.trackingParameters.entities[id]
};

const actions = {
    async fetchTrackingParameterByLandingPageId({ commit }, {lpId})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({lpId});
            if(!response)
            {
                commit(mt.SetLoading, false);
                throw new Error("No tracking parameters found for landing page");
            }
    
            const trackingParameters = response.data.data;
            commit(mt.SetTrackingParameters, trackingParameters);
    
            commit(mt.UpdateLandingPage, {
                id: lpId,
                changes: {
                    trackingParametersIds: trackingParameters.map(parameter => parameter.id)
                }
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async createTrackingParameters({ commit, rootGetters }, parameter = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(parameter);
    
            const trackingParameter = response.data;
            commit(mt.AddTrackingParameter, trackingParameter);
            const landingPage = rootGetters.getLandingPageById(trackingParameter.lpId);
            const trackingParametersIds = landingPage.trackingParametersIds || [];
            
            commit(mt.UpdateLandingPage, {
                id: landingPage.id,
                changes: {
                    trackingParametersIds: [...trackingParametersIds, trackingParameter.id]
                }
            });
            
            // return newly created tracking parameter
            return trackingParameter.id;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async deleteTrackingParameter({ commit, rootGetters }, {id, lpId} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            await destroy(id);
    
            commit(mt.RemoveTrackingParameter, id);
            // remove tracking parameter from landing page
    
            const landingPage = rootGetters.getLandingPageById(lpId);
            
            commit(mt.UpdateLandingPage, {
                id: lpId,
                changes: {
                    trackingParametersIds: landingPage.trackingParametersIds.filter(tId => tId !== id)
                }
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const TrackingParametersDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default TrackingParametersDataModule;
