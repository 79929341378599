var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "styled-input relative",
        class: { "has-error": !_vm.isPasswordValid },
      },
      [
        _vm.passwordInputMode === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: {
                name: "password",
                placeholder: "New password",
                autocomplete: "new-password",
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.password)
                  ? _vm._i(_vm.password, null) > -1
                  : _vm.password,
              },
              on: {
                input: _vm.validatePassword,
                change: function ($event) {
                  var $$a = _vm.password,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.password = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.password = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.password = $$c
                  }
                },
              },
            })
          : _vm.passwordInputMode === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: {
                name: "password",
                placeholder: "New password",
                autocomplete: "new-password",
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.password, null) },
              on: {
                input: _vm.validatePassword,
                change: function ($event) {
                  _vm.password = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: {
                name: "password",
                placeholder: "New password",
                autocomplete: "new-password",
                type: _vm.passwordInputMode,
              },
              domProps: { value: _vm.password },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                  _vm.validatePassword,
                ],
              },
            }),
        _vm._v(" "),
        _c(
          "i",
          {
            staticClass: "styled-input__showpassword cursor-pointer",
            class: { show: _vm.isPasswordShowMode },
            attrs: { "data-fa-i2svg": "" },
            on: {
              click: function ($event) {
                _vm.isPasswordShowMode = !_vm.isPasswordShowMode
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "svg-inline--fa fa-eye fa-w-18",
                attrs: {
                  "aria-hidden": "true",
                  focusable: "false",
                  "data-prefix": "far",
                  "data-icon": "eye",
                  role: "img",
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 576 512",
                  "data-fa-i2svg": "",
                },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: {
                  placement: "bottom",
                  arrow: true,
                  onShow: _vm.shouldShowTooltip,
                  content: _vm.$refs["tippy-content"],
                  followCursor: "horizontal",
                  boundary: "viewport",
                },
                expression:
                  "{ placement : 'bottom',\n                 arrow: true,\n                 onShow: shouldShowTooltip,\n                 content: $refs['tippy-content'],\n                 followCursor: 'horizontal',\n                 boundary: 'viewport' }",
              },
            ],
            staticClass: "strength-meter",
          },
          [_c("span", { class: [_vm.getStrengthProps] })]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isPasswordValid,
            expression: "!isPasswordValid",
          },
        ],
        staticClass: "help-block",
      },
      [_vm._v("\n    " + _vm._s(_vm.passwordValidationMessage) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "tippy-content",
        class: { hidden: !_vm.passwordStrength.feedback.warning },
        attrs: { id: "template-1" },
      },
      [
        _c("div", { staticClass: "max-w-md w-full tippy-content" }, [
          _c("div", { staticClass: "m-2" }, [
            _c("h3", { staticClass: "text-white f-16" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.passwordStrength.feedback.warning) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "pt-1" },
              _vm._l(
                _vm.passwordStrength.feedback.suggestions,
                function (suggestion, key) {
                  return _c("li", { key: key }, [
                    _vm._v(
                      "\n            " + _vm._s(suggestion) + "\n          "
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }