import {LocalStorageService} from '@/services/localStorageService';
import {BehaviorSubject} from 'rxjs';
import * as Sentry from "@sentry/browser";

const LocalStorageObservableService = (() =>
{
    let subjects = new Map();
    const storage = LocalStorageService.getInstance();
    let instance;

    /**
     * watch data of given key
     * @param key
     */
    const watch = (key) =>
    {
        if (!subjects.has(key))
            subjects.set(key, new BehaviorSubject(null));

        let item = storage.get(key);
        if (typeof item === "undefined")
            item = undefined;
        subjects.get(key).next(item);
        return subjects.get(key).asObservable();
    };

    /**
     * Get data of given key
     * @param key
     */
    const get = (key, defaultValue = "") =>
    {
        let item = storage.get(key, defaultValue);
        if (typeof item === "undefined")
            item = undefined;

        return item;
    };

    /**
     * Set value on given key
     * @param key
     * @param value
     */
    const set = (key, value) =>
    {
        storage.set(key, value);
        if (!subjects.has(key))
            subjects.set(key, new BehaviorSubject(value));
        else
            subjects.get(key).next(value);

    };

    /**
     * Remove given key
     * @param key
     */
    const remove = (key, keepWatch = false) =>
    {
        if (subjects.has(key) && !keepWatch)
        {
            subjects.get(key).complete();
            subjects.delete(key);
        }
        storage.remove(key);
    };

    /**
     * Clear all available keys
     */
    const clear = () =>
    {
        Sentry.captureException(new Error(`[Local Storage Observable Service]: clear `), {
            tags: {
                section: "errors",
            },
        });
        subjects.clear();
        storage.clear();
    };

    const init = () =>
    {
        return  {
            watch,
            get,
            set,
            remove,
            clear
        };
    };

    const getInstance = () =>
    {
        if(!instance)

            instance = init();

        return instance;
    };

    return {
        getInstance
    };
})();

export {LocalStorageObservableService};
