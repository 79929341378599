export function CampaignDto({
    clientId,
    name,
    type,
    maxBudget,
    cpcGoal,
    cplGoal,
    cpmGoal,
    shouldRepeat,
    isActive,
    status,
    clientPermissions,
    maxMp,
    async,
    isClientSpendVariable,
    maxCpc,
    maxCpm,
    maxCpl,
} = {})
{
    return {
        clientId,
        name,
        type,
        maxBudget,
        cpcGoal,
        cplGoal,
        cpmGoal,
        shouldRepeat,

        startDate: null,
        endDate: null,
        demoSetRateGoal: null,
        demoSetGoal: null,
        winRateGoal: null,
        winGoal: null,

        batchId: null,

        isActive,
        status,
        clientPermissions,
        maxMp,
        async,
        isClientSpendVariable,
        maxCpc,
        maxCpm,
        maxCpl,
    };
}
