import { ErrorBase } from "./ErrorBase";

const REFRESH_TOKEN_ERROR = "refreshTokenError";
export const REFRESH_TOKEN_ERRORS = Object.freeze({
    REFRESH_TOKEN_EXPIRED: "refreshTokenExpired",
    REFRESH_TOKEN_MISSING: "refreshTokenMissing",
    REFRESH_TOKEN_REQUEST_ERROR: "refreshTokenRequestError",
});

export class RefreshTokenError extends ErrorBase
{
    constructor({
        name = REFRESH_TOKEN_ERROR, message, cause
    } = {})
    {
        super({name, message, cause});
    }
}
