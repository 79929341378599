import {WSMessage} from '@/models/ws-message/WSMessage';
import {WSMessageDto} from '@/models/ws-message/WSMessageDto';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
function setCreatedAtTime(dto)
{
    if (dto.createdAt)
        return dto;
    return {
        ...dto,
        createdAt: new Date().toISOString()
    };

}
export const wsMessageDtoToObject = dto =>
{
    const object = flowRight(WSMessage,setCreatedAtTime)(dto);
    return object;
};

export const objectToWSMessageDto = data =>
{
    const dto = flowRight(removeEmpty, WSMessageDto)(data);
    return dto;
};
