import { PRESETS } from "../helpers/enums/enums";

export default function createMediaStatsPreset()
{
    return {
        name: PRESETS.MEDIA_STATS,
        columns: [
            "isActive",
            "leads",
            "cpl",
            "clientCpl",
            "status",
            "impressions",
            "spend",
            "clientSpend",
            "conversionRate",
            "mediaPercentage",
            "clicks",
            "cpc",
            "clientCpc",
            "ctr",
            "cpm",
            "clientCpm",
            "networkLeads"
        ]
    };
}
