import { REFRESH_TOKEN, TOKEN, TOKEN_EXPIRATION, TOKEN_EXPIRES_IN, USERNAME } from '@/helpers/enums/enums';
import { removeEmpty } from '@/helpers/objectUtils';
import createMediaStatsPreset from '@/models/MediaStatsPreset';
import createSalesPerformancePreset from '@/models/SalesPerformancePreset';
import { columnPresetDtoToObject } from '@/models/column-presets';
import { companyDtoToObject } from '@/models/company';
import { authDtoToObject, userDtoToObject } from '@/models/user';
import { GroupAvailableColumns, LOCAL_STORAGE_SETTINGS } from '@/modules/performance/services/columns/columnsConfig';
import { login, refreshAuthToken } from '@/repositories/AuthRepository';
import { fetch as fetchClient } from '@/repositories/ClientRepository';
import { createSettings, fetchAssetSignedUrl, fetchSettings, fetch as fetchUser, updateSettings } from '@/repositories/UserRepository';
import { post, put } from '@/services/httpService';
import { LocalStorageObservableService } from '@/services/localStorageObservableService';
import { LocalStorageService } from '@/services/localStorageService';
import EntityAdapter from '@/store/adapter';
import { default as axios } from 'axios';
import curry from 'lodash/curry';
import moment from 'moment';
import Vue from 'vue';
import projConfig from '../../projConfig';
import { USER_GROUPS } from '../constants';
import mt from '../mutationTypes';
import * as Sentry from "@sentry/browser";
import { REFRESH_TOKEN_ERRORS, RefreshTokenError } from '@/errors/RefreshTokenError';

const UserGroups =
{
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: "SystemAdministrators",
    [USER_GROUPS.ADMINISTRATORS]: "Administrators",
    [USER_GROUPS.MANAGERS]: "Managers",
    [USER_GROUPS.MARKETERS]: "Marketers",
    [USER_GROUPS.SALES]: "Sales",
    [USER_GROUPS.MEDIA_BUYERS]: "MediaBuyers",
    [USER_GROUPS.ORGANIZATION_USER]: "OrganizationUsers",
    [USER_GROUPS.ADVERTISER]: "Advertisers"
};

const GroupRestrictedComponents =
{
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: [],
    [USER_GROUPS.ADMINISTRATORS]: [],
    [USER_GROUPS.MANAGERS]: ['clientEdit', 'editLandingPage', 'newLandingPage', 'overview', 'leaderboard', 'operations', 'billing'],
    [USER_GROUPS.MARKETERS]: ['landingPageNew', 'landingPageEdit', 'editLandingPage', 'editBatch', 'newBatch', 'clientEdit', 'leads-perf', 'roi', 'reports',
        'clientCreate', 'campaignCreate', 'leaderboard', 'overview',
        'leads', 'operations', 'maintenance', 'integrations', 'offerEdit', 'leadsDetails', 'billing'
    ],
    [USER_GROUPS.SALES]: ['editBatch', 'newBatch', 'clientEdit', 'leads-perf', 'roi', 'reports',
        'clientCreate', 'campaignCreate', 'editLandingPage', 'newLandingPage', 'overview', 'leaderboard', 'operations', 'adEdit'],
    [USER_GROUPS.MEDIA_BUYERS]: ['editBatch', 'newBatch', 'clientEdit', 'overview', 'leaderboard', 'operations', 'billing'],
    [USER_GROUPS.ORGANIZATION_USER]: ['landingPageNew', 'landingPageEdit', 'clientCreate', 'clientDetails', 'clientEdit', 'leads-perf', 'campaignCreate',
        'campaignDetails', 'campaignEdit', 'newBatch', 'editBatch', 'batches', 'newlead', 'userEdit',
        'userCreate', 'roi', 'reports', 'clientPage',
        'overview', 'leaderboard', 'operations', 'maintenance', 'adEdit', 'offerEdit', 'adGroupPage', 'billing'],
    [USER_GROUPS.ADVERTISER]: ['landingPageNew', 'landingPageEdit', 'clientCreate', 'clientDetails', 'clientEdit', 'leads-perf', 'campaignCreate',
        'newBatch', 'editBatch', 'batches', 'newlead', 'userEdit',
        'userCreate', 'roi', 'reports', 'clientPage',
        'overview', 'leaderboard', 'operations', 'maintenance', 'offerEdit', 'billing']
};

const GroupAvailableActions =
{
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: {
        add: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        pause: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        run: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        duplicate: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"]
    },
    [USER_GROUPS.ADMINISTRATORS]: {
        add: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        pause: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        run: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"],
        duplicate: ["Client", "Campaign", "Offers", "LandingPage", "Ad", "Leads", "AdGroup", "AdCreative"]
    },
    [USER_GROUPS.MANAGERS]: {},
    [USER_GROUPS.MARKETERS]: {
        add: ["LandingPage", "AdGroup", "Ad", "AdCreative"],
        pause: [],
        run: [],
        toggle: ["Campaign", "AdGroup", "Ad", "AdCreative"],
        duplicate: ["AdCreative"],
    },
    [USER_GROUPS.SALES]: {
        add: ["Client", "Campaign", "Offers", "Ad", "AdCreative"],
        pause: ["Campaign", "Offers", "Ad", "AdCreative"],
        run: ["Campaign", "Offers", "Ad", "AdCreative"]
    },
    [USER_GROUPS.MEDIA_BUYERS]: {
        add: ["Client", "Campaign", "Offers", "AdGroup", "Ad", "AdCreative", "LandingPage"],
        pause: ["Client", "Campaign", "Offers", "AdGroup", "Ad", "AdCreative", "LandingPage"],
        run: ["Client", "Campaign", "Offers", "AdGroup", "Ad", "AdCreative", "LandingPage"],
        duplicate: ["Offers", "Ad", "AdCreative"],
        duplicateFirst: []
    },
    [USER_GROUPS.ORGANIZATION_USER]: {
        add: ["Campaign"],
    },
    [USER_GROUPS.ADVERTISER]: {
        add: ["Campaign", "AdGroup", "Ad", "AdCreative"],
        pause: ["Campaign", "AdGroup", "Ad", "AdCreative"],
        run: ["Campaign", "AdGroup", "Ad", "AdCreative"],
        toggle: ["Campaign", "AdGroup", "Ad", "AdCreative"],
        duplicate: ["AdCreative"],
    }
};

const GroupAvailableFilters =
{
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: ['spend', 'leadsCount', 'cpl', 'mediaPercentage', 'conversionRate', 'clicks', 'cpc', 'impressions', 'ctr', 'cpm'],
    [USER_GROUPS.ADMINISTRATORS]: ['spend', 'leadsCount', 'cpl', 'mediaPercentage', 'conversionRate', 'clicks', 'cpc', 'impressions', 'ctr', 'cpm'],
    [USER_GROUPS.MANAGERS]: ['spend', 'leadsCount', 'cpl', 'mediaPercentage', 'conversionRate', 'clicks', 'cpc', 'impressions', 'ctr', 'cpm'],
    [USER_GROUPS.MARKETERS]: ["spend", "leadsCount", "cpl", "conversionRate", "clicks", "impressions", "ctr"],
    [USER_GROUPS.SALES]: ["spend", "leadsCount", "cpl", "conversionRate", "clicks", "impressions", "ctr"],
    [USER_GROUPS.MEDIA_BUYERS]: ['spend', 'leadsCount', 'cpl', 'mediaPercentage', 'conversionRate', 'clicks', 'cpc', 'impressions', 'ctr', 'cpm'],
    [USER_GROUPS.ORGANIZATION_USER]: ["spend", "leadsCount", "cpl", "conversionRate", "clicks", "impressions", "ctr"],
    [USER_GROUPS.ADVERTISER]: ["spend", "leadsCount", "cpl", "conversionRate", "clicks", "impressions", "ctr"]
};

function userFromInfoObj({
    IdToken,
    IdTokenExpiresIn,
    RefreshToken,
    UserDetails
})
{
    const { DisplayName, Username, Email, Picture, Organization, Clients, Groups, TimeZone, JobTitle, Phone, FirstName, LastName } = UserDetails;
    // set user data on the state
    return {
        idToken: IdToken,
        idTokenExpiresIn: IdTokenExpiresIn,
        refreshToken: RefreshToken,

        displayName: DisplayName,
        username: Username,
        email: Email,
        picture: Picture,
        timezone: TimeZone,
        jobTitle: JobTitle,
        phone: Phone,
        firstName: FirstName,
        lastName: LastName,
        organization: Organization,
        clients: Clients,
        groups: Groups
    };
}

function persistAuth(data)
{
    const localStorageService = LocalStorageObservableService.getInstance();
    // commit to local storage.
    let expiration = moment().add(data.idTokenExpiresIn, "seconds");
    localStorageService.set(REFRESH_TOKEN, data.refreshToken);
    localStorageService.set(TOKEN, data.idToken);
    localStorageService.set(TOKEN_EXPIRES_IN, data.idTokenExpiresIn);
    localStorageService.set(TOKEN_EXPIRATION, expiration);
    localStorageService.set(USERNAME, data.username);
}

export function resetAuth()
{
    const localStorageService = LocalStorageObservableService.getInstance();

    localStorageService.remove(REFRESH_TOKEN);
    // don't remove watcher on token. we need the subscriber in index.js to listen for all token changes
    localStorageService.remove(TOKEN, true);
    localStorageService.remove(TOKEN_EXPIRES_IN);
    localStorageService.remove(TOKEN_EXPIRATION);
    localStorageService.remove(USERNAME);

    localStorageService.remove(LOCAL_STORAGE_SETTINGS);
}

function getHighestAccessLevel(groups)
{
    if (groups.indexOf(0) >= 0)
        return 0; // UserGroup.SystemAdministrators;
    if (groups.indexOf(1) >= 0)
        return 1; // UserGroup.Administrators;
    if (groups.indexOf(2) >= 0)
        return 2; // UserGroup.Managers;
    if (groups.indexOf(5) >= 0)
        return 5; // UserGroup.MediaBuyers;
    if (groups.indexOf(3) >= 0)
        return 3; // UserGroup.Marketers;
    if (groups.indexOf(4) >= 0)
        return 4; // UserGroup.Sales;
    if (groups.indexOf(6) >= 0)
        return 6; // UserGroup.OrganizationUsers;
    if (groups.indexOf(7) >= 0)
        return 7; // UserGroup.Advertisers;

    return null;
}

const userModule = {
    state:
    {
        user: EntityAdapter().createEntityAdapter(),
        companyAdapter: EntityAdapter().createEntityAdapter(),

        isLoggedIn: false,
        isLoaded: false,
        sessionEnc: '',

        displayName: '',
        username: '',
        email: '',
        picture: '',
        organization: -1,
        clients: [],
        groups: [],
        company: {
            name: "",
            website: "",
            phoneNumber: "",
            email: "",
            address: ""
        },
        redirectRouteAfterLogin: '',
        selectedColumnsPreset: null,
        selectedLeadsPreset: null,
        settings: {
            columnsPresets: {
                leads: [],
                campaignManager: [],
                campaignManagerDefault: [
                    createMediaStatsPreset(),
                    createSalesPerformancePreset()
                ]
            },
            appNotifications: {},
            hasAgreedToTos: 1,
        }
    },
    getters:
    {
        getUserById: state => id => state.user.entities[id],
        getCompanyById: state => id => state.companyAdapter.entities[id],
        getUserGroup: state =>
        {
            return getHighestAccessLevel(state.groups);
        },
        getUserOrganization: state =>
        {
            return state.organization;
        },
        hasAccessLevelAbove: state => level =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);
            return highestAccess < level;
        },
        isAdvertiserUser: state =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 7);
        },
        isOrganizationUser: state =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 6);
        },
        isMarketer: state =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 3);
        },
        isSalesUser: state =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 4);
        },
        isMediaBuyer: state =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 5);
        },
        isAdmin: (state) => () =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 0 || highestAccess === 1);
        },
        isSystemAdmin: (state) => () =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);

            return (highestAccess === 0 || highestAccess === 0);
        },
        getUserGroupName: () => (id) =>
        {
            return UserGroups[id];
        },
        canAccessComponent: (state) => (name) =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);
            if (highestAccess == null)
                return false;

            return GroupRestrictedComponents[highestAccess].indexOf(name) < 0;
        },
        canDoActionOnModel: state => curry((model, action) =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);
            if (highestAccess == null) return false;
            // if action is not restricted, return true
            if (typeof GroupAvailableActions[highestAccess][action] === 'undefined') return false;

            if (highestAccess === USER_GROUPS.ORGANIZATION_USER)
                return GroupAvailableActions[highestAccess][action].indexOf(model) > -1 && projConfig.showUserOrgAddButton;
            else
                return GroupAvailableActions[highestAccess][action].indexOf(model) > -1;

        }),
        canShowFiltersForUserGroup: state => column =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);
            if (highestAccess === null) return false;
            if (GroupAvailableColumns[highestAccess].length === 0) return true; // empty columns means everything is available
            if (column === "name") return true; // everyone has access to name column

            return GroupAvailableFilters[highestAccess].indexOf(column) > -1;
        },
        canEdit: (state) => () =>
        {
            let highestAccess = getHighestAccessLevel(state.groups);
            if (highestAccess == null)
                return false;

            //TODO: expand on this using object names?
            // Sales and OrganizationUsers cannot edit
            //return ![3, 4, 6].includes(highestAccess);
            return ![3, 4].includes(highestAccess);
        },
        getUsersGroups()
        {
            // remove Managers
            let groups = Object.assign({}, UserGroups);
            delete groups['2'];

            return groups;
        },
        getRedirectRouteAfterLogin(state)
        {
            return state.redirectRouteAfterLogin;
        },
        getUserPicture(state)
        {
            return state.picture;
        },
        getUserDisplayName(state)
        {
            return state.displayName;
        },
        getUserTimezone(state)
        {
            return state.timezone;
        },
        getAPITimezone()
        {
            return 'PDT';
        },
        getUserSettingsDefaultColumnsPresets(state)
        {
            return state.settings.columnsPresets.campaignManagerDefault || [];
        },
        getUserSettingsColumnsPresets(state)
        {
            return state.settings.columnsPresets.campaignManager || [];
        },
        getUserLeadsColumnsPresets(state)
        {
            return state.settings.columnsPresets.leads || [];
        },
        getUserSettingsAdStatusNotifications(state)
        {
            if (state.settings.appNotifications !== null)
                return state.settings.appNotifications.get_ad_status_notifications;
            return null;
        },
        getUserSettingsTOS(state)
        {
            return state.settings.hasAgreedToTos;
        },
        getUserData(state, getters)
        {
            let userId = Vue.ls.get(USERNAME, null);
            let userData = getters.getUserById(userId) || {};

            const { displayName, username, email, picture, organization, clients, groups, jobTitle, phone, timezone } = userData;

            let company = getters.getCompanyById(organization) || {};

            let { name: companyName, website, phoneNumber: companyPhoneNumber, billingEmail, billingAddress } = company;
            return {
                displayName,
                username,
                email,
                picture,
                organization,
                clients,
                groups,
                jobTitle,
                phone,
                timezone,
                companyName,
                website,
                companyPhoneNumber,
                billingEmail,
                billingAddress
            };
        },
        getSelectedPresetName(state)
        {
            return state.selectedColumnsPreset;
        },
        getSelectedLeadsPresetName(state)
        {
            return state.selectedLeadsPreset;
        },
        canUserSeeOffer(state, getters)
        {
            let userId = Vue.ls.get(USERNAME, null);
            let userData = getters.getUserById(userId);
            const { canSeeOffers } = userData;
            return !!canSeeOffers;
        }
    },
    mutations:
    {
        [mt.UpsertUser](state, user)
        {
            state.user = state.user.upsertOne(user, state.user);
        },
        [mt.UpdateUser](state, user)
        {
            state.user = state.user.updateOne(user, state.user);
        },
        [mt.RemoveAllUsers](state)
        {
            state.user = state.user.removeAll(state.user);
        },
        [mt.AddCompany](state, company)
        {
            state.companyAdapter = state.companyAdapter.setOne(company, state.companyAdapter);
        },

        // synchronous state changes
        [mt.SetUserSession](state, evt)
        {
            state.email = evt.email;
            state.sessionEnc = evt.sessionEnc;
        },
        [mt.SetUserInfo](state, evt)
        {
            state.isLoaded = true;
            state.isLoggedIn = true;

            // other data
            const { displayName, username, email, picture, organization, clients, groups, jobTitle, phone, timezone } = evt;

            state = Object.assign(
                state,
                {
                    displayName,
                    username,
                    email,
                    picture,
                    organization,
                    clients,
                    groups,
                    jobTitle,
                    phone,
                    timezone
                }
            );
            state.groups = evt.groups;
        },
        [mt.SetUserGroup](state, groups)
        {
            state.groups = groups;
        },
        [mt.SetUserPicture](state, image)
        {
            state.picture = image;
        },
        [mt.ResetUserSession](state)
        {
            state.isLoggedIn = false;
            state.isLoaded = false;
            state.sessionEnc = '';
            state.displayName = '';
            state.username = '';
            state.email = '';
            state.picture = '';
            state.organization = -1;
            state.clients = [];
            state.groups = [];
        },
        [mt.SetRedirectRoute](state, { redirectRoute })
        {
            state.redirectRouteAfterLogin = redirectRoute;
        },
        [mt.SetUserSelectedColumns](state, columns)
        {
            state.settings.columnsPresets.campaignManager = columns;
        },
        [mt.SetUserLeadsColumns](state, columns)
        {
            state.settings.columnsPresets.leads = columns;
        },
        [mt.SetUserSettingsNotifications](state, value)
        {
            state.settings.appNotifications = value;
        },
        [mt.SetUserSettingsTOS](state, value)
        {
            state.settings.hasAgreedToTos = value;
        },
        [mt.SetUserPersonalInfo](state, info)
        {
            // other data
            const { displayName, username, email, picture, organization, clients, groups, jobTitle, phone, timezone } = info;

            state = Object.assign(
                state,
                {
                    displayName,
                    username,
                    email,
                    picture,
                    organization,
                    clients,
                    groups,
                    jobTitle,
                    phone,
                    timezone
                }
            );
        },
        [mt.SetUserCompanyInfo](state, info)
        {
            const { name, website, phoneNumber, billingEmail, billingAddress } = info;
            state.company = Object.assign({}, state.company, { name, website, phoneNumber, billingEmail, billingAddress });
        },
        [mt.SetSelectedColumnPreset](state, { name })
        {
            state.selectedColumnsPreset = name;
        },
        [mt.SetSelectedLeadsPreset](state, name)
        {
            state.selectedLeadsPreset = name;
        }
    },
    actions:
    {
        async loginUser({ commit, dispatch }, credentials)
        {
            try
            {
                commit(mt.SetLoading, true);
                const loginResponse = await login(credentials);

                let data = loginResponse.data;

                const authData = authDtoToObject(data);
                // if new password ...
                if (data.NewPasswordRequired)
                {
                    // set session on state
                    commit(mt.SetUserSession,
                        {
                            sessionEnc: data.SessionEnc,
                            email: credentials.username,
                        });

                    commit(mt.SetLoading, false);
                    return { loggedIn: true, newPasswordRequired: true };
                }

                const userData = userDtoToObject(data.UserDetails);

                // commit to local storage.
                persistAuth({
                    ...authData,
                    username: userData.username
                });

                // set user to entity adapter
                commit(mt.UpsertUser, userData);
                // set user data on the state
                commit(mt.SetUserInfo, userData);

                // if local, set username as default x-aws-sub header
                if (projConfig.isLocal)
                    axios.defaults.headers.common['x-aws-sub'] = userData.username;

                commit(mt.SetLoading, false);
                dispatch('fetchUserSettings');
                return { loggedIn: true, newPasswordRequired: false };

            }
            catch (error)
            {
                throw new Error(error.response.data);
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async logoutUser({ commit, dispatch })
        {
            commit(mt.ResetUserSession);
            commit(mt.RemoveAllUsers);
            resetAuth();
            Sentry.captureException(new Error(`[logoutUser]: User clicked logout`), {
                tags: {
                    section: "errors",
                },
            });
            // TODO: clear whole store if needed, just remove notifications at the moment
            dispatch("removeAllNotifications");

            if(Vue.prototype.$websocket)
                Vue.prototype.$websocket.destroy();
        },
        async loadUser({ commit, dispatch })
        {
            const localStorageService = LocalStorageService.getInstance();
            const username = localStorageService.get(USERNAME, null);
            const refreshToken =  localStorageService.get(REFRESH_TOKEN, '');

            try
            {
                commit(mt.SetLoading, true);

                if(!username)
                {
                    // don't do anything?
                    commit(mt.SetLoading, false);
                    return { isUserLoaded: false, refresh: false };
                }


                const userResponse = await fetchUser({username: username});
                let data = userResponse.data;
                // response is UserInfo, same as login's UserData subclass
                const userInfo = userDtoToObject(data);

                // if local, set username as default x-aws-sub header
                if (projConfig.isLocal)
                    axios.defaults.headers.common['x-aws-sub'] = userInfo.username;

                // set user data on the state
                commit(mt.SetUserInfo, userInfo);
                // set user to entity adapter
                commit(mt.UpsertUser, userInfo);

                dispatch('fetchUserSettings');
                return { isUserLoaded: true, refresh: false };
            }
            catch(error)
            {
                // something a bit more elegant?
                console.error(error); // eslint-disable-line

                // check for 401
                if (error && error.data &&
                    (error.status === 401 ||
                        error.status === 403) &&
                    refreshToken !== null)

                    return { isUserLoaded: false, refresh: true };

            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async exchangeRefreshToken({ commit })
        {
            try
            {
                const localStorageService = LocalStorageService.getInstance();

                let refreshToken = localStorageService.get(REFRESH_TOKEN, null);
                let username = localStorageService.get(USERNAME, null);

                if (!refreshToken || refreshToken.length <= 0 || !username)
                    throw new RefreshTokenError({
                        message: 'Refresh token or username empty.',
                        cause: REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_MISSING
                    });

                commit(mt.SetLoading, true);

                const refreshTokenResponse = await refreshAuthToken({username, refreshToken});

                // update auth
                persistAuth({
                    idToken: refreshTokenResponse.data.IdToken,
                    idTokenExpiresIn: refreshTokenResponse.data.IdTokenExpiresIn,
                    refreshToken,
                    username
                });
            }
            catch(error)
            {
                if(error instanceof RefreshTokenError)
                    // rethrow error
                    throw error;

                // check error message ('UserNotFoundException', 'UserNotConfirmedException', 'NotAuthorizedException') for refresh token has expiration
                const {data} = error;

                for (const item of ['UserNotFoundException', 'UserNotConfirmedException', 'NotAuthorizedException'])
                    if(data.includes(item))
                        throw new RefreshTokenError({
                            message: 'No data after refresh request.',
                            cause: REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_EXPIRED
                        });

                // on other errors eg. connection ERR_NAME_NOT_FOUND error throw new RefreshTokenError
                throw new RefreshTokenError({
                    message: 'Refresh token request error.',
                    cause: REFRESH_TOKEN_ERRORS.REFRESH_TOKEN_REQUEST_ERROR
                });
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async setNewPassword({ commit, state, dispatch }, { newPassword })
        {
            let email = state.email;
            let sessionEnc = state.sessionEnc;

            if (!sessionEnc || sessionEnc.length < 0)
                return new Promise((resolve) =>
                {
                    resolve({ isLoggedIn: false });
                });

            const url = `/auth/${email}/password`;

            try
            {
                commit(mt.SetLoading, true);

                const { data } = await put(url, {
                    newPassword,
                    sessionEnc
                });

                // if all is well ...
                let userInfo = userFromInfoObj(data);

                // commit to local storage.
                persistAuth(userInfo);

                // set user data on the state
                commit(mt.SetUserInfo, userInfo);

                // set user to entity adapter
                commit(mt.UpsertUser, userDtoToObject(data));

                commit(mt.SetLoading, false);

                await dispatch('fetchUserSettings');

                // redirect to user home
                return { isLoggedIn: true };
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async setNewPasswordReset({ commit, state }, { confirmationCode, newPassword })
        {
            let email = state.email;

            const url = `/auth/${email}/password`;

            try
            {
                commit(mt.SetLoading, true);
                const result = await post(url, {
                    code: confirmationCode,
                    newPassword: newPassword
                });
                return result;
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        resetPassword({ commit }, { email })
        {
            let url = projConfig.apiRoot + `/auth/${email}/password`;

            commit(mt.SetLoading, true);
            commit(mt.SetUserSession,
                {
                    sessionEnc: '',
                    email: email,
                });

            return axios.delete(url)
                .then(res =>
                {
                    commit(mt.SetLoading, false);
                    return res;
                })
                .catch(error =>
                {
                    commit(mt.SetLoading, false);

                    throw error;
                });
        },
        async fetchUserSettings({ commit })
        {
            try
            {
                commit(mt.SetLoading, true);
                const username = LocalStorageService.getInstance().get(USERNAME);
                const response = await fetchSettings({username});

                if (response && response.status === 200 && response.data.IsSuccessful)
                {
                    let { perfLeads = [], cmpManagerCols = [], appNotifications = [], hasAgreedToTos = 0 } = response.data.Value;
                    // use array as default value, so we can differentiate between no settings added
                    if (!perfLeads) perfLeads = [];
                    if (!cmpManagerCols) cmpManagerCols = [];

                    commit(mt.SetUserLeadsColumns, perfLeads);
                    commit(mt.SetUserSelectedColumns, cmpManagerCols.map(columnPresetDtoToObject));
                    commit(mt.SetUserSettingsNotifications, appNotifications);
                    commit(mt.SetUserSettingsTOS, hasAgreedToTos);
                }
                else
                {
                    commit(mt.SetUserLeadsColumns, null);
                    commit(mt.SetUserSelectedColumns, null);
                    commit(mt.SetUserSettingsNotifications, null);
                }
            }
            catch (error)
            {
                if (error.status === 404)
                {
                    commit(mt.SetUserLeadsColumns, null);
                    commit(mt.SetUserSelectedColumns, null);
                }
                else
                {
                    throw error;
                }
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async createUserSetting({ commit }, settings)
        {
            try
            {
                const username = LocalStorageService.getInstance().get(USERNAME);
                commit(mt.SetLoading, true);
                const settingsResponse = await createSettings({username, settings});

                const success = (settingsResponse && settingsResponse.status === 200 && settingsResponse.data.IsSuccessful);

                return { success };
            }
            catch(error)
            {
                return { success: false, error: error };
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async updateUserSetting({ commit }, settings)
        {
            try
            {
                const username = LocalStorageService.getInstance().get(USERNAME);
                commit(mt.SetLoading, true);
                const settingsResponse = await updateSettings({username, settings});

                const success = (settingsResponse && settingsResponse.status === 200 && settingsResponse.data.IsSuccessful);

                if(success)
                {
                    let { perfLeads = [], cmpManagerCols = [], appNotifications = [], hasAgreedToTos = 0 } = settingsResponse.data.Value;
                    // use array as default value, so we can differentiate between no settings added
                    if (!perfLeads) perfLeads = [];
                    if (!cmpManagerCols) cmpManagerCols = [];

                    commit(mt.SetUserLeadsColumns, perfLeads);
                    commit(mt.SetUserSelectedColumns, cmpManagerCols.map(columnPresetDtoToObject));
                    commit(mt.SetUserSettingsNotifications, appNotifications);
                    commit(mt.SetUserSettingsTOS, hasAgreedToTos);
                }
                else
                {
                    commit(mt.SetUserLeadsColumns, null);
                    commit(mt.SetUserSelectedColumns, null);
                    commit(mt.SetUserSettingsNotifications, null);
                    commit(mt.SetUserSettingsTOS, null);
                }

                return { success };
            }
            catch(error)
            {
                return { success: false, error: error };
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        async getSignedUrlForAwsS3({ commit }, file)
        {
            try
            {
                commit(mt.SetLoading, true);
                const username = LocalStorageService.getInstance().get(USERNAME);
                const signedUrlResponse = await fetchAssetSignedUrl({
                    ...username,
                    ...file
                });

                if (signedUrlResponse && signedUrlResponse.data && signedUrlResponse.status === 200)
                    return { success: true, signedUrl: signedUrlResponse.data };
                else
                    return { success: false };
            }
            catch (error)
            {
                return { success: false, error: error };
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
        uploadImageToS3({ commit }, { url, file })
        {
            commit(mt.SetLoading, true);
            return axios.put(url, file,
                {
                    headers: {
                        'Content-type': file.type
                    }
                })
                .then(response =>
                {
                    commit(mt.SetLoading, false);
                    let result = (response.status === 200 && response.statusText === 'OK') ? true : false;
                    return { success: result };
                })
                .catch(error =>
                {
                    commit(mt.SetLoading, false);
                    console.log(error); // eslint-disable-line
                    return { success: false };
                });
        },
        async saveUserSettingsColumns({ state, dispatch }, event = { isDeleteEvent: null, name: "", columns: [] })
        {
            try
            {
                const { isDeleteEvent, name, columns } = event;
                if (isDeleteEvent)
                {
                    let userSelectedColumnsPresets = state.settings.columnsPresets.campaignManager;
                    return dispatch('updateUserSetting', { cmpManagerCols: userSelectedColumnsPresets });
                }

                const preset = {
                    name,
                    columns
                };

                if (state.settings.columnsPresets.campaignManager)
                {
                    let userSelectedColumnsPresets = state.settings.columnsPresets.campaignManager;
                    let existingIndex = userSelectedColumnsPresets.findIndex(item => item.name === name);

                    if (existingIndex >= 0)
                        userSelectedColumnsPresets[existingIndex] = preset;
                    else
                        userSelectedColumnsPresets.push(preset);
                    const result = await dispatch('updateUserSetting', { cmpManagerCols: userSelectedColumnsPresets });
                    return result;
                }
                else
                {
                    const result = await dispatch('createUserSetting', { cmpManagerCols: [preset] });
                    return result;
                }
            }
            catch (error)
            {
                console.log(error);
            }
        },
        async fetchCompanyDetailsForUser({ commit, getters }, { id })
        {
            try
            {
                commit(mt.SetLoading, true);
                const response = await fetchClient(id);

                if (response && response.status && response.status === 200)
                {
                    const client = response.data;

                    // legacy store entity
                    commit(mt.SetUserCompanyInfo, removeEmpty(client));

                    // entity adapter store
                    commit(mt.AddCompany, companyDtoToObject(client));

                    const username = Vue.ls.get(USERNAME, null);
                    const user = getters.getUserById(username);

                    if (user)
                        commit(mt.UpdateUser, {
                            id: username,
                            changes: {
                                isActive: client.isActive,
                                verticalId: client.verticalId,
                                canSeeOffers: client.canSeeOffers,
                                addMethod: client.addMethod,
                                // TODO: check validity of using client id as companies' one
                                companiesIds: [...new Set([...user.companiesIds, client.id])]
                            }
                        });
                }
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        },
    }
};

export default userModule;
