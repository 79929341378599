import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import { NetworkCampaignStatDto} from '@/models/network-campaign/networkCampaignStatDto';
import { NetworkCampaignStat } from '@/models/network-campaign/networkCampaignStat';
import {flowRight} from 'lodash';

export const networkCampaignStatDtoToObject = dto =>
{
    const object = NetworkCampaignStat(dto);
    return object;
};

export const objectToNetworkCampaignStatDto = networkCampaignStat =>
{
    const dto = flowRight(removeEmpty, setDtoBase, NetworkCampaignStatDto)(networkCampaignStat);
    return dto;
};
