import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {flowRight} from 'lodash';
import {PrivacyPolicy} from './privacyPolicy.js';
import {PrivacyPolicyDto} from './privacyPolicyDto.js';

const transformDtoParams = data =>
{
    return {
        ...data,
        type: data.privacyPolicyDisclaimer ? "customAndExternal" : "external",
        status: data.status ? data.status : "active",
        statusText: null,
        requireUserConsent: 0,
        userConsentPrompt: true,
    };
};

export const privacyPolicyDtoToObject = dto =>
{
    const object = PrivacyPolicy(dto);
    return object;
};

export const objectToPrivacyPolicyDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, PrivacyPolicyDto, transformDtoParams)(data);
    return dto;
};
