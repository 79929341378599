export function Billing({
    id = null,
    clientId = null,
    type = '', // "credit" | "invoiced"
    totalSpend = 0,
    totalPaid = 0,
    balance = 0,
    balanceLimit = 0,
    credit = 0,
    allowance = 0,
    paymentSchedule = '',
    paidThrough = null,
    createdAt = null,
    updatedAt = null,
} = {})
{
    return {
        id: clientId || id,
        clientId,
        type,
        totalSpend,
        totalPaid,
        balance,
        balanceLimit,
        credit,
        allowance,
        paymentSchedule,
        paidThrough,
        createdAt,
        updatedAt,
    };
}