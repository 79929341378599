export function Suggestion({
    id,
    jobResult,
    status,
    error
} = {})
{
    return {
        id,
        jobResult,
        status,
        error
    };
}
