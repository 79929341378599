function LandingPageError(...args)
{
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    instance.rawData = args;
    return instance;
}

LandingPageError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        configurable: true,
        writable: true
    }
});

Reflect.setPrototypeOf(LandingPageError, Error);

export default LandingPageError;