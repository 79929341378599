import mt from '../../mutationTypes';
import { createUrlObjectFilters, createUrlFiltersParameters } from '@/helpers/urlUtils';
import { removeEmpty } from "@/helpers/objectUtils";
import { get } from '@/services/httpService';
import moment from "moment";

const state = {
    mediaPlacementData: [],
    doughnutMediaData: [],
    doughnutMediaOpt: { responsive: true },
    updatedAt: moment(),
    lineMediaScale: "Day",
    lineMediaRawData: [],
    lineMediaData: {
        impressions: [],
        clicks: [],
        leads: []
    },
    lineMediaOpt: {
        responsive: true,
        maintainAspectRatio: true,
        lineTension: 1,
        scales: {
            yAxes: [
                {
                    ticks: { beginAtZero: true, padding: 25 },
                    gridLines: { display: false }
                }
            ],
            xAxes: [
                {
                    gridLines: {
                        display: true,
                        color: "#D1EFE1"
                    }
                }
            ]
        },
        tooltips: { intersect: false },
        legend: {
            display: false
        }
    },
    socialEngagement: [],
    polarAreaMediaLeadsData: [],
    polarAreaMediaImpressionsData: [],
    polarAreaMediaClicksData: [],
    polarAreaMediaOpt: {
        startAngle: -0.4 * Math.PI,
        responsive: true
    },
    placementPage: {
        top: 10,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    placementSort: { field: "impressions", desc: true },
    campaignTypeData: {
        total: {
            impressions: 0,
            clicks: 0,
            leads: 0,
            cpl: 0,
            spend: 0
        },
        data: []
    },
    loadingStatuses: {
        placements: false,
        lineChart: false,
        barChart: false,
        campaignTypes: false
    },
    campaignTypeSort: { field: null, desc: null },
    stats: []
};

const mutations =
    {
        [mt.SetMediaPlacementData](state, res)
        {
            state.mediaPlacementData = res.data;
        },
        [mt.SetDoughnutMediaData](state, res)
        {
            state.doughnutMediaData = res;
        },
        [mt.SetLineMediaData](state, res)
        {
            state.lineMediaData = res;
            state.updatedAt = moment();
        },
        [mt.SetMediaSocialEngagement](state, res)
        {
            state.socialEngagement = res;
        },
        [mt.SetPolarAreaMediaLeadsData](state, res)
        {
            state.polarAreaMediaLeadsData = res;
        },
        [mt.SetMediaPlacementPaging](state, page)
        {
            state.placementPage.skip = page.skip;
            state.placementPage.totalCount = page.totalCount;
            state.placementPage.totalPages = Math.ceil(page.totalCount / page.top);
            state.placementPage.currPage = (page.skip / page.top) + 1;
        },
        [mt.SetMediaPlacementSort](state, { field, desc })
        {
            state.placementSort.field = field;
            state.placementSort.desc = desc;
        },
        [mt.SetMediaCampaignTypeData](state, campaignTypeData)
        {
            state.campaignTypeData = campaignTypeData;
        },
        [mt.SetMediaLoadingStatuses](state, loadingStatuses)
        {
            state.loadingStatuses = loadingStatuses;
        },
        [mt.SetMediaCampaignTypeSort](state, { field, desc })
        {
            state.campaignTypeSort.field = field;
            state.campaignTypeSort.desc = desc;
        },
        [mt.SetLineMediaScale](state, scale)
        {
            state.lineMediaScale = scale;
        },
        [mt.SetLineMediaRawData](state, data)
        {
            state.lineMediaRawData = data;
        },
        [mt.SetPolarAreaMediaImpressionsData](state, data)
        {
            state.polarAreaMediaImpressionsData = data;
        },
        [mt.SetPolarAreaMediaClicksData](state, data)
        {
            state.polarAreaMediaClicksData = data;
        },
        [mt.SetCRMStats](state, crmStats)
        {
            const crmStatsClone = [...crmStats];
            const requiredLabels = [
                "working",
                "contacted",
                "demoSet",
                "demoPerformed",
                "contractSent",
                "closedWin"
            ];
            requiredLabels.forEach(label =>
            {
                const crmStatus = crmStatsClone.find(stat => stat.crmStatus === label);
                if(typeof crmStatus === "undefined") crmStatsClone.push({
                    crmStatus: label,
                    leadsCount: 0
                });
            });
            state.stats = crmStatsClone;
        },
        [mt.SetDashboardUpdatedAt](state)
        {
            // set updated data
            state.updatedAt = moment();
        }
    };

const getters =
    {
        getMediaPlacementData(state)
        {
            return state.mediaPlacementData;
        },
        getPlacementsPage(state)
        {
            return state.placementPage;
        },
        getDoughnutMediaData(state)
        {
            return state.doughnutMediaData;
        },
        getDoughnutMediaOpt(state)
        {
            return state.doughnutMediaOpt;
        },
        getLineMediaData(state)
        {
            return state.lineMediaData;
        },
        getLineMediaRawData()
        {
            return state.lineMediaRawData;
        },
        getLineMediaOpt(state)
        {
            return state.lineMediaOpt;
        },
        getMediaSocialEngagement(state)
        {
            return state.socialEngagement;
        },
        getPolarAreaMediaLeadsData(state)
        {
            return state.polarAreaMediaLeadsData;
        },
        getPolarAreaMediaImperssionsData(state)
        {
            return state.polarAreaMediaImpressionsData;
        },
        getPolarAreaMediaClicksData(state)
        {
            return state.polarAreaMediaClicksData;
        },
        getPolarAreaMediaOpt(state)
        {
            return state.polarAreaMediaOpt;
        },
        getMediaCampaignTypeData(state)
        {
            return state.campaignTypeData;
        },
        getMediaPlacementSort(state)
        {
            return state.placementSort;
        },
        getMediaCampaignTypeSort(state)
        {
            return state.campaignTypeSort;
        },
        getLineMediaScale(state)
        {
            return state.lineMediaScale;
        },
        getCRMStats(state)
        {
            return state.stats.length > 0 ? state.stats : [{
                crmStatus: "untouched",
                leadsCount: 100
            }];
        },
        getDashboardLastUpdatedAt(state)
        {
            return state.updatedAt;
        }
    };

const checkLoadingStatuses = ({ commit, type, state }) =>
{
    let loadingStatuses = state.loadingStatuses;
    let toggleLoading = true;

    loadingStatuses[type] = false;

    for (let name in loadingStatuses)

        if (loadingStatuses[name])
            toggleLoading = false;

    commit(mt.SetMediaLoadingStatuses, loadingStatuses);

    if (toggleLoading)
        commit(mt.SetLoading, false);
};

const actions =
    {
        loadMediaBuyData({ commit, dispatch })
        {
            commit(mt.SetLoading, true);
            commit(mt.SetMediaLoadingStatuses,
                {
                    placements: true,
                    lineChart: true,
                    barChart: true,
                    campaignTypes: true
                });
            dispatch('loadDoughnutMediaData');
            commit(mt.SetDashboardUpdatedAt);
        },
        async loadCRMStatsData({ commit, state, rootState, rootGetters }, { filters })
        {
            const url = "/s/crm/stats";

            const objectFilters = createUrlObjectFilters(rootGetters);
            const timeZoneDates =  rootGetters.getDateRangeFilters;
            const urlFiltersData  = {...rootState.filters, timeZoneDates};
            const filterParams = createUrlFiltersParameters(urlFiltersData, state.sort, 'dashboardPage');

            let {top, skip} = { top: 500, skip: 0 };

            const stateParams = removeEmpty({
                top,
                skip,
                ...filterParams,
                ...objectFilters
            });

            if (filters)
                commit(mt.SetLoading, true);

            try
            {
                const response = await get(url, {
                    params: {...stateParams}
                });
                commit(mt.SetLoading, false);

                if (response && response.status && response.status === 200 && response.data)
                {
                    commit(mt.SetCRMStats, response.data.data ? response.data.data : []);
                    return { success: true, crmStats: response.data.data };
                }
                else
                {
                    commit(mt.SetCRMStats, []);
                    return { success: false };
                }

            }
            catch(error)
            {
                commit(mt.SetLoading, false);
                console.error(error);
                return { success: false, error };
            }
        },
        loadDoughnutMediaData({ commit })
        {
            commit(mt.SetDoughnutMediaData,
                {
                    labels: ['Dentists', 'Optometrists'],
                    datasets:
                        [
                            {
                                data: [50, 50],
                                backgroundColor: ["rgba(255, 0, 0, 0.3)", "rgba(0, 100, 255, 0.3)"],
                                borderColor: ["rgba(255, 0, 0, 0.7)", "rgba(0, 100, 255, 0.7)"],
                                borderWidth: 1
                            }
                        ]
                });
        },
        async loadLineMediaData({ commit, state, rootState, rootGetters }, {cancelable = true, selectStatParams = ["impressions","clicks","leads","spend"]})
        {
            const objectFilters = createUrlObjectFilters(rootGetters);
            const timeZoneDates =  rootGetters.getDateRangeFilters;
            const urlFiltersData  = {...rootState.filters, timeZoneDates};
            const filterParams = createUrlFiltersParameters(urlFiltersData,{} ,'dashboardPage');

            let {top, skip} = {top: 5000, skip: 0};
            //let isActive = rootState.filters.isActive;

            let url = '/s/stats/';

            const stateParams = removeEmpty({
                select: selectStatParams.toString(),
                groupBy: "actionDate",
                top,
                skip,
                ...filterParams,
                ...objectFilters
            });

            const setData = (data) =>
            {
                commit(mt.SetLineMediaData,
                    {
                        impressions:
                            {
                                labels: data.labels,
                                datasets:
                                    [
                                        {
                                            label: 'Impressions',
                                            data: data.impressionsData,
                                            backgroundColor: (ctx) => 
                                            {
                                                const canvas = ctx.chart.ctx;
                                                const gradient = canvas.createLinearGradient(0, ctx.chart.height, 0, 0);
                                                gradient.addColorStop(1, '#F3B836');
                                                gradient.addColorStop(0.7, 'rgba(243, 184, 54, 0.8)');
                                                gradient.addColorStop(0, 'rgba(255,255,255,0.37)');
                                                return gradient;
                                            },
                                            borderColor: ['#F3B836'],
                                            borderWidth: 2.7,
                                            color:['#f33685']
                                        }
                                    ]
                            },
                        clicks:
                            {
                                labels: data.labels,
                                datasets:
                                    [
                                        {
                                            label: 'Clicks',
                                            data: data.clicksData,
                                            backgroundColor: (ctx) =>
                                            {
                                                const canvas = ctx.chart.ctx;
                                                const gradient = canvas.createLinearGradient(0, ctx.chart.height, 0, 0);
                                                gradient.addColorStop(1, '#1CAA66');
                                                gradient.addColorStop(0.7, 'rgba(28,170,102,0.8)');
                                                gradient.addColorStop(0, 'rgba(255,255,255,0.37)');
                                                return gradient;
                                            },
                                            borderColor: ['#1C9C5F'],
                                            borderWidth: 2.7
                                        }
                                    ]
                            },
                        leads:
                            {
                                labels: data.labels,
                                datasets:
                                    [{
                                        label: 'Conversions',
                                        data: data.leadsData,
                                        backgroundColor: (ctx) =>
                                        {
                                            const canvas = ctx.chart.ctx;
                                            const gradient = canvas.createLinearGradient(0, ctx.chart.height, 0, 0);
                                            gradient.addColorStop(1, '#98C1D8');
                                            gradient.addColorStop(0.7, 'rgba(152, 193, 216,0.8)');
                                            gradient.addColorStop(0, 'rgba(255,255,255,0.37)');
                                            return gradient;
                                        },
                                        borderColor: ['#78ABC8'],
                                        borderWidth: 2.7
                                    }]
                            },
                        shares:
                            {
                                labels: data.labels,
                                datasets:
                                    [
                                        {
                                            label: 'Shares',
                                            data: data.sharesData,
                                            backgroundColor:
                                                [
                                                    'rgba(255, 0, 0, .4)', 'rgba(255, 0, 0, .4)', 'rgba(255, 0, 0, .4)',
                                                    'rgba(255, 0, 0, .4)', 'rgba(255, 0, 0, .4)', 'rgba(255, 0, 0, .4)',
                                                    'rgba(255, 0, 0, .4)'
                                                ],
                                            borderColor: ['rgba(255, 0, 0, .4)'],
                                            borderWidth: 3
                                        }
                                    ]
                            },
                    });
            };

            const calculateChatData = (data, scaleType) =>
            {
                let chartData = {
                    labels: [],
                    impressionsData: [],
                    clicksData: [],
                    leadsData: []
                };

                if (scaleType === 'Day')
                {
                    data.forEach(rec =>
                    {
                        chartData.labels.push(rec['actionDate'].substr(0, 10));
                        chartData.impressionsData.push(rec['impressions']);
                        chartData.clicksData.push(rec['clicks']);
                        chartData.leadsData.push(rec['leads']);
                    });

                    return chartData;
                }

                if (scaleType === 'Week')
                {

                    for (let i = 0; i < data.length; i = i + 7)
                    {
                        let rec = data[i];
                        chartData.labels.push(rec['actionDate'].substr(0, 10));
                        chartData.impressionsData.push(rec['impressions']);
                        chartData.clicksData.push(rec['clicks']);
                        chartData.leadsData.push(rec['leads']);
                    }
                    return chartData;
                }

                if (scaleType === 'Month')
                {
                    let lastSetedDate = null;

                    data.forEach(rec =>
                    {
                        if (!lastSetedDate)
                        {
                            lastSetedDate = new Date(rec.actionDate);
                            chartData.labels.push(rec['actionDate'].substr(0, 10));
                            chartData.impressionsData.push(rec['impressions']);
                            chartData.clicksData.push(rec['clicks']);
                            chartData.leadsData.push(rec['leads']);
                        }
                        else
                        {
                            if (lastSetedDate.getMonth() != new Date(rec.actionDate).getMonth())
                            {
                                lastSetedDate = new Date(rec.actionDate);
                                chartData.labels.push(rec['actionDate'].substr(0, 10));
                                chartData.impressionsData.push(rec['impressions']);
                                chartData.clicksData.push(rec['clicks']);
                                chartData.leadsData.push(rec['leads']);
                            }
                        }
                    });

                    return chartData;
                }
            };

            try
            {
                const response = await get(url, {
                    cancelable,
                    params: {...stateParams}
                });
                checkLoadingStatuses({ commit: commit, type: 'lineChart', state: state });

                if (response && response.status === 200 && response.data)
                {
                    commit(mt.SetLineMediaRawData, response.data.data);
                    setData(calculateChatData(response.data.data, state.lineMediaScale));

                    return { success: true };
                }
                else
                {
                    commit(mt.SetLineMediaRawData, []);
                }

                return { success: false };
            }
            catch(error)
            {
                checkLoadingStatuses({ commit: commit, type: 'lineChart', state: state });

                commit(mt.SetLineMediaRawData, []);
                setData(
                    {
                        labels: [],
                        impressionsData: [],
                        clicksData: [],
                        leadsData: [],
                        sharesData: []
                    });

                return { success: false, error: error };
            }
        },
        loadPolarAreaMediaData({ commit }, campaignTypes)
        {
            let labels = [];
            let dataLeads = [];
            let dataImpressions = [];
            let dataClicks = [];

            campaignTypes.forEach(type =>
            {
                labels.push(type.campaignType);
                dataLeads.push(type.leads);
                dataImpressions.push(type.impressions);
                dataClicks.push(type.clicks);
            });

            commit(mt.SetPolarAreaMediaLeadsData,
                {
                    labels: labels,
                    datasets:
                        [{
                            data: dataLeads,
                            backgroundColor: ["rgba(255, 0, 0, 0.3)", "rgba(100, 255, 0, 0.3)", "rgba(200, 50, 255, 0.3)", "rgba(0, 100, 255, 0.3)"],
                            borderColor: ["rgba(255, 0, 0, 0.7)", "rgba(100, 255, 0, 0.7)", "rgba(200, 50, 255, 0.7)", "rgba(0, 100, 255, 0.7)"],
                            borderWidth: 1
                        }],
                    title: { display: true, text: 'test' }
                });
            commit(mt.SetPolarAreaMediaImpressionsData,
                {
                    labels: labels,
                    datasets:
                        [
                            {
                                data: dataImpressions,
                                backgroundColor: ["rgba(255, 0, 0, 0.3)", "rgba(100, 255, 0, 0.3)", "rgba(200, 50, 255, 0.3)", "rgba(0, 100, 255, 0.3)"],
                                borderColor: ["rgba(255, 0, 0, 0.7)", "rgba(100, 255, 0, 0.7)", "rgba(200, 50, 255, 0.7)", "rgba(0, 100, 255, 0.7)"],
                                borderWidth: 1
                            }
                        ]
                });
            commit(mt.SetPolarAreaMediaClicksData,
                {
                    labels: labels,
                    datasets:
                        [
                            {
                                data: dataClicks,
                                backgroundColor: ["rgba(255, 0, 0, 0.3)", "rgba(100, 255, 0, 0.3)", "rgba(200, 50, 255, 0.3)", "rgba(0, 100, 255, 0.3)"],
                                borderColor: ["rgba(255, 0, 0, 0.7)", "rgba(100, 255, 0, 0.7)", "rgba(200, 50, 255, 0.7)", "rgba(0, 100, 255, 0.7)"],
                                borderWidth: 1
                            }
                        ]
                });
        }
    };

const DashboardDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default DashboardDataModule;
