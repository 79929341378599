import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {flowRight} from 'lodash';
import {LandingPageTarget} from './landingPageTarget.js';
import {LandingPageTargetDto} from './landingPageTargetDto.js';

function setType(data)
{
    return {
        ...data,
        type: "thankYou",
    };
}

export const landingPageTargetDtoToObject = dto =>
{
    const object = LandingPageTarget(dto);
    return object;
};

export const objectToLandingPageTargetDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, LandingPageTargetDto, setType)(data);
    return dto;
};
