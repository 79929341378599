import mt from '../../mutationTypes';
import projConfig from '../../../projConfig';
import moment from 'moment';
import * as _ from 'lodash';
import { fetchStatsForOverview as fetchLandingPageStats } from '@/repositories/LandingPageRepository';
import { get } from "@/services/httpService";

const state = 
{
    overviewData: [],
    landingPageData: [],
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: 'leads',
        desc: true
    }
};

const mutations = 
{
    [mt.SetOverviewData](state, res)
    {
        
        const _goalTypeEnum =
        {
            0: "none",
            1: "leads",
            3: "impressions"
        };

        for (const elem of res.data)
        {
            let batchDates = actions.getBatchDuration(elem);
            if (elem.hasOwnProperty('batchStatus') && elem.batchStatus.length > 0)  // eslint-disable-line
                elem['batchGoalType'] = _goalTypeEnum[elem.batchStatus[0]['goalType']];
            else
                elem['batchGoalType'] = _goalTypeEnum[0];
            
            if (elem.hasOwnProperty('batchGoals') && elem.batchGoals && (elem.batchGoals['leadsGoal'] || elem.batchGoals['impressionsGoal']))   // eslint-disable-line
            {
                // elem['clicksGoal'] = elem.batchGoals['clicksGoal'] || 0;
                if (elem.batchGoals['impressionsGoal'])
                {
                    elem['batchGoal'] = elem.batchGoals['impressionsGoal'];
                    elem['goalPostfix'] = 'impressions';
                    elem['pendingDelivery'] = Math.max(0, elem.batchGoals['impressionsGoal'] - elem.impressions);
                    elem['dailyFlow'] = Math.max(0, elem['pendingDelivery'] / batchDates.daysForGoal) || '-';
                    elem['actualStats'] = elem.impressions;
                }
                else
                {
                    elem['batchGoal'] = elem.batchGoals['leadsGoal'];
                    elem['goalPostfix'] = 'leads';
                    elem['pendingDelivery'] = Math.max(0, elem.batchGoals['leadsGoal'] - elem.leads);
                    elem['dailyFlow'] = Math.max(0, elem['pendingDelivery'] / batchDates.daysForGoal) || '-';
                    elem['actualStats'] = elem.leads;
                }

                if ((elem['dailyFlow'] == 0 || isNaN(elem['dailyFlow'])) && elem['pendingDelivery'] != 0)
                    elem['dailyFlow'] = elem['pendingDelivery'];
            }
            else
            {
                elem['batchGoal'] = '-';
                elem['pendingDelivery'] = '-';
                elem['actualStats'] = 0;
            }

            if (elem['lpNeeded'] == null)
                elem['lpNeeded'] = '?';

            if (elem['netCmpNeeded'] == null)
                elem['netCmpNeeded'] = '?';

            // if (batchDates.daysLeft <= 0 || batchDates.daysPassed <= 0 || isNaN(elem['pendingDelivery']) || 
            //         isNaN(elem['batchGoal']) || elem['pendingDelivery'] <= 0 || 
            //         elem['batchGoal'] <= 0 || batchDates.duration <= 0 || elem['actualStats'] <= 0)
            //     elem['deliveryPace'] = 0;
            // else
            //     elem['deliveryPace'] = (elem['actualStats']) / ( batchDates.daysPassed * 
            //                                     ( elem['batchGoal'] / batchDates.duration ));

            elem['batchDaysLeft'] = Math.max(0, batchDates.daysLeft);
            
            let deliveryPaces = {};
            elem.deliveryPace = 0;
            if (elem.batchGoals)
            {
                if (elem.batchGoals.leadsGoal && elem.batchGoals.leadsGoal > 0)
                {
                    deliveryPaces.leadsPace = elem.leads / elem.batchGoals.leadsGoal;
                    elem.deliveryPace += deliveryPaces.leadsPace;
                }
                
                if (elem.batchGoals.clicksGoal && elem.batchGoals.clicksGoal > 0)
                {
                    deliveryPaces.clicksPace = elem.clicks / elem.batchGoals.clicksGoal;
                    elem.deliveryPace += deliveryPaces.clicksPace;
                }

                if (elem.batchGoals.impressionsGoal && elem.batchGoals.impressionsGoal > 0)
                {
                    deliveryPaces.impressionsPace = elem.impressions / elem.batchGoals.impressionsGoal;
                    elem.deliveryPace += deliveryPaces.impressionsPace;
                }
            }
            elem.deliveryPaces = deliveryPaces;
            
            let timeRatio = batchDates.daysPassed / batchDates.duration;
            let deliveryScore = (timeRatio - (deliveryPaces.leadsPace ? deliveryPaces.leadsPace : 
                (deliveryPaces.clicksPace ? deliveryPaces.clicksPace : 
                    (deliveryPaces.impressionsPace ? deliveryPaces.impressionsPace : 0)))) * 11;
            
            if (deliveryScore > 4)
                deliveryScore = 4;
            if (deliveryScore < 0)
                deliveryScore = 0;
            
            let derivedMediaPct = elem.mediaPercentage === 0 ? 4 : elem.mediaPercentage * 8;
            if (derivedMediaPct > 4)
                derivedMediaPct = 4;
            
            let derivedBudget = 0; 
            if (elem.budget < 10000) derivedBudget = 0;
            else if (elem.budget < 20000) derivedBudget = 1;
            else if (elem.budget >= 20000) derivedBudget = 2;
            
            elem['pressure'] = (deliveryScore || 0) + (derivedMediaPct || 0) + (derivedBudget || 0);

            
            let lpActiveCount = 0;
            if (state.landingPageData)
            {
                state.landingPageData
                    .filter(lp => lp.cmpId === elem.id)
                    .map(lp => 
                    { 
                        if (lp.cmpType === 'SQL' && lp.conversionRate >= 0.025)
                            lpActiveCount++;
                        else if (lp.cmpType === 'MQL' && lp.conversionRate >= 0.05)
                            lpActiveCount++;
                    });

                elem['activeLp'] = lpActiveCount;
            }
            else
            {
                elem['activeLp'] = '?';
            }
        }

        state.overviewData = res.data;
        state.statTotals = res.totals;
    },
    [mt.SetOverviewPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, 
            {
                currPage: state.page.currPage,
                totalResults: page.totalCount,
                currentResults: page.currentCount,
                totalPages: state.page.totalPages
            });
    },
    [mt.SetOverviewCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetOverviewSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.SetLandingDataOverview](state, res)
    {
        state.landingPageData.push(...res.data);
    }
};

const getters = 
{
    getOverviewData(state)
    {
        return state.overviewData;
    },
    getOverviewPage(state)
    {
        return state.page;
    },
    getOverviewSort(state)
    {
        return state.sort;
    },
};

const actions =
{
    getBatchDuration(row)
    {
        let endDate = moment(row.batchEndDate);
        let startDate = moment(row.batchStartDate);
        let batchDates = { duration: 0, daysLeft: 0, daysPassed: 0, daysForGoal: 0 };

        batchDates.duration = _.toNumber(endDate.diff(startDate, 'days'));
        batchDates.daysLeft = _.toNumber(endDate.diff(moment(), 'days'));
        batchDates.daysPassed = _.toNumber(moment().endOf('day').subtract(1, 'day').diff(startDate, 'days'));
        batchDates.daysForGoal = _.toNumber(endDate.diff(moment().startOf('day').subtract(1, 'day'), 'days'));

        if (batchDates.daysForGoal < 1)
            batchDates.daysForGoal = 1;

        return batchDates;
    },
    async loadLp({ commit, dispatch, state }, filters, customSkip)
    {
        try 
        {
            // TODO: The functionality is unchanged, but the correctness is uncertain.
            const { page, sort, currPage } = state;
            const mergedTop = 250 || page.top;
            const mergedSkip = customSkip || page.skip;
            commit(mt.SetLoading, true);

            const response = await fetchLandingPageStats(
                { page: { top: mergedTop, skip: mergedSkip },
                    sort: {field: 'conversionRate', desc:sort.desc}, currPage }, filters);
            if (response && response.status === 200 && response.data)
            {
                const res = response.data;
                commit(mt.SetLandingDataOverview, res);
                const length = res.data.length - 1;
                if (res.data[length]["conversionRate"] >= 0.025 && length >= 0)
                    dispatch("loadLp", { customSkip: 250 });
            }
            else
            {
                commit(mt.SetLandingDataOverview, { data: [], totals: null });
            }
        }
        catch (error)
        {
            console.error(error);
            throw error || new Error('');
        }
        finally 
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadOverviewData({ commit, state, rootState }, filters) 
    {
        let top = state.page.top;
        let skip = state.page.skip;
        let batchNum = rootState.FiltersOperationsDataModule.batches.currNum;
        let adStatuses = rootState.filters.adStatuses;

        if (filters && filters.currPage !== state.page.currPage)
            skip = (filters.currPage - 1) * top;

        let url = projConfig.apiRoot + '/s/campaigns/stats';
        // add page data
        url += `?top=${top}&skip=${skip}`;
        if (batchNum != null)
            url += `&batchNum=${batchNum}`;
        if (state.sort.field != null)
            url += `&orderBy=${state.sort.field}`;
        if (state.sort.desc != null)
            url += `&orderByDesc=${state.sort.desc}`;
        if (adStatuses.filtersCount > 0)
            for (let elem in adStatuses.fields)
                if (adStatuses.fields[elem]) url += `&${elem}=${adStatuses.fields[elem]}`;

        url += `&isActive=${true}`;

        commit(mt.SetLoading, true);
        try 
        {
            const response = await get(url);
            if (response && response.status === 200 && response.data) 
            {
                commit(mt.SetOverviewData,
                    {
                        data: response.data.data
                    });
                commit(mt.SetOverviewPaging,
                    {
                        ...response.data.pages,
                        currentCount: response.data.data.length
                    });
            }
            else 
            {
                commit(mt.SetOverviewData, { data: [] });
                commit(mt.SetOverviewPaging, {
                    top: projConfig.itemsPerPage,
                    skip: 0,
                    totalCount: 0,
                    currentCount: 0,
                    currPage: 1
                });
            }
        }
        catch (error) 
        {
            console.error(error);
            throw error || new Error('');
        }
        finally 
        {
            commit(mt.SetLoading, false);
        }
    }
};

const OverviewDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default OverviewDataModule;
