import {get} from "@/services/httpService";

const resource = "/s/network-ad-sets";

const fetchAll = (params = {}, ...rest) =>
{
    return get(`${resource}`, {
        params,
        ...rest
    });
};

export {
    fetchAll,
};
