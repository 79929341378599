import projConfig from '@/projConfig';

export default function Page({
    top = projConfig.itemsPerPage,
    skip = 0,
    totalCount = 0,
    totalPages = 0,
    currPage = 1,
    currentCount = 0
} = {})
{
    return {
        top,
        skip,
        totalCount,
        totalPages,
        currPage,
        currentCount
    };
}
