const WEBSOCKET_MESSAGES = Object.freeze({
    GET_MESSAGES: "getMessages",
    PING: "ping",
    MARK_MESSAGE_READ: "markMessageRead",
    DELETE_MESSAGE: "deleteMessage",
    MARK_ALL_MESSAGES_READ: "markAllMessagesRead",
    DELETE_ALL_MESSAGES: "deleteAllMessages"
});

const WEBSOCKET_MESSAGE_TYPE = Object.freeze({
    AD: "ad",
    COMMENT: "comment",
});

const WEBSOCKET_MESSAGE_STATUS = Object.freeze({
    APPROVED: "approved",
    REJECTED: "rejected",
    SUBMITTED_TO_CLIENT: "submittedToClient",
    SUBMITTED_BY_CLIENT: "submittedByClient",
    SUBMITTED_BY_MARKETER: "submittedByMarketer",
    REJECTED_BY_NETWORK: "rejectedByNetwork",
    FEEDBACK: "feedback",
});
const WEBSOCKET_PONG_ERROR_CODE = 1001;
const WEBSOCKET_ABNORMAL_ERROR_CODE = 1006;
const PONG_ERROR_REASON = "Going away";
export {WEBSOCKET_MESSAGES, WEBSOCKET_MESSAGE_TYPE, WEBSOCKET_MESSAGE_STATUS ,WEBSOCKET_PONG_ERROR_CODE, PONG_ERROR_REASON, WEBSOCKET_ABNORMAL_ERROR_CODE};
