export function AudienceDto({
    clientId,
    size,
    name,
    externalId
} = {})
{
    return {
        clientId,
        audienceSize: size,
        name,
        externalId
    };
}
