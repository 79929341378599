import {getTimezonesFromAbbreviation} from '@/services/formsDataService';
import moment from 'moment';
import mt from '../../mutationTypes';
import projConfig from '../../../projConfig';
import hash from 'object-hash';
import enums from "@/components/performance/constants";
import {fetchAll} from "@/repositories/VerticalsRepository";
import { fetchMax } from '@/repositories/BatchRepository';

const state =
{
    objectFilters:
    {
        [enums.OBJECT_FILTERS.CLIENT]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.CLIENT,
            param: "clId",
            index: 0
        },
        [enums.OBJECT_FILTERS.CAMPAIGN]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.CAMPAIGN,
            param: "cmpId",
            index: 1
        },
        [enums.OBJECT_FILTERS.OFFER]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.OFFER,
            param: "offerId",
            index: 2
        },
        [enums.OBJECT_FILTERS.LANDING_PAGE]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.LANDING_PAGE,
            param: "lpId",
            index: 3
        },
        [enums.OBJECT_FILTERS.AD_GROUP]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.AD_GROUP,
            param: "netCmpGrpId",
            index: 4
        },
        [enums.OBJECT_FILTERS.NETWORK_CAMPAIGN]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.AD_GROUP,
            param: "netCmpId",
            index: 5
        },
        [enums.OBJECT_FILTERS.AD_CREATIVE]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.AD_CREATIVE,
            param: "adCreativeId",
            index: 6
        },
        [enums.OBJECT_FILTERS.AD]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.AD,
            param: "adId",
            index: 7
        },
        [enums.OBJECT_FILTERS.AD_STATUS]: {
            id: null,
            name: null,
            route: enums.ROUTE_NAMES.AD,
            param: "adCrStatuses",
            index: 8
        }
    },
    selectedObjectFilters: [],
    nextObjectFilter: null,
    verticalId: null,
    verticalName: '',
    verticalsList: [],
    clientName: '',
    campaignId: null,
    campaignName: '',
    offerId: null,
    offerName: null,
    networkCmpName: '',
    netCmpGrpId: null,
    networkCmpId: null,
    cmpType: null,
    lpId: null,
    lpName: '',
    adGroupId: null,
    adGroupName: '',
    clientId: null,
    adId: null,
    adName: '',
    adCreativeId: null,
    adCreativeName: '',
    dateRange:
    {
        start: moment(),
        end: moment()
    },
    dateDisplay: '',
    batches:
    {
        maxNum: 0,
        currNum: null,
    },
    placements:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            fb: null,
            google: null,
            yahoo: null,
            outbrain: null
        }
    },
    deliveryMetric:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            spend: null,
            leadsCount: null,
            cpl: null,
            mediaPercentage: null,
            conversionRate: null,
            clicks: null,
            cpc: null,
            impressions: null,
            ctr: null,
            cpm: null
        }
    },
    verticals:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            value: null
        }
    },
    objectives:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            mql: null,
            sql: null,
            traffic: null,
            awareness: null
        }
    },
    searchBy:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            clientName: null,
            cmpName: null,
            lpName: null,
            adGroupName: null,
            netCmpName: null,
            adCreativeName: null,
            adCrStatuses: null
        }
    },
    adStatuses:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            adCrStatuses: null
        }
    },
    netCmpStatuses:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            netCmpStatuses: null
        }
    },
    leadInfo:
    {
        hash: null,
        filtersCount: 0,
        fields:
        {
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            clientId: null,
            source: null,
            sourceCampaignId: null,
            sourceAdId: null,
            pageUrl: null,
            pageVariant: null,
            pageName: null,
            pageId: null,
            jobTitle: null,
            address: null,
            company: null,
            bestTime: null,
            audienceId: null,
            assetTitle: null,
            companyInfoJson: null,
            companySize: null,
            contactInfoJson: null,
            jobDescription: null,
            pageSearchTerms: null,
            preferredContact: null,
            sourceChannel: null,
            sourceMedium: null,
            companyWebsite: null,
            handraise: null,
            ipAddress: null,
            state: null,
            zipCode: null
        }
    },
    isActive: [
        { type: "dashboardPage", value: undefined },
        { type: "clientPage", value: undefined },
        { type: "campaignPage", value: undefined },
        { type: "offersPage", value: undefined },
        { type: "landingPage", value: undefined },
        { type: "adGroup", value: undefined },
        { type: "networkAdRoot", value: undefined },
        { type: "leadsPage", value: true }
    ],
    globalPaging:
    {
        currPage: 1,
        totalPages: 1,
        pageSize: projConfig.itemsPerPage,
        totalResults: 0,
        currentResults: 0
    },
    isParseQS: false
};

const mutations =
{
    [mt.SetObjectFilter](state, {id, name, type})
    {
        if(state.selectedObjectFilters.indexOf(type) < 0 ) state.selectedObjectFilters.push(type);

        state.objectFilters[type] = Object.assign(state.objectFilters[type], {
            id,
            name
        });
    },
    [mt.SetClearObjectFilter](state, {type})
    {
        if(state.selectedObjectFilters.indexOf(type) > -1 )

            state.selectedObjectFilters = state.selectedObjectFilters.filter(filter => filter !== type);


        state.objectFilters[type] = Object.assign(state.objectFilters[type], {
            id: null,
            name: null
        });
    },
    [mt.SetNextObjectFilter](state, name)
    {
        state.nextObjectFilter = name;
    },
    [mt.SetVerticals](state, data)
    {
        state.verticalsList = data;
    },
    [mt.SetDateDisplayFilter](state, dateDisplay)
    {
        state.dateDisplay = dateDisplay;
    },
    [mt.SetGlobalPaging](state, { currPage, totalResults, currentResults, totalPages })
    {
        state.globalPaging.currPage = currPage;
        state.globalPaging.totalResults = totalResults;
        state.globalPaging.currentResults = currentResults;
        state.globalPaging.totalPages = totalPages;
    },
    [mt.SetActiveFilter](state, isActive)
    {
        state.isActive = isActive;
    },
    [mt.SetDefaultActiveFilter](state)
    {
        state.isActive = [
            { type: "dashboardPage", value: undefined },
            { type: "clientPage", value: undefined },
            { type: "campaignPage", value: undefined },
            { type: "offersPage", value: undefined },
            { type: "landingPage", value: undefined },
            { type: "adGroup", value: undefined },
            { type: "adSetPage", value: undefined },
            { type: "networkAdRoot", value: undefined },
            { type: "leadsPage", value: true }
        ];
    },
    [mt.SetDefaultLeadInfoFilter](state)
    {
        state.leadInfo = {
            hash: null,
            filtersCount: 0,
            fields:
                {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phoneNumber: null,
                    clientId: null,
                    source: null,
                    sourceCampaignId: null,
                    sourceAdId: null,
                    pageUrl: null,
                    pageVariant: null,
                    pageName: null,
                    pageId: null,
                    jobTitle: null,
                    address: null,
                    company: null,
                    bestTime: null,
                    audienceId: null,
                    assetTitle: null,
                    companyInfoJson: null,
                    companySize: null,
                    contactInfoJson: null,
                    jobDescription: null,
                    pageSearchTerms: null,
                    preferredContact: null,
                    sourceChannel: null,
                    sourceMedium: null,
                    companyWebsite: null,
                    handraise: null,
                    ipAddress: null,
                    state: null,
                    zipCode: null
                }
        };
    },
    [mt.SetDateRangeFilter](state, dateRange)
    {
        state.dateRange.start = dateRange.start;
        state.dateRange.end = dateRange.end;
    },
    [mt.SetNetworkCampaignFilter](state, { id, name })
    {
        state.networkCmpId = id;
        state.networkCmpName = name;
    },
    [mt.SetCampaignFilter](state, { id, name })
    {
        state.campaignId = id;
        state.campaignName = name;
    },
    [mt.SetLandingFilter](state, { id, name })
    {
        state.lpId = id;
        state.lpName = name;
    },
    [mt.SetAdGroupFilter](state, { id, name })
    {
        state.adGroupId = id;
        state.adGroupName = name;
    },
    [mt.SetClientFilter](state, { id, name })
    {
        // legacy filter data
        state.clientId = id;
        state.clientName = name;
    },
    [mt.SetAdFilter](state, { id, name })
    {
        state.adId = id;
        state.adName = name;
    },
    [mt.SetAdCreativeFilter](state, { id, name })
    {
        state.adCreativeId = id;
        state.adCreativeName = name;
    },
    [mt.SetBatchMaxNum](state, maxNum)
    {
        state.batches.maxNum = maxNum;
    },
    [mt.SetBatchCurrNum](state, batchNum)
    {
        state.batches.currNum = typeof batchNum === 'object' ? batchNum.currNum : batchNum;
    },
    [mt.SetIsParseQS](state, data)
    {
        state.isParseQS = data;
    },
    [mt.SetCmpTypeFilter](state, data)
    {
        state.cmpType = data;
    },
    [mt.SetDeliveryMetricFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.deliveryMetric.filtersCount = Object.keys(parsedObj).length;
            state.deliveryMetric.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.deliveryMetric.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.deliveryMetric.fields[filter.type] = filter.value != null ? filter.value.join(',') : null;

            if (filter.hash != null)
                state.deliveryMetric.hash = filter.hash;

            if (filter.filtersCount != null)
                state.deliveryMetric.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetVerticalFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.verticals.filtersCount = Object.keys(parsedObj).length;
            state.verticals.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.verticals.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.verticals.fields.value = filter.value;

            if (filter.hash != null)
                state.verticals.hash = filter.hash;

            if (filter.filtersCount != null)
                state.verticals.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetLeadInfoFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.leadInfo.filtersCount = Object.keys(parsedObj).length;
            state.leadInfo.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.leadInfo.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.leadInfo.fields[filter.type] = filter.value;

            if (filter.hash != null)
                state.leadInfo.hash = filter.hash;

            if (filter.filtersCount != null)
                state.leadInfo.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetAdStatusFilter](state, filter)
    {
        // It's string when I parse from URL and it's not when user select from filter
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.adStatuses.filtersCount = Object.keys(parsedObj).length;
            state.adStatuses.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.adStatuses.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.adStatuses.fields['adCrStatuses'] = filter.value != null ? filter.value.toString() : null;

            if (filter.hash != null)
                state.adStatuses.hash = filter.hash;

            if (filter.filtersCount != null)
                state.adStatuses.filtersCount += filter.filtersCount;
        }
    },

    [mt.SetNetCmpStatusFilter](state, filter)
    {
        // It's string when I parse from URL and it's not when user select from filter
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.netCmpStatuses.filtersCount = Object.keys(parsedObj).length;
            state.netCmpStatuses.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.netCmpStatuses.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.netCmpStatuses.fields['netCmpStatuses'] = filter.value != null ? filter.value.toString() : null;

            if (filter.hash != null)
                state.netCmpStatuses.hash = filter.hash;

            if (filter.filtersCount != null)
                state.netCmpStatuses.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetSearchByNameFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.searchBy.filtersCount = Object.keys(parsedObj).length;
            state.searchBy.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.searchBy.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.searchBy.fields[filter.type] = filter.value != null ? filter.value.join(' , ') : null;

            if (filter.hash != null)
                state.searchBy.hash = filter.hash;

            if (filter.filtersCount != null)
                state.searchBy.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetPlacementFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.placements.filtersCount = Object.keys(parsedObj).length;
            state.placements.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.placements.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.placements.fields[filter.type] = filter.value;

            if (filter.hash != null)
                state.placements.hash = filter.hash;

            if (filter.filtersCount != null)
                state.placements.filtersCount += filter.filtersCount;
        }
    },
    [mt.SetObjectiveFilter](state, filter)
    {
        if (typeof filter === "string")
        {
            let parsedObj = JSON.parse(filter);
            state.objectives.filtersCount = Object.keys(parsedObj).length;
            state.objectives.hash = hash(parsedObj);

            for (let elem in parsedObj)
                state.objectives.fields[elem] = parsedObj[elem];
        }
        else
        {
            state.objectives.fields[filter.type] = filter.value;

            if (filter.hash != null)
                state.objectives.hash = filter.hash;

            if (filter.filtersCount != null)
                state.objectives.filtersCount += filter.filtersCount;
        }
    }
};

const getters =
{
    getSelectedObjectFilters(state)
    {
        const sortedFilters = state.selectedObjectFilters.sort((a, b) => state.objectFilters[a].index - state.objectFilters[b].index);
        return sortedFilters.reduce((prev, filter) =>
        {
            prev[filter] = state.objectFilters[filter];
            return prev;
        }, {});
    },
    getObjectFilterIdOfType: state => type =>
    {
        return state.objectFilters[type].id;
    },
    getNextObjectFilter: state =>
    {
        return state.nextObjectFilter;
    },
    getVerticalFilter(state)
    {
        return state.verticals;
    },
    getVerticalFilterName(state)
    {
        return state.verticalName;
    },
    getNetworkFilter(state)
    {
        return state.network;
    },
    getVerticalsList(state)
    {
        return state.verticalsList;
    },
    getVerticalObjectFilter(state)
    {
        return (state.verticalId || state.verticalName) ?
            {id: state.verticalId, name: state.verticalName} : null;
    },
    getGlobalPaging(state)
    {
        return state.globalPaging;
    },
    getDateRangeFilters(state, getters)
    {
        // convert data ranges to user timezone
        const timezone = getTimezonesFromAbbreviation(getters.getAPITimezone);

        const start = moment.tz(moment.utc(state.dateRange.start.format('YYYY-MM-DD')).format('YYYY-MM-DD'), timezone);
        const end = moment.tz(moment.utc(state.dateRange.end.format('YYYY-MM-DD')).clone().endOf("day").format(), timezone);
        return {
            start: start,
            end: end.clone().endOf("day")
        };
    },
    getDateRange(state)
    {
        return state.dateRange;
    },
    getCampaignIdFilters(state)
    {
        return state.campaignId;
    },
    getClientIdFilters(state)
    {
        return state.clientId;
    },
    getClientNameFilters(state)
    {
        return state.clientName;
    },
    getNetworkCampaignNameFilters(state)
    {
        return state.networkCmpName;
    },
    getLandingPageIdFilters(state)
    {
        return state.lpId;
    },
    getAdGroupIdFilters(state)
    {
        return state.adGroupId;
    },
    getNetworkCampaignIdFilters(state)
    {
        return state.networkCmpId;
    },
    getLandingNameFilters(state)
    {
        return state.lpName;
    },
    getAdNameFilters(state)
    {
        return state.adName;
    },
    getAdCreativeNameFilters(state)
    {
        return state.adCreativeName;
    },
    getBatchCurrNumFilter(state)
    {
        return state.batches.currNum;
    },
    getBatchMaxNumFilter(state)
    {
        return state.batches.maxNum;
    },
    getActiveFilter(state)
    {
        return state.isActive;
    },
    getIsParseQS(state)
    {
        return state.isParseQS;
    },
    getDeliveryMetricFilter(state)
    {
        return state.deliveryMetric;
    },
    getFilteredByMetric(state)
    {
        return state.deliveryMetric;
    },
    getCmpTypeFilter(state)
    {
        return state.cmpType;
    },
    getSearchBy(state)
    {
        return state.searchBy;
    },
    getAdStatuses(state)
    {
        return state.adStatuses;
    },
    getNetCmpStatuses(state)
    {
        return state.netCmpStatuses;
    },
    getSearchByFilterField: state => field =>
    {
        return state.searchBy.fields[field];
    },
    getMetricFilter(state)
    {
        return state.deliveryMetric;
    },
    getPlacementFilter(state)
    {
        return state.placements;
    },
    getObjectiveFilter(state)
    {
        return state.objectives;
    },
    getLeadInfoFilter(state)
    {
        return state.leadInfo;
    },
    getAllFiltersCount: (state) => (activePage) =>
    {
        const isActiveFilter = state.isActive.find(item => item.type === activePage);
        const isActiveFilterCount = (isActiveFilter && isActiveFilter.value) ? 1 : 0;
        return state.placements.filtersCount +
        state.deliveryMetric.filtersCount +
        state.verticals.filtersCount +
        state.objectives.filtersCount +
        state.searchBy.filtersCount +
        state.adStatuses.filtersCount +
        state.netCmpStatuses.filtersCount +
        state.selectedObjectFilters.length + isActiveFilterCount;
    }
};

const actions =
{
    async loadBatchNum({ commit })
    {
        try
        {
            commit(mt.SetLoading, true);

            const batchesMaxResponse = await fetchMax();

            commit(mt.SetBatchMaxNum, batchesMaxResponse.data.max);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchVerticalByName({ commit }, { name, top = 1000, skip = 0, load = true, ...rest } = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll({
                params: {
                    name,
                    top,
                    skip
                },
                ...rest
            });
            if(load) commit(mt.SetLoading, false);
            if(response && response.data) return response.data.data;
        }
        catch(error)
        {
            if(load) commit(mt.SetLoading, false);
            throw error;
        }
    },
    async loadVerticals({ commit })
    {
        try
        {
            commit(mt.SetLoading, true);

            const verticalsResponse = await fetchAll();

            commit(mt.SetVerticals, verticalsResponse.data.data || []);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    setIsActiveFilter({commit, state}, { active, type })
    {
        let isActiveFilters = state.isActive;
        let filter = isActiveFilters.find(fltr => type === fltr.type);

        if (filter)
        {
            filter.value = active;
            if (active === null) isActiveFilters.splice(isActiveFilters.indexOf(filter), 1);
        }
        else
        {
            if (active === null) return;
            isActiveFilters.push({
                type: type,
                value: active
            });
        }
        commit(mt.SetActiveFilter, isActiveFilters);
    },
};

const FiltersPerformanceDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default FiltersPerformanceDataModule;
