import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {create, createNetworkAudience, fetch, fetchAll, fetchAllNetworkAudiences, fetchNetworkAudience, toggleNetworkAudience, update, updateNetworkAudience} from '@/repositories/AudienceRepository';
import projConfig from "@/projConfig";
import { networkAudienceDtoToObject } from "@/models/network-audience";

const state = {
    audiences: EntityAdapter().createEntityAdapter(),
    networkAudiences: EntityAdapter().createEntityAdapter(),

    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
};

const mutations = {
    [mt.UpsertAudience](state, audience)
    {
        state.audiences = state.audiences.upsertOne(audience, state.audiences);
    },
    [mt.UpdateAudience](state, audience)
    {
        state.audiences = state.audiences.updateOne(audience, state.audiences);
    },
    [mt.UpsertManyAudiences](state, audiences)
    {
        state.audiences = state.audiences.upsertMany(audiences, state.audiences);
    },
    [mt.UpsertManyNetworkAudiences](state, networkAudiences)
    {
        state.networkAudiences = state.networkAudiences.upsertMany(networkAudiences, state.networkAudiences);
    },
    [mt.UpdateNetworkAudience](state, networkAudience)
    {
        state.networkAudiences = state.networkAudiences.updateOne(networkAudience, state.networkAudiences);
    },
    [mt.RemoveAudiences](state)
    {
        state.audiences = state.audiences.removeAll(state.audiences);
    },
    [mt.SetAudiencePaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;
    },
};

const getters = {
    getAudienceIds: (state) => state.audiences.ids,
    getAudienceEntities: (state) => state.audiences.entities,
    getAudienceById: (state) => id => state.audiences.entities[id],

    getNetworkAudienceIds: (state) => state.networkAudiences.ids,
    getNetworkAudienceEntities: (state) => state.networkAudiences.entities,
    getNetworkAudienceById: (state) => id => state.networkAudiences.entities[id],

    getNetworkAudiencesFor: (state, getters) => id =>
    {
        const audience = getters.getAudienceById(id);
        if (!audience)
            return;
        const { networkAudiencesIds } = audience;
        return networkAudiencesIds ? networkAudiencesIds.map(getters.getNetworkAudienceById) : [];
    },

    getAudiencePage(state)
    {
        return state.page;
    },
};

const actions = {
    async fetchAudiencesByName({commit}, {name, top = 1000, skip = 0, load = true, clientId, ...rest} = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);
            const response = await fetchAll({
                name,
                clId: clientId,
                top,
                skip
            }, rest);
            if(load) commit(mt.SetLoading, false);
            if(response && response.data) return response.data.data;
        }
        catch(error)
        {
            if(load) commit(mt.SetLoading, false);
            throw error;
        }
    },
    async fetchAudienceDetails({ commit }, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id, ...rest});

            const audience = response.data;
            commit(mt.UpsertAudience, audience);

            return audience;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }

    },
    async createAudience({ commit }, params = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(params);

            const audience = response.data;
            commit(mt.UpsertAudience, audience);

            return audience;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateAudience({ commit }, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await update({id, ...rest});

            const audience = response.data;
            commit(mt.UpsertAudience, {
                id,
                changes: {
                    ...rest
                }
            });

            return audience;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchAudiences({ commit }, params)
    {
        try
        {
            commit(mt.SetLoading, true);
            const audienceResponse = await fetchAll(params, {
                validateStatus: (status) => status < 500,
            });
            const audiences = audienceResponse.data.data;
            const page = audienceResponse.data.pages;

            commit(mt.UpsertManyAudiences, audiences);
            commit(mt.SetAudiencePaging, page);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    // FIXME: don't return response, instead add it to the store. components should listen to store changes
    async fetchNetworkAudiences({commit, getters}, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const audienceResponse = await fetchAllNetworkAudiences({id}, {
                validateStatus: (status) => status < 500,
            });

            const networkAudiences = audienceResponse && audienceResponse.data ? audienceResponse.data.data.map(networkAudienceDtoToObject) : [];

            commit(mt.UpsertManyNetworkAudiences, networkAudiences);
            const audience = getters.getAudienceById(id);
            if(audience)
            {
                const newAudiences = networkAudiences.map(networkAudience => networkAudience.id);

                commit(mt.UpdateAudience, {
                    id,
                    changes: {
                        networkAudiencesIds: audience.networkAudiencesIds
                            .concat(
                                newAudiences
                                    .filter((item) => audience.networkAudiencesIds.indexOf(item) < 0)
                            )
                    }
                });
            }

            return audienceResponse && audienceResponse.data ? audienceResponse.data.data : [];
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchNetworkAudience({ commit }, params)
    {
        commit(mt.SetLoading, true);
        const audienceResponse = await fetchNetworkAudience(params);
        commit(mt.SetLoading, false);

        return audienceResponse.data;
    },
    async createNetworkAudience({ commit }, params)
    {
        commit(mt.SetLoading, true);
        const audienceResponse = await createNetworkAudience(params);
        commit(mt.SetLoading, false);

        return audienceResponse.data;
    },
    async updateNetworkAudience({ commit }, params)
    {
        try
        {
            commit(mt.SetLoading, true);

            const {id, ...rest} = params;
            const audienceResponse = await updateNetworkAudience(params);
            const networkAudience = getters.getNetworkAudienceById(id);
            if(networkAudience)
                commit(mt.UpdateNetworkAudience, {
                    id,
                    changes: {
                        ...rest
                    }
                });

            return audienceResponse.data;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleNetworkAudience({ commit }, params)
    {
        commit(mt.SetLoading, true);
        const audienceResponse = await toggleNetworkAudience(params);
        commit(mt.SetLoading, false);

        return audienceResponse.data;
    }
};

const AudienceDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default AudienceDataModule;
