import {wsMessageDtoToObject} from '@/models/ws-message';
import EntityAdapter from '@/store/adapter';
import mt from "@/store/mutationTypes";

const state =
{
    notifications: EntityAdapter().createEntityAdapter(),
};

const mutations =
{
    [mt.SetNotifications](state, notifications)
    {
        state.notifications = state.notifications.upsertMany(notifications, state.notifications);
    },
    [mt.UpdateNotification](state, notification)
    {
        state.notifications = state.notifications.updateOne(notification, state.notifications);
    },
    [mt.UpdateNotifications](state, notifications)
    {
        state.notifications = state.notifications.updateMany(notifications, state.notifications);
    },
    [mt.AddNotification](state, notification)
    {
        state.notifications = state.notifications.upsertOne(notification, state.notifications);
    },
    [mt.DeleteNotification](state, id)
    {
        state.notifications = state.notifications.removeOne(id, state.notifications);
    },
    [mt.DeleteNotifications](state, ids)
    {
        state.notifications = state.notifications.removeMany(ids, state.notifications);
    }
};

const getters =
{
    getNotificationsIds: (state) => state.notifications.ids,
    getNotificationsEntities: (state) => state.notifications.entities,
    getNotificationById: (state) => id => state.notifications.entities[id],
};

const actions =
{
    setNotifications({commit}, notifications = [])
    {
        const transformedNotifications = notifications.map(wsMessageDtoToObject);
        commit(mt.SetNotifications, transformedNotifications);
    },
    addAdNotification({commit}, notification)
    {
        const transformedNotification = wsMessageDtoToObject(notification);
        commit(mt.AddNotification, transformedNotification);
    },
    readNotification({commit}, {id})
    {
        commit(mt.UpdateNotification, {
            id,
            changes: {
                read: true
            }
        });
    },
    deleteNotification({commit}, {id})
    {
        commit(mt.DeleteNotification, id);
    },
    markAllNotificationsRead({commit, getters})
    {
        const notificationsIds = getters.getNotificationsIds;
        commit(mt.UpdateNotifications, notificationsIds.map(id => ({
            id,
            changes: {
                read: true
            }
        })
        ));
    },
    removeAllNotifications({commit, getters})
    {
        const notificationsIds = getters.getNotificationsIds;
        commit(mt.DeleteNotifications, notificationsIds);
    }
};

const NotificationsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default NotificationsDataModule;
