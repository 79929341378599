import enums from "@/components/performance/constants";
import {reduce, curry} from "lodash";
import moment from "moment";
import {replaceString} from "@/helpers/stringUtils";
import {convertMomentDateToTimestamp} from "@/services/dateService";
import store from "@/store/store";

const urlFilters = Object.freeze({
    clId: enums.OBJECT_FILTERS.CLIENT,
    cmpId: enums.OBJECT_FILTERS.CAMPAIGN,
    offerId: enums.OBJECT_FILTERS.OFFER,
    lpId: enums.OBJECT_FILTERS.LANDING_PAGE,
    netCmpGrpId: enums.OBJECT_FILTERS.AD_GROUP,
    netCmpId: enums.OBJECT_FILTERS.NETWORK_CAMPAIGN,
    adId: enums.OBJECT_FILTERS.AD,
    adCreativeId: enums.OBJECT_FILTERS.AD_CREATIVE,
    adStatuses: enums.OBJECT_FILTERS.AD_STATUS
});

const createUrlFilters = rootGetters =>
{
    return reduce(urlFilters, (prev, current, key) =>
    {
        const filter = rootGetters.getObjectFilterIdOfType(current);
        if(filter !== null)
            prev.push(`${key}=${filter}`);
        return prev;
    }, []);
};

const createUrlObjectFilters = rootGetters =>
{
    const getters = rootGetters || store.getters;

    return reduce(urlFilters, (prev, current, key) =>
    {
        const filter = getters.getObjectFilterIdOfType(current);
        if(filter !== null)
            prev[key] = filter;
        return prev;
    }, {});
};

const _createFilter = filterType =>
{
    const filters = {};
    if (filterType.filtersCount > 0)
        for (let elem in filterType.fields)
            if (filterType.fields[elem]) filters[elem] = filterType.fields[elem];
    return filters;
};

const _createVerticalsFilter = verticals =>
{
    if (verticals.filtersCount > 0 && verticals.fields.value.length > 0)
        return {
            verticals: `${verticals.fields.value.join()}`
        };
};

const getBlob = file => URL.createObjectURL(file);

/**
 *
 * @param {*} rootGetters
 * @param {*} filters
 * @param {*} sorters
 * @param {*} page
 */
const createUrlFiltersParameters = (filters, sorters = {}, page = "") =>
{
    const { timeZoneDates, isActive = [], batches = {}, objectives = {}, searchBy = {}, leadInfo ={}, adStatuses = {}, netCmpStatuses = {}, deliveryMetric = {}, placements = {}, verticals = {} } = filters;
    const { start: startDate, end: endDate } = timeZoneDates;
    const { currNum } = batches;
    const isActiveFilter = isActive.find(fltr => fltr.type === page);
    const isActiveValue = isActiveFilter ? isActiveFilter.value : null;

    const { field: orderBy, desc: orderByDesc} = sorters;

    const verticalsFilters = _createVerticalsFilter(verticals);
    const objectivesFilters = _createFilter(objectives);
    const searchByFilters = _createFilter(searchBy);
    const leadInfoFilters = _createFilter(leadInfo);
    const adStatusesFilters = _createFilter(adStatuses);
    const netCmpStatusesFilters = _createFilter(netCmpStatuses);
    // deliveryMetricFilters should not apply for Network Campaigns (DEV-2976)
    const deliveryMetricFilters = page !== "networkCampaignsPage" ? _createFilter(deliveryMetric) : {};
    const placementsFilters = _createFilter(placements);

    return {
        startDate: convertMomentDateToTimestamp(startDate),
        endDate: convertMomentDateToTimestamp(endDate),
        batchNum: currNum,
        isActive: isActiveValue,
        orderBy,
        orderByDesc,
        ...verticalsFilters,
        ...objectivesFilters,
        ...searchByFilters,
        ...leadInfoFilters,
        ...adStatusesFilters,
        ...netCmpStatusesFilters,
        ...deliveryMetricFilters,
        ...placementsFilters
    };
};

const createDashboardUrlFiltersParameters = filters =>
{
    const { dateRange, batches } = filters;
    const { start: startDate, end: endDate } = dateRange;
    const { currNum } = batches;

    return {
        startDate: moment.utc(startDate).valueOf(),
        endDate: moment.utc(endDate).valueOf(),
        batchNum: currNum
    };
};

const reformatUrl = urlString =>
{
    if(!urlString) return;
    try
    {
        const url = new URL(urlString);
        return url ? url.hostname : urlString;
    }
    catch(error)
    {

        const urlArray = urlString.split("/");
        const domain = replaceString("/", urlArray[0], "");
        if(domain.length > 0) return domain;
    }
};

const getQSParameters = urlString =>
{
    if(!urlString) return null;
    try
    {
        const url = new URL(urlString);
        const parameters = new URLSearchParams(url.search);
        return parameters;
    }
    catch(error)
    {
        throw new Error("URL is expected as parameter");
    }
};

const createQSFromURLSearchParams = urlSearchParams =>
{
    if(!urlSearchParams) return null;
    if(!(urlSearchParams instanceof URLSearchParams)) throw new Error("URLSearchParams object expected");
    let qs = [];
    for(const [key, value] of urlSearchParams.entries())
        qs.push(`${key}=${value}`);

    return qs.join("&");
};

const replaceQS = curry((params, url) =>
{
    if(!params) throw new Error("No parameters passed");
    const newUrl = new URL(url);
    if(!newUrl) throw new Error("URL is expected as parameter");
    // set UrlSearchParams objects
    const urlSearchParams = newUrl.searchParams;
    const urlAppendedParams = new URLSearchParams(params);

    // overwrite existing params with new ones
    const newParams = Object.fromEntries(urlAppendedParams);
    const existingParams = Object.fromEntries(
        Array.from(urlSearchParams.entries())
            .filter(([key]) => !newParams[key])
    );

    // create new UrlSearchParamsObject
    const mergedSearchParams = new URLSearchParams({
        ...newParams,
        ...existingParams
    });

    // replace the search query
    newUrl.search = mergedSearchParams.toString();
    return newUrl.toString();
});

const createUrlWithProtocol = url =>
{
    if(url.search(/^http[s]?:\/\//) === -1)
        url = 'https://' + url;
    return url;
};

const areUrlsWithoutQsEqual = (url1, url2) =>
{
    if(!url1 || !url2) return false;
    let url1Url = new URL(url1);
    let url2Url = new URL(url2);
    return new URL(url1Url.pathname, url1Url.href).href === new URL(url2Url.pathname, url2Url.href).href;
};

const isValidUrl = url =>
{
    const urlRegex = /^(https?:\/\/)?((([a-zA-Z0-9_-]+)\.)+[a-zA-Z]{2,})(\/[^\s]*)?$/i;
    return urlRegex.test(url);
};

export {createUrlFilters, createUrlObjectFilters, createUrlFiltersParameters, createDashboardUrlFiltersParameters, getBlob, reformatUrl, getQSParameters, createQSFromURLSearchParams, replaceQS, createUrlWithProtocol, areUrlsWithoutQsEqual, isValidUrl};
