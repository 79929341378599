import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { FbPage} from "@/models/fb-page/fbPage";
import { FbPageDto} from "@/models/fb-page/fbPageDto";
import { removeEmpty } from "@/helpers/objectUtils";

export const fbPageDtoToObject = dto =>
{
    const object = FbPage(dto);
    return object;
};

export const objectToFbPageDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, FbPageDto)(data);
    return dto;
};
