import moment from "moment";
import projConfig from '../../../projConfig';
import mt from '../../mutationTypes';
import enums from '@/components/performance/constants';
import {
    fetchStatsForAdCreative,
    fetchPreviewOfType,
    fetch,
    fetchStatsForLandingPage,
    toggle
} from '@/repositories/NetworkAdRepository';
import EntityAdapter from "@/store/adapter";
import {networkAdPreviewDtoToObject} from "@/models/network-ad-preview";

const state = {
    networkAds: EntityAdapter().createEntityAdapter(),
    adStatsAvailableFields: [],
    updatedAt: moment(),
    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort: {
        field: 'spend',
        desc: true
    }
};

const mutations = {
    [mt.AddNetworkAds](state, networkAds)
    {
        state.networkAds = state.networkAds.upsertMany(networkAds, state.networkAds);
    },
    [mt.UpdateNetworkAd](state, networkAd)
    {
        state.networkAds = state.networkAds.updateOne(networkAd, state.networkAds);
    },
    [mt.UpsertNetworkAd](state, networkAd)
    {
        state.networkAds = state.networkAds.upsertOne(networkAd, state.networkAds);
    },
    [mt.RemoveAllNetworkAd](state)
    {
        state.networkAds = state.networkAds.removeAll(state.networkAds);
    },

    [mt.SetAdPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetAdCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetAdSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    }
};

const getters = {
    getNetworkAdsIds: (state) => state.networkAds.ids,
    getNetworkAdsEntities: (state) => state.networkAds.entities,
    getNetworkAdById: (state) => id => state.networkAds.entities[id],
};

const actions = {
    async fetchNetworkAdsForAdCreative({commit, state}, {id} = {})
    {
        commit(mt.SetLoading, true);
        const response = await fetchStatsForAdCreative(state, {id});
        if (response && response.status === 200 && response.data) 
        {
            const networkAds = response.data.data;
            const networkAdsIds = networkAds.map(networkAd => networkAd.id);

            commit(mt.AddNetworkAds, networkAds);
            commit(mt.UpdateAdCreative, {
                id,
                changes: {
                    networkAdsIds
                }
            });
            commit(mt.UpsertAdCreativeExpanded,{
                id,
                isExpanded: true,
                networkAdsIds
            });
            commit(mt.SetLoading, false);
        }
        else 
        {
            commit(mt.UpsertAdCreativeExpanded,{
                id,
                isExpanded: true,
            });
            commit(mt.SetLoading, false);
        }
    },
    async loadAdAndSetFilter({ commit, dispatch, getters }, id)
    {
        try
        {
            const result = await dispatch('fetchAdDetails', id);
            if (!result) return;
            
            const networkAd = getters.getNetworkAdById(id);
            
            if(!networkAd)
                throw new Error("No network ad found");
            
            // legacy mutation
            commit(mt.SetAdFilter, { id: networkAd.id, name: networkAd.name });
            commit(mt.SetObjectFilter, { id: networkAd.id, name: networkAd.name, type: enums.OBJECT_FILTERS.AD });
            return Promise.resolve(networkAd);
        }
        catch(error)
        {
            console.error(error);
        }
    },
    async fetchAdDetails({ commit }, id)
    {
        commit(mt.SetLoading, true);
        const response = await fetch(id);
        if(!response || response.status !== 200 || !response.data)
        {
            commit(mt.SetLoading, false);
            throw new Error("No network ad details found");
        }
        
        const networkAd = response.data;
        
        commit(mt.UpsertNetworkAd, networkAd);
        commit(mt.SetLoading, false);
    },
    async fetchAdPreview({ commit }, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const types = ["desktopStandard", "mobileStandard", "mobileBanner", "mobileNative", "instagramStandard"];

            const response = await Promise.all(types.map(
                type => fetchPreviewOfType({id, type}))
            );

            const previews = response.map((previewHtml, index) => networkAdPreviewDtoToObject({
                id,
                preview: previewHtml,
                type: types[index]
            }));

            commit(mt.UpsertThumbnailsForNetworkAd, previews);

            const networkAd = getters.getNetworkAdById(id);
            const previewsIds = previews.map(preview => preview.id);
            const networkAdThumbnailsIds = networkAd.thumbnailsIds ? networkAd.thumbnailsIds : [];
            commit(mt.UpdateNetworkAd, {
                id,
                changes: {
                    thumbnailsIds: [...networkAdThumbnailsIds, ...previewsIds]
                }
            });
        }
        catch(error)
        {

            if (error && error.request && (error.request.status === 404 || error.request.status === 504))
                // if needed throw custom error
                throw error;

            return error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchNetworkAdStatsForLandingPage({ commit }, lpId)
    {
        try
        {
            commit(mt.SetLoading, true);
            
            const response = await fetchStatsForLandingPage(lpId);
            
            const networkAd = response.data;
            commit(mt.UpsertNetworkAd, networkAd);
            commit(mt.SetLoading, false);
    
            // TODO: get image where needed from the store
            const image = networkAd.data[0].largeRectangleUrl;
            return image;
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleAdDelivery({ commit }, { id, turnOn })
    {
        try
        {
            let actionString = (turnOn ? 'play' : 'pause');
            commit(mt.SetLoading, true);
    
            const response = await toggle({id, action: actionString});
    
            if (response && response.status && [200, 204].includes(response.status))
                commit(mt.UpdateNetworkAd, {
                    id,
                    changes: {
                        isActive: turnOn
                    }
                });
        }
        catch(error)
        {
            let errMsg = (error.response ?
                errMsg = error.response.data :
                (!error.request ? error.message : null));
    
            throw new Error(errMsg);
            //return { success: false, err: errMsg };
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const NetworkAdDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default NetworkAdDataModule;
