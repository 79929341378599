import $store from "@/store/store";
import enums from "@/components/performance/constants";

const isActiveFilter = routeName =>
{
    let activeFilter = $store.getters.getActiveFilter.find(
        fltr => fltr.type === routeName
    );
    return activeFilter ? activeFilter.value : null;
};


/**
 * Creates query parameters for each route
 * @param {string} routeName Name of the current route
 */
const updateQueryString = routeName =>
{
    const filters =
    {
        dateRange: $store.getters.getDateRangeFilters,
        clId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.CLIENT),
        cmpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.CAMPAIGN),
        offerId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.OFFER),
        lpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.LANDING_PAGE),
        netCmpGrpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD_GROUP),
        netCmpId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.NETWORK_CAMPAIGN),
        adId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD),
        adCreativeId: $store.getters.getObjectFilterIdOfType(enums.OBJECT_FILTERS.AD_CREATIVE),
        batchNum: $store.getters.getBatchCurrNumFilter,
        objectives: $store.getters.getObjectiveFilter,
        searchBy: $store.getters.getSearchBy,
        deliveryMetric: $store.getters.getMetricFilter,
        placements: $store.getters.getPlacementFilter,
        leadInfo: $store.getters.getLeadInfoFilter,
        isActive: isActiveFilter(routeName),
        verticals: $store.getters.getVerticalFilter
    };

    let query = {};
    const propsObjects = ['dateRange'];
    const filtersObj = ['deliveryMetric', 'placements', 'objectives', 'searchBy', 'leadInfo', 'verticals'];


    for (let prop in filters)

        if (filters[prop])

            if (propsObjects.indexOf(prop) > -1)

            {query[prop] = JSON.stringify(filters[prop]);}

            else if (filtersObj.indexOf(prop) > -1)
            {
                let tempObj = {};
                Object.defineProperty(tempObj, prop, { value: {}, writable: true });

                for (let elem in filters[prop]['fields'])

                    if (filters[prop]['fields'][elem] != null)
                        tempObj[prop][elem] = filters[prop]['fields'][elem];


                if (Object.keys(tempObj[prop]) < 1) continue;

                query[prop] = JSON.stringify(tempObj[prop]);
            }
            else

            {query[prop] = filters[prop];}



    return query;
};

export {updateQueryString};
