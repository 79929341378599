import { LeadIntegrationFieldDto } from "@/models/lead-integration-field/leadIntegrationFieldDto";
import { LeadIntegrationField } from "@/models/lead-integration-field/leadIntegrationField";
import {LEAD_INTEGRATION_FIELD_TYPE} from "@/helpers/enums/enums";

export const leadIntegrationFieldDtoToObject = (leadIntegrationFieldDto) =>
{
    const leadIntegrationFieldObject = LeadIntegrationField(leadIntegrationFieldDto);
    return leadIntegrationFieldObject;
};

export const objectToLeadIntegrationFieldDto = (LeadIntegrationField) =>
{
    const {type, value, name} = LeadIntegrationField;
    const leadIntegrationField = Object.assign({}, LeadIntegrationField, {
        value: type === LEAD_INTEGRATION_FIELD_TYPE.LEAD ? null : value,
        name: type === LEAD_INTEGRATION_FIELD_TYPE.LEAD ? name : null,
    });
    const leadIntegrationFieldDto = LeadIntegrationFieldDto(leadIntegrationField);

    Object.keys(leadIntegrationFieldDto).forEach(key =>
    {
        if(leadIntegrationFieldDto[key] === undefined)
            delete leadIntegrationFieldDto[key];
    });

    return leadIntegrationFieldDto;
};
