const Overview = () => import('./Overview.vue');
const AudienceAdmin = () => import('./AudienceAdmin.vue');
const NewAudience = () => import("./NewAudience");
const AddNetworkAudience = () => import("./AddNetworkAudience");
const DuplicateNetworkAudience = () => import("./DuplicateNetworkAudience");
const Leaderboard = () => import('./Leaderboard.vue');


const routes = 
[
    { name: "overview", path: 'overview', component: Overview },
    { name: "leaderboard", path: 'leaderboard', component: Leaderboard },
    { name: "audienceadmin", path: 'audienceAdmin', component: AudienceAdmin, 

        children: [
            { name: "NewAudience", path: ':newaudience', component: NewAudience, props: true},
            { name: "AddNetworkAudience", path: ':addnetworkaudience', component: AddNetworkAudience, props: true},
            { name: "DuplicateNetworkAudience", path: ':duplicatenetworkaudience', component: DuplicateNetworkAudience, props: true},
        ]
    },

];

export default routes;
