import { curry, isEqual } from "lodash";

const compareEqual = (obj1, obj2) => isEqual(obj1, obj2);

const removeNullAndUndefined = obj =>
    Object.entries(obj)
        .reduce((entry, [key, value]) => (
            value === null
            || typeof value === "undefined" ? entry : {...entry, [key]:value}), {});

const removeEmpty = (obj, include = []) =>
    Object.entries(obj)
        .reduce((entry, [key, value]) => (
            value === null
            || typeof value === "undefined"
            || (Array.isArray(value) && value.length === 0) // remove empty arrays
            || (typeof value === "object" && Object.keys(value) === 0) // remove empty objects
            || include.includes(key) ? entry : {...entry, [key]:value}), {});

const removeEmptyWithKeep = curry((include, obj) => Object.entries(obj)
    .reduce((entry, [key, value]) =>
    {
        if(include.includes(key)) entry[key] = value;

        if(value === null
        || typeof value === "undefined"
        || (Array.isArray(value) && value.length === 0) // remove empty arrays
        || (typeof value === "object" && Object.keys(value) === 0) // remove empty objects)
        )
            return entry;
        else entry[key] = value;
        return entry;
    }, {}));


const get = (object, path) =>
{
    if(typeof object !== "object" || Object.keys(object).length === 0) return null;
    let index = 0;
    let objectified = object;
    const length = path.length;
    while(object != null && index < length)
        objectified = objectified[path[index++]];

    return (index && index === length) ? objectified : null;
};

const removeUndefinedFormsAttributes = obj => Object.entries(obj).reduce((prev, [key, value]) =>
{
    if(value === null || value === undefined) return prev;
    if(typeof value === 'object') prev[key] = removeUndefinedFormsAttributes(value);
    else prev[key] = value;

    return prev;
}, {});

const removeAllEmpty = (obj, skip = []) =>
    Object.fromEntries(
        Object.entries(obj)
            .filter(([k, v]) =>
            {
                if(skip.includes(k)) return false;
                if(typeof v === "object") return Object.keys(v).length > 0;
                return v !== null;
            })
            .map(([k, v]) => (typeof v === "object" ? [k, removeAllEmpty(v, skip)] : [k, v]))
    );


const removeProperty = curry((property, object) =>
{
    // eslint-disable-next-line no-unused-vars
    const { [property]: _, ...newObject } = object;
    return newObject;
});

const reindexNumericalKeys = curry((startIndex, object) =>
{
    const newObject = {};
    for (let key in object)

        if (key >= startIndex)
            newObject[key - 1] = object[key];
        else
            newObject[key] = object[key];


    return newObject;
});

export { removeNullAndUndefined, removeEmpty, removeEmptyWithKeep, get, removeUndefinedFormsAttributes, removeAllEmpty, compareEqual, removeProperty, reindexNumericalKeys};
