const Login = () => import("./auth/Login.vue");
const ForgotPassword = () => import("./auth/ForgotPassword.vue");
const NewPassword = () => import("./auth/NewPassword.vue");
import ResetPassword from "./auth/ResetPassword.vue";
const Register = () => import("./auth/Register.vue");
const UserHome = () => import("./UserHome.vue");
const Performance = () => import("./performance/Performance.vue");
const NewLead = () => import("./NewLead.vue");
const UserEdit = () => import("./maintenance/UserEdit.vue");
const Leads = () => import("../modules/leads/Leads.vue");
const Maintenance = () => import("./maintenance/Maintenance.vue");
const Operations = () => import("./operations/Operations.vue");
const Integrations = () => import("../modules/integrations/Integrations.vue");
const AssetEmotionalScore = () => import("./AssetEmotionalScore");
const Billing = () => import("../modules/billing/Billing");

import performanceRoutes from "./performance/routes";
import leadsRoutes from "../modules/leads/routes";
import maintenanceRoutes from "./maintenance/routes";
import operationsRoutes from "./operations/routes";
import integrationsRoutes from "../modules/integrations/routes";

import projConfig from "@/projConfig";
const Playground = () => import("../../playground/Playground.vue");

// define top-level routes in this file.
// for child routes, import from routes.js in the appropriate directory.

const routes = [
    {
        name: "home",
        path: "/",
        component: UserHome,
        redirect: "/performance",
        children: [
            {
                name: "performance",
                path: "/performance",
                component: Performance,
                redirect: "/performance/campaigns",
                children: performanceRoutes
            },
            {
                name: "leads",
                path: "/leads",
                component: Leads,
                redirect: "/leads",
                children: leadsRoutes
            },
            {
                name: "operations",
                path: "/operations",
                component: Operations,
                redirect: "/operations/overview",
                children: operationsRoutes
            },
            {
                name: "batches",
                path: "/batches"
            },
            {
                name: "newlead",
                path: "/leads/new",
                component: NewLead
            },
            {
                name: "maintenance",
                path: "/maintenance",
                component: Maintenance,
                redirect: "/maintenance/users",
                children: maintenanceRoutes
            },
            {
                name: "userEdit",
                path: "/users/:id",
                component: UserEdit
            },
            {
                name: "userCreate",
                path: "/users/new",
                component: UserEdit
            },
            {
                name: "integrations",
                path: "/integrations",
                component: Integrations,
                children: integrationsRoutes
            },
            {
                name: "assetEmotionalScore",
                path: "/emotional-score",
                component: AssetEmotionalScore,
            },
            {
                name: "billing",
                path: "/billing",
                component: Billing,
            },
        ]
    },
    { name: "login", path: "/login", component: Login },
    {
        name: "forgot-password",
        path: "/forgot-password",
        component: ForgotPassword
    },
    {
        name: "reset-password",
        path: "/reset-password",
        component: ResetPassword
    },
    { name: "new-password", path: "/new-password", component: NewPassword },
    { name: "register", path: "/register", component: Register },
    {
        name: "playground",
        path: "/playground",
        component: Playground,
        beforeEnter: (to, from, next) =>
        {
            if(!projConfig.env || projConfig.env !== "DEVELOPMENT") next({name: "campaignPage"});
            else next();
        }
    }
];

export default routes;
