import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from '@/store/store';
import {removeEmpty} from "@/helpers/objectUtils";
import {get, post, put} from "@/services/httpService";
import projConfig from "@/projConfig";

const resource = "/s/network-ads";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'networkAdRoot');
    return {
        objectFilters,
        filterParams
    };
};

const fetchStats = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    const stateParams = removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetchStatsForAdCreative = ({sort} = {}, {id} = {}) =>
{
    let top = projConfig.itemsPerPage;
    let skip = 0;
    let adCreativeId = id;

    const filterParams = createFilters(sort);

    const stateParams = removeEmpty({
        top,
        skip,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
        orderBy: "startDate",
        orderByDesc: true,
        adCreativeId,
        isActive: null
    });

    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetchStatsForLandingPage = lpId =>
{
    return get(`${resource}/stats`, {
        params: {
            lpId,
            top: 1
        }
    });
};

const fetch = id =>
{
    return get(`${resource}/${id}`);
};

const fetchPreviewOfType = ({id, type = {}} = {}) =>
{
    return get(`${resource}/${id}/preview`, {
        params: {
            type
        }
    });
};

const fetchAll = (payload = {}) =>
{
    return get(`${resource}`, {
        params: payload
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (id, payload = {}) =>
{
    return put(`${resource}/${id}`, payload);
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

export {
    fetch,
    fetchAll,
    fetchStats,
    fetchStatsForAdCreative,
    fetchStatsForLandingPage,
    fetchPreviewOfType,
    create,
    update,
    toggle
};
