import enums from "@/components/performance/constants";
import { capitalize, replaceString } from "@/helpers/stringUtils";
import { setDtoBase } from "@/models/DtoBase";
import { flowRight } from "lodash";
import { AdCreative } from "@/models/ad-creative/adCreative";
import { AdCreativeDto } from "@/models/ad-creative/adCreativeDto";
import { removeEmpty, removeNullAndUndefined } from "@/helpers/objectUtils";
import {
    createQSFromURLSearchParams,
    getQSParameters,
} from "@/helpers/urlUtils";
import store from "@/store/store";

// Igor(02.09.2021): Temporary fix for Google(display) Ads, since we don't support display creative type we will consider them as native
const getCreativeType = (creativeType) => 
{
    if (creativeType === enums.AD_TYPES.DISPLAY) return enums.AD_TYPES.VERIZON;

    return creativeType || null;
};

const createCallToActionValue = (callToAction, adType) => 
{
    if (!callToAction) return null;

    if (adType === enums.AD_TYPES.NATIVE) return capitalize(callToAction);
    return callToAction
        ? replaceString(" ", callToAction.toLowerCase(), "_")
        : null;
};

const transformResponseDtoParams = (dto) =>
    Object.assign({}, dto, {
        creativeType: getCreativeType(dto.creativeType),
        basicCreativeType: dto.creativeType,
        callToAction: createCallToActionValue(
            dto.callToAction,
            dto.creativeType
        ),
        assetIds: dto.assetIds,
        imageText:
            dto.imageText && dto.imageText.length > 0
                ? { 0: dto.imageText }
                : {},
    });

const transformDataParams = (data) => 
{
    const {
        clientId,
        companyName,
        landingPageId,
        externalLink,
        callToAction,
        adTitle,
        adDescription,
        linkDescription,
        type,
        assetIds,
        imageText,
    } = data;
    const client = store.getters.getClientById(clientId) || {};

    const companyNameValue = companyName ? companyName : client.name;
    // shortDescription exists only for social
    const shortDescription =
        type === enums.AD_TYPES.SOCIAL ? adDescription : null;
    // linkDescription for social, adDescription for native
    const longDescription =
        type === enums.AD_TYPES.SOCIAL ? linkDescription : adDescription;
    const hasAssets = assetIds && assetIds.length > 0;

    let additionalParams = {};

    if (type === enums.AD_TYPES.SOCIAL)
        additionalParams = {
            imageText: hasAssets
                ? assetIds.map((_, index) => 
                {
                    return imageText[index] || null;
                })
                : null,
        };

    return {
        ...data,
        landingPageId,
        companyName: companyNameValue,
        headline: adTitle,
        shortDescription,
        longDescription,
        callToAction: callToAction ? callToAction.toUpperCase() : null,
        lpQsParams: flowRight(
            createQSFromURLSearchParams,
            getQSParameters
        )(externalLink),
        assetIds: hasAssets ? assetIds : null,
        ...additionalParams,
    };
};

export const adCreativeDtoToObject = (dto) => 
{
    const object = flowRight(
        removeNullAndUndefined,
        AdCreative,
        transformResponseDtoParams
    )(dto);
    return object;
};

/**
 *
 * @param data SocialAdCreativeForm || NativeAdCreativeForm
 * @return {*}
 */
export const objectToAdCreativeDto = (data) => 
{
    const dto = flowRight(
        removeEmpty,
        setDtoBase,
        AdCreativeDto,
        transformDataParams
    )(data);
    return dto;
};

export const objectToSingleSuggestionFieldDto = (object) => 
{
    let { field } = object;
    switch (field) 
    {
        case "adTitle":
            field = "headline";
            break;
        case "adDescription":
            field = "description";
            break;
        case "imageText":
            field = "imageText";
            break;
    }
    return { ...object, field };
};
