import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { TrackingParameters} from "./trackingParameters.js";
import { TrackingParametersDto } from "./trackingParametersDto.js";
import { removeEmpty } from "@/helpers/objectUtils";

export const trackingParametersDtoToObject = dto =>
{
    const object = TrackingParameters(dto);
    return object;
};

export const objectToTrackingParametersDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, TrackingParametersDto)(data);
    return dto;
};
