export const REFRESH_TOKEN = 'refreshToken';
export const TOKEN = 'token';
export const TOKEN_EXPIRES_IN = 'tokenExpiresIn';
export const TOKEN_EXPIRATION = 'tokenExpiration';
export const USERNAME = 'username';

const PRESETS = Object.freeze({
    CUSTOM: "Custom",
    MEDIA_STATS: "Media Stats",
    SALES_PERFORMANCE: "Sales Performance"
});

const LEAD_INTEGRATION_FIELD_TYPE = Object.freeze({
    // one of networkAd, landingPage, campaign, lead, offer, audience, custom
    NETWORK_AD: "networkAd",
    LANDING_PAGE: "landingPage",
    CAMPAIGN: "campaign",
    LEAD: "lead",
    OFFER: "offer",
    AUDIENCE: "audience",
    CUSTOM: "custom",
});

const ERROR_SEVERITY = Object.freeze({
    WARNING: 1,
    ERROR: 2
});

const ENTITY_SUCCESS_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    CREATE: "CREATE",
    DUPLICATE: "DUPLICATE",
    CREATE_WITH_ERRORS: "CREATE_WITH_ERRORS",
    PROCESS_ERROR: "PROCESS_ERROR",
    UPDATED: "UPDATED",
    EDIT: "EDIT",
    STATUS_CHANGED: "STATUS_CHANGED",
    STATUS_ERROR: "STATUS_ERROR"
});

const FB_FORM_SELECTION_STATE = Object.freeze({
    DEFAULT: "DEFAULT",
    NEW: "NEW",
    COPY: "COPY",
    SELECTED: "SELECTED"
});

const RESPONSE_TYPES = Object.freeze({
    ARRAY_BUFFER: 'arraybuffer',
    BLOB: 'blob',
    DOCUMENT: "document",
    JSON: "json",
    TEXT: "text",
    STREAM: "stream"
});

const PROGRESS_DIALOG_MESSAGE_TYPE = Object.freeze({
    INFO: 0,
    ERROR: 1
});

const ActiveServiceType = Object.freeze({
    PROGRESS: "progress",
    SUCCESS: "success"
});

const FORM_ENTITY = Object.freeze({
    CAMPAIGN: "CAMPAIGN",
    AD_CREATIVE: "AD_CREATIVE"
});

const CampaignType = Object.freeze({
    AWARENESS: "Awareness",
    TRAFFIC: "Traffic",
    LEADS: "Leads",
    CONVERSION: "Conversion",
    LEAD_MAGNET: "LeadMagnet"
});

const ClientPermissions = Object.freeze({
    READ: "read",
    // FIXME: change readOnly permission to read on frontend
    READ_ONLY: "readOnly", // used only in campaign
    WRITE: "write",
    READ_WRITE: "readWrite"
});

const LANDING_PAGE_SOURCE = Object.freeze({
    FB: "fb",
    UNBOUNCE: "unbounce",
    QUORA: "quora",
    OTHER: "other"
});

const VideoUploadState = Object.freeze({
    DEFAULT: "default",
    VALIDATING: "validating",
    PROCESSING: "processing",
    UPLOADED: "uploaded",
    EDIT: "editing",
    ERROR: "error"
});

const UploadTypes = Object.freeze({
    IMAGE: "image",
    VIDEO: "video"
});

const MimeTypes = Object.freeze({
    AVI: "video/x-msvideo",
    BMP: "image/bmp",
    GIF: "image/gif",
    JPEG: "image/jpeg",
    JPG: "image/jpg",
    PNG: "image/png",
    PDF: "application/pdf",
    SVG: "image/svg+xml",
    MP4: "video/mp4",
});

const UploadStatusState = Object.freeze({
    DEFAULT: "default",
    UPLOADING: "uploading",
    FINISHED: "finished",
    ERROR: "error"
});

const ThumbImagePreviewVariants = Object.freeze({
    DEFAULT: "default",
    BIG: "variant-big",
    PREVIEW: "variant-preview",
    THUMB: "variant-thumb",
    THUMB_FULL: "variant-thumb-full",
    THUMB_VIDEO: "variant-thumb-video",
});

const FormState = Object.freeze({
    DEFAULT: "default",
    SUBMITTING: "submitting"
});

const AutoGeneratedContentState = Object.freeze({
    DEFAULT: "DEFAULT",
    LOADING: "LOADING",
});
const ContentGeneratedState = Object.freeze({
    DEFAULT: "DEFAULT",
    AUTO: "AUTO"
});
export { PRESETS, LEAD_INTEGRATION_FIELD_TYPE, ERROR_SEVERITY, ENTITY_SUCCESS_STATE, FB_FORM_SELECTION_STATE, RESPONSE_TYPES, PROGRESS_DIALOG_MESSAGE_TYPE, FORM_ENTITY, LANDING_PAGE_SOURCE, ActiveServiceType, CampaignType, ClientPermissions, VideoUploadState, ThumbImagePreviewVariants, UploadTypes, MimeTypes, UploadStatusState, FormState, AutoGeneratedContentState, ContentGeneratedState };
