export function Offer({
    id,
    assetId,
    clientId,
    dailyLeadsGoal,
    isActive,
    name,
    status,
    statusText,
    type,
    createdBy,
    updatedBy,

    // stats props
    clicks,
    impressions,
    leads,
    spend,
    cpl,
    cpc,
    cpm,
    ctr,
    clientCpl,
    clientSpend,
    clientCpc,
    clientCpm,
    networkLeads,
    comments,
    likes,
    shares,
    postReactions,
    mediaPercentage,
    conversionRate,
    crmDisqualified,
    crmNeedsSalesFollowing,
    crmWorking,
    crmContacted,
    crmDemosSet,
    crmDemosPerformed,
    crmLosses,
    crmWins,
    crmWinRate,
    crmDemoSetRate,
    crmContactRate,
    ctrStatus,
    lpCount,
    thumbnailsIds,
    landingPagesIds,
    createdAt,
    isExpanded
} = {})
{
    return {
        id,
        assetId,
        clientId,
        name,
        isActive,
        dailyLeadsGoal,
        status,
        type,
        thumbnailsIds,
        landingPagesIds,
        // use for local sorting
        createdAt,
        
        // stats props
        clicks,
        impressions,
        leads,
        spend,
        cpl,
        cpc,
        cpm,
        ctr,
        clientCpl,
        clientSpend,
        clientCpc,
        clientCpm,
        networkLeads,
        comments,
        likes,
        shares,
        postReactions,
        mediaPercentage,
        conversionRate,
        crmDisqualified,
        crmNeedsSalesFollowing,
        crmWorking,
        crmContacted,
        crmDemosSet,
        crmDemosPerformed,
        crmLosses,
        crmWins,
        crmWinRate,
        crmDemoSetRate,
        crmContactRate,
        ctrStatus,
        statusText,
        createdBy,
        lpCount,
        isExpanded
    };
}
