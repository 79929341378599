import {get, post, put} from "@/services/httpService";

const resource = "/s/audiences";

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

const fetchAll = (payload = {}, ...rest) =>
{
    return get(`${resource}`, {
        params: payload,
        ...rest
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (payload = {}) =>
{
    const {id, ...rest} = payload;
    return put(`${resource}/${id}`, {...rest});
};

const fetchAllNetworkAudiences = (payload = {}) =>
{
    const {id} = payload;
    return get(`${resource}/network-audiences/`,
        {
            params: {
                audId: id
            }
        });
};

const fetchNetworkAudience = (payload = {}) =>
{
    const {netId} = payload;
    return get(`${resource}/network-audiences/${netId}`);
};

const createNetworkAudience = (payload = {}) =>
{
    const {id, ...rest} = payload;
    return post(`${resource}/${id}/network-audiences`, {...rest});
};

const updateNetworkAudience = (payload = {}) =>
{
    const {id, netId} = payload;
    return put(`${resource}/${id}/network-audiences/${netId}`, payload);
};

const toggleNetworkAudience = (payload = {}) =>
{
    const {id, netId, status} = payload;
    return put(`${resource}/${id}/network-audiences/${netId}`, status);
};

export {
    fetch,
    fetchAll,
    create,
    update,
    fetchAllNetworkAudiences,
    fetchNetworkAudience,
    createNetworkAudience,
    updateNetworkAudience,
    toggleNetworkAudience
};
