export function AdGroup({
    id,
    name,
    clientId,
    cmpId,
    cmpName,
    offerId,
    dailyBudget,
    clientDailyBudget,
    cpcGoal,
    isActive,
    campaignType,
    source,
    sources,
    type,
    startDate,
    endDate,
    audiences,
    clientPermissions,
    status,
    effectiveStatus,
    // stats properties
    clicks,
    impressions,
    leads,
    spend,
    cpl,
    cpc,
    cpm,
    ctr,
    clientCpl,
    clientSpend,
    clientCpc,
    clientCpm,
    networkLeads,
    comments,
    likes,
    shares,
    postReactions,
    mediaPercentage,
    conversionRate,
    crmDisqualified,
    crmNeedsSalesFollowing,
    crmWorking,
    crmContacted,
    crmDemosSet,
    crmDemosPerformed,
    crmLosses,
    crmWins,
    crmWinRate,
    crmDemoSetRate,
    crmContactRate,
    ctrStatus,
    netCmpCount,
    netCmpActiveCount,
    createdBy,
    createdAtLocal
} = {})
{
    return {
        id,
        name,
        clientId,
        campaignId: cmpId,
        campaignName: cmpName,
        clientDailyBudget,
        offerId,
        dailyBudget,
        cpcGoal,
        type,
        campaignType,
        isActive,
        source,
        sources,
        startDate,
        endDate,
        clientPermissions,
        status,
        effectiveStatus,
        adCreativesIds: [],
        networkCampaignsIds: [],
        audiences, // remove this when time comes
        audiencesIds: audiences,

        // stats properties
        clicks,
        impressions,
        leads,
        spend,
        cpl,
        cpc,
        cpm,
        ctr,
        clientCpl,
        clientSpend,
        clientCpc,
        clientCpm,
        networkLeads,
        comments,
        likes,
        shares,
        postReactions,
        mediaPercentage,
        conversionRate,
        crmDisqualified,
        crmNeedsSalesFollowing,
        crmWorking,
        crmContacted,
        crmDemosSet,
        crmDemosPerformed,
        crmLosses,
        crmWins,
        crmWinRate,
        crmDemoSetRate,
        crmContactRate,
        ctrStatus,
        netCmpCount,
        netCmpActiveCount,
        createdBy,
        createdAtLocal
    };
}
