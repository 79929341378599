export function UserDto({
    id ,
    addMethod ,
    billingAddress ,
    billingEmail ,
    isActive,
    name ,
    phoneNumber,
    verticalId,
    website
} = {})
{
    return {
        id,
        name,
        phoneNumber,
        addMethod,
        billingAddress,
        billingEmail,
        website,
        isActive,
        verticalId
    };
}
