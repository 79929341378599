import {adCreativeStatusDtoToObject, adCreativeStatusJobDtoToObject} from '@/models/ad-creative-status';
import {adCreativeDtoToObject} from '@/models/ad-creative';

export function AdCreativeResponseFactory()
{
    const create = (path, method) =>
    {
        if(path.indexOf("status") > -1)
            if(method === "put")
                return adCreativeStatusDtoToObject;
            else if(method === "get")
                return adCreativeStatusJobDtoToObject;

        return adCreativeDtoToObject;
    };

    return {
        create
    };
}
