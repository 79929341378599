import {leadDtoToObject} from '@/models/lead';
import {create, download, fetchStats, partialUpdate, toggle} from '@/repositories/LeadsRepository';
import EntityAdapter from '@/store/adapter';
import {StatusCodes} from 'http-status-codes';
import moment from 'moment';
import projConfig from '../../../projConfig';
import mt from '../../mutationTypes';

const state =
{
    leads: EntityAdapter().createEntityAdapter(),
    updatedAt: moment(),
    page:
    {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort:
    {
        field: null,
        desc: null
    }
};

const mutations =
{
    [mt.SetLeadsData](state, leads)
    {
        state.leads = state.leads.setAll(leads, state.leads);
    },
    [mt.SetLeadsPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetLeadsCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetLeadsSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.UpdateLead](state, lead)
    {
        state.leads = state.leads.updateOne(lead, state.leads);
    },
    [mt.AddLead](state, lead)
    {
        state.leads = state.leads.addOne(lead, state.leads);
    },
    [mt.SetLeadsLastUpdatedAt](state)
    {
        // set updated data
        state.updatedAt = moment();
    },
    [mt.ClearLeadsData](state, leads)
    {
        // set updated data
        state.leads = state.leads.removeMany(leads, state.leads);
    },

};

const getters =
{
    getLeadsIds: (state) => state.leads.ids,
    getLeadsEntities: (state) => state.leads.entities,
    getLeadById: (state) => id => state.leads.entities[id],

    getLeadsPage(state)
    {
        return state.page;
    },
    getLeadsColumns(state)
    {
        return state.leadsColumns;
    },
    getLeadsLastUpdatedAt(state)
    {
        return state.updatedAt;
    }
};

const actions =
{
    async downloadLeadsData({ commit, state }, filters = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await download(state, filters);

            return response;
        }
        catch(error)
        {
            return {success: false, error: error};
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchLeads({ commit, state, getters }, filters = null)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);

            commit(mt.SetLeadsData, response.data.data);
            commit(mt.SetLeadsPaging,
                {
                    ...response.data.pages,
                    currentCount: response.data.data.length
                });
        }
        catch(error)
        {
            if (error && error.status === 404)
            {
                commit(mt.ClearLeadsData, getters.getLeadsIds);
                commit(mt.SetLeadsPaging,
                    {
                        top: projConfig.itemsPerPage,
                        skip: 0,
                        totalCount: 0,
                        currPage: 1,
                        currentCount: 0
                    });
            }
            else
            {
                throw error || new Error('Error getting leads.');
            }
        }
        finally
        {
            commit(mt.SetLeadsLastUpdatedAt);
            commit(mt.SetLoading, false);
        }
    },

    async createLead({ commit }, lead = {})
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await create(lead);

            if (response && [StatusCodes.OK, StatusCodes.CREATED].includes(response.status))
            {
                const leadData = leadDtoToObject(lead);
                let createdAtLocal = new Date().getTime();
                // create dto
                commit(mt.AddLead, {
                    ...leadData,
                    createdAtLocal
                });
            }
        }
        catch(error)
        {
            if(error && error.data)
                throw new Error(error.data);

            throw new Error();
        }
        finally
        {
            commit(mt.SetLoading, false);
        }

    },

    async toggleLead({ commit }, { id, turnOn })
    {
        let action = (turnOn ? 'activate' : 'deactivate');

        try
        {
            commit(mt.SetLoading, true);
            const response = await toggle({id, action});

            if (response && response.status && [200, 204].includes(response.status))
                // if all is good, commit to state
                commit(mt.UpdateLead, {
                    id,
                    changes: {
                        isActive: turnOn
                    }
                });
        }
        catch(error)
        {
            // TODO: remove this after api refactor
            let errMsg = (error.response
                ? errMsg = error.response.data
                : (!error.request ? error.message : null));

            throw new Error(errMsg);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    saveLeadsColumnSelection({ rootGetters, dispatch}, event = {isDeleteEvent: null, name: "", columns: []})
    {

        const {isDeleteEvent, name, columns} = event;
        if (isDeleteEvent)
        {
            let perfLeads = rootGetters.getUserLeadsColumnsPresets;
            return dispatch('updateUserSetting', { perfLeads: perfLeads });
        }

        const preset = {
            name,
            columns
        };
        let perfLeads = rootGetters.getUserLeadsColumnsPresets;

        if (perfLeads)
        {
            let existingIndex = perfLeads.findIndex(item => item.name === name);

            if (existingIndex >= 0)
                perfLeads[existingIndex] = preset;
            else
                perfLeads.push(preset);

            return dispatch('updateUserSetting', { perfLeads });
        }
        else
        {
            return dispatch('createUserSetting', { perfLeads: [preset] });
        }
    },
    async approveFraudLead({ commit }, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await partialUpdate(id, {
                fraudStatus: null
            });
            if (response && response.status && [200, 204].includes(response.status))
                commit(mt.UpdateLead, {
                    id,
                    changes: {
                        fraudStatus: null
                    }
                });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const LeadsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default LeadsDataModule;
