export function AuthDto({
    IdToken,
    IdTokenExpiresIn,
    RefreshToken,
    NewPasswordRequired
})
{
    return {
        idToken: IdToken,
        idTokenExpiresIn: IdTokenExpiresIn,
        refreshToken: RefreshToken,
        isNewPasswordRequired: NewPasswordRequired,
    };
}
