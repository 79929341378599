export function AdSet({
    dailyBudget,
    id,
    isActive,
    name,
    netCmpId
} = {})
{
    return {
        id,
        name,
        dailyBudget,
        isActive,
        netCmpId
    };
}
