import {LEAD_INTEGRATION_FIELD_TYPE} from "@/helpers/enums/enums";

export function LeadIntegrationFieldDto({
    id,
    liId,
    type = LEAD_INTEGRATION_FIELD_TYPE.LEAD,
    name,
    localValue,
    value
})
{
    return {
        id, // number;
        liId, // number; the lead integration id
        type, // LeadIntegrationFieldType; // one of networkAd, landingPage, campaign, lead, offer, audience, custom
        clientField: localValue, // string;
        internalField: name, // string;  null if the "type" is "custom"
        value //string; null if the "type" is not "custom"
    };
}
