import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from '@/store/store';
import {removeEmpty} from "@/helpers/objectUtils";
import {get, post, put} from "@/services/httpService";
import {USER_GROUPS} from "@/store/constants";
import {STATUSES} from "@/components/performance/constants";

const resource = "/s/ad-creatives";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData  = {...store.state.filters, timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'networkAdRoot');
    return {
        objectFilters,
        filterParams
    };
};

const getFilters = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;
    const {field, desc} = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const userBaseFilterParams = filters && filters.userBaseParams ? filters.userBaseParams : {};

    const filterParams = createFilters(sort);

    return removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams,
        ...userBaseFilterParams
    });
};

const getUserGroupBaseParams = userGroup =>
{
    switch (userGroup)
    {
        case USER_GROUPS.SYSTEM_ADMINISTRATORS:
        case USER_GROUPS.ADMINISTRATORS:
        case USER_GROUPS.MEDIA_BUYERS:
            return  {status: STATUSES.ADMIN_REVIEW};
        case USER_GROUPS.ORGANIZATION_USER:
        case USER_GROUPS.ADVERTISER:
            return  {status: STATUSES.DRAFT, onlyMine: true};

    }
};

const fetchStats = (stateParams) =>
{
    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetchPreviews = ({params = {}} = {}) =>
{
    return get(`${resource}/preview`, {
        params
    });
};

const fetchPreviewOfType = ({id, type = {}} = {}) =>
{
    return get(`${resource}/${id}/preview`, {
        params: {
            type
        }
    });
};

const fetch = ({id, ...rest} = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

const fetchAll = (payload = {}, ...rest) =>
{
    return get(`${resource}`, {
        params: payload,
        ...rest
    });
};

// TODO: move to own repository when decided to create separate stores
const fetchComments = ({id,  ...rest} = {}) =>
{
    return get(`${resource}/${id}/comments`, {
        ...rest
    });
};

const createComment = ({id, comment} = {}) =>
{
    return post(`${resource}/${id}/comments`, comment, {
        isSilent: true
    });
};

const fetchActivities = ({id,  ...rest} = {}) =>
{
    return get(`${resource}/${id}/activities`, {
        ...rest
    });
};

//end TODO

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (id, payload = {}) =>
{
    return put(`${resource}/${id}`, payload);
};

const updateStatus = (id, payload = {}) =>
{
    return put(`${resource}/${id}/status`, payload);
};

const checkStatus = (id, payload = {
    jobId: null
}) =>
{
    return get(`${resource}/${id}/status/${payload.jobId}`, {
        isThrowable: true
    });
};

const toggle = ({id, action = ""}) =>
{
    return post(`${resource}/${id}/${action}`);
};

const createSuggestions = requestData =>
{
    return post(`${resource}/suggestions`, requestData);
};

const createSuggestionsForField = (sessionId, requestData) =>
{
    return post(`${resource}/suggestions/${sessionId}`, requestData);
};

const checkSuggestionsStatus = jobId =>
{
    return get(`${resource}/suggestions/${jobId}`);
};

export {
    fetch,
    fetchAll,
    fetchStats,
    fetchPreviews,
    fetchPreviewOfType,
    fetchComments,
    fetchActivities,
    getFilters,
    create,
    createComment,
    createFilters,
    update,
    updateStatus,
    checkStatus,
    toggle,
    getUserGroupBaseParams,
    createSuggestions,
    createSuggestionsForField,
    checkSuggestionsStatus
};
