import {get, post} from "@/services/httpService";
import {createUrlFiltersParameters, createUrlObjectFilters} from "@/helpers/urlUtils";
import store from "@/store/store";
import {removeEmpty} from "@/helpers/objectUtils";

const resource = "/s/transactions";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getBillingDateRangeFilter;
    const urlFiltersData  = {...store.state.filters ,timeZoneDates};
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'billingPage');
    return {
        objectFilters,
        filterParams
    };
};

const fetch = ({page, sort, currPage} = {}, filters) =>
{
    let {top, skip} = page;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    const stateParams = removeEmpty({
        top,
        skip,
        ...filters,
        ...filterParams.filterParams,
        ...filterParams.objectFilters
    });

    return get(`${resource}`, {
        params: stateParams
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

export {
    fetch,
    create
};
