import Vue from "vue";
import * as Sentry from "@sentry/browser";

const LocalStorageService = (() =>
{
    let instance;

    const AD_CREATIVE_STATUS_JOBS = "Genly_adCreativeStatusJobs";
    const CAMPAIGN_JOBS = "Genly_campaignJobs";
    const NETWORK_CAMPAIGN_DUPLICATE_JOBS = "Genly_networkCampaignDuplicateJobs";
    const AD_CREATIVE_SUGGESTIONS_JOBS = "Genly_adCreativeSuggestionsJobs";

    const get = (key, defaultValue = "") =>
    {
        return Vue.ls.get(key, defaultValue);
    };

    const set = (key, value) =>
    {
        Vue.ls.set(key, value);
    };

    const remove = key =>
    {
        Sentry.captureException(new Error(`[Local Storage Service]: remove `), {
            tags: {
                section: "errors",
            },
        });
        Vue.ls.remove(key);
    };

    const clear = () =>
    {
        Sentry.captureException(new Error(`[Local Storage Service]: clear `), {
            tags: {
                section: "errors",
            },
        });
        Vue.ls.clear();
    };

    const init = () =>
    {
        return {
            get,
            set,
            remove,
            clear
        };
    };

    const getInstance = () =>
    {
        if(!instance)

            instance = init();

        return instance;
    };

    return {
        getInstance,
        AD_CREATIVE_STATUS_JOBS,
        CAMPAIGN_JOBS,
        NETWORK_CAMPAIGN_DUPLICATE_JOBS,
        AD_CREATIVE_SUGGESTIONS_JOBS
    };
})();


export {LocalStorageService};
