import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {fetch, update, create, destroy} from "@/repositories/CampaignSchedulesRepository";
import {objectToCampaignSchedulesDto} from "@/models/campaign-schedules";


const state = {
    campaignSchedules : EntityAdapter().createEntityAdapter()
};

const mutations = {
    [mt.SetCampaignSchedules](state, campaignSchedules)
    {
        state.campaignSchedules = state.campaignSchedules.setAll(campaignSchedules, state.campaignSchedules);
    },
    [mt.AddCampaignSchedules](state, campaignSchedules)
    {
        state.campaignSchedules = state.campaignSchedules.addOne(campaignSchedules, state.campaignSchedules);
    },
    [mt.UpdateCampaignSchedules](state, campaignSchedules)
    {
        state.campaignSchedules = state.campaignSchedules.updateOne(campaignSchedules, state.campaignSchedules);
    },
    [mt.RemoveCampaignSchedule](state, id)
    {
        state.campaignSchedules = state.campaignSchedules.removeOne(id, state.campaignSchedules);
    },
    [mt.RemoveAllCampaignSchedule](state)
    {
        state.campaignSchedules = state.campaignSchedules.removeAll(state.campaignSchedules);
    }
};

const getters = {
    getCampaignSchedulesById: state => id => state.campaignSchedules.entities[id]
};

const actions = {
    async fetchCampaignSchedules({ commit }, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch( {
                params: {
                    cmpId: id
                },
                ...rest
            });
            commit(mt.SetLoading, false);

            if(response && response.data && response.data.data)
            {

                const campaignSchedules = response.data.data;
                commit(mt.SetCampaignSchedules, campaignSchedules);
                const campaignSchedulesIds = campaignSchedules.map(schedule => schedule.id);
                commit(mt.UpdateCampaign, {
                    id,
                    changes:{
                        campaignSchedulesIds
                    }
                });
                commit(mt.SetLoading, false);
            }

        }
        catch(error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createCampaignSchedule({ commit }, schedule = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(objectToCampaignSchedulesDto(schedule));
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async updateCampaignSchedule({commit}, {id, schedule})
    {
        try
        {

            commit(mt.SetLoading, true);
            const response = await update({id, data: schedule});
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async deleteCampaignSchedule({commit}, {id} = {})
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await destroy(id);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    }
};

const CampaignSchedulesDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default CampaignSchedulesDataModule;
