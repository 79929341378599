import {setDtoBase} from '@/models/DtoBase';
import {LandingPageAdCreativePreview} from '@/models/landing-page-ad-creative-preview/landingPageAdCreativePreview';
import {LandingPageAdCreativePreviewDto} from '@/models/landing-page-ad-creative-preview/landingPageAdCreativePreviewDto';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";

const adPreviewElementDto = {
    dbId: null,
    description: null,
    source: null,
    sourceId: null
};

const normalizePreviewElement = ({
    ad = adPreviewElementDto,
    adFormat,
    html
} = {}) =>
{
    
    const element = new DOMParser().parseFromString(html, "text/html");
    const frame = element.documentElement.getElementsByTagName('img')[0];
    return {
        id: `${ad.dbId}-${ad.sourceId}`,
        dbId: ad.dbId,
        description: ad.description,
        source: ad.source,
        sourceId: ad.sourceId,
        preview: frame.src.toString(),
        adFormat
    };
};

export const landingPageAdCreativePreviewDtoToObject = dto =>
{
    const object = flowRight(LandingPageAdCreativePreview, normalizePreviewElement)(dto);
    return object;
};

export const objectToLandingPageAdCreativePreviewDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, LandingPageAdCreativePreviewDto)(data);
    return dto;
};
