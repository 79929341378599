import { map } from 'lodash';
import { formQuestionDtoToObject } from "@/models/landing-page-form-questions";

export function LandingPageFields({
    data,
    pages
} = {})
{
    return {
        data: map(data, (field) => formQuestionDtoToObject(field)),
        pages
    };
}
