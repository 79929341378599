import mt from '../mutationTypes';
import {fbPageDtoToObject} from "@/models/fb-page";
import { fetchAll, fetch } from "@/repositories/NetworkAssetRepository";

const state = {
    fbPages: []
};

const mutations = {
    [mt.SetFbPages](state, data)
    {
        state.fbPages = data.map(fbPageDtoToObject);
    },
    [mt.AddFbPages](state, data)
    {
        state.fbPages = [...state.fbPages, ...data.map(fbPageDtoToObject)]
            .filter((schema, index, self) => self.findIndex(page => (page.id === schema.id)) !== -1);
    }
};



const getters = {
    getFbPagesList(state)
    {
        return state.fbPages;
    },
    getFbPageForId: state => id =>
    {
        const fbPage = state.fbPages.find(page => page.id === id);
        return fbPage || fbPageDtoToObject({});
    }
};

const actions = {
    // FB Pages
    async fetchFacebookPages({ commit })
    {
        commit(mt.SetLoading, true);

        try
        {
            const result = await fetchAll();

            commit(mt.SetLoading, false);

            if (result && result.data && result.data.data)
            {
                commit(mt.SetFbPages, result.data.data);
                return result.data.data;
            }
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            console.error(ex); // eslint-disable-line
            throw new Error(error);
        }
    },
    async fetchFacebookPageByName({commit}, {name, clientId, top = 1000, skip = 0, load = true, ...rest} = {})
    {
        try
        {
            if(load) commit(mt.SetLoading, true);

            const response = await fetchAll( {
                name,
                clId: clientId,
                top,
                skip
            }, rest);

            if(load) commit(mt.SetLoading, false);
            if (response && response.data && response.data.data)
            {
                commit(mt.AddFbPages, response.data.data);
                return response.data.data;
            }

            return [];
        }
        catch(error)
        {
            if(load) commit(mt.SetLoading, false);
            throw error;
        }
    },
    async fetchFacebookPageForLandingPage({ commit }, {id, ...rest} = {})
    {
        commit(mt.SetLoading, true);
        const response = await fetch({id,...rest});

        commit(mt.SetLoading, false);

        if(response && response.status === 200)
        {
            commit(mt.AddFbPages, [response.data]);
            // TODO: get response where needed from the store
            return response.data;
        }

        // TODO: get response where needed from the store
        return response;
    },
};

const NetworkAssetDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default NetworkAssetDataModule;
