export default function Filters({
    // TODO: add additional filters
    clientId,
    campaignId,
    adGroupId,
    adCreativeId,
    orderBy,
    orderByDesc,
    top,
    skip
} = {})
{
    return {
        clientId,
        cmpId: campaignId,
        netCmpId: adGroupId,
        adCreativeId,
        orderBy,
        orderByDesc,
        top,
        skip
    };
}
