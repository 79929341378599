import {LANDING_PAGE_FIELD_TYPE, LANDING_PAGE_FIELDS} from "@/components/performance/constants";

export function FormQuestion({
    index,
    id = null,
    lpId = null,
    name = null,
    label = null,
    orderNum = null,
    type = null,
    optionsJson = null
} = {})
{
    return {
        index,
        id,
        lpId,
        name,
        label,
        order: orderNum,
        type,
        optionsJson
    };
}

// do we add formQuestion logic in models?
export function isOpenQuestionType(field)
{
    return field.type === LANDING_PAGE_FIELD_TYPE.STRING || [LANDING_PAGE_FIELDS.MULTIPLE_CHOICE, LANDING_PAGE_FIELDS.SHORT_ANSWER].includes(field.name);
}