export function Activity({
    id = null,
    adCreativeId = null,
    sessionId = null,
    field = null,
    oldVal = null,
    newVal = null,
    createdBy = null,
    createdAt = null
} = {})
{
    return {
        id,
        adCreativeId,
        sessionId,
        field,
        oldVal,
        newVal,
        createdBy,
        createdAt
    };
}