import { map } from "lodash";
import {LANDING_PAGE_FIELDS} from "@/components/performance/constants";

const getFieldName = (name, label) =>
{
    if (name === LANDING_PAGE_FIELDS.SHORT_ANSWER)
        return label;
    else if (name === LANDING_PAGE_FIELDS.MULTIPLE_CHOICE)
        return label;
    else
        return name;
};

function parseOptionsJson(optionsJson)
{
    return map(optionsJson, (jsonValue) =>
    {
        return {
            key: jsonValue.value.replace(/ /g,"_").toLowerCase(),
            value: jsonValue.value
        };
    });
}
export function FormQuestionDto({
    type = null,
    name = null,
    label = null,
    order = 0,
    optionsJson = [],
} = {})
{
    return {
        name: getFieldName(name, label),
        type,
        orderNum: order,
        optionsJson: optionsJson.length !== 0 ? JSON.stringify(parseOptionsJson(optionsJson)) : null
    };
}
