export function TrackingParametersDto({
    id,
    lpId,
    type = "custom",
    field,
    value
} = {})
{
    return {
        id,
        lpId,
        type,
        clientField: field,
        value
    };
}
