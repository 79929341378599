import mt from '../../mutationTypes';
import { get, post } from '../../../services/httpService';
import { has } from 'lodash';

const state =
{
    clientSfLeadStatuses: {},
    availableSfStatuses: [],
};

const mutations =
{
    [mt.SetClientSalesforceLeadStatuses](state, fields)
    {
        const finalFields = {};
        const availableSfStatuses = state.availableSfStatuses;

        for (const field of fields)
        {
            const statusKey = field.leadStage + '.' + field.statusString;
            finalFields[statusKey] = field;
        }

        for (const availStatus of availableSfStatuses)
        {
            const availStatusKey = availStatus.leadStage + '.' + availStatus.statusString;

            if (finalFields[availStatusKey])
                continue;
            
            finalFields[availStatusKey] =
            {
                leadStage: availStatus.leadStage,
                statusString: availStatus.statusString,
                leadStatus: null,
            };
        }

        state.clientSfLeadStatuses = finalFields;
    },
    [mt.SetSalesforceAvailableLedStatuses](state, fields)
    {
        state.availableSfStatuses = fields;
    },
};

const getters =
{
    getClientSalesforceLeadStatuses(state)
    {
        return state.clientSfLeadStatuses;
    },
    getSalesforceAvailableLeadStatuses(state)
    {
        return state.availableSfStatuses;
    },
};

const actions =
{
    async loadCrmSettings({ commit, rootGetters })
    {
        const orgId = rootGetters.getUserOrganization;
        const url = `/s/crm?crmType=salesforce&clientId=${orgId}`;

        commit(mt.SetLoading, true);

        let result = null;

        try
        {
            const response = await get(url);
            const isRequestSuccessful =
                ( response && response.status === 200 && has(response, 'data') && response.data.length > 0);
            if (isRequestSuccessful)
                result = response.data[0]['id'];
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
        }
        finally
        {
            commit(mt.SetLoading, false);
        }

        return result;
    },
    async loadSalesforceAvailableLeadStatuses({ commit }, crmId)
    {
        const url = `/s/crm/${crmId}/available-statuses`;

        commit(mt.SetLoading, true);
        try
        {
            const response = await get(url);
            const isRequestSuccessful = (response && response.status === 200 && has(response, 'data'));
            if (isRequestSuccessful)
            {
                const result = response.data;
                commit(mt.SetSalesforceAvailableLedStatuses, result);
            }
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadClientSalesforceLeadStatuses({ commit, dispatch })
    {
        commit(mt.SetLoading, true);

        try
        {
            const crmId = await dispatch('loadCrmSettings');
            if (!crmId)
                return;

            await dispatch('loadSalesforceAvailableLeadStatuses', crmId);

            const url = `/s/crm/${crmId}/lead-status-mapping`;
            const response = await get(url);
            const isRequestSuccessful = (response && response.status === 200 && has(response, 'data'));
            if (isRequestSuccessful)
            {
                const result = response.data;
                commit(mt.SetClientSalesforceLeadStatuses, result);
            }
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async initSalesforceIntegration({ commit }, params)
    {
        const url = `/s/clients/integrations/salesforce`;
        let result = null;

        commit(mt.SetLoading, true);
        try
        {
            const response = await post(url, {
                ...params
            });
            if (response && response.data)
                result = response.data;
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
        }
        finally
        {
            commit(mt.SetLoading, false);
        }

        return result;
    },
    async updateClientSalesforceLeadStatuses({ commit, dispatch }, statuses)
    {
        commit(mt.SetLoading, true);
        let result = false;

        try
        {
            const crmId = await dispatch('loadCrmSettings');
            if (!crmId)
                return false;

            const url = `/s/crm/${crmId}/lead-status-mapping`;
            const mapping = [];

            const availableKeys = Object.keys(statuses);
        
            for (const currKey of availableKeys)
            {
                const isStatusValid = (statuses[currKey].leadStatus != "null" && statuses[currKey].leadStatus != null);
                if (isStatusValid)
                {
                    const { leadStage, statusString, leadStatus } = statuses[currKey];
                    mapping.push({ leadStage, statusString, leadStatus });
                }
            }

            const response = await post(url, { mapping });
            const isRequestSuccessful = (response && response.status === 200);
            
            result = isRequestSuccessful;
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
        }
        finally
        {
            commit(mt.SetLoading, false);
        }

        return result;
    },
};

const SalesforceDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default SalesforceDataModule;
