import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { Audience } from "@/models/audience/audience";
import { AudienceDto } from "@/models/audience/audienceDto";
import { removeEmpty } from "@/helpers/objectUtils";

export const audienceDtoToObject = dto =>
{
    const object = Audience(dto);
    return object;
};

export const objectToAudienceDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, AudienceDto)(data);
    return dto;
};
