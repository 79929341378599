import EntityAdapter from "@/store/adapter";
import mt from "@/store/mutationTypes";
import {fetch, create, update} from "@/repositories/TargetsRepository";

const state = {
    targets: EntityAdapter().createEntityAdapter()
};

const mutations = {
    [mt.SetTargets](state, targets)
    {
        state.targets = state.targets.setAll(targets, state.targets);
    },
    [mt.UpsertTarget](state, target)
    {
        state.targets = state.targets.upsertOne(target, state.targets);
    },
    [mt.UpdateTarget](state, target)
    {
        state.targets = state.targets.updateOne(target, state.targets);
    }
};

const getters = {
    getTargetById: state => id => state.targets.entities[id]
};

const actions = {
    async fetchTargetForLandingPage({ commit }, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id, ...rest});
            const target = response.data;
            commit(mt.UpsertTarget, target);
            /*commit(mt.UpdateLandingPage, {
                id,
                changes: {
                    lpTargetId: targets.id
                }
            });*/
    
            // TODO: get target where needed from the store
            return target;
        }
        catch (error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async createLandingPageTarget({commit}, targetData)
    {
        commit(mt.SetLoading, true);
        const response = await create(targetData);

        const target = response.data;
        commit(mt.UpsertTarget, target);
        commit(mt.SetLoading, false);
        // return newly created target
        return target.id;
    },

    async updateLandingPageTarget({ commit }, {id, data} = {}) {
        commit(mt.SetLoading, true);
        const response = update({id, data});
        if (response.status === 204)
        {
            const target = response.data;
            commit(mt.UpdateTarget, {
                id,
                changes: target
            });
        }
        commit(mt.SetLoading, false);
    }
};

const TargetsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default TargetsDataModule;
