import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import {OfferThumbnail} from '@/models/offer-thumbnail/offerThumbnail';
import {OfferThumbnailDto} from '@/models/offer-thumbnail/offerThumbnailDto';
import { removeEmpty } from "@/helpers/objectUtils";

export const offerThumbnailDtoToObject = dto =>
{
    const object = OfferThumbnail(dto);
    return object;
};

export const objectToOfferThumbnailDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, OfferThumbnailDto)(data);
    return dto;
};
