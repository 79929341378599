export function LandingPageAdCreativePreview({
    id,
    dbId,
    description,
    source,
    sourceId,
    preview,
    adFormat
})
{
    return {
        id,
        dbId,
        description,
        source,
        sourceId,
        preview,
        adFormat
    };
}
