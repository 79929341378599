export function PrivacyPolicy({
    id = null,
    clientId = null,
    name = null,
    url = null,
    title = null,
    text = null,
    linkText = null,
    type = null,
    requireUserConsent = null,
    userConsentPrompt = null,
    status = null,
    statusText = null,
    updatedBy = null,
    createdBy = null
} = {})
{
    return {
        id,
        clientId,
        linkText,
        url,
        title,
        text,
        name,
        type,
        status,
    };
}
