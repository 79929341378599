export function CampaignSchedules({
    id =  null,
    clientId = null,
    cmpId = null,
    name = "",
    dayOfWeek = "",
    timeRange= [],
    createdBy="",
    updatedBy=""
} = {})
{
    return{
        id,
        clientId,
        cmpId,
        name,
        dayOfWeek,
        timeRange,
        createdBy,
        updatedBy
    };
}