export function LandingPage({
    id,
    imageAssetId,
    offerId,
    source,
    version,
    lpGroupId,
    clientId,
    cmpId,
    accountId,
    subAccountId,
    integrationsCount,
    integrationsErrCount,
    pageId,
    fbPageId,
    privacyId,
    lpTargetId,
    formQuestionsIds,
    useAdImage,
    headline,
    questionHeadline,
    description,
    url,
    protocolRelativeUrl,
    name,
    state,
    variantsCount,
    domain,
    isActive,
    status,
    statusText,
    lastPublishedAt,
    type,
    thankYouPageUrl,
    preview,

    // stats props
    clicks,
    clientCpc,
    clientCpl,
    clientSpend,
    cmpType,
    comments,
    conversionRate,
    cpc,
    cpl,
    cpm,
    createdBy,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    impressions,
    leads,
    likes,
    mediaPercentage,
    networkLeads,
    postReactions,
    shares,
    spend
} = {})
{
    return {
        id,
        name,
        status,
        source,
        lpSource: source,
        clientId,
        lpGroupId,
        campaignId: cmpId,
        fbPageId,
        privacyId,
        lpTargetId,
        offerId,
        headline,
        description,
        questionHeadline,
        url,
        isActive,
        statusText,
        lastPublishedAt,
        type,
        thankYouPageUrl,
        preview,
        thumbnailsIds: [],
        formQuestionsIds: [],
        trackingParametersIds: [],
        adCreativesPreviewIds: [],

        // stats props
        clicks,
        clientCpc,
        clientCpl,
        clientSpend,
        cmpType,
        comments,
        conversionRate,
        cpc,
        cpl,
        cpm,
        createdBy,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        impressions,
        leads,
        likes,
        mediaPercentage,
        networkLeads,
        postReactions,
        shares,
        spend
    };
}
