const BrowserService = () =>
{
    const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];
    let isVisible = true; // internal flag, defaults to true

    // get the correct attribute name
    const getHiddenPropertyName = prefix =>
    {
        return (prefix ? prefix + 'Hidden' : 'hidden');
    };

    // get the correct event name
    const getVisibilityEvent = prefix =>
    {
        return (prefix ? prefix : '') + 'visibilitychange';
    };

    // get current browser vendor prefix
    const getBrowserPrefix = () =>
    {
        for (var i = 0; i < browserPrefixes.length; i++)
            if(getHiddenPropertyName(browserPrefixes[i]) in document)
                // return vendor prefix
                return browserPrefixes[i];
        
        // no vendor prefix needed
        return null;
    };
    
    const onVisible = () =>
    {
        // prevent double execution
        if(isVisible)
            return;
        
        isVisible = true;
    };
    
    const onHidden = () =>
    {
        // prevent double execution
        if(!isVisible)
            return;
        
        isVisible = false;
    };
    
    const handleVisibilityChange = forcedFlag =>
    {
        // forcedFlag is a boolean when this event handler is triggered by a
        // focus or blur event otherwise it's an Event object
        if(typeof forcedFlag === "boolean")
        {
            if(forcedFlag)
                return onVisible();
           
            return onHidden();
        }
        
        if(document[hiddenPropertyName])
            return onHidden();
        
        return onVisible();
    };
    
    // bind and handle events
    let browserPrefix = getBrowserPrefix();
    let hiddenPropertyName = getHiddenPropertyName(browserPrefix);
    let visibilityEventName = getVisibilityEvent(browserPrefix);
    
    const getVisibilityEventName = () => visibilityEventName;
    
    return {
        getVisibilityEventName,
        handleVisibilityChange
    };
};

export {BrowserService};
