function NetworkCreateError(...args)
{
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    instance.rawData = args[0];
    if (args[1])
        instance.rawError = args[1];
    return instance;
}

NetworkCreateError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        configurable: true,
        writable: true
    }
});

Reflect.setPrototypeOf(NetworkCreateError, Error);

export default NetworkCreateError;
