import curry from "lodash/curry";

const capitalizeSingle = string => string[0].toUpperCase() + string.slice(1);

const generateRandomString = () => [...Array(30)].map(() => Math.random().toString(36)).join('');

const toCamelCase = (string, divider = " ") =>
{
    const stringsArray = string.split(divider);
    return stringsArray.reduce((prev, current, index) =>
    {
        if (index === 0)
            prev += current.toLowerCase();
        else
            prev += capitalizeSingle(current);
        return prev;
    }, "");
};

const fromCamelCase = string => string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase() );

const capitalize = (string, divider = " ") =>
{
    const stringsArray = string.split(divider);
    return stringsArray
        .reduce((prev, current, index) =>
        {
            prev += capitalizeSingle(current.toLowerCase());
            if (index < current.length) prev += " ";
            return prev;
        }, "")
        .trim();
};

const replaceMappedString = curry((needleMap, string) =>
{
    if (!(needleMap instanceof Map)) return string;
    let newString = string;
    for (let [key, value = " "] of needleMap.entries())
    {
        const replacement = new RegExp(key, "gi");
        newString = newString.replace(replacement, value);
    }
    return newString;
});

const replaceString = curry((needle, string, replaceChar = " ") =>
{
    const replacement = new RegExp(needle, "gi");
    return string.replace(replacement, replaceChar);
});
//Delete after DEV-3085
const truncateString = (string, maxLength = 150) =>
{
    if(!string) return;
    if(string.length < maxLength) return string;
    let firstPartTruncated = `${string.substr(0, maxLength)}...`;
    return firstPartTruncated;
};

const isValidHttpUrl = string =>
{
    let url;

    try
    {
        url = new URL(string);
    }
    catch (_)
    {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

const contains = (haystack, needle) => haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;

const isBlank = string =>
{
    return (!string || /^\s*$/.test(string));
};

const removeDoubleSymbols = string => string.replace(/([\.\,\!])\1+/g, "$1");

export { capitalize, contains, isValidHttpUrl, toCamelCase, fromCamelCase, generateRandomString, replaceString, replaceMappedString, truncateString, isBlank, removeDoubleSymbols };
