export function Lead({
    address= null,
    assetTitle= null,
    audienceId= null,
    bestTime= null,
    city= null,
    clientId= null,
    company= null,
    companyInfoJson= {},
    companySize= null,
    companyWebsite= null,
    contactInfoJson= {},
    country= null,
    email= null,
    firstName= null,
    fraudReasons= null,
    fraudStatus= null,
    handraise= null,
    id= null,
    ipAddress= null,
    isActive= null,
    jobDescription= null,
    jobTitle= null,
    lastName= null,
    pageId= null,
    pageName= null,
    pageSearchTerms= null,
    pageUrl= null,
    pageVariant= null,
    phoneNumber= null,
    preferredContact= null,
    receivedDate= null,
    source= null,
    sourceAdId= null,
    sourceCampaignId= null,
    sourceChannel= null,
    sourceMedium= null,
    state= null,
    zipCode= null,
    lpId= null,
    netCmpId= null,
    adCreativeId= null,
    netAdId= null,
} = {})
{
    return {
        address,
        assetTitle,
        audienceId,
        bestTime,
        city,
        clientId,
        company,
        companyInfoJson,
        companySize,
        companyWebsite,
        contactInfoJson,
        country,
        email,
        firstName,
        fraudReasons,
        fraudStatus,
        handraise,
        id,
        ipAddress,
        isActive,
        jobDescription,
        jobTitle,
        lastName,
        pageId,
        pageName,
        pageSearchTerms,
        pageUrl,
        pageVariant,
        phoneNumber,
        preferredContact,
        receivedDate,
        source,
        sourceAdId,
        sourceCampaignId,
        sourceChannel,
        sourceMedium,
        state,
        zipCode,
        lpId,
        netCmpId,
        adCreativeId,
        netAdId
    };
}
