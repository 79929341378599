export function CompanyDto({
    id = null,
    addMethod = null,
    billingAddress = null,
    billingEmail = null,
    isActive = null,
    name = null,
    phoneNumber = null,
    verticalId = null,
    website = null
} = {})
{
    return {
        id,
        name,
        phoneNumber,
        addMethod,
        billingAddress,
        billingEmail,
        website,
        isActive,
        verticalId
    };
}
