import projConfig from '@/projConfig';
import mt from '../../mutationTypes';
import enums, {STATUSES} from '@/components/performance/constants';
import createColumnizator from '../../../models/Columnizator';
import createTotalizator from '../../../models/Totalizator';
import GenlyCreateError from "@/errors/genlyCreateError";
import LandingPageError from "@/errors/landingPageError";
import EntityAdapter from "@/store/adapter";
import {
    fetch,
    fetchByName,
    fetchPreview,
    fetchStat,
    fetchStats,
    fetchStatsForOffer,
    create,
    update,
    updateStatus
} from "@/repositories/LandingPageRepository";

const state = {
    landingPages: EntityAdapter().createEntityAdapter(),
    totals: null,

    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    sort: {
        field: 'spend',
        desc: true
    }
};

const mutations = {
    [mt.SetLandingPages](state, landingPages)
    {
        state.landingPages = state.landingPages.setAll(landingPages, state.landingPages);
    },
    [mt.SetLandingPagesTotals](state, totals)
    {
        state.totals = totals;
    },
    [mt.AddLandingPage](state, landingPage)
    {
        state.landingPages = state.landingPages.addOne(landingPage, state.landingPages);
    },
    [mt.UpsertLandingPages](state, landingPage)
    {
        state.landingPages = state.landingPages.upsertMany(landingPage, state.landingPages);
    },
    [mt.UpsertLandingPage](state, landingPage)
    {
        state.landingPages = state.landingPages.upsertOne(landingPage, state.landingPages);
    },
    [mt.UpdateLandingPage](state, landingPage)
    {
        state.landingPages = state.landingPages.updateOne(landingPage, state.landingPages);
    },
    [mt.RemoveLandingPage](state, id)
    {
        state.landingPages = state.landingPages.removeOne(id, state.landingPages);
    },
    [mt.UpdateThumbnailsForLandingPage](state, payload)
    {
        state.landingPages = state.landingPages.updateOne({
            id: payload.id,
            changes: {
                thumbnailsIds: payload.thumbnailsIds
            }
        }, state.landingPages);
    },

    [mt.SetLandingPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages
        });
    },
    [mt.SetLandingSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    }
};

const getters = {
    getLandingPagesIds: (state) => state.landingPages.ids,
    getLandingPagesEntities: (state) => state.landingPages.entities,
    getLandingPageById: (state) => id => state.landingPages.entities[id],

    getLandingPages(state, getters)
    {
        return getters.getLandingPagesIds.map(id => getters.getLandingPagesEntities[id]);
    },
    getLandingPaging(state)
    {
        return state.page;
    }
};

const actions = {
    async fetchLandingPagesForOffer({commit, state, rootGetters}, offerId)
    {
        commit(mt.SetLoading, true);
        const response = await fetchStatsForOffer(state, offerId);

        if(response && response.data)
        {
            const landingPages = createColumnizator(response.data.data);
            const totals = createTotalizator(response.data.totals);
            const landingPagesIds = landingPages.map(landingPage => landingPage.id);

            const offer = rootGetters.getOfferById(offerId);
            const offerLandingPagesIds = offer.landingPagesIds ? offer.landingPagesIds : [];
            commit(mt.UpdateOffer, {
                id: offerId,
                changes: {
                    landingPagesIds: [...new Set([...offerLandingPagesIds, ...landingPagesIds])]
                }
            });
            commit(mt.UpsertOfferExpanded, {
                id: offerId,
                isExpanded: true,
                landingPagesIds: [...new Set([...offerLandingPagesIds, ...landingPagesIds])]
            });
            commit(mt.UpsertLandingPages, landingPages);
            commit(mt.SetLandingPagesTotals, totals);
        }
        else
        {
            commit(mt.UpsertOfferExpanded, {
                id: offerId,
                isExpanded: true,
            });
        }
        commit(mt.SetLoading, false);
    },
    async fetchLandingPageStats({commit, rootGetters}, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStat({id});

            if(response && response.data)
            {
                const landingPage = response.data.data;
                commit(mt.UpsertLandingPage, createColumnizator(landingPage));

                const offer = rootGetters.getOfferById(landingPage.offerId);
                if (offer)
                {
                    const offerLandingPagesIds = offer.landingPagesIds ? offer.landingPagesIds : [];
                    commit(mt.UpdateOffer, {
                        id: landingPage.offerId,
                        changes: {
                            landingPagesIds: [...new Set([...offerLandingPagesIds, landingPage.id])]
                        }
                    });
                }

                commit(mt.SetLoading, false);
            }
        }
        catch(error)
        {
            throw error.data ? new Error(error.data) : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchLandingPageDetails({commit, rootGetters}, {id, ...rest} = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetch({id, ...rest});

            if(response && response.data)
            {
                const landingPage = response.data;

                const offer = rootGetters.getOfferById(landingPage.offerId);
                if(offer)
                {
                    const offerLandingPagesIds = offer.landingPagesIds ? offer.landingPagesIds : [];
                    commit(mt.UpdateOffer, {
                        id: landingPage.offerId,
                        changes: {
                            landingPagesIds: [...new Set([...offerLandingPagesIds, landingPage.id])]
                        }
                    });
                }

                commit(mt.UpsertLandingPage, landingPage);

                // TODO: remove once all code calling this method is refactored - still used in ComboSelect
                return landingPage;
            }
        }
        catch(error)
        {
            throw error.data ? new Error(error.data) : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchLandingPages({ commit, state }, filters)
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await fetchStats(state, filters);

            if (response && response.status === 200 && response.data)
            {
                const landingPages = response.data.data;
                commit(mt.UpsertLandingPages, landingPages);

                commit(mt.SetLandingPaging, {
                    ...response.data.pages,
                    currentCount: response.data.data.length
                });
            }
        }
        catch(error)
        {
            throw error || new Error('Error getting landing page stats.');
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchLandingPagePreview({ commit }, {id, type = "desktop"})
    {
        commit(mt.SetLoading, true);

        const response = await fetchPreview({id, type});

        if(response && response.data)
        {
            const landingPageResponse ={};
            landingPageResponse.id = id;
            landingPageResponse.preview = response.data.html;
            commit(mt.UpsertLandingPage, landingPageResponse);
        }

        commit(mt.SetLoading, false);
    },
    // used in combo selects
    async fetchLandingPagesByName({ commit }, {name, top = 1000, skip = 0, offerId = null, clientId = null, source = null, load = true, ...rest} = {})
    {
        // TODO: check if load is needed (it was added on fetchLandingPagesWithSource merge)
        if(load) commit(mt.SetLoading, true);
        const response = await fetchByName({name, top, skip, offerId, clientId, source, ...rest});
        if(load) commit(mt.SetLoading, false);

        if(response)
            return response.data.data;
        else
            throw new Error(response || "No landing pages were found.");
    },
    async loadLpAndSetFilter({ dispatch, commit }, id)
    {
        const response = await dispatch('fetchLandingPageDetails', { id });

        commit(mt.SetLandingFilter, { id: response.id, name: response.name });
        commit(mt.SetObjectFilter, { id: response.id, name: response.name, type: enums.OBJECT_FILTERS.LANDING_PAGE });
    },

    async createLandingPage({ commit, rootGetters }, landingPageData = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await create(landingPageData);

            const landingPage = response.data;
            commit(mt.AddLandingPage, landingPage);

            const offer = rootGetters.getOfferById(landingPage.offerId);
            if (offer)
            {
                const offerLandingPagesIds = offer.landingPagesIds ? offer.landingPagesIds : [];
                commit(mt.UpdateOffer, {
                    id: offer.id,
                    changes: {
                        landingPagesIds: [landingPage.id, ...offerLandingPagesIds]
                    }
                });
            }
            return landingPage.id;
        }
        catch(error)
        {
            console.error(error); // eslint-disable-line
            throw new GenlyCreateError(error.data);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateLandingPage({ commit }, { id, landingPage })
    {
        commit(mt.SetLoading, true);

        const response = await update({id, landingPage});

        commit(mt.SetLoading, false);

        if ([200, 204].includes(response.status))
            commit(mt.UpdateLandingPage, {
                id,
                changes: landingPage
            });
        else
            throw new Error("Error updating landing page");
    },
    async updateLandingPageStatus({ commit }, { id, status })
    {
        try
        {
            commit(mt.SetLoading, true);
            await updateStatus({id, status});

            commit(mt.UpdateLandingPage, {
                id,
                changes: {
                    status
                }
            });
        }
        catch(error)
        {
            throw error.data
                ? new LandingPageError(error.data)
                : new GenlyCreateError(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async toggleLandingPageDelivery({ commit }, { id, turnOn })
    {
        try
        {
            const status = turnOn ? STATUSES.RUNNING : STATUSES.PAUSED;

            commit(mt.SetLoading, true);
            const response = await updateStatus({id, status});

            if (response && response.status && [200, 204].includes(response.status))
                // if all is good, commit to state
                commit(mt.UpdateLandingPage, {
                    id,
                    changes: {
                        isActive: turnOn
                    }
                });
            else
                throw new Error("Error toggling landing page delivery");
        }
        catch (error)
        {
            let errMsg = error.response
                ? (errMsg = error.response.data)
                : !error.request
                    ? error.message
                    : null;

            throw new Error(errMsg);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    }
};

const LandingDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default LandingDataModule;
