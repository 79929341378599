<template>
  <div
    ref="dropdownMenu"
    v-click-outside="menuClose"
    :class="[{ 'disabled-dropdown': disabled }, 'dropdown', 'dropdown-toggle']"
    @click="sendOpenEvent"
  >
    <div
      id="dropdown-toggle"
      :class="toggleClasses"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <slot name="toggle-button-content" />
      <font-awesome-icon
        icon="caret-down"
        class="dropdown--arrow"
      />
    </div>

    <div
      class="dropdown-menu"
      :class="menuClasses"
      :style="menuStyles"
      aria-labelledby="dropdown-toggle"
    >
      <slot name="menu-content" />
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
    name: "Dropdown",
    directives: {
        clickOutside: vClickOutside.directive
    },
    model: {
        prop: "isOpen"
    },
    props: {
        toggleClasses: {
            type: Array,
            default: () =>
            {
                return [];
            }
        },
        menuClasses: {
            type: Array,
            default: () =>
            {
                return [];
            }
        },
        menuStyles: {
            type: Object,
            default: () =>
            {
                return {};
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean,
            required: false,
            default: false
        },
        closeOnSelection: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    watch: {
        isOpen(newValue, oldValue)
        {
            if (!newValue) this.menuClose();
        }
    },
    mounted()
    {
        this.initDropdown();
    },
    methods: {
        initDropdown()
        {
            if (this.closeOnSelection)
                window
                    .$(this.$refs.dropdownMenu)
                    .find(".dropdown-item:not(.dropdown-submenu-toggle)")
                    .on("click", () =>
                    {
                        this.menuClose();
                    });

            window
                .$(this.$refs.dropdownMenu)
                .find(".dropdown-toggle")
                .click(e =>
                {
                    this.sendOpenEvent();

                    window.$(e.target).toggleClass("open");

                    if (
                        !window
                            .$(e.target)
                            .next()
                            .hasClass("show")
                    )
                        window
                            .$(e.target)
                            .parents(".dropdown-menu")
                            .first()
                            .find(".show")
                            .removeClass("show");

                    if (this.$mq.xs)
                    {
                        // find all open toggles, different from the clicked ones and close them
                        const toggles = window
                            .$(e.target)
                            .parents(".dropdown-menu")
                            .first()
                            .find(".dropdown-toggle")
                            .get();
                        toggles.forEach(toggle =>
                        {
                            if (
                                toggle !== e.target &&
								window.$(toggle).hasClass("open")
                            )
                                window.$(toggle).removeClass("open");
                        });
                    }

                    const $subMenu = window.$(e.target).next(".dropdown-menu");
                    $subMenu.toggleClass("show");

                    window
                        .$(e.target)
                        .parents(".dropdown.show")
                        .on("hidden.bs.dropdown", () =>
                            window
                                .$(".dropdown-submenu .show")
                                .removeClass("show")
                        );
                });
        },
        menuClose()
        {
            this.$emit("dropdown-close");

            const mainMenu = window
                .$(this.$refs.dropdownMenu)
                .find(".dropdown-menu");
            // set all icons to default (closed) state
            const toggles = mainMenu
                .first()
                .find(".dropdown-toggle")
                .get();
            toggles.forEach(toggle =>
            {
                window.$(toggle).removeClass("open");
            });
            mainMenu.removeClass("show");
        },
        sendOpenEvent()
        {
            this.$emit("dropdown-open");
        }
    }
};
</script>

<style lang="scss" scoped>
.disabled-dropdown {
    pointer-events: none !important;
    background-color: transparent !important;
}
</style>
