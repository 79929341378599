export function User({
    isActive,
    verticalId,
    canSeeOffers,

    DisplayName,
    FirstName,
    LastName,
    Email,
    GroupCategory,
    Clients,
    ClientsFull,
    Groups,
    IsLoggedIn,
    JobTitle,
    Organization,
    Phone,
    Picture,
    TimeZone,
    Username


} = {})
{
    return {
        id: Username,
        username: Username,
        displayName: DisplayName,
        firstName: FirstName,
        lastName: LastName,
        email: Email,
        picture: Picture,
        organization: Organization,
        groups: Groups,
        jobTitle: JobTitle,
        phone: Phone,
        timezone: TimeZone,
        isActive,
        verticalId,
        canSeeOffers,
        isLoggedIn: IsLoggedIn,
        groupCategory: GroupCategory,
        clients: Clients,
        clientsIds: Clients,
        clientsFullData: ClientsFull,
        companiesIds: []
    };
}
