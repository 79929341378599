export function SuggestionJob({
    headline = null,
    description = null,
    imageText = null,
    imageTags = null,
    assetIDs = null
} = {}) 
{
    return {
        adTitle: headline,
        adDescription: description,
        aiGeneratedImageText: imageText,
        imageTags,
        assetIds: assetIDs
    };
}
