import { AdSet } from '@/models/ad-set/adSet';
import {AdSetDto} from '@/models/ad-set/adSetDto';
import { flowRight } from 'lodash';
import { removeEmpty } from "@/helpers/objectUtils";

export const adSetDtoToObject = dto =>
{
    const object = AdSet(dto);
    return object;
};

export const objectToAdSetDto = data =>
{
    const dto = flowRight(removeEmpty, AdSetDto)(data);
    return dto;
};
