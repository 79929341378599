<template>
  <main
    class="wrapper"
    :class="{ 'safari': safari }"
  >
    <router-view />

    <transition name="fade">
      <loader
        v-if="loading"
        key="loader"
      />
    </transition>
  </main>
</template>

<script>
import {ColumnManager} from '@/modules/performance/services/columns/ColumnManager';
import {BrowserService} from '@/services/tabVisibilityService';
import redirectMixin from './mixins/redirect';
import Loader from './components/Loader';
import {addClass, removeClass} from "@/helpers/classie";
import { mapGetters } from 'vuex';
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/browser";

export default {
    components: { Loader },
    mixins: [redirectMixin],
    provide()
    {
        return {
            leadsColumnManager: this.leadsColumnManager
        };
    },
    data()
    {
        return {
            safari: false,
            leadsColumnManager: new ColumnManager()
        };
    },
    computed:
    {
        ...mapGetters({
            loading: "getIsLoading",
            getUserData : "getUserData",
        })
    },
    watch:
    {
        getUserData: {
            deep: true,
            handler(newValue, oldValue)
            {
                if (FullStory.isInitialized() && (newValue.username !== oldValue.username || newValue.displayName !== oldValue.displayName))
                    FullStory.identify(newValue.username, {
                        displayName: newValue.displayName,
                    });
            }
        }
    },
    created: async function ()
    {
        this.BrowserService = new BrowserService();
        this.addListeners();
        this.safari = window.safari !== undefined;
    },
    methods:
    {
        addListeners()
        {
            document.addEventListener(this.BrowserService.getVisibilityEventName(), this.handleVisibilityChange, false);

            // extra event listeners for better behaviour
            document.addEventListener('focus', () =>
            {
                this.handleVisibilityChange(true);
            }, false);

            window.addEventListener('focus', () =>
            {
                this.handleVisibilityChange(true);
            }, false);

            VueEvent.$on("show-preview-panel", () =>
            {
                addClass(document.getElementsByTagName('body')[0], "overflow-hidden");
            });

            VueEvent.$on("close-preview-panel", () =>
            {
                removeClass(document.getElementsByTagName('body')[0], "overflow-hidden");
            });

            window.onbeforeunload = () =>
            {
                Sentry.captureException(new Error(`[onbeforeunload]: websocket destroy `), {
                    tags: {
                        section: "errors",
                    },
                });
                if(this.$websocket && this.$websocket.isConnected())
                    this.$websocket.destroy();
            };
        },
        handleVisibilityChange(forcedFlag)
        {
            this.BrowserService.handleVisibilityChange(forcedFlag);
            if(this.$websocket && this.$websocket.isClosed())
                this.$websocket.reconnect();
        }
    }
};
</script>

<style lang="scss">
    @import "./assets/styles/preciseos-theme/style.css";
    @import "./assets/styles/preciseos-theme/svg-inline.min.css";
    @import "./assets/styles/theme/preciseos-theme-main";

    .wrapper
    {
        .home
        {
            .navbar.fixed-top + .content
            {
                //margin-top: $top-nav-height;
            }
        }
    }
</style>
