const USER_GROUPS = Object.freeze({
    SYSTEM_ADMINISTRATORS: 0,
    ADMINISTRATORS: 1,
    MANAGERS: 2,
    MARKETERS: 3,
    SALES: 4,
    MEDIA_BUYERS: 5,
    ORGANIZATION_USER: 6,
    ADVERTISER: 7,
});

export {USER_GROUPS};
