import { ENTITY_SUCCESS_STATE } from "@/helpers/enums/enums";
import { adGroupDtoToObject } from "@/models/ad-group";
import { landingPageAdCreativePreviewDtoToObject } from "@/models/landing-page-ad-creative-preview";
import { PendingJob } from "@/models/PendingJob";
import { LocalStorageObservableService } from "@/services/localStorageObservableService";
import { LocalStorageService } from "@/services/localStorageService";
import { StatusCodes } from "http-status-codes";
import moment from "moment";
import projConfig from "../../../projConfig";
import { convertMomentDateToTimestamp } from "@/services/dateService";
import mt from "../../mutationTypes";
import createColumnizator from "../../../models/Columnizator";
import createTotalizator from "../../../models/Totalizator";
import { removeEmpty } from "@/helpers/objectUtils";
import { isJsonString } from "@/services/stringService";
import GenlyCreateError from "@/errors/genlyCreateError";
import NetworkCreateError from "@/errors/networkCreateError";
import {
    checkStatus,
    create,
    createComment,
    fetch,
    fetchActivities,
    fetchAll,
    fetchComments,
    fetchPreviewOfType,
    fetchPreviews,
    fetchStats,
    getFilters,
    toggle,
    update,
    updateStatus,
    getUserGroupBaseParams,
    createSuggestions,
    checkSuggestionsStatus,
    createSuggestionsForField,
} from "@/repositories/AdCreativeRepository";
import EntityAdapter from "@/store/adapter";
import AdCreateError from "@/errors/adCreateError";
import { adCreativePreviewDtoToObject } from "@/models/ad-creative-preview";
import { commentDtoToObject } from "@/models/comment";
import { activityDtoToObject } from "@/models/activity";
import enums, {
    JOB_STATUS,
    STATUSES,
} from "@/components/performance/constants";
import { get } from "@/services/httpService";
import { adCreativeDtoToObject } from "@/models/ad-creative";
import { objectToSuggestionDto, suggestionDtoToObject, suggestionJobDtoToObject, objectToSuggestionFieldDto} from "@/models/suggestion";
import { GenlySuggestionJobError } from "@/errors/GenlySuggestionJobError";

const state = {
    adCreatives: EntityAdapter().createEntityAdapter(),
    adCreativeFilteredIds: [],
    expandedAdCreatives: EntityAdapter().createEntityAdapter(),
    // local ad creative preview
    adCreativePreviewData: {},
    // TODO: move to entity adapters if decided
    comments: {},
    activities: {},
    // END TODO:
    totals: null,
    loading: false,
    updatedAt: moment(),
    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1,
    },
    sort: {
        field: "spend",
        desc: true,
    },
};

const mutations = {
    [mt.SetAdCreatives](state, adCreatives)
    {
        state.adCreatives = state.adCreatives.setAll(
            adCreatives,
            state.adCreatives
        );
    },
    [mt.UpdateAdCreative](state, adCreative)
    {
        state.adCreatives = state.adCreatives.updateOne(
            adCreative,
            state.adCreatives
        );
    },
    [mt.SetAdCreativesTotals](state, totals)
    {
        state.totals = totals;
    },
    [mt.SetAdCreativeThumbnail](state, adCreative)
    {
        state.adCreatives = state.adCreatives.updateOne(
            adCreative,
            state.adCreatives
        );
    },
    [mt.AddAdCreative](state, adCreative)
    {
        state.adCreatives = state.adCreatives.upsertOne(
            adCreative,
            state.adCreatives
        );
    },
    [mt.UpsertManyAdCreatives](state, adCreatives)
    {
        state.adCreatives = state.adCreatives.upsertMany(
            adCreatives,
            state.adCreatives
        );
    },
    // TODO: decide if we add entity adapter for comments and activities
    [mt.AddAdCreativeComment](state, { id, comment })
    {
        if (state.comments[id])
            state.comments[id] = [comment, ...state.comments[id]];
        else state.comments = { ...state.comments, [id]: [comment] };
    },
    [mt.UpdateAdCreativeComments](state, { id, comments })
    {
        state.comments = { ...state.comments, [id]: comments };
    },
    [mt.UpdateAdCreativeActivities](state, { id, activities })
    {
        state.activities = { ...state.activities, [id]: activities };
    },
    // end TODO

    [mt.SetAdCreativeLoading](state, isLoading)
    {
        state.loading = isLoading;
    },
    [mt.SetAdCreativePaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = page.skip / page.top + 1;

        this.commit(mt.SetGlobalPaging, {
            currPage: state.page.currPage,
            totalResults: page.totalCount,
            currentResults: page.currentCount,
            totalPages: state.page.totalPages,
        });
    },
    [mt.SetAdCreativeCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
    [mt.SetAdCreativeSorting](state, { field, desc })
    {
        state.sort.field = field;
        state.sort.desc = desc;
    },
    [mt.RemoveAllAdCreatives](state)
    {
        state.adCreatives = state.adCreatives.removeAll(state.adCreatives);
    },
    [mt.RemoveAdCreative](state, id)
    {
        state.adCreatives = state.adCreatives.removeOne(id, state.adCreatives);
    },
    [mt.SetAdPreview](state, value)
    {
        state.adCreativePreviewData = value;
    },
    [mt.SetAdPreviewData](state, { key, value })
    {
        state.adCreativePreviewData[key] = value;
    },
    [mt.ResetAdPreviewData](state, initialState)
    {
        Object.keys(initialState).forEach((key) =>
        {
            state.adCreativePreviewData[key] = initialState[key];
        });
    },
    [mt.SetAdCreativeFilteredIds](state, data)
    {
        state.adCreativeFilteredIds = data;
    },
    [mt.RemoveAdCreativeFilteredId](state, id)
    {
        state.adCreativeFilteredIds = state.adCreativeFilteredIds.filter(
            (filteredId) => filteredId !== id
        );
    },
    [mt.SetAdStatsLastUpdatedAt](state)
    {
        state.updatedAt = moment();
    },
    [mt.UpsertAdCreativeExpanded](state, data)
    {
        state.expandedAdCreatives = state.expandedAdCreatives.upsertOne(
            data,
            state.expandedAdCreatives
        );
    },
    [mt.UpdateAdCreativeExpanded](state, data)
    {
        state.expandedAdCreatives = state.expandedAdCreatives.updateOne(
            data,
            state.expandedAdCreatives
        );
    },
    [mt.RemoveAllAdCreativeExpanded](state)
    {
        state.expandedAdCreatives = state.expandedAdCreatives.removeAll(
            state.expandedAdCreatives
        );
    },
};

const getters = {
    getAdCreativesIds: (state) => state.adCreatives.ids,
    getAdCreativesEntities: (state) => state.adCreatives.entities,
    getAdCreativeById: (state) => (id) => state.adCreatives.entities[id],
    getAdCreativeFilteredIds: (state) => state.adCreativeFilteredIds,

    getAdCreativeTotals(state)
    {
        return state.totals;
    },
    getAdCreativePage(state)
    {
        return state.page;
    },
    getAdCreativeSort(state)
    {
        return state.sort;
    },
    getAdCreativesLastUpdatedAt(state)
    {
        return state.updatedAt;
    },
    getCommentsForAdCreative(state)
    {
        return state.comments;
    },
    getActivitiesForAdCreative(state)
    {
        return state.activities;
    },
    getAdCreativeLoading(state)
    {
        return state.loading;
    },
    getAdPreviewData(state)
    {
        return state.adCreativePreviewData;
    },
    getAdCreativeExtendById: (state) => (id) =>
        state.expandedAdCreatives.entities[id],
    getNetworkAds:
        (state, getters, rootState, rootGetters) => (adCreativeId) =>
        {
            const adCreativeExpanded =
                getters.getAdCreativeExtendById(adCreativeId);
            if (!adCreativeExpanded) return;
            const { networkAdsIds } = adCreativeExpanded;
            return networkAdsIds
                ? networkAdsIds.map(rootGetters.getNetworkAdById)
                : [];
        },
};

const actions = {
    async fetchAdCreativeStats(
        { commit, state, rootGetters },
        filters = { currPage: 1 }
    )
    {
        try
        {
            commit(mt.SetAdCreativeLoading, true);
            commit(mt.SetLoading, true);
            let responseData = [];
            let responseDataTotals = null;
            let responseDataPaging = null;

            const stateParams = getFilters(state, filters);
            let response = [];
            if (
                !rootGetters.getAllFiltersCount("networkAdRoot") &&
                filters.currPage === 1
            )
            {
                let userBaseParams = getUserGroupBaseParams(
                    rootGetters.getUserGroup
                );
                if (userBaseParams)
                {
                    const stateStatusParams = getFilters(state, {
                        userBaseParams,
                        currPage: filters.currPage,
                    });
                    response = await Promise.all([
                        fetchStats(stateParams),
                        fetchStats(stateStatusParams),
                    ]);
                }
                else
                {
                    response = await Promise.all([fetchStats(stateParams)]);
                }
            }
            else
            {
                response = await Promise.all([await fetchStats(stateParams)]);
            }

            for (let index in response)
                if (
                    response[index] &&
                    response[index].status === 200 &&
                    response[index].data
                )
                {
                    responseData = [
                        ...responseData,
                        ...response[index].data.data,
                    ];
                    // set only if not requesting draft ads
                    if (index === "0")
                    {
                        responseDataTotals = response[index].data.totals;
                        responseDataPaging = response[index].data.pages;
                    }
                }

            if (responseData.length !== 0)
            {
                const adCreatives = [];
                responseData.forEach((adCreative) =>
                {
                    let adCreativeData = {
                        ...adCreative,
                        showUpRate: !isNaN(
                            adCreative.crmDemosPerformed /
                                adCreative.crmDemosSet
                        )
                            ? adCreative.crmDemosPerformed /
                              adCreative.crmDemosSet
                            : 0,
                        networkAdsIds: [],
                        thumbnailsIds: [],
                    };
                    adCreatives.push(adCreativeData);
                });
                const totals = createTotalizator(responseDataTotals);

                commit(mt.SetAdCreatives, adCreatives);
                commit(mt.SetAdCreativesTotals, totals);
                commit(mt.SetAdCreativePaging, {
                    ...responseDataPaging,
                    currentCount: responseData.length,
                });

                if (rootGetters.getAllFiltersCount("networkAdRoot"))
                    commit(
                        mt.SetAdCreativeFilteredIds,
                        adCreatives.map((data) => data.id)
                    );
                else commit(mt.SetAdCreativeFilteredIds, []);
            }
            else
            {
                commit(mt.SetAdCreatives, []);
                commit(mt.SetAdCreativeFilteredIds, []);
                commit(mt.SetAdCreativesTotals, null);

                commit(mt.SetAdCreativePaging, {
                    top: projConfig.itemsPerPage,
                    skip: 0,
                    totalCount: 0,
                    currentCount: 0,
                    currPage: 1,
                });
            }
        }
        catch (error)
        {
            throw error || new Error("Error getting ad creatives stats.");
        }
        finally
        {
            commit(mt.SetAdStatsLastUpdatedAt);
            commit(mt.SetLoading, false);
            commit(mt.SetAdCreativeLoading, false);
        }
    },
    async fetchAdCreativeDetails(
        { commit, rootGetters },
        { id, ...rest } = {}
    )
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetch({ id, ...rest });
            const adCreative = response.data;
            if (
                !adCreative.assetIds &&
                state.adCreatives.entities[adCreative.id] &&
                !state.adCreatives.entities[adCreative.id].assetIds
            )
                adCreative.assetIds = [];
            commit(mt.AddAdCreative, {
                ...adCreative,
                networkAdsIds: [],
                thumbnailsIds: [],
            });

            const adGroup = rootGetters.getAdGroupById(adCreative.adGroupId);
            if (adGroup)
            {
                const adCreativesIds = adGroup.adCreativesIds
                    ? adGroup.adCreativesIds
                    : [];
                commit(mt.UpdateAdGroup, {
                    id: adCreative.adGroupId,
                    changes: {
                        adCreativesIds: [
                            ...new Set([...adCreativesIds, adCreative.id]),
                        ],
                    },
                });
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    // TODO: Move to thumbnails repo if needed;
    async fetchAdCreativesPreviewForLandingPage(
        { rootState, commit, rootGetters },
        lpId,
        adCrIds = null
    )
    {
        const dateRange = rootGetters.getDateRangeFilters;

        let top = 1000;
        let skip = 0;
        let startDate = dateRange.start;
        let endDate = dateRange.end;
        let clId = rootState.filters.clientId;
        let cmpId = rootState.filters.campaignId;
        let netCmpId = rootState.filters.networkCmpId;
        let batchNum = rootState.filters.batches.currNum;

        const params = removeEmpty({
            top,
            skip,
            startDate: convertMomentDateToTimestamp(startDate),
            endDate: convertMomentDateToTimestamp(endDate),
            clId,
            cmpId,
            batchNum,
            lpId,
            netCmpId,
            adCrIds,
        });
        commit(mt.SetLoading, true);

        const response = await fetchPreviews({ params });
        if (response && response.status === 200 && response.data)
        {
            const previews = response.data.data
                ? response.data.data.map(
                    landingPageAdCreativePreviewDtoToObject
                )
                : [];

            commit(mt.UpsertManyAdCreativePreviewsForLandingPage, previews);

            const landingPage = rootGetters.getLandingPageById(lpId);
            if (landingPage)
            {
                const adCreativesPreviewIds = previews.map(
                    (preview) => preview.id
                );
                commit(mt.UpdateLandingPage, {
                    id: lpId,
                    changes: {
                        adCreativesPreviewIds,
                    },
                });
            }
            commit(mt.SetLoading, false);
        }
        else
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchAdCreativePreview({ commit, getters }, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const types = [
                "desktopStandard",
                "mobileStandard",
                "mobileBanner",
                "mobileNative",
                "instagramStandard",
            ];

            const response = await Promise.all(
                types.map((type) => fetchPreviewOfType({ id, type }))
            );

            const previews = response.map((previewHtml, index) =>
                adCreativePreviewDtoToObject({
                    id,
                    preview: previewHtml,
                    type: types[index],
                })
            );

            commit(mt.UpsertThumbnailsForAdCreative, previews);

            const adCreative = getters.getAdCreativeById(id);
            const previewsIds = previews.map((preview) => preview.id);

            commit(mt.UpdateAdCreative, {
                id,
                changes: {
                    thumbnailsIds: [
                        ...adCreative.thumbnailsIds,
                        ...previewsIds,
                    ],
                    updatedAtLocal: new Date().getTime(),
                },
            });
        }
        catch (error)
        {
            console.error(error);
            if (
                error &&
                error.request &&
                (error.request.status === 404 || error.request.status === 504)
            )
                // if needed throw custom error
                throw error;

            return error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchAdCreativesForAdGroup(
        { commit, rootGetters },
        { id: netCmpGrpId, ...rest } = {}
    )
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await fetchAll(
                {
                    netCmpGrpId,
                },
                rest
            );

            if (
                response &&
                response.data &&
                Array.isArray(response.data.data)
            )
            {
                const adCreatives = response.data.data.map((adCreative) =>
                {
                    return {
                        ...adCreative,
                        networkAdsIds: [],
                        thumbnailsIds: [],
                    };
                });
                let adCreativesIds = response.data.data.map(
                    (adCreative) => adCreative.id
                );

                commit(mt.UpsertManyAdCreatives, adCreatives);

                const adGroup = rootGetters.getAdGroupById(netCmpGrpId);
                if (adGroup)
                {
                    let adGroupIds = adGroup.adCreativesIds
                        ? adGroup.adCreativesIds
                        : [];
                    commit(mt.UpdateAdGroup, {
                        id: netCmpGrpId,
                        changes: {
                            adCreativesIds: [
                                ...new Set([...adCreativesIds, ...adGroupIds]),
                            ],
                        },
                    });
                }
            }
            else
            {
                console.log(new Error(response));
            }
        }
        catch (error)
        {
            throw error.data ? error.data : error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async createNewAd({ commit, rootGetters }, adData)
    {
        try
        {
            commit(mt.SetLoading, true);

            const response = await create(adData);
            if (response.status !== StatusCodes.OK) throw response;

            const adCreative = response.data;
            const adCreativeIds = [];

            adCreative.forEach((adCreativeData) =>
            {
                commit(mt.AddAdCreative, {
                    ...adCreativeData,
                    // use for local sorting
                    createdAtLocal: new Date().getTime(),
                    networkAdsIds: [],
                });
                adCreativeIds.push(adCreativeData.id);
            });

            if (rootGetters.getAllFiltersCount("networkAdRoot"))
                adCreative.forEach((adCreativeData) =>
                {
                    commit(mt.SetAdCreativeFilteredIds, [
                        ...new Set([
                            adCreativeData.id,
                            ...state.adCreativeFilteredIds,
                        ]),
                    ]);
                });
            // checking only the first one because we have same adGroupId
            const adGroup = rootGetters.getAdGroupById(adCreative[0].adGroupId);
            if (adGroup)
                adCreative.forEach((adCreative) =>
                {
                    commit(mt.UpdateAdGroup, {
                        id: adCreative.adGroupId,
                        changes: {
                            adCreativesIds: [
                                adCreative.id,
                                ...adGroup.adCreativesIds,
                            ],
                        },
                    });
                });

            // return newly created ids
            return adCreativeIds;
        }
        catch (error)
        {
            switch (error.status)
            {
                case StatusCodes.BAD_REQUEST:
                    throw new NetworkCreateError(error.data);
                case StatusCodes.INTERNAL_SERVER_ERROR:
                    throw new GenlyCreateError(
                        error.data || "Error creating ad"
                    );
                default:
                    throw new Error("Error creating ad");
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateAd({ commit }, { id, data })
    {
        try
        {
            commit(mt.SetLoading, true);
            delete data.status; //(DEV-2975)
            const response = await update(id, data);

            const adCreative = adCreativeDtoToObject(response.data);
            commit(mt.UpdateAdCreative, {
                id,
                changes: {
                    ...adCreative,
                    updatedAtLocal: new Date().getTime(),
                },
            });
        }
        catch (error)
        {
            if (error.response.status === StatusCodes.BAD_REQUEST)
                throw new AdCreateError(
                    "Ad has impressions or is running. Please create a new one"
                );

            switch (error.status)
            {
                case 400:
                    throw new NetworkCreateError(error.data);
                case 500:
                    throw new GenlyCreateError("Error updating ad");
                default:
                    throw new Error("Error updating ad");
            }
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async updateAdCreativeStatus({ commit, getters }, statusParams)
    {
        try
        {
            commit(mt.SetLoading, true);
            const { id, ...rest } = statusParams;
            const statusJobResponse = await updateStatus(id, {
                ...rest,
            });

            const statusJob = statusJobResponse.data;

            // add jobid to localstorage

            const localStorageObservable =
                LocalStorageObservableService.getInstance();
            const jobs = localStorageObservable.get(
                LocalStorageService.AD_CREATIVE_STATUS_JOBS
            );

            localStorageObservable.set(
                LocalStorageService.AD_CREATIVE_STATUS_JOBS,
                {
                    ...jobs,
                    [id]: new PendingJob({
                        metadata: {
                            name: getters.getAdCreativeById(id).name,
                        },
                        jobId: statusJob?.jobId,
                        status: statusParams.status,
                        netCmpGrpId: statusParams.adGroupId,
                    }),
                }
            );
        }
        catch (error)
        {
            if (error.data)
            {
                // handle different types of error, some are returned as a json object, some as regular string messages
                const isJson = isJsonString(error.data);

                if (isJson)
                {
                    const { issues } = JSON.parse(error.data);
                    // error happened in external network activation
                    throw new NetworkCreateError(issues);
                }
                // error happened on genly's side
                throw new NetworkCreateError(error.data, error);
            }
            throw new Error(error);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async checkAdCreativeStatus({ commit }, { id, jobId })
    {
        const localStorageObservable =
            LocalStorageObservableService.getInstance();
        const jobs = localStorageObservable.get(
            LocalStorageService.AD_CREATIVE_STATUS_JOBS
        );

        try
        {
            const statusJobResponse = await checkStatus(id, {
                jobId,
            });

            const statusJob = statusJobResponse.data;

            if (statusJob.status === JOB_STATUS.DONE)
            {
                // fire when status job is realized
                const status = jobs[id].status;
                const netCmpGrpId = jobs[id].netCmpGrpId;

                // update local status of the ad
                commit(mt.UpdateAdCreative, {
                    id,
                    changes: { status, updatedAtLocal: new Date().getTime() },
                });

                if (status === STATUSES.RUNNING && netCmpGrpId)
                {
                    const url = "/s/network-campaign-groups/stats";
                    const stateParams = removeEmpty({
                        top: 50,
                        skip: 0,
                        netCmpGrpId,
                    });

                    const adGroupResponse = await get(url, {
                        params: { ...stateParams },
                    });

                    commit(mt.UpsertOneAdGroupsDetails, {
                        ...createColumnizator(adGroupResponse.data.data).map(
                            adGroupDtoToObject
                        )[0],
                        createdAtLocal: new Date().getTime(),
                    });
                }

                return ENTITY_SUCCESS_STATE.UPDATED;
            }
            else if (statusJob.status === JOB_STATUS.ERROR)
            {
                throw new Error(statusJob.error);
            }
        }
        catch (error)
        {
            let errMsg;

            errMsg = error.data
                ? (errMsg = error.data)
                : !error.request
                    ? error.message
                    : null;

            throw new Error(errMsg);
        }
    },

    async generateContent({ commit }, contentData)
    {
        try
        {
            const { id } = contentData;
            commit(mt.SetLoading, true);
            const suggestionResponse = await createSuggestions(
                objectToSuggestionDto(contentData)
            );

            const suggestionJob = suggestionResponse.data;

            // add jobid to localstorage

            const localStorageObservable =
                LocalStorageObservableService.getInstance();
            const jobs = localStorageObservable.get(
                LocalStorageService.AD_CREATIVE_SUGGESTIONS_JOBS
            );

            const { jobId, sessionId } = suggestionJob;

            localStorageObservable.set(
                LocalStorageService.AD_CREATIVE_SUGGESTIONS_JOBS,
                {
                    ...jobs,
                    [id]: new PendingJob({
                        metadata: {
                            sessionId,
                        },
                        jobId,
                    }),
                }
            );

            return suggestionJob;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async generateContentForField({ commit }, contentFieldData)
    {
        try
        {
            const { id, field, index, sessionId } = contentFieldData;

            commit(mt.SetLoading, true);
            const suggestionResponse = await createSuggestionsForField(sessionId, objectToSuggestionFieldDto(contentFieldData));

            const suggestionJob = suggestionResponse.data;

            // add jobid to localstorage

            const localStorageObservable =
                LocalStorageObservableService.getInstance();
            const jobs = localStorageObservable.get(
                LocalStorageService.AD_CREATIVE_SUGGESTIONS_JOBS
            );

            const { jobId } = suggestionJob;

            localStorageObservable.set(
                LocalStorageService.AD_CREATIVE_SUGGESTIONS_JOBS,
                {
                    ...jobs,
                    [id]: new PendingJob({
                        jobId,
                        metadata: {
                            index,
                            field
                        },
                    }),
                }
            );

            return suggestionJob;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },

    async checkAdCreativeSuggestionStatus({}, { jobId })
    {
        try
        {
            const suggestionJobResponse = await checkSuggestionsStatus(jobId);

            const suggestion = suggestionDtoToObject(suggestionJobResponse.data);

            if ([JOB_STATUS.DONE, JOB_STATUS.RUNNING].includes(suggestion.status))
            {
                // console.log(suggestionJobDtoToObject(
                //     suggestion.jobResult
                // ), suggestion);

                const jobResult = suggestionJobDtoToObject(
                    suggestion.jobResult
                );

                return {
                    jobResult,
                    state: suggestion.status === JOB_STATUS.DONE ? ENTITY_SUCCESS_STATE.UPDATED : ENTITY_SUCCESS_STATE.DEFAULT
                };
            }
            else if (suggestion.status === JOB_STATUS.ERROR)
            {
                throw new GenlySuggestionJobError({
                    name: "JOB_SUGGESTION_ERROR",
                    message: suggestion.error,
                    jobResult: suggestionJobDtoToObject(suggestion.jobResult)
                });
            }
        }
        catch (error)
        {
            if(error instanceof GenlySuggestionJobError)
                throw error;

            let errMsg;

            errMsg = error.data
                ? (errMsg = error.data)
                : !error.request
                    ? error.message
                    : null;

            throw new Error(errMsg);
        }
    },

    async createNewComment({ commit, rootGetters }, { id, comment })
    {
        try
        {
            commit(mt.SetLoading, true);
            const response = await createComment({ id, comment });
            const newComment = response.data;

            commit(mt.AddAdCreativeComment, {
                id,
                comment: commentDtoToObject({
                    ...newComment,
                    createdBy: rootGetters.getUserDisplayName,
                }),
            });
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async fetchCommentsForAd({ commit }, { id } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const { data = [] } = await fetchComments({
                id,
                isThrowable: true,
            });
            const comments = data.map(commentDtoToObject);
            commit(mt.UpdateAdCreativeComments, {
                id,
                comments,
            });
            commit(mt.SetLoading, false);
        }
        catch (error)
        {
            commit(mt.SetLoading, false);
        }
    },

    async fetchActivityForAd({ commit }, { id } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const { data = [] } = await fetchActivities({
                id,
                isThrowable: true,
            });
            commit(mt.SetLoading, false);
            const activities = data.map(activityDtoToObject);

            commit(mt.UpdateAdCreativeActivities, { id, activities });
        }
        catch (error)
        {
            commit(mt.SetLoading, false);
        }
    },

    async toggleMultipleNetworkAdsDelivery({ commit }, { id, ids, turnOn })
    {
        try
        {
            let actionString = turnOn ? "play" : "pause";
            commit(mt.SetLoading, true);
            const response = await toggle({ id, action: actionString });

            if (
                response &&
                response.status &&
                [200, 204].includes(response.status)
            )
            {
                // if all is good, commit to state
                if (ids)
                    ids.forEach((id) =>
                    {
                        commit(mt.UpdateAdCreative, {
                            id,
                            changes: {
                                isActive: turnOn,
                                updatedAtLocal: new Date().getTime(),
                            },
                        });
                    });

                commit(mt.UpdateAdCreative, {
                    id,
                    changes: {
                        isActive: turnOn,
                        updatedAtLocal: new Date().getTime(),
                    },
                });
            }

            commit(mt.SetLoading, false);
        }
        catch (error)
        {
            commit(mt.SetLoading, false);

            let errMsg = error.response
                ? (errMsg = error.error.data)
                : !error.request
                    ? error.message
                    : null;

            throw new Error(errMsg);
        }
    },

    async loadAdCreativeAndSetFilter({ dispatch, commit, getters }, id)
    {
        try
        {
            let adCreative = getters.getAdCreativeById(id);
            if (!adCreative)
            {
                await dispatch("fetchAdCreativeDetails", { id });
                adCreative = getters.getAdCreativeById(id);
            }

            // legacy mutation
            commit(mt.SetAdCreativeFilter, {
                id: adCreative.id,
                name: adCreative.name,
            });
            commit(mt.SetObjectFilter, {
                id: adCreative.id,
                name: adCreative.name,
                type: enums.OBJECT_FILTERS.AD_CREATIVE,
            });
            return adCreative;
        }
        catch (error)
        {
            console.error(error);
        }
    },
};

const AdCreativeDataModule = {
    state,
    mutations,
    getters,
    actions,
};

export default AdCreativeDataModule;
