import {setDtoBase} from '@/models/DtoBase';
import {flowRight} from "lodash";
import { LandingPageFields} from "./landingPageFields.js";
import { LandingPageFieldsDto } from "./landingPageFieldsDto.js";
import { removeEmpty } from "@/helpers/objectUtils";

export const landingPageFieldsDtoToObject = dto =>
{
    const object = LandingPageFields(dto);
    return object;
};

export const objectToLandingPageFieldsDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, LandingPageFieldsDto)(data);
    return dto;
};
