"use strict";

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'mutationobserver-shim';

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

import projConfig from './projConfig';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App';
import router from './router.js';
import VueRouter from 'vue-router';
import store from './store/store.js';
import VueLocalStorage from 'vue-ls'; // this also sets up local storage
import VeeValidate from 'vee-validate';
import VueTippy, { TippyComponent } from "vue-tippy";
import MQ from 'vue-match-media/src';
import vuescroll from 'vue-scroll';
import 'bootstrap';
import './faInit';
import jQuery from 'jquery';
import Popper from 'popper.js';
import GlobalComponents from "./globalComponents";
import VueRx from 'vue-rx';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import Inview from 'vueinview';
import imageFallback from "@/directives/imageFallback";
import permissions from "@/directives/permissions";
import * as FullStory from '@fullstory/browser';
import WebSocketClient from "@/services/webSocket";
import ImageEditorLibrary from "preciseos.admin.image-editor";
import {WEBSOCKET_ABNORMAL_ERROR_CODE} from "@/services/webSocket/enums";
import { TOKEN } from './helpers/enums/enums';
import { LocalStorageObservableService } from './services/localStorageObservableService';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { refresh } from './services/requestHelper.service';

window.Popper = Popper.default;
window.$ = window.jQuery = jQuery;
window.moment = moment;
window.VueEvent = new Vue();

window.$.extend(true, window.$.fn.datetimepicker.defaults, {
    icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-chevron-up',
        down: 'fas fa-chevron-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
    }
});

const VueCharts = () =>
    import ('vue-chartjs');


// The Plugin API gives you access to the ErrorBag prototype.
VeeValidate.use(({ ErrorBag }) =>
{
    // get the original method.
    const first = ErrorBag.prototype.first;
    ErrorBag.prototype.first = function(...args)
    {
        // call it after binding it to the error bag instance.
        const msg = first.bind(this)(...args);

        // if there is a message returned, change the style.
        if (msg && msg.indexOf("-"))
        {
            const field = args[0].split("-");
            return msg.replace(/^The .+field/i, `The ${_.startCase(field[0])} field`);
        }

        return msg;
    };
});

// This package takes care of checking whether localStorage is available.
// Also injects $ls object for use everywhere.
Vue.use(VueLocalStorage);

// only connect to websocket if user is logged in and refreshes
LocalStorageObservableService.getInstance()
    .watch(TOKEN)
    .pipe(
        filter(token => !!token),
        startWith(null), // using it with null|undefined is not deprecated https://stackoverflow.com/posts/61834849/revisions
        pairwise(),
        filter(([previousToken, currentToken]) => previousToken !== currentToken)
    )
    .subscribe(() =>
    {
        Vue.use(WebSocketClient);

        const websocket = Vue.prototype.$websocket;
        if(websocket.isConnected())
            websocket.disconnect();

        websocket.connect(
            {
                url: projConfig.websocket,
                options: {
                    reconnectEnabled: true
                }
            });

        if(websocket.onCloseFetchToken)
            return;

        websocket.onCloseFetchToken = async (event) =>
        {
            const {code} = event;
            if (code === WEBSOCKET_ABNORMAL_ERROR_CODE)

                try
                {
                    await refresh();
                }
                catch(error)
                {
                    // don't do anything on websocket refresh token errors
                    console.error(error.message, error.cause);
                    Sentry.captureException(new Error(`[onCloseFetchToken] exchangeRefreshToken from web socket `), {
                        tags: {
                            section: "errors",
                        },
                    });
                }
            else
                websocket.reconnect();

        };
    });

Vue.use(VueCharts);
Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: 'veeFields'
});
Vue.use(MQ);
Vue.use(vuescroll);
Vue.use(GlobalComponents);
Vue.use(VueRx);
Vue.use(Inview);
Vue.use(VueRouter);
// install and overwrite default tippy values
Vue.use(VueTippy, {
    arrow: true,
    followCursor: 'horizontal',
    boundary: 'viewport',
    delay: [800, 20]
});

Vue.use(ImageEditorLibrary);

Vue.component("Tippy", TippyComponent);
Vue.directive('image-fallback', imageFallback);
Vue.directive('permissions', permissions);

//define a custom validation
VeeValidate.Validator.extend('ipCheck', {
    // eslint-disable-next-line no-unused-vars
    getMessage: field => 'The ip address must be a valid ipv4/ipv6 address',
    validate: value => /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(value)
});
// stick our own config into a custom instance prop
Vue.prototype.$projConfig = projConfig;
// define media query breakpoints
const mq = {
    xs: '(max-width: 767px)',
    sm: '(min-width: 768px)',
    md: '(min-width: 992px)',
    mdlg: '(min-width: 1024px)',
    lg: '(min-width: 1200px)',
    lgm: '(min-width: 1280px)'
};

// disabled since it's defined in webpack.config.js
// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;

if (env === "production" || env === "stg")
{
    Sentry.init({
        // disabled since it's defined in webpack.config.js
        // eslint-disable-next-line no-undef
        dsn: process.env.SENTRY_DSN,
        integrations: [
            new VueIntegration({
                Vue,
                tracing: true,
            }),
            new Integrations.BrowserTracing({
                beforeNavigate: (context) =>
                {
                    try
                    {
                        let newName = window.location.pathname;
                        if (window.location.hash)
                            newName += window.location.hash.replace('#/', '');

                        // change name to contain hash portion
                        context.name = newName;
                    }
                    catch (e)
                    {
                        console.error('beforeNavigate error:', e);
                    }

                    return context;
                }
            }),
        ],
        // disabled since it's defined in webpack.config.js
        // eslint-disable-next-line no-undef
        environment: process.env.NODE_ENV,
        // disabled since it's defined in webpack.config.js
        // eslint-disable-next-line no-undef
        release: "preciseos.admin@" + VERSION,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,

        autoSessionTracking: true,
    });

    FullStory.init({ orgId: '192ZRV' });
    Vue.prototype.$FullStory = FullStory;
}
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>',
    mq
});
