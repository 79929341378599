import {setDtoBase} from '@/models/DtoBase';
import { flowRight, curry } from "lodash";
import { AdAsset } from '@/models/ad-asset/adAsset';
import { AdAssetDto } from '@/models/ad-asset/adAssetDto';
import { removeEmpty } from "@/helpers/objectUtils";

const getSize = {
    0: "1200x627",
    1: "627x627",
    2: "180x180"
};

const getOrder = {
    "1200x627": 0,
    "627x627": 1,
    "180x180": 2
};

const setOrder = object => Object.assign({}, object, { order: getOrder[object.size] });
const setSize = curry((index, object) => Object.assign({}, object, { size: object.size ? object.size : getSize[index] }));

export const adAssetDtoToObject = (dto, index) =>
{
    const object = flowRight(
        setOrder,
        setSize(index),
        AdAsset
    )(dto);
    return object;
};

export const objectToAdAssetDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, AdAssetDto)(data);
    return dto;
};
