import Vue from 'vue';

import BootstrapVue, {
    BCarousel,
    BCarouselSlide,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavbarToggle,
    BNav,
    BTabs,
    BNavItem,
    BNavForm,
    BNavItemDropdown,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BModal,
    BBreadcrumb,
    BBreadcrumbItem
} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);

Vue.component('BModal', BModal);
Vue.component('BTabs', BTabs);
Vue.component('BNavbar', BNavbar);
Vue.component('BCarousel', BCarousel);
Vue.component('BCarouselSlide', BCarouselSlide);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BNav', BNav);
Vue.component('BNavItem', BNavItem);
Vue.component('BNavForm', BNavForm);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BCollapse', BCollapse);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BBreadcrumbs', BBreadcrumb);
Vue.component('BBreadcrumbItem', BBreadcrumbItem);