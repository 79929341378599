export function AdCreativeStatusJob({
    id,
    status,
    error
})
{
    return {
        id,
        status,
        error
    };
}
