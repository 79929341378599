import { TOKEN } from '@/helpers/enums/enums';
import {LocalStorageService} from '@/services/localStorageService';
import {WEBSOCKET_MESSAGES} from '@/services/webSocket/enums';
export class WebSocketService
{
    constructor()
    {
        this.websocket = null;
        this.repeat = 0;
        this.lastAction = 0;
        // define methods in components e.g. this.$websocket.[method]
        this.onOpen = null;
        this.onError = null;
        this.onClose = null;
        this.onCloseFetchToken = null;
        this.onMessage = null;
    }

    defaultOptions()
    {
        return {
            reconnectEnabled: false,
            reconnectInterval: 1000,
            reconnectCap: 5,
            pingTimeout: 120000, // 2min timeout for ping heartbeat
            pongTimeout: 10000, // 10sec wait time on server response
        };
    }

    connect({url, options} = {})
    {
        this.url = url;
        this.options = {
            ...this.defaultOptions(),
            ...options
        };

        const token = LocalStorageService.getInstance().get(TOKEN);
        if(!token || this.isConnected())
            return;
        try
        {
            this.websocket = new WebSocket(`${this.url}?token=${encodeURIComponent(token)}`);

            this.websocket.onopen = event =>
            {
                if (typeof this.onOpen === 'function')
                    this.onOpen(event);

                this.repeat = 0;
                this.heartbeatCheck();
            };

            this.websocket.onclose = event =>
            {
                if (typeof this.onClose === 'function')
                    this.onClose(event);

                if (!event.wasClean && this.options.reconnectEnabled && this.onCloseFetchToken)
                    this.onCloseFetchToken(event);
            };

            this.websocket.onerror = event =>
            {
                if (typeof this.onError === 'function')
                    this.onError(event);
            };

            this.websocket.onmessage = (msg) =>
            {
                if (typeof this.onMessage === 'function')
                    this.onMessage(JSON.parse(msg.data));

                this.heartbeatCheck();
            };
        }
        catch (error)
        {
            console.error(error);
        }
    }

    reconnect(isRefreshingToken = false)
    {
        if(this.options.reconnectCap > 0 && this.options.reconnectCap <= this.repeat) return;
        this.websocket = null;
        if (isRefreshingToken)
        {
            this.connect({
                url: this.url,
                options: this.options
            });
            return;
        }
        this.repeat++;
        setTimeout(() =>
        {
            this.connect({
                url: this.url,
                options: this.options
            });
        }, this.options.reconnectInterval);
    }

    send(action)
    {
        if(!this.websocket) return;
        this.lastAction = action;
        this.websocket.send(action);
    }

    sendObj(data)
    {
        if(!this.websocket) return;
        this.lastAction = data.action;
        this.websocket.send(JSON.stringify(data));
    }

    heartbeatCheck()
    {
        this.heartbeatReset();
        this.heartbeatStart();
    }

    // no ping functionality is exposed on websocket API, so we use a regular message to keep the connection alive
    heartbeatStart()
    {
        this.pingTimeoutId = setTimeout(() =>
        {
            this.sendObj({
                action: WEBSOCKET_MESSAGES.PING
            });
            this.pongTimeoutId = setTimeout(() =>
            {
                if(!this.websocket) return;
                this.websocket.close();
            }, this.options.pongTimeout);
        }, this.options.pingTimeout);
    }

    heartbeatReset()
    {
        clearTimeout(this.pingTimeoutId);
        clearTimeout(this.pongTimeoutId);
    }

    isConnected()
    {
        return this.websocket && ![WebSocket.CLOSED, WebSocket.CLOSING, WebSocket.CONNECTING].includes(this.websocket.readyState);
    }

    isOpenOrClosed()
    {
        return this.websocket && [WebSocket.CLOSED, WebSocket.OPEN].includes(this.websocket.readyState);
    }

    isClosingOrConnecting()
    {
        return this.websocket && [WebSocket.CLOSING, WebSocket.CONNECTING].includes(this.websocket.readyState);
    }

    isOpen()
    {
        return this.websocket && [WebSocket.OPEN].includes(this.websocket.readyState);
    }

    isClosed()
    {
        return this.websocket && [WebSocket.CLOSED].includes(this.websocket.readyState);
    }

    disconnect()
    {
        this.heartbeatReset();
        this.lastAction = null;
        this.websocket.close();
        this.websocket = null;
    }

    destroy()
    {
        this.onOpen = null;
        this.onMessage = null;
        this.onClose = null;
        this.onError = null;
        this.lastAction = null;
        this.disconnect();
    }
}
