import {removeEmpty} from '@/helpers/objectUtils';

export default function Column({
    name = "",
    value = true,
    selectable = true,
    siblingToggle = null,
    notAvailableForCol = null,
    visible = []
} = {})
{
    return removeEmpty({
        name,
        value,
        selectable,
        siblingToggle,
        notAvailableForCol,
        visible
    });
}
