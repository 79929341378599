import { removeEmpty } from "@/helpers/objectUtils";
import { get, post, put } from "@/services/httpService";
import { createUrlFiltersParameters, createUrlObjectFilters } from "@/helpers/urlUtils";
import store from "@/store/store";

const resource = "/s/users";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData = { ...store.state.filters, timeZoneDates };
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort,);
    return {
        objectFilters,
        filterParams
    };
};
const getFilters = ({ page, sort, currPage } = {}, filters) =>
{
    let { top, skip } = page;
    const { field, desc } = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    return removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams
    });
};

const fetch = (params) =>
{
    const { username } = params;
    return get(`${resource}/${username}`, {
        isThrowable: true,
    });
};

const fetchAll = () =>
{
    return get(`${resource}`, {
        isThrowable: true,
    });
};

const create = (params) =>
{
    return post(`${resource}`, params);
};

const update = (params) =>
{
    const { username } = params;
    return put(`${resource}/${username}`, params);
};

const fetchSettings = (params) =>
{
    const { username } = params;
    return get(`${resource}/${username}/settings`);
};

const createSettings = (params) =>
{
    const { username, settings } = params;
    return post(`${resource}/${username}/settings`, {
        settings,
    });
};

const updateSettings = (params) =>
{
    const { username, settings } = params;
    return put(`${resource}/${username}/settings`, {
        ...settings
    });
};

const fetchAssetSignedUrl = (params) =>
{
    const { name, type, username } = params;
    return post(`${resource}/${username}/images`, {
        filename: name,
        filetype: type,
    });
};

const getFiltersEvents = (state, filters) =>
{
    const {eventsPaging, eventsSort} = state;
    let {top, skip, currPage} = eventsPaging;
    const {field = null, desc = null} = eventsSort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    return removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc
    });
};

const fetchEvents = (state, filters) =>
{
    return get(`${resource}/events`, {
        params: {
            ...getFiltersEvents(state, filters)
        }
    });
};

const fetchStats = (state, filters) =>
{
    return get(`${resource}/stats`, {
        params: {
            ...getFilters(state, filters)
        }
    });
};

export {
    create,
    createSettings,
    fetch,
    fetchAll,
    fetchAssetSignedUrl,
    fetchSettings,
    update,
    updateSettings,
    fetchEvents,
    fetchStats,
};
