import { adGroupDtoToObject } from '@/models/ad-group';
import { adSetDtoToObject } from '@/models/ad-set';
import { audienceDtoToObject } from "@/models/audience";
import { campaignDtoToObject, objectToCampaignDto } from "@/models/campaign";
import { campaignSchedulesDtoToObject } from "@/models/campaign-schedules";
import { clientDtoToObject, objectToClientDto } from "@/models/client";
import { landingPageDtoToObject, objectToLandingPageDto } from "@/models/landing-page";
import { objectToLandingPageFieldsDto } from "@/models/landing-page-fields";
import { formQuestionDtoToObject } from "@/models/landing-page-form-questions";
import { landingPageTargetDtoToObject, objectToLandingPageTargetDto } from "@/models/landing-page-target";
import { leadDtoToObject, objectToLeadDto } from '@/models/lead';
import { networkAdDtoToObject } from '@/models/network-ad';
import { networkCampaignStatDtoToObject, objectToNetworkCampaignStatDto } from '@/models/network-campaign';
import { objectToOfferDto, offerDtoToObject } from "@/models/offer";
import { objectToPrivacyPolicyDto, privacyPolicyDtoToObject } from "@/models/privacy-policy";
import { objectToTrackingParametersDto, trackingParametersDtoToObject } from "@/models/tracking-parameters";
import { AdCreativeRequestFactory } from "@/services/transformers/adCreativeRequestFactory";
import { AdCreativeResponseFactory } from '@/services/transformers/adCreativeResponseFactory';
import { AudienceRequestFactory } from './audienceRequestFactory';

class ResponseMapper
{
    transformer;
    constructor({transformer, shouldTransform = () => true})
    {
        this.transformer = transformer;
        this.shouldTransform = shouldTransform;
    }
}

const responseMappings = {
    clients: new ResponseMapper({
        transformer: clientDtoToObject,
        shouldTransform: value => value.indexOf("integrations") === -1 && value.indexOf("billing") === -1
    }),
    // thumbnails use same endpoint with __preview__ route,
    // so we don't do transform on those
    offers: new ResponseMapper({
        transformer: offerDtoToObject,
        shouldTransform: value => value.indexOf("preview") === -1
    }),
    // thumbnails use same endpoint with __preview__ route,
    // so we don't do transform on those
    "landing-pages": new ResponseMapper({
        transformer: landingPageDtoToObject,
        shouldTransform: value => value.indexOf("preview") === -1
    }),
    "privacy-policies": new ResponseMapper({
        transformer: privacyPolicyDtoToObject
    }),
    "landing-page-targets": new ResponseMapper({
        transformer: landingPageTargetDtoToObject
    }),
    "landing-page-fields": new ResponseMapper({
        transformer: formQuestionDtoToObject
    }),
    "lp-tracking-parameters": new ResponseMapper({
        transformer: trackingParametersDtoToObject
    }),
    "ad-creatives": new ResponseMapper({
        transformer: {
            factory: AdCreativeResponseFactory().create
        },
        shouldTransform: value => value.indexOf("preview") === -1 && value.indexOf("comments") === -1 && value.indexOf("activities") === -1 && value.indexOf("suggestions") === -1
    }),
    "audiences": new ResponseMapper({
        transformer: audienceDtoToObject,
        shouldTransform: value => value.indexOf("network-audiences") === -1
    }),
    campaigns: new ResponseMapper({
        transformer: campaignDtoToObject,
        shouldTransform: value => value.indexOf("networks") === -1 && value.indexOf("toggle") === -1
    }),
    "campaign-schedules": new ResponseMapper({
        transformer: campaignSchedulesDtoToObject,
    }),
    "network-ads": new ResponseMapper({
        transformer: networkAdDtoToObject,
        shouldTransform: value => value.indexOf("preview") === -1
    }),
    "network-campaigns": new ResponseMapper({
        transformer: networkCampaignStatDtoToObject,
        shouldTransform: value => value.indexOf("duplicate") === -1
    }),
    "network-campaign-groups": new ResponseMapper({
        transformer: adGroupDtoToObject
    }),
    "network-ad-sets": new ResponseMapper({
        transformer: adSetDtoToObject
    }),
    leads: new ResponseMapper({
        transformer: leadDtoToObject
    })
};

class RequestMapper
{
    transformer;
    data;
    constructor({transformer})
    {
        this.transformer = transformer;
    }

    // eslint-disable-next-line no-dupe-class-members
    set data(data)
    {
        this.data = data;
    }

    // eslint-disable-next-line no-dupe-class-members
    get data()
    {
        return this.data;
    }

    shouldTransform()
    {
        return this.data && !Object.hasOwn(this.data, "__stamp");
    }

    clearStamp()
    {
        if(!this?.data?.__stamp)
            return;
        delete this.data.__stamp;
    }
}

class RequestFactoryMapper extends RequestMapper {}

const requestMappings = {
    clients: new RequestMapper({
        transformer: objectToClientDto
    }),
    offers: new RequestMapper({
        transformer: objectToOfferDto
    }),
    "landing-pages": new RequestMapper({
        transformer: objectToLandingPageDto
    }),
    "landing-page-fields": new RequestMapper({
        transformer: objectToLandingPageFieldsDto
    }),
    "privacy-policies": new RequestMapper({
        transformer: objectToPrivacyPolicyDto
    }),
    "landing-page-targets": new RequestMapper({
        transformer: objectToLandingPageTargetDto
    }),
    "lp-tracking-parameters": new RequestMapper({
        transformer: objectToTrackingParametersDto
    }),
    "ad-creatives": new RequestFactoryMapper({
        transformer: AdCreativeRequestFactory().create
    }),
    "audiences": new RequestFactoryMapper({
        transformer: AudienceRequestFactory().create,
    }),
    campaigns: new RequestMapper({
        transformer: objectToCampaignDto
    }),
    "network-campaigns": new RequestMapper({
        transformer: objectToNetworkCampaignStatDto
    }),
    leads: new RequestMapper({
        transformer: objectToLeadDto
    }),
};


export
{
    RequestFactoryMapper, RequestMapper, ResponseMapper,
    requestMappings,
    responseMappings
};
