import {removeDoubleSymbols} from "@/helpers/stringUtils";
import {TOAST_HIDE} from "@/components/performance/constants";

export default {
    methods:
    {
        handleError(error, config)
        {
            const errorMessage = `${error}`;
            this.$bvToast.toast(
                `${removeDoubleSymbols(errorMessage)}`,
                Object.assign(
                    {
                        toastClass: "notify mb-3",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        autoHideDelay: TOAST_HIDE.TIMER_FOR_ERROR
                    },
                    config
                )
            );
        },
        handleWarning(warning, config)
        {
            const warningMessage = `${warning}`;
            this.$bvToast.toast(
                `${removeDoubleSymbols(warningMessage)}`,
                Object.assign({
                    toastClass: "notify mb-3",
                    variant: "warning",
                    toaster: "b-toaster-bottom-right"
                },
                config
                )
            );
        },
        handleSuccess(message,config)
        {
            const successMessage = `${message}.`;
            this.$bvToast.toast(
                `${removeDoubleSymbols(successMessage)}`,
                Object.assign({
                    toastClass: 'notify mb-3',
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right'
                },
                config
                )
            );
        },
        handleInfo(info,config)
        {
            const infoMessage = `${info}.`;
            this.$bvToast.toast(
                `${removeDoubleSymbols(infoMessage)}`,
                Object.assign({
                    toastClass: 'notify mb-3',
                    variant: 'info',
                    toaster: 'b-toaster-bottom-right'
                },
                config
                )
            );
        }
    }
};