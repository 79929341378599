import constants from '@/components/performance/constants';
import createOrganizationUserColumns from '@/models/OrganizationUserColumns';
import createSalesPerformancePreset from '@/models/SalesPerformancePreset';
import {USER_GROUPS} from '@/store/constants';

export const LOCAL_STORAGE_SETTINGS = "Genly_Settings";
export const LEADS_PRESET_NAME = "leadsColumnPreset";

const GroupAvailableColumns =
{
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: [],
    [USER_GROUPS.ADMINISTRATORS]: [],
    [USER_GROUPS.MANAGERS]: [],
    [USER_GROUPS.MARKETERS]:  {
        default: createOrganizationUserColumns()
            .concat(createSalesPerformancePreset().columns)
            .filter(column => column !== "isActive" && column !== "clientCpm"),
        [constants.ROUTE_NAMES.CAMPAIGN]: ["isActive"],
        [constants.ROUTE_NAMES.AD_GROUP]: ["isActive"],
        [constants.ROUTE_NAMES.AD]: ["isActive"],
    },
    [USER_GROUPS.SALES]: ["leads", "clientSpend", "clientCpl", "status", "conversionRate", "clicks", "impressions", "ctr"],
    [USER_GROUPS.MEDIA_BUYERS]: {
        default: ["isActive", "leads", "mediaPercentage", "spend", "clientSpend", "cpl", "crmDemosPerformed", "crmDemosSet", "crmWins",
            "crmWinRate", "conversionRate", "clicks", "cpc", "impressions", "ctr", "cpm", "networkLeads"],
        [constants.ROUTE_NAMES.AD]: ["status"],
        [constants.ROUTE_NAMES.AD_GROUP]: ["status", 'source', 'sources']
    },
    [USER_GROUPS.ORGANIZATION_USER]: createOrganizationUserColumns()
        .concat(createSalesPerformancePreset().columns)
        .filter(column => column !== "isActive"),
    [USER_GROUPS.ADVERTISER]: {
        default: createOrganizationUserColumns()
            .concat(createSalesPerformancePreset().columns)
            .filter(column => column !== "isActive"),
        [constants.ROUTE_NAMES.CAMPAIGN]: ["isActive"],
        [constants.ROUTE_NAMES.AD_GROUP]: ["isActive"],
        [constants.ROUTE_NAMES.AD]: ["isActive"],
    }
};

const GroupColumnsPages = {
    [USER_GROUPS.SYSTEM_ADMINISTRATORS]: {},
    [USER_GROUPS.ADMINISTRATORS]: {},
    [USER_GROUPS.MANAGERS]: {},
    [USER_GROUPS.MARKETERS]: {
        isActive: [constants.ROUTE_NAMES.CAMPAIGN, constants.ROUTE_NAMES.AD_GROUP, constants.ROUTE_NAMES.AD]
    },
    [USER_GROUPS.SALES]: {},
    [USER_GROUPS.MEDIA_BUYERS]: {
        isActive: [constants.ROUTE_NAMES.CLIENT, constants.ROUTE_NAMES.CAMPAIGN]
    },
    [USER_GROUPS.ORGANIZATION_USER]: {},
    [USER_GROUPS.ADVERTISER]: {
        isActive: [constants.ROUTE_NAMES.CAMPAIGN, constants.ROUTE_NAMES.AD_GROUP, constants.ROUTE_NAMES.AD],
    },
};

const LeadsPresetGroupedColumns = ["city", "country", "state", "zipCode"];

export {GroupAvailableColumns, GroupColumnsPages, LeadsPresetGroupedColumns};
