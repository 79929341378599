import {removeEmpty} from '@/helpers/objectUtils';
import {setDtoBase} from '@/models/DtoBase';
import {NetworkAd} from '@/models/network-ad/networkAd';
import {NetworkAdDto} from '@/models/network-ad/networkAdDto';
import {flowRight} from 'lodash';

export const networkAdDtoToObject = dto =>
{
    const object = NetworkAd(dto);
    return object;
};

export const objectToNetworkAdDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, NetworkAdDto)(data);
    return dto;
};
