export function OfferThumbnail({
    clientId,
    offerId,
    order,
    previewUrl
} = {})
{
    return {
        id: order ? `${clientId}-${offerId}-${order}` : `${clientId}-${offerId}-${new Date().getTime()}`,
        offerId,
        clientId,
        order,
        url: previewUrl
    };
}
