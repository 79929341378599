export function AdCreativeDto({
    id = null,
    type = null,
    campaignId = null,
    landingPageId = null,
    fbPageId = null,
    name = null,
    status,
    statusText,
    adTitle = null,
    shortDescription = null,
    longDescription = null,
    displayLink = null,
    callToAction = null,
    adGroupId = null,
    lpQsParams = null,

    companyName = null,
    assetIds = null,
    imageText = null,
    commentText = null
} = {})
{
    return {
        id,
        companyName,
        creativeType: type,
        cmpId: campaignId,
        lpId: landingPageId,
        fbPageId,
        name,
        status,
        statusText,
        headline: adTitle,
        // shortDescription exists only for social
        shortDescription,
        // linkDescription for social, adDescription for native
        longDescription,
        displayLink,
        netCmpGrpId: adGroupId,
        callToAction,
        lpQsParams,
        commentText,
        imageText,
        assetIds
    };
}
