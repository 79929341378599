export function TrackingParameters({
    id,
    lpId,
    clientField,
    internalField,
    type,
    value
} = {})
{
    return {
        id,
        lpId,
        field: clientField,
        internalField,
        type,
        value
    };
}
