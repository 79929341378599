import Transformer from "@/services/transformers/ITransformer";
import {RequestFactoryMapper, requestMappings} from "@/services/transformers/transformMapper";

function RequestTransformer(){}
RequestTransformer.prototype = Object.create(Transformer);
RequestTransformer.prototype = {
    ...RequestTransformer.prototype,
    transform: request =>
    {
        const {url} = request;
        const urlSplit = url.split("/");

        for(const [key, mappedAction] of Object.entries(requestMappings))
            if(urlSplit.indexOf(key) > -1)
            {
                mappedAction.data = request.data;
                const shouldTransform = mappedAction.shouldTransform();

                if(shouldTransform)
                {
                    const action = mappedAction instanceof RequestFactoryMapper ? Reflect.apply(mappedAction.transformer, this, [url]) : mappedAction.transformer;
                    mappedAction.data = Reflect.apply(action, this, [mappedAction.data]);
                }

                // we clear the added stamp, so we don't send it to the api
                mappedAction.clearStamp();
                return {
                    ...request,
                    data: mappedAction.data
                };
            }
        return request;
    }
};

export default RequestTransformer;
