export function WSMessageDto({
    // currently not used
} = {})
{
    return {
    
    };
}


