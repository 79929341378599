import {adAssetDtoToObject} from '@/models/ad-asset';
import projConfig from '@/projConfig';
import mt from '../mutationTypes';
import {deleteReq, get, post, put} from '@/services/httpService';

const state =
{
    emotionalScore:
    {
        min: null,
        max: null,
        median: null,
        mean: null,
    },
};

const mutations =
{
    [mt.SetAssetEmotionalScore](state, { min, max, median, mean })
    {
        state.emotionalScore.min = min;
        state.emotionalScore.max = max;
        state.emotionalScore.median = median;
        state.emotionalScore.mean = mean;
    },
};

const getters =
{
    imageEmotionalScore(state)
    {
        return state.emotionalScore;
    }
};

const actions =
{
    async calculateEmotionalScore({ commit }, assetUrl)
    {

        const url = `${projConfig.apiRoot}/s/assets/emotional-score?assetUrl=${encodeURIComponent(assetUrl)}`;
        commit(mt.SetLoading, true);

        try
        {
            const response = await get(url);
            commit(mt.SetLoading, false);
            if (!response || !response.data)
            {
                commit(mt.SetAssetEmotionalScore, { min: null, max: null, median: null, mean: null });
                return;
            }

            const { min, max, median, mean } = response.data;
            commit(mt.SetAssetEmotionalScore, { min, max, median, mean });
        }
        catch (e)
        {
            commit(mt.SetLoading, false);
            commit(mt.SetAssetEmotionalScore, { min: null, max: null, median: null, mean: null });
        }
    },
    async getS3UploadAssetUrl({ commit }, file)
    {
        let url = `${projConfig.apiRoot}/s/assets/create`;

        if (file.clientId)
        {
            url += `?clId=${file.clientId}`;
            if (file.name)
                url += `&name=${encodeURIComponent(file.name)}`;
            if (file.type)
                url += `&type=${file.type}`;
        }

        commit(mt.SetLoading, true);

        try
        {
            const response = await get(url);
            if (response && response.data && response.status === 200)
                return { success: true, signedUrl: response.data };
            else
                return { success: false };
        }
        catch (error)
        {
            
            console.error(error); // eslint-disable-line
            return { success: false, error: error };
        } 
        finally 
        {
            commit(mt.SetLoading, false);
        }
    },
    async uploadAssetToS3({ commit }, { url, data})
    {
        commit(mt.SetLoading, true);

        try
        {
            const response = await put(url, data.file, {headers: {'Content-type': data.type}});

            let result =  (response && response.status === 200 && response.statusText === 'OK');
            return { success: result };
        }
        catch (error)
        {
            console.error(error);
            return { success: false };
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async saveAssetToDb({ commit }, asset)
    {
        let url = `${projConfig.apiRoot}/s/assets/`;

        commit(mt.SetLoading, true);

        try
        {
            const response = await post(url, asset);

            if (response && response.data)
                return {
                    id: response.data['id'],
                    url: response.data['uri'],
                    name: response.data['name']
                };

            return null;
        }
        catch (error)
        {
            console.error(error); // eslint-disable-line
            return null;
        } 
        finally 
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadAssetData({ commit }, id)
    {
        try
        {
            commit(mt.SetLoading, true);
            const url = `/s/assets/${id}`;
            const response = await get(url);
            return response;
        }
        catch(error)
        {
            console.error(error);
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadAssetChildren({ commit }, { id: parentId, ...rest } = {})
    {
        try
        {
            commit(mt.SetLoading, true);
            const url = `/s/assets`;
            // If there is no images return empty array, so user can upload new ones on edit
            const {data = { data: []}} = await get(url, {
                params: {
                    parentId
                },
                ...rest
            }) || [];
            return data.data.map(adAssetDtoToObject);
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async deleteAsset({ commit }, { id, isThrowable = true })
    {
        try
        {
            commit(mt.SetLoading, true);
            const url = `/s/assets/${id}`;
            const response = await deleteReq(url);
            
            return response;
        }
        catch(error)
        {
            if(!isThrowable) return;
            throw error;
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async loadExternalAsset({}, {url})
    {
        console.log('loadExternalAsset');
        try
        {
            const response = await get(url, {
                responseType: "arraybuffer"
            });
            if(response && response.data)
                return response.data;
        }
        catch(error)
        {
            console.error(error);
            throw error;
        }
    }
};

const AssetsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default AssetsDataModule;
