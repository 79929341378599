import moment from "moment";
import EntityAdapter from "@/store/adapter";
import projConfig from "@/projConfig";
import mt from "@/store/mutationTypes";
import {fetch, create} from "@/repositories/TransactionsRepository";
import {transactionsDtoToObject, transactionsObjectToDto} from "@/models/transactions";

const state =
    {
        transactionHistory: EntityAdapter().createEntityAdapter(),
        totals: {},
        loading: false,
        updatedAt: moment(),

        page:
            {
                top: projConfig.itemsPerPage,
                skip: 0,
                totalCount: 0,
                totalPages: 0,
                currPage: 1
            },
        sort:
            {
                field: null,
                desc: null,
            }
    };

const mutations =
    {
        [mt.SetTransactionHistory](state, data)
        {
            state.transactionHistory = state.transactionHistory.setAll(data, state.transactionHistory);
        },
        [mt.SetTransaction](state, data)
        {
            state.transactionHistory = state.transactionHistory.addOne(data, state.transactionHistory);
        },
        [mt.SetTransactionHistoryPaging](state, page)
        {
            state.page.top = page.top;
            state.page.skip = page.skip;
            state.page.totalCount = page.totalCount;
            state.page.totalPages = Math.ceil(page.totalCount / page.top);
            state.page.currPage = (page.skip / page.top) + 1;

            this.commit(mt.SetGlobalPaging, {
                currPage: state.page.currPage,
                totalResults: page.totalCount,
                currentResults: page.currentCount,
                totalPages: state.page.totalPages
            });
        },
        [mt.SetTransactionHistoryCurrPage](state, currPage)
        {
            state.page.currPage = currPage;
        },
        [mt.SetTransactionHistorySorting](state, { field, desc })
        {
            state.sort.field = field;
            state.sort.desc = desc;
        },
        [mt.ClearTransactionHistoryData](state)
        {
            state.transactionHistory = state.transactionHistory.removeAll(state.transactionHistory);
        },
        [mt.SetTransactionHistoryStatsLastUpdatedAt](state)
        {
            state.updatedAt = moment();
        }
    };

const getters =
    {
        getTransactionHistoryIds: (state) => state.transactionHistory.ids,
        getTransactionHistoryEntities: (state) => state.transactionHistory.entities,
        getTransactionHistoryById: (state) => id => state.transactionHistory.entities[id],
        getTransactionHistory(state, getters)
        {
            return getters.getTransactionHistoryIds.map(id => getters.getTransactionHistoryEntities[id]);
        },
        getTransactionHistoryPage(state)
        {
            return state.page;
        },
        getTransactionHistorySort(state)
        {
            return state.sort;
        },
        getTransactionHistoryLastUpdatedAt(state)
        {
            return state.updatedAt;
        }
    };

const actions =
    {
        async fetchTransactionsStats({ commit, state }, filters)
        {
            try
            {
                commit(mt.SetLoading, true);
                const response = await fetch(state, filters);
                if (response && response.status === 200 && response.data)
                {
                    commit(mt.SetTransactionHistory, (response.data.data).map(transactionsDtoToObject));
                    commit(mt.SetTransactionHistoryPaging,{
                        ...response.data.pages,
                        currentCount: response.data.data.length
                    });
                }
                else
                {
                    commit(mt.SetTransactionHistory,[]);
                    commit(mt.SetTransactionHistoryPaging,{
                        top: projConfig.itemsPerPage,
                        skip: 0,
                        totalCount: 0,
                        currentCount: 0,
                        currPage: 1
                    });
                }
            }
            catch(error)
            {
                throw error || new Error('');
            }
            finally
            {
                commit(mt.SetTransactionHistoryStatsLastUpdatedAt);
                commit(mt.SetLoading, false);
            }
        },
        async createTransaction({ commit, getters }, transaction)
        {
            try
            {
                commit(mt.SetLoading, true);
                const response = await create(transactionsObjectToDto(transaction));

                if (response && response.status && response.status === 200)
                    commit(mt.SetTransactionHistory, [response.data].concat(getters.getTransactionHistory));
            }
            catch (error)
            {
                console.error(error);
                throw error;
            }
            finally
            {
                commit(mt.SetLoading, false);
            }
        }
    };

const TransactionHistoryModule = {
    state,
    mutations,
    getters,
    actions
};

export default TransactionHistoryModule;
