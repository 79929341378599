export function AdCreative({
    id,
    name,
    assetIds,
    assetSize,
    adFormat,
    audiences,
    callToAction,
    cmpId,
    clientPermissions,
    companyName,
    creativeType,
    basicCreativeType,
    details = {
        dateCreated: null,
        createdBy: "Unknown"
    },
    displayLink,
    imageText,
    fbPageId,

    image1,
    image2,
    image3,
    isActive,
    headline,
    shortDescription,
    longDescription,
    lpId,
    lpQsParams,
    netCmpGrpId,

    status,
    statusText,
    videoUrl,
    videoLength,

    // stats props
    clicks,
    clientCpl,
    clientCpm,
    clientSpend,
    conversionRate,
    cpc,
    clientCpc,
    cpl,
    cpm,
    crmContactRate,
    crmContacted,
    crmDemoSetRate,
    crmDemosPerformed,
    crmDemosSet,
    crmDisqualified,
    crmLosses,
    crmNeedsSalesFollowing,
    crmWinRate,
    crmWins,
    crmWorking,
    ctr,
    ctrStatus,
    impressions,
    leads,
    likes,
    mediaPercentage,
    netAdCount,
    networkLeads,
    postReactions,
    qualityScore,
    shares,
    showUpRate,
    spend,
    effectiveStatus,
    networkAdsIds,
    createdAt
} = {})
{
    return {
        id,
        adGroupId: netCmpGrpId,
        netCmpGrpId, // keep for legacy reasons
        campaignId: cmpId,
        landingPageId: lpId,
        assetIds,
        assetSize,
        imageText,
        adFormat,
        audiences,
        creativeType,
        basicCreativeType,
        name,
        clientPermissions,
        lpQsParams,
        details,
        fbPageId,
        image1,
        image2,
        image3,
        adTitle: headline,
        adDescription: shortDescription,
        linkDescription: longDescription,
        companyName,
        callToAction,
        displayLink,
        status,
        statusText,
        effectiveStatus,
        networkAdsIds,
        thumbnailsIds: [],
        isActive,
        isExpanded: false,
        videoUrl,
        videoLength,
        // TODO: check if these are still needed
        headline,
        shortDescription,
        longDescription,
        // END TODO
        createdAt,

        // stats props
        clicks,
        clientCpl,
        clientCpm,
        clientSpend,
        conversionRate,
        cpc,
        clientCpc,
        cpl,
        cpm,
        crmContactRate,
        crmContacted,
        crmDemoSetRate,
        crmDemosPerformed,
        crmDemosSet,
        crmDisqualified,
        crmLosses,
        crmNeedsSalesFollowing,
        crmWinRate,
        crmWins,
        crmWorking,
        ctr,
        ctrStatus,
        impressions,
        leads,
        likes,
        mediaPercentage,
        netAdCount,
        networkLeads,
        postReactions,
        qualityScore,
        shares,
        showUpRate,
        spend
    };
}
