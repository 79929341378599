import { removeEmpty } from '@/helpers/objectUtils';
import { createUrlFiltersParameters, createUrlObjectFilters } from '@/helpers/urlUtils';
import { deleteReq, get, post, put } from '@/services/httpService';
import store from '@/store/store';

const resource = "/s/network-campaign-groups";

const createFilters = sort =>
{
    const objectFilters = createUrlObjectFilters();
    const timeZoneDates = store.getters.getDateRangeFilters;
    const urlFiltersData = { ...store.state.filters, timeZoneDates };
    const filterParams = createUrlFiltersParameters(urlFiltersData, sort, 'adGroupPage');
    return {
        objectFilters,
        filterParams
    };
};

const getFilters = ({ page, sort, currPage } = {}, filters) =>
{
    let { top, skip } = page;
    const { field, desc } = sort;

    if (filters && filters.currPage !== currPage)
        skip = (filters.currPage - 1) * top;

    const filterParams = createFilters(sort);

    return removeEmpty({
        top,
        skip,
        orderBy: field,
        orderByDesc: desc,
        ...filterParams.objectFilters,
        ...filterParams.filterParams
    });
};

const fetchStats = stateParams =>
{
    return get(`${resource}/stats`, {
        params: stateParams
    });
};

const fetch = ({ id, ...rest } = {}) =>
{
    return get(`${resource}/${id}`, {
        ...rest
    });
};

const fetchByName = ({ name, top = 1000, skip = 0, campaignId = null, clientId = null, clientPermissions = null, campaignTypes = null, ...rest } = {}) =>
{
    return get(`${resource}`, {
        params: {
            // TODO: move to transformer
            adGroupName: name,
            clId: clientId,
            cmpId: campaignId,
            cmpType: campaignTypes ? campaignTypes.join(",") : null,
            top,
            skip,
            clientPermissions
        },
        ...rest
    });
};

const create = (payload = {}) =>
{
    return post(resource, payload);
};

const update = (id, payload = {}) =>
{
    return put(`${resource}/${id}`, payload);
};

const destroy = (id) =>
{
    return deleteReq(`${resource}/${id}`);
};

const toggleDelivery = ({ id, status = "" }) =>
{
    return put(`${resource}/${id}/status`, {
        status
    });
};

const toggleSchedule = ({ id }) =>
{
    return post(`${resource}/${id}/schedule`);
};

const duplicate = ({ id }) =>
{
    return post(`${resource}/${id}/duplicate`);
};


export
{
    getFilters,
    fetchStats,
    fetch,
    fetchByName,
    create,
    update,
    toggleDelivery,
    toggleSchedule,
    duplicate,
    destroy
};
