import {setDtoBase} from '@/models/DtoBase';
import { flowRight } from "lodash";
import { removeEmpty } from "@/helpers/objectUtils";
import {LandingPageThumbnail} from "@/models/landing-page-thumbnail/landingPageThumbnail";
import {LandingPageThumbnailDto} from "@/models/landing-page-thumbnail/landingPageThumbnailDto";

// used as response interfaces
const LpDto = {
    createdBy: null,
    dbId: null,
    pageId: null
};

const LandingPageThumbnailDtoResponse = {
    lp: LpDto,
    html: null
};

function createUrl(dto = LandingPageThumbnailDtoResponse)
{
    if(dto.html)
        dto.html = dto.html.match(/https?:\/\/[^\s]+/g)[0].replace('"', '');

    const getImageName = dto.html.split('/');
    const id = dto.html ? `${dto.lp.dbId}-${getImageName[getImageName.length - 1]}` : dto.lp.dbId;
    return {
        id,
        url: dto.html
    };
}

export const landingPageThumbnailDtoToObject = dto =>
{
    const object = flowRight(LandingPageThumbnail, createUrl)(dto);
    return object;
};

export const objectToLandingPageThumbnailDto = data =>
{
    const dto = flowRight(removeEmpty, setDtoBase, LandingPageThumbnailDto)(data);
    return dto;
};
