import {integrationDtoToObject} from '@/models/integration';
import mt from '../../mutationTypes';
import { get, post, put, deleteReq } from '../../../services/httpService';
import { has } from 'lodash';
import projConfig from '@/projConfig';
import {objectToLeadIntegrationFieldDto} from '@/models';

const state =
{
    integrations: [],
    page: {
        top: projConfig.itemsPerPage,
        skip: 0,
        totalCount: 0,
        totalPages: 0,
        currPage: 1
    },
    genlyLeadStatuses: {},
};

const mutations =
{
    [mt.SetGenlyLeadStatuses](state, fieldsData)
    {
        state.genlyLeadStatuses = fieldsData.data;
    },
    [mt.SetIntegrationData](state, integrationsData)
    {
        state.integrations = integrationsData.map(integrationDtoToObject);
    },
    [mt.SetIntegrationPaging](state, page)
    {
        state.page.top = page.top;
        state.page.skip = page.skip;
        state.page.totalCount = page.totalCount;
        state.page.totalPages = Math.ceil(page.totalCount / page.top);
        state.page.currPage = (page.skip / page.top) + 1;
    },
    [mt.SetIntegrationsCurrPage](state, currPage)
    {
        state.page.currPage = currPage;
    },
};

const getters =
{
    getGenlyLeadStatuses(state)
    {
        return state.genlyLeadStatuses;
    },
    getIntegrationData(state)
    {
        return state.integrations;
    },
    getIntegrationPaging(state)
    {
        return state.page;
    },
    getHasSalesForceIntegration(state, rootGetters)
    {
        const currentUserId = rootGetters.getUserOrganization;
        return state.integrations
            .filter(integration => integration.integrationTypeUncased === "salesforce" && integration.clientId === currentUserId)
            .length > 0;
    }
};

const actions =
{
    async loadGenlyLeadStatuses({ commit })
    {
        const result =
        {
            data:
            {
                "untouched": 'Untouched',
                "working": 'Working',
                "contacted": 'Contacted',
                "demoSet": 'Demo set',
                "disqualified": 'Disqualified',
                "needsSalesFollowing": 'Needs sales follow-up',
                "demoPerformed": 'Demo performed',
                "contractSent": 'Contract sent',
                "closedWin": 'Closed - win',
                "closedLoss": 'Closed - lost',
                null: 'Not set',
            },
        };

        commit(mt.SetGenlyLeadStatuses, result);
    },
    async loadIntegrationData({ commit, rootGetters })
    {
        const url = `/s/clients/integrations`;

        commit(mt.SetLoading, true);

        try
        {
            const response = await get(url);
            const isRequestSuccessful = (response && response.status === 200 && has(response, 'data'));

            if (isRequestSuccessful)
            {
                const result = response.data.data;
                commit(mt.SetIntegrationData, result);
                commit(mt.SetIntegrationPaging, {
                    ...response.data.pages,
                    currentCount: response.data.length,
                });
                return {success: true};
            }
        }
        catch (ex)
        {
            console.error(ex); // eslint-disable-line
            return {success: false};
        }
        finally
        {
            commit(mt.SetLoading, false);
        }
    },
    async createClientIntegration({ commit }, integration = {
        clientId,
        integrationType,
        accessToken,
        tokenType,
        refreshToken,
        accessUrl,
        isExpired,
        issuedAt
    })
    {
        try
        {
            const url = "/s/lead-integrations/client";
            commit(mt.SetLoading, true);
            const response = await post(url, integration);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async createIntegrationEmails({ commit }, emails = {
        clientId,
        emails
    })
    {
        try
        {
            const url = "/s/lead-integrations/email";
            commit(mt.SetLoading, true);
            const response = await post(url, emails);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async createLeadIntegration({ commit }, integration = {
        clientId,
        cmpId,
        type,
        clientIntegrationId,
        emailIntegrationId,
        url,
        body,
        isQueryString,
        status
    })
    {
        try
        {
            const url = "/s/lead-integrations";
            commit(mt.SetLoading, true);
            const response = await post(url, integration);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async createLeadIntegrationFields({ commit }, integration)
    {
        try
        {
            const url = "/s/lead-integration-fields";
            commit(mt.SetLoading, true);
            const response = await post(url, objectToLeadIntegrationFieldDto(integration));
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async updateLeadIntegrationFields({ commit }, integration)
    {
        try
        {
            const url = `/s/lead-integration-fields/${integration.id}`;
            commit(mt.SetLoading, true);
            const response = await put(url, objectToLeadIntegrationFieldDto(integration));
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async deleteLeadIntegrationFields({ commit }, id)
    {
        try
        {
            const url = `/s/lead-integration-fields/${id}`;
            commit(mt.SetLoading, true);
            const response = await deleteReq(url);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },

    async updateClientIntegration({commit}, {id, integration = {
        clientId,
        integrationType,
        accessToken,
        tokenType,
        refreshToken,
        accessUrl,
        isExpired,
        issuedAt
    }})
    {
        try
        {
            const url = `/s/lead-integrations/client/${id}`;
            commit(mt.SetLoading, true);
            const response = await put(url, integration);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async updateLeadIntegration({ commit }, {id, integration = {
        clientId,
        cmpId,
        type,
        clientIntegrationId,
        emailIntegrationId,
        url,
        body,
        isQueryString,
        status
    } })
    {
        try
        {
            const url = `/s/lead-integrations/${id}`;
            commit(mt.SetLoading, true);
            const response = await put(url, integration);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async updateIntegrationEmail({ commit }, {id, integration = {} })
    {
        try
        {
            const url = `/s/lead-integrations/email/${id}`;
            commit(mt.SetLoading, true);
            const response = await put(url, integration);
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async fetchIntegrationClientDetails({ commit }, {id, ...rest} = {})
    {
        try
        {
            const url = `/s/lead-integrations/client/${id}`;
            commit(mt.SetLoading, true);
            const response = await get(url, {
                ...rest
            });
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async fetchIntegrationEmailDetails({ commit }, {id, ...rest} = {})
    {
        try
        {
            const url = `/s/lead-integrations/email/${id}`;
            commit(mt.SetLoading, true);
            const response = await get(url, {
                ...rest
            });
            commit(mt.SetLoading, false);

            return {...response};
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    },
    async fetchIntegrationFieldsFor({ commit }, { id, ...rest } = {})
    {
        try
        {
            const url = "/s/lead-integration-fields";
            commit(mt.SetLoading, true);
            const response = await get(url, {
                params: {
                    liId: id
                },
                ...rest
            });
            commit(mt.SetLoading, false);
            if(!response) return;// throw new Error("No lead integration fields found");
            return [...response.data];
        }
        catch(error)
        {
            commit(mt.SetLoading, false);
            throw error;
        }
    }
};

const IntegrationsDataModule = {
    state,
    mutations,
    getters,
    actions
};

export default IntegrationsDataModule;
