import moment from "moment";

/**
 *
 * @param {moment} updatedAt
 */
const formatLastUpdatedAt = updatedAt =>
{
    const yesterday = moment().subtract(1, "day");
    if(updatedAt.isAfter(yesterday))
    {
        const diff = updatedAt.toNow(true);
        return `Last updated: ${diff} ago`;
    }
    const date = updatedAt.format("M/DD/YYYY h:mm A");
    return `Last updated: ${date}`;
};

const formatVideoDuration = timeInSeconds =>
{
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
    
    return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2),
    };
};

const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const convertMomentDateToTimestamp = date =>
{
    return date.clone().valueOf();
};

export {formatLastUpdatedAt, getBrowserTimezone, convertMomentDateToTimestamp, formatVideoDuration};
