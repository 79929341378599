export function LandingPageThumbnail({
    id,
    url
})
{
    return {
        id,
        url
    };
}
